import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './BrickHero.module.scss'
import GenericSection from './GenericSection'

class BrickHero extends GenericSection {
  UNSAFE_componentWillReceiveProps(newProps) {
    const { section } = this.props
    if (newProps.section.selected && !section.selected) scrollToNode(this.node)
  }

  getBackgroundImage(sectionData, spot) {
    if (!sectionData.artwork && !spot) return null
    if (sectionData.artwork) return sectionData.artwork[0].url
    if (spot) return spot.artworkUrl
  }

  render() {
    const { section, fade, spots, match } = this.props

    const spot = spots.first()
    const { metadata } = section
    const data = JSON.parse(metadata)
    const backgroundImage = this.getBackgroundImage(data, spot)
    const inlineStyle = {
      backgroundImage: `url(${backgroundImage})`,
    }

    const classNames = cx(style.item, {
      [style.highlight]: section.selected,
      [style.fade]: fade,
    })
    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match)
    return (
      <a
        id={spot && `spot_${spot.id}`}
        className={classNames}
        style={inlineStyle}
        target={target}
        href={url}
        ref={node => (this.node = node)}
      />
    )
  }
}

const ConnectedBrickHeroWithRouter = withRouter(BrickHero)
export default ConnectedBrickHeroWithRouter
