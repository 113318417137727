import { DATA, MERCHANDIZING, SECTIONS, SPOTS } from '../utils'

export function deselectSpot({ mutable, spot }) {
  mutable.setIn([MERCHANDIZING, SPOTS, DATA, spot.id, 'selected'], false)
}

export function deselectSpots({ mutable }) {
  const selected = mutable.getIn([MERCHANDIZING, SPOTS, DATA]).filter(c => c.selected)
  selected.forEach(spot =>
    deselectSpot({
      spot,
      mutable,
    })
  )
}

export function deselectSection({ mutable, section }) {
  mutable.setIn([MERCHANDIZING, SECTIONS, DATA, section.id, 'selected'], false)
}

export function deselectSections({ mutable }) {
  const selected = mutable.getIn([MERCHANDIZING, SECTIONS, DATA]).filter(c => c.selected)
  selected.forEach(section =>
    deselectSection({
      section,
      mutable,
    })
  )
}
