import React from 'react'
import { withRouter } from 'react-router-dom'
import { scrollToNode } from '../../utils'
import style from './TwinHeroItem.module.scss'

class TwinHeroItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, client } = this.props

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    return (
      <div className={style.item} ref={node => (this.node = node)} style={inlineStyle}>
        <div className={style.container}>
          <div className={style.position}>{spot.position + 1}</div>
          <div
            id={`spot_${spot.id}`}
            className={style.image}
            style={{
              background: `black url('${spot.artworkUrl}') center center no-repeat`,
              backgroundSize: 'cover',
              borderRadius: '5px',
            }}
          ></div>
          <div className={style.titleName}>
            <div>
              <span>{spot.name && spot.name}</span>
              <div className={style.detail}>
                {spot.metadata.newPrice && (
                  <div className={style.newPrice}>{spot.metadata.newPrice && spot.metadata.newPrice}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ConnectedItemWithRouter = withRouter(TwinHeroItem)
export default ConnectedItemWithRouter
