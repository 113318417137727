import React from 'react'
import style from './Grid.module.scss'
import GridItem from './GridItem'

class Grid extends React.Component {
  spots() {
    const { spots } = this.props

    return spots.filter(spot => (spot && spot.spotType === 'link') || spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <GridItem
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  render() {
    const { section, page } = this.props
    const { rootSectionsCount } = page
    return (
      <div className={style.container}>
        {section.sectionType === 'amazon::grid' && rootSectionsCount > 3 && (
          <div className={style.sectionHeader}>
            <h3>{section.name}</h3>
          </div>
        )}
        <div className={style.spotWrapper}>{this.renderSpots()}</div>
      </div>
    )
  }
}

export default Grid
