import { Button, Popover, PopoverInteractionKind, Position, Tooltip } from '@blueprintjs/core'
import React from 'react'
import LeterMultiSelectList from './LeterMultiSelectList'
import styles from './Toolbar.module.scss'

const TitleNameSelector = ({ selectedFilter, setLetterFilter, isTitlesLoading }) => {
  return (
    <div>
      <Popover
        interactionKind={PopoverInteractionKind.CLICK}
        position={Position.BOTTOM_RIGHT}
        content={<LeterMultiSelectList selectedFilter={selectedFilter} setLetterFilter={setLetterFilter} />}
      >
        <Tooltip content='Filter By Letter Or Number' position={Position.BOTTOM_LEFT}>
          <Button text='ABC' className={styles.btnDefault} disabled={isTitlesLoading} />
        </Tooltip>
      </Popover>
    </div>
  )
}

export default TitleNameSelector
