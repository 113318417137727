import { SCAN_DOWNLOADS, initialState, removeScanDownload } from './utils.js'

export default function scanDownloadReducer(state, action) {
  switch (action.type) {
    case 'LOAD_SCAN_DOWNLOADS': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([SCAN_DOWNLOADS], action.scanDownloads)
          : s.mergeIn([SCAN_DOWNLOADS], action.scanDownloads)
      })
    }
    case 'UPDATE_SCAN_DOWNLOAD': {
      return state.withMutations(s => {
        s.mergeIn([SCAN_DOWNLOADS, action.id], action.data)
      })
    }
    case 'DELETE_SCAN_DOWNLOAD': {
      const { scanDownloadId } = action
      return state.withMutations(s => {
        removeScanDownload({
          mutable: s,
          scanDownloadId,
        })
      })
    }
    default:
      return state || initialState
  }
}
