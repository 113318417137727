import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import style from './HeroCarousel.module.scss'
import Item from './Item'

function renderSpots(spots, section) {
  return spots.map(spot => {
    return (
      spot && (
        <div className={style.item} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function HeroCarousel(props) {
  const { section, spots, page } = props

  const classnames = cx({
    [style.wideContainer]: page.isRoot === true,
    [style.container]: page.isRoot === false,
  })

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    centerPadding: 0,
    variableWidth: false,
    arrows: false,
    autoplay: true,
    swipe: true,
    appendDots: dots => {
      const customDots = React.Children.map(dots, dot => {
        if (dot.props['className'] === 'slick-active') {
          return React.cloneElement(dot, {
            className: style.slickActive,
          })
        } else {
          return React.cloneElement(dot, {
            className: style.slickNormal,
          })
        }
      })
      return (
        <div style={{ bottom: '-30px' }}>
          <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
        </div>
      )
    },
    customPaging: i => (
      <div
        style={{
          display: 'block',
          textIndent: '-9999px',
          backgroundColor: 'transparent',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          border: '1px solid #fff',
          boxShadow: '1px 1px 4px rgba(0,0,0,.4)',
          overflow: 'hidden',
        }}
      >
        {i + 1}
      </div>
    ),
  }

  return (
    <div className={classnames}>
      <h2>{section.name}</h2>
      <Slider {...sliderSettings}>{renderSpots(spots, section)}</Slider>
    </div>
  )
}

export default HeroCarousel
