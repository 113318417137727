import cx from 'classnames'
import React from 'react'
import styles from './Artwork.module.css'

class Artwork extends React.Component {
  render() {
    const { className, width, height, artworkUrl } = this.props

    return (
      <div
        className={cx(styles.root, className)}
        style={{
          width: width,
          height: height,
        }}
      >
        <img
          className={styles.img}
          src={artworkUrl}
          style={{
            maxWidth: width,
            maxHeight: height,
          }}
        />
      </div>
    )
  }
}

export default Artwork
