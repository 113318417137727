import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Match, PageProps, Spot } from 'Interfaces/interfaces'
import React from 'react'
import { linkToPage } from '../utils'
import style from './TabMenu.module.scss'

interface SubMenuProps {
  spot: Spot
  spots: Spot[]
  page: PageProps
  match: Match
}

const SubMenu: React.FC<SubMenuProps> = ({ spot, spots, page, match }) => {
  const renderDropdown = (spot, spots) => {
    return (
      <div className={style.dropdownWrapper}>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM}
          popoverClassName='bp5-popover-display-none'
          content={
            <div className={style.dropdown}>
              <ul className={style.dropdownList}>
                {spots
                  .filter(spot => !!spot)
                  .map(spot => {
                    return (
                      <li className={style.dropdownItem} key={spot.id}>
                        <a href={linkToPage(spot, match)}>
                          <span>
                            {spot.name}
                            {spot.linkedPageId === page.id && <i className='bp5-icon-standard bp5-icon-tick' />}
                          </span>
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </div>
          }
        >
          <a className={style.listItemWithDropdown} id={`spot_${spot.id}`}>
            {spot.name}
            <i className='bp5-icon-large bp5-icon-caret-down' />
          </a>
        </Popover>
      </div>
    )
  }

  return renderDropdown(spot, spots)
}

export default SubMenu
