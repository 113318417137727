import React from 'react'
import { Position, Popover, PopoverInteractionKind } from '@blueprintjs/core'
import { TerritoryRecordType } from 'Records/territoryRecord'
import styles from './styles.module.scss'

type FlagTooltipProps = {
  territory: TerritoryRecordType
  showFlag: boolean
  width: number
  height: number
  offsetY: number
}

const FlagTooltip: React.FC<FlagTooltipProps> = ({ territory, showFlag, width, height, offsetY }) => {
  if (!territory || !showFlag) return null

  return (
    <Popover
      interactionKind={PopoverInteractionKind.HOVER}
      content={<div className={styles.territoryWrap}>{territory.name}</div>}
      position={Position.BOTTOM}
      modifiers={{
        preventOverflow: { enabled: false },
        flip: { enabled: false },
        offset: { enabled: true, options: { offset: [0, offsetY] } },
      }}
    >
      <div
        className={styles.flag}
        style={{
          backgroundImage: `url(${territory.flagUrl})`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    </Popover>
  )
}

export default FlagTooltip
