import { BRANDS, OPTIMIZATION_SCORES, TITLE_AUDITS } from './utils.js'

export const LOAD_OPTIMIZATION_SCORES = 'optimization_scores/LOAD'
export const LOAD_BRAND_OPTIMIZATION_SCORES = 'optimization_scores/LOAD_BRAND'

export default function optimizationScoreReducer(state, action) {
  switch (action.type) {
    case LOAD_OPTIMIZATION_SCORES: {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([OPTIMIZATION_SCORES, TITLE_AUDITS], action.optimizationScores)
          : s.mergeIn([OPTIMIZATION_SCORES, TITLE_AUDITS], action.optimizationScores)
      })
    }

    case LOAD_BRAND_OPTIMIZATION_SCORES: {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([OPTIMIZATION_SCORES, BRANDS], action.optimizationScores)
          : s.mergeIn([OPTIMIZATION_SCORES, BRANDS], action.optimizationScores)
      })
    }
    default:
      return state
  }
}
