import { Record } from 'immutable'

export interface ClientMeta {
  analytics: {
    gtm: string[]
    hotjar: string
  }
  user: {
    email: string
    username: string
    admin: boolean
    employee: boolean
  }
}

export const defaultMetaValues: ClientMeta = {
  analytics: {
    gtm: [],
    hotjar: '',
  },
  user: {
    email: '',
    username: '',
    admin: false,
    employee: false,
  }
}

export default class ClientMetaRecord extends Record(defaultMetaValues) {
  get<T extends keyof ClientMeta>(value: T): ClientMeta[T] {
    return super.get(value)
  }
}