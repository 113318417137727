import WrappedSection from 'Containers/WrappedSection'
import { PageProps, Scan } from 'Interfaces/interfaces'
import { Map } from 'immutable'
import React from 'react'
import style from './Page.module.scss'
import SectionDivider from './SectionDivider'

interface Props {
  page: PageProps
  scan: Scan
  rootSections: Map<string, any>
}

const Page: React.FC<Props> = ({ page, scan, rootSections }) => {
  return (
    <div className={style.page}>
      <div className={style.container}>
        {rootSections
          .valueSeq()
          .toArray()
          .map((s: any) => (
            <React.Fragment key={s.id}>
              {s.sectionType !== 'youtube::main_menu' && <SectionDivider />}
              <WrappedSection type={s.sectionType} scan={scan} page={page} section={s} />
            </React.Fragment>
          ))}
      </div>
    </div>
  )
}

export default Page
