import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './TabMenu.module.scss'

function renderNavSpots({ spots, match, page }) {
  const pageId = page.id

  return (
    <ul className={style.mediaNav}>
      {spots &&
        spots.map(
          spot =>
            spot && (
              <li className={`${style.listItem} ${spot.linkedPageId === pageId ? style.active : ''}`} key={spot.id}>
                <a href={linkToPage(spot, match)}>{spot.name}</a>
              </li>
            )
        )}
    </ul>
  )
}

function TabMenu(props) {
  return (
    <div className={style.root}>
      <React.Fragment>{renderNavSpots(props)}</React.Fragment>
    </div>
  )
}

export default withRouter(TabMenu)
