import { DATA, MERCHANDIZING, META, PAGES } from '../utils'

export const LOAD_MERCHANDIZING_PAGES = 'merchandizing/pages/LOAD'
export const LOAD_MERCHANDIZING_PAGE_SECTIONS = 'merchandizing/LOAD_MERCHANDIZING_PAGE_SECTIONS'
export const LOADING_PAGE_DATA = 'merchandizing/LOADING_PAGE_DATA'
export const UPDATE_MERCHANDIZING_PAGE = 'merchandizing/UPDATE_PAGE_DATA'

export default function merchandizingPageReducer(state, action) {
  switch (action.type) {
    case LOAD_MERCHANDIZING_PAGES: {
      return state.withMutations(s => {
        const { pages, reset } = action
        reset ? s.setIn([MERCHANDIZING, PAGES, DATA], pages) : s.mergeIn([MERCHANDIZING, PAGES, DATA], pages)
      })
    }

    case LOAD_MERCHANDIZING_PAGE_SECTIONS: {
      return state.withMutations(s => {
        const { pageId, sections, reset } = action
        reset
          ? s.setIn([MERCHANDIZING, PAGES, DATA, pageId, 'relationships', 'sections', 'data'], sections)
          : s.mergeIn([MERCHANDIZING, PAGES, DATA, pageId, 'relationships', 'sections', 'data'], sections)
      })
    }
    case LOADING_PAGE_DATA: {
      return state.updateIn([MERCHANDIZING, PAGES, META, 'loadingPageData'], _loadingPageData => action.loading)
    }

    case UPDATE_MERCHANDIZING_PAGE: {
      const { page } = action

      return state.withMutations(s => {
        s.setIn([MERCHANDIZING, PAGES, DATA, page.id], page)
      })
    }

    default:
      return state
  }
}
