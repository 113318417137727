import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { withRouter } from 'react-router-dom'

import style from 'Components/Merchandizing/Chili/GenresDropdown.module.scss'

class GenresDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const {
      match,
      match: { path },
    } = this.props

    const toPath = pathToRegexp.compile(path)

    const pagePath = toPath({ ...match.params, pageId: event.target.value })
    window.location = pagePath
  }

  render() {
    const { spots, page } = this.props

    return (
      spots.size > 0 && (
        <div className={style.root}>
          <select onChange={this.handleChange}>
            <option>{page.displayName}</option>
            {spots.map(
              s =>
                s && (
                  <option key={s.id} value={s.linkedPageId}>
                    {s.name}
                  </option>
                )
            )}
          </select>
        </div>
      )
    )
  }
}

export default withRouter(GenresDropdown)
