import { Button } from '@blueprintjs/core'
import React from 'react'

class SpotTableSearch extends React.Component {
  render() {
    const { filter, retrieveScanSpots, updateTitleQuery, clearTitleQuery } = this.props
    return (
      <div className='bp5-navbar-group'>
        <div className='bp5-input-group'>
          <span className='bp5-icon bp5-icon-search' />
          <input
            className='bp5-round bp5-input'
            value={filter.searchName}
            placeholder='Search'
            dir='auto'
            onChange={e => {
              updateTitleQuery(e.target.value)
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                retrieveScanSpots()
              }
            }}
          />
        </div>
        {filter.hasActiveQuery() && (
          <div className='bp5-navbar-group'>
            <div className='bp5-navbar-divider' />
            <Button
              className='bp5-icon-cross'
              onClick={() => {
                clearTitleQuery()
                retrieveScanSpots()
              }}
            >
              Clear Search
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default SpotTableSearch
