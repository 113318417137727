import ChannelRecord from 'Records/channelRecord'
import { LOAD_CHANNELS } from 'Reducers/app/channelReducer'
import { OrderedMap } from 'immutable'

export const loadChannels = channels => ({
  type: LOAD_CHANNELS,
  channels,
})

export const normalizeAndLoadChannels = data => {
  // When no data is provided, don't modify the existing channels
  if (!data || data.length === 0) return { type: 'NO_OP_CHANNELS' }

  const channels = data.reduce((memo, item) => {
    if (!item?.id) return memo

    return memo.set(
      item.id,
      new ChannelRecord({
        ...item,
      })
    )
  }, new OrderedMap())

  return loadChannels(channels)
}
