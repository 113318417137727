import { Spot } from 'Interfaces/*'
import React from 'react'
import { CanalPlusSectionTypes } from '../enums'
import style from './Item.module.scss'

interface SpotProps {
  spot: Spot
  sectionType: string
}

const SpotDetails: React.FC<SpotProps> = ({ spot, sectionType }) => {
  const renderDetails = (className: string) => (
    <p className={className}>
      <span className={style.name}>{spot?.label}</span>
      <span className={style.subtitle}>{spot?.metadata?.subtitle}</span>
      <span className={style.description}>{spot?.description}</span>
    </p>
  )

  switch (sectionType) {
    case CanalPlusSectionTypes._Container:
    case CanalPlusSectionTypes._LargeContainer:
      return renderDetails(style.spotDetails)
    case CanalPlusSectionTypes._Grid:
    case CanalPlusSectionTypes._PortraitGrid:
      return renderDetails(style.gridDetails)
    default:
      return null
  }
}

export default SpotDetails
