import { Record } from 'immutable'

export interface StudioStatistics {
  id: string
  studio: {
    id: string
    color: string
    name: string
    presentInMerchandisingFilter: boolean
  }
  totalMpv: string
  totalShareOfVoice: string
  totalSpots: number
  totalStudioSpots: number
}

export const defaultStudioStatistics: StudioStatistics = {
  id: '',
  studio: {
    id: '',
    color: '',
    name: '',
    presentInMerchandisingFilter: false,
  },
  totalMpv: '0',
  totalShareOfVoice: '0',
  totalSpots: 0,
  totalStudioSpots: 0,
}

export default class StudioStatisticsRecord extends Record(defaultStudioStatistics) {
  get<T extends keyof StudioStatistics>(value: T): StudioStatistics[T] {
    return super.get(value)
  }
}
