import PlatformTitleDetail from 'Containers/PlatformTitleDetail'
import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

class PlatformsPanel extends React.Component {
  render() {
    const { platforms, titleAudit } = this.props
    return (
      <table className={cx('bp5-table bp5-striped bp5-condensed', styles.table)}>
        <thead>
          <tr>
            <th>Platform</th>
            <th>Territory</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {platforms.map(platform => {
            return <PlatformTitleDetail platformId={platform.id} titleAudit={titleAudit} key={platform.id} />
          })}
        </tbody>
      </table>
    )
  }
}

export default PlatformsPanel
