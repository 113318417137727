import { normalizeAndLoadScanResponse } from 'Actions/scanActions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useFetchScan from './queries/useFetchScan'

const useScan = (scanId, query) => {
  const dispatch = useDispatch()
  const scanData = useFetchScan(scanId)

  useEffect(() => {
    if (!scanData.data) return

    dispatch(normalizeAndLoadScanResponse(scanData.data, query))
  }, [dispatch, query, scanData.data, scanData.isSuccess])
}

export default useScan
