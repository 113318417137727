import React from 'react'
import { withRouter } from 'react-router-dom'
import GenericSection from './GenericSection'
import styles from './Menu18.module.scss'
import MenuItem from './MenuItem'

class Menu18 extends GenericSection {
  constructor(props) {
    super(props)

    const { section } = this.props
    const { metadata } = section
    this.metadata = JSON.parse(metadata)
  }

  displayCount() {
    const { displayCount } = this.metadata
    return displayCount || 10
  }

  spotsToShow() {
    const { spots } = this.props
    const displayCount = this.displayCount()
    return spots.filter(spot => spot && spot.spotType === 'content').slice(0, displayCount)
  }

  render() {
    const { section } = this.props

    return (
      <div className={styles.root}>
        <h4 className={styles.header}>{section.name}</h4>
        <ol className={styles.list}>
          {this.spotsToShow().map(spot => {
            return spot && <MenuItem spot={spot} section={section} key={spot.id} />
          })}
        </ol>
      </div>
    )
  }
}

export default withRouter(Menu18)
