import React from "react";

const IconRenderer = ({ iconKey, iconMap, className, width, height }) => {
  if (!iconKey || !iconMap[iconKey]) return null;

  return (
    <img
      className={className}
      src={iconMap[iconKey]}
      width={width}
      height={height}
    />
  );
};

export default IconRenderer;
