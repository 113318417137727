import React from 'react'

import AmazonPage from './Amazon/Page.jsx'
import AmcTheatres from './AmcTheatres/Page.jsx'
import AppleTvPage from './AppleTv/Page.jsx'
import CanalPlus from './CanalPlus/Page.tsx'
import ChiliPage from './Chili/Page.jsx'
import FandangoNowPage from './FandangoNow/Page.jsx'
import GenericPage from './GenericScan/Page.jsx'
import GooglePlayPage from './GooglePlay/Page.jsx'
import ItunesPage from './Itunes/Page.jsx'
import Kaleidescape from './Kaleidescape/Page.jsx'
import Megogo from './Megogo/Page.tsx'
import MoviesAnywhere from './MoviesAnywhere/Page.jsx'
import Orange from './Orange/Page.tsx'
import Pathe from './Pathe/Page.tsx'
import RakutenTvPage from './RakutenTv/Page.tsx'
import RedBox from './RedBox/Page.jsx'
import RowEightMenu from './RowEight/Page.jsx'
import SFAnytimePage from './SFAnytime/Page.tsx'
import TubiPage from './Tubi/Page.jsx'
import UnsupportedPage from './UnsupportedPage.jsx'
import Viaplay from './Viaplay/Page.tsx'
import YouTubePage from './YouTube/Page.tsx'

const platformMap = {
  itunes: ItunesPage,
  google_play: GooglePlayPage,
  movies_anywhere: MoviesAnywhere,
  amazon: AmazonPage,
  chili: ChiliPage,
  fandango_now: FandangoNowPage,
  apple_tv: AppleTvPage,
  xbox: GenericPage,
  vudu: GenericPage,
  fandango_at_home: GenericPage,
  set_top_box: GenericPage,
  tubi_tv: TubiPage,
  cineplex: GenericPage,
  amctheatres: AmcTheatres,
  kaleidescape: Kaleidescape,
  row8: RowEightMenu,
  redbox: RedBox,
  youtube: YouTubePage,
  rakuten: RakutenTvPage,
  sfanytime: SFAnytimePage,
  pathe: Pathe,
  viaplay: Viaplay,
  orange: Orange,
  canalplus: CanalPlus,
  megogo: Megogo,
}

function WrappedPage(props) {
  const { type, ...rest } = props
  const Page = platformMap[type]
  return Page ? <Page {...rest} /> : <UnsupportedPage />
}

export default WrappedPage
