import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './Menu.module.scss'

function Menu({ spots, section, match }) {
  return (
    <div className={style.root}>
      <h3>{section.name}</h3>
      <ul>
        {spots.map(
          spot =>
            spot && (
              <li key={spot.id}>
                <a href={linkToPage(spot, match)}>{spot.name}</a>
              </li>
            )
        )}
      </ul>
    </div>
  )
}

Menu.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(Menu)
