import React from 'react'
import { withRouter } from 'react-router-dom'
import Item from './Item'
import style from './SingleSpotHeader.module.scss'

const SingleSpotHeader = ({ section, isMerchandiseFilteringEnabled, spots, selectedStudios }) => {
  const renderSpots = () => {
    return spots.map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={selectedStudios[spot.id]}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>{section.name}</h1>
      </div>
      {renderSpots()}
    </div>
  )
}

export default withRouter(SingleSpotHeader)
