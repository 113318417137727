import { apiRequest, readEndpoint } from 'Actions/apiActions'
import { Map, OrderedMap, fromJS } from 'immutable'

import ScanRecord from 'Records/merchandizing/scanRecord'
import { LOAD_MERCHANDIZING_SCAN, LOAD_MERCHANDIZING_SCANS } from 'Reducers/app/merchandizing/scanReducer'

const scanMapper = (scan, item) => {
  return scan.set(
    item.id,
    new ScanRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const filterMerchandizingScans = item => item.type === 'scans'

export const retrieveMerchandizingScans = clientId => dispatch => {
  return dispatch(
    readEndpoint(`merchandizing/scans?filter[clientId]=${clientId}&sort=-createdAt&limit=10&client_id=${clientId}`)
  ).then(response => {
    const { data } = response
    const scans = data.reduce(scanMapper, new OrderedMap())
    dispatch(loadMerchandizingScans(scans))
  })
}

export const retrieveMerchandizingScan = (scanId, clientId) => dispatch => {
  let endpoint = `merchandizing/scans/${scanId}`

  if (clientId) {
    endpoint = `${endpoint}?client_id=${clientId}`
  }

  return readEndpoint(endpoint).then(response => {
    const { merchandizingScan } = response
    const scan = new ScanRecord({ ...merchandizingScan })

    dispatch(loadMerchandizingScan(scan))
  })
}

export const normalizeAndLoadMerchandizingScans = data => dispatch => {
  const merchandizingScans = data.reduce((memo, item) => {
    return memo.set(item.id, new ScanRecord({ ...item }))
  }, new OrderedMap())

  return dispatch(loadMerchandizingScans(merchandizingScans))
}

export const loadMerchandizingScan = scan => ({
  type: LOAD_MERCHANDIZING_SCAN,
  scan,
})

export const loadMerchandizingScans = scans => ({
  type: LOAD_MERCHANDIZING_SCANS,
  scans,
})

export const runMerchandizingScreenshootService = (scan, spot, clientId, auditScanId) => (dispatch, getState) => {
  const state = getState(state)
  const options = {
    spotId: spot.id,
    scanId: scan.id,
    clientId: clientId,
    auditScanId: auditScanId,
  }
  const url = scan.links.get('expose_merch_screenshoot_link')
  return dispatch(apiRequest(url, 'POST', options))
}
