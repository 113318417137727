import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavbarMenu.module.scss'

class NavbarDropdown extends React.Component {
  movieDropdownBreakPoints() {
    return [0, 5, 10, 15, 20]
  }

  tvDropdownBreakPoints() {
    return [0, 5, 8, 11, 14]
  }

  divideSpots() {
    const { spots, section } = this.props

    const breakPoints =
      section && section.platformIdentifier.includes('movies')
        ? this.movieDropdownBreakPoints()
        : this.tvDropdownBreakPoints()

    return spots
      .filter(spot => !!spot)
      .reduce((acc, item, index) => {
        if (breakPoints.includes(index)) {
          return [...acc, [item]]
        } else {
          acc.splice(acc.length - 1, 1, acc[acc.length - 1].concat(item))
          return [...acc]
        }
      }, [])
  }

  renderDropdown() {
    const { spot, match } = this.props

    const spotsDividedIntoColumns = this.divideSpots()

    return (
      <Popover
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM_LEFT}
        popoverClassName='bp5-popover-display-none'
        content={
          <div className={style.dropdown}>
            {spotsDividedIntoColumns.map((newSpot, index) => {
              return <ul key={index}>{newSpot.map(spot => this.renderSpot(spot))}</ul>
            })}
          </div>
        }
      >
        <li className={style.listItem}>
          {spot && <a href={linkToPage(spot, match)}>{spot.name}</a>}
          <div className={style.dropdownIcon} />
        </li>
      </Popover>
    )
  }

  renderSpot(spot) {
    const classnames = cx(style.listItem, style.dropdownItem)

    return (
      <li key={spot.id} className={classnames}>
        <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
      </li>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

NavbarDropdown.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(NavbarDropdown)
export default ConnectedNavbarDropdownWithRouter
