import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import styles from 'Components/ScanPage/styles.module.scss'
import { List } from 'immutable'
import React from 'react'
import cx from 'classnames'

class Channel extends React.Component {
  render() {
    const { channels, actions } = this.props

    const isChannelsEmpty =
      (List.isList(channels) && channels.isEmpty()) || (Array.isArray(channels) && channels.length === 0)

    return (
      <div className={styles.channelDimension}>
        {!isChannelsEmpty ? (
          <ul className={styles.subscription}>
            {channels.map(channel => {
              if (!channel) return null

              const channelActions = actions.valueSeq().filter(action => action?.channelId === channel.id)

              return (
                <li key={channel.id} className={cx({ [styles.channelAction]: !channelActions.isEmpty() })}>
                  {channelActions.isEmpty() ? (
                    channel.name
                  ) : (
                    <Popover
                      interactionKind={PopoverInteractionKind.HOVER}
                      popoverClassName='bp5-popover-content-sizing'
                      position={Position.BOTTOM}
                      disabled={channelActions.isEmpty() && actions.isEmpty()}
                      content={
                        <div>
                          {channelActions.valueSeq().map(action => (
                            <div key={action.id}>{action.message}</div>
                          ))}
                        </div>
                      }
                    >
                      {channel.name}
                    </Popover>
                  )}
                </li>
              )
            })}
          </ul>
        ) : (
          <div className={styles.notAvailableforSubscription}> - </div>
        )}
      </div>
    )
  }
}

export default Channel
