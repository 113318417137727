import ChannelItem from 'Components/Merchandizing/Itunes/ChannelItem'
import React from 'react'
import style from './ChannelSection.module.scss'

class ChannelSection extends React.Component {
  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  render() {
    const { section, spots, isMerchandiseFilteringEnabled } = this.props

    return (
      <div className={style.root}>
        <h5 className={style.header}>{section.name}</h5>
        <div className={style.container}>
          {spots.map(
            spot =>
              spot && (
                <ChannelItem
                  key={spot.id}
                  spot={spot}
                  section={section}
                  isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
                  client={this.isStudioSelected(spot.id)}
                />
              )
          )}
        </div>
      </div>
    )
  }
}

export default ChannelSection
