import { Spot } from 'Interfaces/interfaces'
import React from 'react'
import style from './HeroCarousel.module.scss'

const CustomPaging = ({ spot }: { spot: Spot }) => (
  <div
    className={style.pagingContainer}
    style={{
      background: `url(${spot.artworkUrl || spot.metadata.coverUrl})`,
      backgroundSize: 'cover',
    }}
  >
    {spot.metadata.price && (
      <span className={style.priceBadge}>
        {spot.metadata.price.priceType} {spot.metadata.price.price} {spot.metadata.price.amountCurrency}
      </span>
    )}
  </div>
)

export default CustomPaging
