import { readEndpoint } from 'Actions/apiActions'
import LocationCandidateRecord from 'Records/locationCandidateRecord'
import { LOAD_LOCATION_CANDIDATES, SELECT_LOCATION_CANDIDATE } from 'Reducers/app/locationCandidateReducer'
import { getTitleFilters } from 'Selectors/index'
import { OrderedMap } from 'immutable'
import queryString from 'query-string'

export const loadLocationCandidates = (locationCandidates, reset) => ({
  type: LOAD_LOCATION_CANDIDATES,
  locationCandidates,
  reset,
})

export const retrieveCandidatesForPlatformTitle =
  (platformTitle, reset = true) =>
  (dispatch, getState) => {
    const state = getState()
    const filters = getTitleFilters(state)

    const options = {
      'filter[newCandidates]': filters.newCandidates,
    }

    const url = `platform_titles/${platformTitle.id}/location_candidates?`

    const queryParams = queryString.stringify(options)

    return readEndpoint(url + queryParams).then(response => {
      const data = response.locationCandidates
      const locationCandidates = data.reduce(locationCandidateRecordsMapper, new OrderedMap())
      dispatch(loadLocationCandidates(locationCandidates, reset))
    })
  }

export const locationCandidateRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new LocationCandidateRecord({
      id: item.id,
      ...item,
    })
  )
}

export const normalizeAndLoadLocationCandidates =
  (data, reset = false) =>
  dispatch => {
    if (data) {
      const candidates = data.reduce(
        (memo, item) => memo.set(item.id, new LocationCandidateRecord({ ...item })),
        new OrderedMap()
      )

      dispatch(loadLocationCandidates(candidates, reset))
    }
  }

export const selectLocationCandidate = (platformTitle, locationCandidate) => ({
  type: SELECT_LOCATION_CANDIDATE,
  platformTitleId: platformTitle.id,
  locationCandidateId: locationCandidate.id,
})
