import { PageItems, Spot } from 'Interfaces/interfaces'
import { List } from 'immutable'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { renderSpotsWithItem } from '../helpers'
import style from './DetailContainer.module.scss'
import Item from './Item'
const imdbLogo = require('./imdb.png')

type DetailContainerProps = RouteComponentProps &
  PageItems & {
    spots: List<Spot>
  }

const DetailContainer: React.FC<DetailContainerProps> = ({
  spots,
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
}) => {
  const spot = spots.first()
  if (!spot) return <div />
  const { imdbRating, year, duration, variant } = spot?.metadata

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.detailItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={style.detailContainer}>
      <div className={style.spotImg}>{spotsRender}</div>
      <div className={style.details}>
        <h1>{spot.name}</h1>
        <span>
          {imdbRating && (
            <>
              <img src={imdbLogo} alt='imdb' /> <span>{imdbRating}</span>
            </>
          )}
          {spot.genre && <span> | {spot.genre}</span>}
          {year && <span>| {year}</span>}
          {duration && <span> | {duration}</span>}
          {variant && (
            <span>
              {' '}
              | <span className={style.variant}>{variant}</span>
            </span>
          )}
        </span>
        <p>{spot.description}</p>
      </div>
    </div>
  )
}

export default withRouter(DetailContainer)
