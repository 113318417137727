import { Card } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import SectionInfo from './SectionInfo'
import PieChart from '../Graphs/PieChart'

import BigNumber from 'Components/BigNumber'
import type { ScanSummary } from './interfaces'

import { OPTIMIZATION_OVERVIEW_DESCRIPTION } from './constants'
import styles from './styles.module.scss'

type ScoreStatProps = {
  scanSummary: ScanSummary
}

const ScoreStat: React.FC<ScoreStatProps> = ({ scanSummary }) => {
  const percentage = scanSummary?.optimizationScore
  if (!scanSummary) {
    return <div data-testid='empty-div' />
  }

  return (
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <h5 className={styles.header}>
          Optimisation Overview
          <SectionInfo info={OPTIMIZATION_OVERVIEW_DESCRIPTION} />
        </h5>
        <div className={styles.scoreStatContainer}>
          <div className={styles.detailWrapper}>
            <div className={styles.cardHeading}>
              <h4 className={styles.cardSubHeading}>Total Actions</h4>
            </div>
            <div className={styles.percentageWrapper}>
              <BigNumber
                number={percentage}
                units='%'
                headingLevel={1}
                decimalPlaces={2}
                numberClassname={styles.optimizationScore}
              />
              <span
                className={cx({
                  [styles.changeAmount]: scanSummary.changeAmount === 0,
                  [styles.changeAmountUp]: scanSummary.changeAmount > 0,
                  [styles.changeAmountDown]: scanSummary.changeAmount < 0,
                })}
              >{`${scanSummary.changeAmount}%`}</span>
            </div>
          </div>
          <PieChart percentage={percentage} />
        </div>
      </Card>
    </div>
  )
}

export default ScoreStat
