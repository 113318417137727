import { PageItems } from 'Interfaces/interfaces'
import React, { useMemo } from 'react'
import { computeContainerClasses, renderSpotsWithItem } from '../helpers'
import style from './Grid.module.scss'
import Item from './Item'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const { name, metadata } = section
  const { subtitle, artwork: backroundImage, background_color } = JSON.parse(metadata as unknown as string)

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.gridItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <>
      {backroundImage && (
        <div
          style={{
            backgroundImage: `url(${decodeURIComponent(backroundImage)})`,
            backgroundColor: `#${background_color}`,
            backgroundBlendMode: 'overlay',
          }}
          className={style.sectionBackgroundImage}
        />
      )}
      <div className={containerClasses}>
        <div className={style.sectionHeader}>
          <h1>{name}</h1>
          <p>{subtitle}</p>
        </div>
        <div className={style.spotWrapper}>{spotsRender}</div>
      </div>
    </>
  )
}

export default Grid
