import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import Infinite from 'react-infinite'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import useInfiniteScroll from 'hooks/useInfiniteScroll'

import styles from './Grid.module.scss'
import cssVariables from './variables.scss'

import ArtworkTitleAuditRow from 'Containers/ArtworkTitleAuditRow.jsx'
import PlatformHeader from './PlatformHeader'

const GridContainer = styled.div`
  min-width: calc(${cssVariables.rowHeaderWidth} + ${cssVariables.gridCellTotalWidth} * ${props => props.cells});
`

const GhostRow = ({ children }) => {
  return <div className={styles.ghostRow}>{children}</div>
}

const Grid = ({
  titleAudits,
  scan,
  platforms,
  rootPath,
  client,
  platformsWithFlags,
  retrieveMoreTitleAudits,
  hasMoreTitleAuditsToLoad,
}) => {
  const { isInfiniteLoading, handleInfiniteLoad, elementInfiniteLoad } = useInfiniteScroll(
    retrieveMoreTitleAudits,
    hasMoreTitleAuditsToLoad && titleAudits?.size > 0,
    styles.spinnerWrapper
  )

  const heightsForInfinite = () => {
    return titleAudits
      .valueSeq()
      .map(title => {
        const titleHeight = title && title.titleTypeCode === 'movie' ? 200 + 15 : 150 + 15
        return titleHeight || 0
      })
      .toArray()
  }

  const headerOffset = 180
  const gridCellWidth = parseInt(cssVariables.gridCellWidth)
  const gridCellHeight = parseInt(cssVariables.gridCellHeight)

  return (
    <GridContainer className={styles.root} cells={platformsWithFlags.size}>
      <PlatformHeader platformsWithFlags={platformsWithFlags} />
      <ContainerDimensions>
        {({ width, height }) => {
          const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
          const ghostCells = Math.trunc((rowBodyWidth - platforms.size * gridCellWidth) / gridCellWidth) + 1

          const ghostRowCount = Math.max(
            0,
            Math.trunc((height - headerOffset - titleAudits.size * gridCellHeight) / gridCellHeight) + 1
          )

          const ghostRows = new Array(ghostRowCount)
            .fill(0)
            .map((_s, index) => (
              <GhostRow client={client} key={`ghost${index}`} ghostCells={ghostCells + platforms.size} />
            ))

          return (
            height && (
              <Infinite
                containerHeight={height - headerOffset}
                elementHeight={heightsForInfinite()}
                onInfiniteLoad={handleInfiniteLoad}
                infiniteLoadBeginEdgeOffset={400}
                isInfiniteLoading={isInfiniteLoading}
                loadingSpinnerDelegate={elementInfiniteLoad()}
                ghostRows={ghostRows}
              >
                {titleAudits.valueSeq().map(titleAudit => (
                  <ArtworkTitleAuditRow
                    scan={scan}
                    platforms={platforms}
                    titleAudit={titleAudit}
                    key={titleAudit.id}
                    scanId={scan.id}
                    rootPath={rootPath}
                    client={client}
                  />
                ))}
              </Infinite>
            )
          )
        }}
      </ContainerDimensions>
    </GridContainer>
  )
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
