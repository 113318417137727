import { Icon } from '@blueprintjs/core'
import { Scan } from 'Interfaces/*'
import React from 'react'
import RadioInput from '../PlatformAndTerritoryListNew/RadioInput'
import { CHEVRON_DOWN } from './constants'

import styles from './Toolbar.module.scss'

type SingleScanProps = {
  scan: Scan
}

const SingleScan: React.FC<SingleScanProps> = ({ scan }) => (
  <div className='bp5-menu'>
    <span className={styles.platformName}>
      {scan?.platformName}
      <Icon icon={CHEVRON_DOWN} className={styles.chevronIcon} />
    </span>
    <ul className='bp5-menu'>
      <li>
        <RadioInput
          label={`${scan?.platformName}, ${scan?.territoryCode}`}
          value={scan?.platformName}
          checked={true}
          onChange={() => {}}
        />
      </li>
    </ul>
  </div>
)

export default SingleScan
