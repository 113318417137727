import cx from 'classnames'
import React, { useEffect, useRef } from 'react'
import style from './index.module.scss'

type CheckboxInputType = {
  checked: boolean
  onChange: (e?: React.ChangeEvent) => void
  label: string
  disabled?: boolean
}

const CheckboxInput: React.FC<CheckboxInputType> = ({ checked, onChange, label, disabled }) => {
  const checkboxRef = useRef(null)

  useEffect(() => {
    if (checkboxRef.current) {
      requestAnimationFrame(() => {
        checkboxRef.current!.checked = checked
      })
    }
  }, [checked])

  return (
    <label className={cx('bp5-label', style.checkboxWrapper)}>
      <input
        ref={checkboxRef}
        className='bp5-checkbox'
        type='checkbox'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onChange()
        }}
      >
        {label}
      </div>
    </label>
  )
}

export default CheckboxInput
