import styles from 'Components/ScanPage/SidePanel.module.scss'
import cx from 'classnames'
import React from 'react'

interface PriceLegendItemProps {
  circleStyle: string
  text: string
}

const PriceLegendItem: React.FC<PriceLegendItemProps> = ({ circleStyle, text }) => (
  <div className={styles.legendRow}>
    <div className={cx(styles.circle, circleStyle)} />
    <div>{text}</div>
  </div>
)

export default PriceLegendItem
