import { saveAuditActionState } from 'Actions/auditActions'
import PriceRecord from 'Records/priceRecord'
import { Map, OrderedMap, fromJS } from 'immutable'

export const loadPrices = (prices, reset) => ({
  type: 'LOAD_PRICES',
  prices,
  reset,
})

export const priceRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new PriceRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadPrices = (data, reset) => {
  const prices = data.reduce((memo, item) => {
    return memo.set(item.id, new PriceRecord({ ...item }))
  }, new OrderedMap())

  return loadPrices(prices, reset)
}

export const applyEmaPriceToAll = (price, actions, title) => dispatch => {
  const auditActions = actions

  auditActions.forEach(auditAction => {
    if (
      auditAction.status === 'pending' &&
      auditAction.name === 'Price' &&
      auditAction.format === price.format &&
      auditAction.license.toLowerCase() === price.offerType
    ) {
      dispatch(saveAuditActionState(auditAction, 'ready', title))
    }
  })
}
