import { Spot } from 'Interfaces/interfaces'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './SubheadingContainer.module.scss'

type SubheadingContainerProps = RouteComponentProps & {
  spots: Spot[]
}

const SubheadingContainer: React.FC<SubheadingContainerProps> = ({ spots, match }) => {
  const spot = [...spots][0]

  return (
    <div className={style.container}>
      <p>
        {spot.name} <a href={linkToPage(spot, match)}>{spot.label}</a>
      </p>
    </div>
  )
}

export default withRouter(SubheadingContainer)
