import cx from 'classnames'
import React from 'react'

type RadioInputType = {
  checked: boolean
  onChange: () => void
  label: string
  value: string
  disabled?: boolean
  className?: string
}

const RadioInput: React.FC<RadioInputType> = ({ checked, onChange, label, value, disabled, className }) => (
  <>
    <label className={cx('bp5-control bp5-radio', className)}>
      <input type='radio' checked={checked} disabled={disabled} onChange={onChange} value={value} />
      <span className='bp5-control-indicator' />
      {label}
    </label>
  </>
)

export default RadioInput
