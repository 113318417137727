import { ASSOCIATED_MERCHANDIZING_SCANS, STUDIO_STATISTICS, PLATFORM_STATISTICS, initialState } from './utils.js'

export const LOAD_ASSOCIATED_MERCHANDIZING_SCANS = 'associatedMerchandizingScans/LOAD'
export const TOGGLE_ASSOCIATED_MERCHANDISING_SCAN = 'associatedMerchandizingScans/TOGGLE_ASSOCIATED_MERCHANDISING_SCAN'
export const LOAD_STATISTICS = 'associatedMerchandizingScans/LOAD_STATISTICS'

const handlers = {
  [LOAD_ASSOCIATED_MERCHANDIZING_SCANS]: (state, action) =>
    state.withMutations(s => {
      s.mergeIn([ASSOCIATED_MERCHANDIZING_SCANS], action.associatedMerchandizingScans)
    }),

  [TOGGLE_ASSOCIATED_MERCHANDISING_SCAN]: (state, { amsIds }) =>
    state.withMutations(s => {
      amsIds.forEach(id => {
        s.updateIn([ASSOCIATED_MERCHANDIZING_SCANS, id, 'selected'], selected => !selected)
      })
    }),

  [LOAD_STATISTICS]: (state, { studioStatistics, platformStatistics }) =>
    state.withMutations(s => {
      if (studioStatistics) s.setIn([STUDIO_STATISTICS], studioStatistics)
      if (platformStatistics) s.setIn([PLATFORM_STATISTICS], platformStatistics)
    }),
}

export default function associatedMerchandizingScansReducer(state = initialState, action) {
  return handlers[action?.type]?.(state, action) ?? state
}
