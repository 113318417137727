import { Spot } from 'Interfaces/interfaces'
import React from 'react'
import style from './Item.module.scss'
const imdbLogo = require('./imdb.png')

type HeroCarouselDetailsProps = {
  spot: Spot
  imdbRating: string
  year: string | number
  duration: string
}

const HeroCarouselDetails: React.FC<HeroCarouselDetailsProps> = ({ spot, imdbRating, year, duration }) => (
  <div className={style.heroCarouselDetails}>
    <h1>{spot.name}</h1>
    <span className={style.spotInfo}>
      {imdbRating && (
        <span className={style.imdbDetails}>
          <img src={imdbLogo} alt='imdb' /> <span>{imdbRating} | </span>
        </span>
      )}
      {spot.genre && <span>{spot.genre} | </span>}
      {year && <span>{year} | </span>}
      {duration && <span>{duration}</span>}
    </span>
    <p className={style.description}>{spot.description}</p>
  </div>
)

export default HeroCarouselDetails
