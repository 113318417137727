import { Section } from 'Interfaces/*'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { CanalPlusSectionTypes } from '../enums'
import style from './BannerImage.module.scss'

type BannerImageProps = {
  section: Section
}

const BannerImage: React.FC<BannerImageProps> = ({ section }) => {
  const { subtitle, artwork } = JSON.parse(section.metadata as string)
  const backgroundStyle = {
    backgroundImage: `url('${decodeURIComponent(artwork)}')`,
  }
  const shouldRenderHeader = useMemo(() => section.sectionType === CanalPlusSectionTypes._BannerImage, [section])
  const shouldRenderShadow = useMemo(() => section.sectionType === CanalPlusSectionTypes._HeaderImage, [section])
  const containerClassNames = useMemo(
    () =>
      cx({
        [style.bannerImageContainer]: section.sectionType === CanalPlusSectionTypes._BannerImage,
        [style.headerImageContainer]: section.sectionType === CanalPlusSectionTypes._HeaderImage,
      }),
    [section]
  )

  return (
    <div className={containerClassNames}>
      {shouldRenderHeader && (
        <div className={style.header}>
          <h1>{section.name}</h1>
          <p>{subtitle}</p>
        </div>
      )}
      <div className={style.backgroundImage} style={backgroundStyle}>
        {shouldRenderShadow && <div className={style.backgroundShadow} />}
      </div>
    </div>
  )
}

export default BannerImage
