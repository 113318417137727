import { apiRequest, readEndpoint } from 'Actions/apiActions'
import { getSelectedScanFromLocalStorage } from 'Actions/scanActions'
import { DownloadType } from 'Hooks/useDownloads'
import ScanDownloadRecord from 'Records/scanDownloadRecord'
import {
  getClient,
  getScanFilters,
  getSelectedPlatforms,
  getSelectedScan,
  getSelectedTerritories,
} from 'Selectors/index'
import { OrderedMap } from 'immutable'
import { normalizeAndLoadScanDownloads as normalizeAndLoadScanMerchDownloads } from './merchandizing/scanDownloadActions'

export const loadScanDownloads = (scanDownloads, reset = false) => ({
  type: 'LOAD_SCAN_DOWNLOADS',
  scanDownloads,
  reset,
})

export const retrieveScanDownloads =
  (type = 'audit') =>
  (dispatch, getState) => {
    const state = getState()
    const scan = getSelectedScan(state)
    const scanId = scan.id || getSelectedScanFromLocalStorage()

    return readEndpoint(`scans/${scanId}/scan_downloads?type=${type}`).then(response => {
      if (type === 'audit') return dispatch(normalizeAndLoadScanDownloads(response.scanDownloads, true))

      return dispatch(normalizeAndLoadScanMerchDownloads(response.scanDownloads, true))
    })
  }

export const retrieveScanDownloadsForClient = clientId => readEndpoint(`clients/${clientId}/scan_downloads`)

export const updateScanDownload = scanDownload => dispatch => {
  dispatch({
    type: 'UPDATE_SCAN_DOWNLOAD',
    id: scanDownload.id,
    data: {
      ...scanDownload,
    },
  })
}

export const deleteScanDownloadFromState = scanDownloadId => ({
  type: 'DELETE_SCAN_DOWNLOAD',
  scanDownloadId,
})

export const normalizeAndLoadScanDownloads =
  (data, reset = false) =>
  dispatch => {
    const scanDownloads = data.reduce((memo, item) => {
      return memo.set(item.id, new ScanDownloadRecord({ ...item }))
    }, OrderedMap())

    dispatch(loadScanDownloads(scanDownloads, reset))
  }

export const createScanDownload = (type, merchandizingScanId) => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const client = getClient(state)
  const filter = getScanFilters(state)
  const fileRandomKey = String(Date.now() / 1000)

  const url = `clients/${client.id}/scan_downloads`

  let options: any = {
    download_type: type,
    file_key: fileRandomKey,
    platforms: getSelectedPlatforms(state)
      .valueSeq()
      .map(p => p.id)
      .toArray(),
    territories: getSelectedTerritories(state)
      .valueSeq()
      .map(t => t.id)
      .toArray(),
    scan_id: scan.id,
  }

  if (type === DownloadType.Merchandizing) {
    options['merchandizing_scan_id'] = merchandizingScanId
  } else if (type === DownloadType.Audit) {
    options = {
      ...options,
      ...filter.getAuditDownloadParams(state),
    }
  } else if (type === DownloadType.Artwork) {
    options = {
      ...options,
      ...filter.getArtworkDownloadParams(state),
    }
  }

  return dispatch(apiRequest(url, 'POST', options))
}

export const cancelDownload = downloadId => (dispatch, getState) => {
  const client = getClient(getState())
  return dispatch(apiRequest(`clients/${client.id}/scan_downloads/${downloadId}/cancel`, 'POST'))
}
