import { Map, Record } from 'immutable'

export default class PublisherRecord extends Record({
  id: 0,
  name: '',
  url: '',
  logoUrl: '',
  territoryId: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
}) {}
