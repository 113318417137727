import { normalizeAndLoadArtworkComparisons } from 'Actions/artworkComparisonActions'
import AuditArtworkRecord from 'Records/auditArtworkRecord'
import { Map, OrderedMap, fromJS } from 'immutable'

export const loadAuditArtworks = (auditArtworks, reset) => ({
  type: 'LOAD_AUDIT_ARTWORKS',
  auditArtworks,
  reset,
})

export const auditArtworkRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AuditArtworkRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadAuditArtworks = (data, reset) => dispatch => {
  const artworkComparisons = data
    .map(artwork => artwork.artworkComparison)
    .flat()
    .filter(ac => !!ac)

  const auditArtworks = data.reduce((memo, item) => {
    const { artworkComparison } = item
    if (artworkComparison) item.artworkComparisonId = artworkComparison.id

    if (artworkComparison?.previousArtwork) {
      return memo
        .set(
          artworkComparison?.previousArtwork.id,
          new AuditArtworkRecord({
            ...artworkComparison?.previousArtwork,
          })
        )
        .set(
          item.id,
          new AuditArtworkRecord({
            ...item,
          })
        )
    }

    return memo.set(
      item.id,
      new AuditArtworkRecord({
        ...item,
      })
    )
  }, new OrderedMap())

  dispatch(normalizeAndLoadArtworkComparisons(artworkComparisons, reset))
  return dispatch(loadAuditArtworks(auditArtworks, reset))
}
