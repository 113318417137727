import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import Item from './Item'
import style from './StaticSection.module.scss'

class StaticSection extends React.Component {
  render() {
    const { section, match } = this.props
    const metadata = section.metadata || {}

    const hasBackgroundImage = !!metadata.background_image
    const sectionType = section.sectionType

    const primarySection = ['vudu::primary', 'fandango_at_home::primary'].includes(sectionType)

    const buttonLabelSpot = this.buttonLabelSpot()

    const classNames = cx(style.root, {
      [style.vuduContentList]: ['vudu::contentllist', 'fandango_at_home::contentllist'].includes(sectionType),
      [style.vuduPrimaryList]: primarySection,
      [style.vuduGrid]: ['vudu::primary', 'vudu::grid'].includes(sectionType),
    })

    const backroungImageStyle = {}

    if (hasBackgroundImage) {
      backroungImageStyle.background = `url(${metadata.background_image}) center center no-repeat`
      backroungImageStyle.backgroundSize = '100% 100%'
      backroungImageStyle.display = 'block'
    }

    const spotListStyles = cx(style.spotList, {
      [style.showAll]: this.isSeeMorePage(),
    })

    return (
      <div className={classNames} style={backroungImageStyle}>
        {this.renderSectionHeader()}
        <div className={spotListStyles}>{this.renderSectionContent(primarySection)}</div>
        {sectionType === 'vudu::grid' && buttonLabelSpot && (
          <a className={style.vuduSeeTheSpot} href={linkToPage(buttonLabelSpot, match)}>
            {buttonLabelSpot.name}
          </a>
        )}
      </div>
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  renderSectionHeader = () => {
    const { section, match } = this.props
    const sectionType = section.sectionType
    const metadata = JSON.parse(section.metadata)
    const seeAllSpot = this.seeAllSpot()
    return (
      <div className={style.sectionHeader}>
        {sectionType === 'vudu::grid' && (
          <div className={style.heading}>
            <h1>{section.name}</h1>
            <h3>{metadata.subtitle}</h3>
          </div>
        )}
        {sectionType !== 'vudu::grid' && (
          <div className={style.heading}>
            <h3>{section.name}</h3>
          </div>
        )}
        <div className={style.seeMoreLink}>
          {seeAllSpot && <a href={linkToPage(seeAllSpot, match)}>{seeAllSpot.name}</a>}
        </div>
      </div>
    )
  }

  renderSectionContent(primarySection) {
    const { section } = this.props

    return this.spots(primarySection).map(
      spot =>
        spot && (
          <div key={spot.id} className={style.spotItem}>
            <Item
              key={spot.id}
              spot={spot}
              isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
              studio={this.isStudioSelected(spot.id)}
              section={section}
            />
          </div>
        )
    )
  }

  spots(primarySection) {
    const { spots } = this.props

    return primarySection ? spots.filter(spot => spot) : spots.filter(spot => spot && spot.spotType === 'content')
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  seeAllSpot() {
    return this.links().find(spot => spot && ['See All', 'Explore All', 'View All'].includes(spot.name))
  }

  buttonLabelSpot() {
    return this.links().find(spot => spot && ['button_label'].includes(spot.label))
  }

  isSeeMorePage() {
    const { section } = this.props
    return [
      'vudu::contentllist',
      'vudu::primary',
      'vudu::grid',
      'fandango_at_home::contentllist',
      'fandango_at_home::primary',
      'fandango_at_home::grid',
    ].includes(section.sectionType)
  }
}

StaticSection.propTypes = {
  spots: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object,
  selectedStudios: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default withRouter(StaticSection)
