import { PageItems } from 'Interfaces/interfaces'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import style from './SubHeadingContainer.module.scss'

type SubHeadingContainer = RouteComponentProps & PageItems

const SubHeadingContainer: React.FC<SubHeadingContainer> = ({ section }) => {
  const metadata = JSON.parse(section.metadata as string) || {}

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>{section.name}</h1>
      </div>
      {metadata.subtitle && (
        <div className={style.subheading}>
          <h2>{metadata.subtitle}</h2>
        </div>
      )}
    </div>
  )
}

export default SubHeadingContainer
