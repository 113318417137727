import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './Item.module.scss'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, studio, section, isMerchandiseFilteringEnabled, match } = this.props

    const inlineStyle = {
      background: `black url(${spot.artworkUrl}) center center no-repeat`,
      backgroundSize: '100% 100%',
      display: 'block',
    }

    if (studio) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = studio.color
      inlineStyle.boxShadow = `0 0 10px ${studio.color}`
    }
    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !studio),
      [style.highlight]: spot.selected || studio,
    })

    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match) || (spot && spot.url)

    return spot.spotType === 'link' ? (
      <a
        id={`spot_${spot.id}`}
        className={classNames}
        style={inlineStyle}
        target={target}
        href={url}
        ref={node => (this.node = node)}
      />
    ) : (
      <div id={`spot_${spot.id}`} className={classNames} style={inlineStyle} ref={node => (this.node = node)} />
    )
  }
}

Item.propTypes = {
  spot: PropTypes.object,
  studio: PropTypes.object,
  section: PropTypes.object,
  match: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
  type: PropTypes.string,
}

export default withRouter(Item)
