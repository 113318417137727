import cx from 'classnames'
import React from 'react'
import styles from './style.module.scss'

const SortMenuItem = ({ name, selected, direction, onClick }) => {
  const menuClassnames = cx('bp5-menu-item', {
    'bp5-icon-small-tick': selected,
    [styles.labelWrapper]: true,
  })

  return (
    <li>
      <a className={menuClassnames} onClick={onClick}>
        {name}
        <span className='bp5-menu-item-text'>{direction}</span>
      </a>
    </li>
  )
}

class TitleSort extends React.Component {
  render() {
    const { filter, setTitleAuditsSortParams, sortingOptions } = this.props

    return (
      <ul className={cx('bp5-menu', styles.menuWrapper)}>
        <li className='bp5-menu-header'>
          <strong>Sort by</strong>
        </li>
        {Object.keys(sortingOptions).map(name => (
          <SortMenuItem
            key={sortingOptions[name]}
            name={name}
            selected={isSelected(filter, sortingOptions[name])}
            direction={currentSortDirection(filter, sortingOptions[name])}
            onClick={() => setTitleAuditsSortParams(sortingOptions[name], sortDirection(filter, sortingOptions[name]))}
          />
        ))}
      </ul>
    )
  }
}

const isSelected = (filter, field) => {
  return filter.titleAuditsSortColumn === field
}

const sortDirection = (filter, field) => {
  return isSelected(filter, field) && filter.titleAuditsSortDirection === 'asc' ? 'desc' : 'asc'
}

const currentSortDirection = (filter, field) => {
  return isSelected(filter, field) && (filter.titleAuditsSortDirection === 'asc' ? 'Asc' : 'Desc')
}

export default TitleSort
