import {
  getAuditActions,
  getAuditArtworks,
  getAuditExceptions,
  getAuditPrices,
  getPlatformTitles,
} from 'Selectors/index'
import { List, Record } from 'immutable'
import { Map as ImmutableMap } from 'immutable'
import { createSelector } from 'reselect'
import { UpdateableMixin } from './mixins'
import { getRelationshipData } from './utils'

class AuditRecord extends Record({
  id: 0,
  scanId: 0,
  platformTitleId: 0,
  titleId: 0,
  platformId: 0,
  pricesIds: [],
  artworksIds: [],
  links: new ImmutableMap(),
  meta: new ImmutableMap(),
  relationships: new ImmutableMap(),
  selected: false,
  state: '',
  isFree: false,
}) {
  artworks(state) {
    const auditArtworks = getAuditArtworks(state)

    return getRelationshipData(auditArtworks, this.artworksIds)
  }

  keyArtwork(state) {
    return this.artworks(state)
      .filter(a => a && a.isKey())
      .first()
  }

  prices(state) {
    const auditPrices = getAuditPrices(state)

    return getRelationshipData(auditPrices, this.pricesIds)
  }

  actions(state) {
    this.getOurActions =
      this.getOurActions ||
      createSelector(getAuditActions, actions => actions.filter(action => action.auditId === this.id))

    return this.getOurActions(state, { relationships: this.relationships })
  }

  auditExceptions(state) {
    this.getOurAuditExceptions =
      this.getOurAuditExceptions ||
      createSelector(getAuditExceptions, auditExceptions =>
        auditExceptions.filter(auditException => auditException.platformTitleId === this.platformTitleId)
      )

    return this.getOurAuditExceptions(state, {
      relationships: this.relationships,
    })
  }

  platformTitle(state) {
    const platformTitles = getPlatformTitles(state)
    return platformTitles.get(this.platformTitleId)
  }

  channels(state) {
    const platformTitle = this.platformTitle(state)
    return platformTitle ? platformTitle.channels(state) : new List()
  }

  isAvailable() {
    return this.state === 'processed'
  }

  isAvod() {
    return this.isFree
  }

  isUnavailable() {
    return this.state === 'unavailable'
  }
}

export default UpdateableMixin(AuditRecord)
