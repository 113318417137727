import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { linkToPage } from '../utils'
import style from './CineplexStandardCarousel.module.scss'
import Item from './Item'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class StandardCarousel extends React.Component {
  render() {
    const { section, match } = this.props

    const seeAllSpot = this.seeAllSpot()

    const itemClassNames = cx(style.container, {
      [style.containerWithOutImage]: this.isFirstSection(),
    })

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 7,
      slidesToShow: 3,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={itemClassNames}>
        <div className={style.heading}>
          {seeAllSpot && (
            <a href={linkToPage(seeAllSpot, match)}>
              <h2>
                {section.name} <span className='bp5-icon-standard bp5-icon-chevron-right bp5-align-right'></span>
              </h2>
            </a>
          )}
        </div>
        <Slider {...sliderSettings} className={style.alignSpotsLeft}>
          {this.renderSpots()}
        </Slider>
      </div>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && (spot.spotType === 'content' || spot.name.includes('SEE MORE' || 'SEE ALL')))
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  isFirstSection() {
    const { index } = this.props

    return index === 0
  }

  seeAllSpot() {
    const { section } = this.props

    return section.sectionType === 'cineplex::cardscarousel'
      ? this.links().first(spot => spot)
      : this.links().find(spot => spot && ['SEE ALL', 'SEE MORE', 'View All', ''].includes(spot.name))
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default withRouter(StandardCarousel)
