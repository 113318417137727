import WrappedSection from 'Containers/WrappedSection'
import React from 'react'

import style from './Submenu.module.scss'

class Submenu extends React.Component {
  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }

  render() {
    return <div className={style.submenu}>{this.renderSubSections()}</div>
  }
}

export default Submenu
