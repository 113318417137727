import { Icon } from '@blueprintjs/core'
import WrappedSection from 'Containers/WrappedSection'
import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { checkCategoryAndBlue, renderMenuSpots } from '../helpers'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'
import logo from './logo.png'

class NavBar extends React.Component {
  renderLink = spot => {
    const { subSections, scan, page, match } = this.props

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    const { isCategory, isBlue } = checkCategoryAndBlue(spot)

    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            )
        )
      )
    }

    return (
      spot && (
        <li
          className={cx(style.listItem, {
            [style.alignRight]: isCategory,
            [style.blueBtn]: isBlue,
          })}
        >
          <a href={linkToPage(spot, match)}>
            {!isCategory ? spot.name : <Icon icon='grid' className={style.iconGrid} />}
          </a>
        </li>
      )
    )
  }

  render() {
    const { spots } = this.props

    return (
      <div className={style.container}>
        <div className={style.navbarWrapper}>
          <ul className={style.list}>
            <li className={style.logo}>
              <a href='#'>
                <img alt='Amazon' data-selenium='site-logo' src={logo} />
              </a>
            </li>
            {renderMenuSpots(spots, this.renderLink)}
          </ul>
        </div>
      </div>
    )
  }
}

export default withRouter(NavBar)
