import cx from 'classnames'
import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import Background from './Background'
import Card from './Card'
import SeeMore from './SeeMore'

import style from './GenericContainer.module.scss'
import cssVariables from './variables.scss'

class GenericContainer extends React.Component {
  render() {
    const { section } = this.props
    const metadata = section.metadata || {}

    const classnames = cx(style.root, {
      [style.tallCover]: metadata.cover_type === 'tall-cover',
      [style.squareCover]: metadata.cover_type === 'square-cover',
      [style.halfCover]: metadata.cover_type === 'half-cover',
    })

    const cardListStyles = cx(style.cardList, {
      [style.hasBackground]: metadata.background_image,
      [style.showAll]: !this.seeMore(),
    })

    return (
      <div className={classnames}>
        <div className={style.inner}>
          <ContainerDimensions>
            {({ width }) => {
              const cards = Math.min(Math.floor(width / parseInt(cssVariables.cardWidthWithPadding)), this.spots().size)
              const headerStyle = {
                width: `calc(${cssVariables.cardWidthWithPadding}*${cards})`,
              }
              return (
                <div className={style.header} style={headerStyle}>
                  <h2 className={style.title}>
                    {section.name}
                    <span>{this.seeMore() && <SeeMore spot={this.seeMore()} />}</span>
                  </h2>
                  {metadata.subtitle && <div className={style.subtitle}>{metadata.subtitle}</div>}
                </div>
              )
            }}
          </ContainerDimensions>
          {metadata.background_image && <Background section={section} />}
          <div className={cardListStyles}>
            {metadata.background_image && <div className={style.spacer} />}

            {this.renderSpots()}
          </div>
        </div>
      </div>
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  renderSpots() {
    const { section } = this.props
    return this.spots().map(
      spot =>
        spot && (
          <Card
            key={spot.id}
            spot={spot}
            isMerchandiseFilteringEnabled={this.props.isMerchandiseFilteringEnabled}
            client={this.isStudioSelected(spot.id)}
            section={section}
          />
        )
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  seeMore() {
    const links = this.links()

    return links.find(link => link.name === 'See more')
  }
}

export default GenericContainer
