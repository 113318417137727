import { LOADING_MERCHANDIZING_PLATFORM_TITLES } from 'Reducers/app/merchandizing/platformTitleReducer'
import { STUDIOS, initialState } from './utils.js'
export const HIGHLIGHT_TITLES = 'clients/HIGHLIGHT_TITLES'
export const FILTERING_SPOTS_FOR_STUDIO = 'clients/FILTERING_SPOTS_FOR_STUDIO'
export const FILTERED_SPOTS_FOR_STUDIO = 'clients/FILTERED_SPOTS_FOR_STUDIO'

export default function studioReducer(state, action) {
  switch (action.type) {
    case 'LOAD_STUDIOS': {
      return state.withMutations(s => {
        action.reset ? s.setIn([STUDIOS], action.studios) : s.mergeIn([STUDIOS], action.studios)
      })
    }
    case HIGHLIGHT_TITLES: {
      const { studioIds } = action
      return state.withMutations(s => {
        s.updateIn([STUDIOS, studioIds, 'higlightedTitles'], higlightedTitles => !higlightedTitles)
      })
    }
    case LOADING_MERCHANDIZING_PLATFORM_TITLES: {
      return state.withMutations(s => {
        s.updateIn(
          [STUDIOS, action.studioId, 'loadingMerchandizingPlatformTitles'],
          loadingMerchandizingPlatformTitles => !loadingMerchandizingPlatformTitles
        )
      })
    }

    default:
      return state || initialState
  }
}
