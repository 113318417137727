import { PageItems, Spot } from 'Interfaces/interfaces'
import { List } from 'immutable'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { renderSpotsWithItem } from '../helpers'
import Arrow from './Arrow'
import style from './CategoryHeroCarousel.module.scss'
import Item from './Item'

type CategoryHeroCarousel = RouteComponentProps &
  PageItems & {
    spots: List<Spot>
  }

const CategoryHeroCarousel: React.FC<CategoryHeroCarousel> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
}) => {
  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const sliderSettings = {
    dots: true,
    autoplay: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: false,
    speed: 1000,
    arrows: true,
    nextArrow: <Arrow right section={section.sectionType} />,
    prevArrow: <Arrow left section={section.sectionType} />,
    appendDots: dots => {
      const activeIndex = dots.findIndex(dot => dot.props.className === 'slick-active')
      const customDots = dots.map((dot, index) =>
        React.cloneElement(dot, {
          className: index === activeIndex ? style.dotActive : style.dot,
        })
      )

      return (
        <div className={style.dotsContainer}>
          <ul className={style.dotsList} style={{ marginLeft: `-${activeIndex * 220}px` }}>
            {customDots}
          </ul>
        </div>
      )
    },
    customPaging: i => {
      const spot = spots.toArray().at(i)
      return (
        <div
          className={style.pagingContainer}
          style={{
            background: `url(${spot.artworkUrl || spot.metadata.coverUrl})`,
            backgroundSize: 'cover',
          }}
        />
      )
    },
  }

  return (
    <div className={style.container}>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(CategoryHeroCarousel)
