import { getCSRF } from 'Selectors/index'
import { reportError } from '../utils/errors'
import { showErrorMessageToast } from './uiActions'

const ENDPOINT_PATH = `${process.env.REACT_APP_CORE_API_URL}/api/v3`

const isJsonResponse = response => response.headers.get('Content-Type')?.includes('application/json')

const isSuccessResponse = response =>
  response.status >= 200 && response.status < 300 && (response.status === 204 || isJsonResponse(response))

export const readEndpoint = (url, options = {}) =>
  fetch(`${ENDPOINT_PATH}/${url}`, { ...options, credentials: 'include' }).then(async response =>
    isSuccessResponse(response) ? handleSuccessResponse(response) : responseError(response)
  )

const handleSuccessResponse = response => (response.status === 204 ? response : response.json())

const responseError = async response => {
  if (response.status === 401) {
    let url = process.env.REACT_APP_CORE_API_URL
    if (window.location.search.length) url = `${url}${window.location.search}`

    window.location = url
    return
  }

  const body = await response.json()

  if (response.status >= 500) {
    const errorMessage = `error: ${JSON.stringify(body.exception)}, status: ${response.status}, status text: ${
      response.statusText
    }, url: ${response.url}`

    const error = new Error(`API client received error response, ${errorMessage}`)

    reportError(error, {
      severity: 'warning',
    })

    throw error
  }

  const responseError = {
    type: 'error',
    message: body.error || 'Something went wrong',
    status: response.status || '',
  }

  return responseError
}

export const apiRequest = (url, method, body) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    readEndpoint(url, {
      method,
      credentials: 'include',
      body: JSON.stringify({ data: body }),
      headers: {
        'X-CSRF-Token': getCSRF(getState()),
        'Content-Type': 'application/json',
      },
    })
      .then(json => {
        resolve(json)
      })
      .catch(error => {
        reject(error)
      })
  })

export const handleErrorResponse =
  (response, message, report = false) =>
  dispatch => {
    if (response.type === 'error') {
      report && reportError(new Error(response.message.detail))

      dispatch(showErrorMessageToast(message))
      return true
    }

    return false
  }
