import WrappedSection from 'Containers/WrappedSection'
import React from 'react'
import style from './NavBar.module.scss'

class NavBar extends React.Component {
  render() {
    return (
      <div className={style.container}>
        <ul className={style.list}>{this.renderSubSections()}</ul>
      </div>
    )
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }
}

export default NavBar
