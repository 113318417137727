import { DATA, LOCATION_CANDIDATES, PLATFORM_TITLES, initialState } from './utils.js'

export const LOAD_LOCATION_CANDIDATES = 'locationCandidates/LOAD'
export const SELECT_LOCATION_CANDIDATE = 'locationCandidates/SELECT'

export default function locationCandidateReducer(state, action) {
  switch (action.type) {
    case LOAD_LOCATION_CANDIDATES: {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([LOCATION_CANDIDATES, DATA], action.locationCandidates)
          : s.mergeIn([LOCATION_CANDIDATES, DATA], action.locationCandidates)
      })
    }

    case SELECT_LOCATION_CANDIDATE: {
      const { platformTitleId, locationCandidateId } = action
      const platformTitle = state.getIn([PLATFORM_TITLES, DATA]).find(pt => pt.id === platformTitleId)
      const locationCandidateIds = platformTitle
        .getIn(['relationships', 'locationCandidates', 'data'])
        .map(item => item.get('id'))

      return state.withMutations(s => {
        locationCandidateIds.forEach(id => {
          s.setIn([LOCATION_CANDIDATES, DATA, id, 'selected'], false)
        })

        s.setIn([LOCATION_CANDIDATES, locationCandidateId, 'selected'], true)
      })
    }
    default:
      return state || initialState
  }
}
