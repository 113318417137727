import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { AppleTvSectionTypes } from '../enums'
import { linkToPage, scrollToNode } from '../utils'
import style from './Item.module.scss'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, studio, isMerchandiseFilteringEnabled, section, match } = this.props

    const inlineStyle = {
      background: `black url(${spot.artworkUrl}) center center no-repeat`,
      backgroundSize: 'cover',
      display: 'block',
    }

    if (studio) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = studio.color
      inlineStyle.boxShadow = `0 0 10px ${studio.color}`
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !studio),
      [style.highlight]: spot.selected || studio,
    })

    const target = spot && spot.linkedPageId ? null : '_blank'
    const url = linkToPage(spot, match) || (spot && spot.url)
    const { subtitle, coverUrl } = spot.metadata

    const shouldDisplayDetails = [AppleTvSectionTypes._ChannelUpsell, AppleTvSectionTypes._Spotlight].includes(
      section.sectionType
    )

    return (
      <a href={url} className={classNames} target={target} ref={node => (this.node = node)}>
        <div id={`spot_${spot.id}`} style={inlineStyle}>
          {shouldDisplayDetails && (
            <div className={style.appleTvDetails}>
              {coverUrl && <img src={coverUrl} />}
              {spot.genre && <span className={style.genre}>{spot.genre}</span>}
              {spot.description && <span>{spot.description}</span>}
              {spot.label && <button>{spot.label}</button>}
              {subtitle && <span>{subtitle}</span>}
            </div>
          )}
        </div>
      </a>
    )
  }
}

Item.propTypes = {
  section: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
  studio: PropTypes.bool,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default withRouter(Item)
