import styles from 'Components/ClientLogo/styles.module.scss'
import { getScanSummary } from 'Selectors/index'
import React from 'react'
import { connect } from 'react-redux'

class ClientLogo extends React.Component {
  render() {
    const { scanSummary, height } = this.props

    const style = {
      height: height,
      width: '30%',
    }

    return (
      <div className={styles.root} style={style}>
        <img className={styles.img} src={scanSummary.clientLogo} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const scanSummary = getScanSummary(state)
  return {
    scanSummary,
  }
}

export default connect(mapStateToProps)(ClientLogo)
