import { DATA, MERCHANDIZING, SCANS } from '../utils'

export const LOAD_MERCHANDIZING_SCAN = 'merchandizing/scan/LOAD'
export const LOAD_MERCHANDIZING_SCANS = 'merchandizing/scans/LOAD'

export default function merchandizingScanReducer(state, action) {
  switch (action.type) {
    case LOAD_MERCHANDIZING_SCAN: {
      return state.withMutations(s => {
        const { scan } = action

        s.setIn([MERCHANDIZING, SCANS, DATA, scan.id], scan)
      })
    }

    case LOAD_MERCHANDIZING_SCANS: {
      return state.withMutations(s => {
        s.mergeIn([MERCHANDIZING, SCANS, DATA], action.scans)
      })
    }

    default:
      return state
  }
}
