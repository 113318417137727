import React from 'react'
import { useState } from 'react'
import { Spinner } from '@blueprintjs/core'

const createLoadingElement = (hasMoreDataToLoad, customLoadingElement, spinnerWrapperClassName, ghostCells) => {
  if (!hasMoreDataToLoad) return null

  if (customLoadingElement) {
    return customLoadingElement?.(ghostCells)
  }

  return (
    <div className={spinnerWrapperClassName}>
      <Spinner />
    </div>
  )
}

const useInfiniteScroll = (
  retrieveMoreData,
  hasMoreDataToLoad,
  spinnerWrapperClassName,
  customLoadingElement = null
) => {
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)

  const handleInfiniteLoad = async () => {
    if (!hasMoreDataToLoad || isInfiniteLoading) return

    setIsInfiniteLoading(true)
    try {
      await retrieveMoreData()
    } finally {
      setIsInfiniteLoading(false)
    }
  }

  const elementInfiniteLoad = ghostCells =>
    createLoadingElement(hasMoreDataToLoad, customLoadingElement, spinnerWrapperClassName, ghostCells)

  return {
    isInfiniteLoading,
    handleInfiniteLoad,
    elementInfiniteLoad,
  }
}

export default useInfiniteScroll
