import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { linkToPage } from '../utils'
import Item from './Item'
import style from './LargeContainer.module.scss'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class LargeContainer extends React.Component {
  renderSpots() {
    const { section, isMerchandiseFilteringEnabled, spots } = this.props

    return spots.map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  // this is a custom link spot that takes us to see more pages, others are content spots
  seeMore() {
    const { spots } = this.props
    return spots.find(spot => spot && spot.spotType === 'link')
  }

  render() {
    const { section, match } = this.props
    const metadata = section.metadata || {}
    const seeMoreSpot = this.seeMore()

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerPadding: 0,
      slidesToScroll: 5,
      slidesToShow: 5,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={style.container}>
        <div className={style.heading}>
          <h1>
            {section.name}{' '}
            {seeMoreSpot && (
              <a href={linkToPage(seeMoreSpot, match)}>
                {seeMoreSpot.name}
                <span className='bp5-icon-standard bp5-icon-chevron-right bp5-align-right'></span>
              </a>
            )}
          </h1>
          {metadata.subtitle && <h3>{metadata.subtitle}</h3>}
        </div>
        <Slider {...sliderSettings} className={style.alignSpotsLeft}>
          {this.renderSpots()}
        </Slider>
      </div>
    )
  }
}

const ConnectedContainer = withRouter(LargeContainer)
export default ConnectedContainer
