import PropTypes from 'prop-types'
import React from 'react'

const HeaderColumn = props => {
  return <div>{props.type === 'svod' ? <div>SVOD</div> : props.isAvail ? <div>Avail</div> : <div>AVOD</div>}</div>
}

HeaderColumn.propTypes = {
  type: PropTypes.string,
  isAvail: PropTypes.bool,
}

HeaderColumn.defaultProps = {
  isAvail: false,
}

export default HeaderColumn
