import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './Item.module.scss'
class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  isSeeMoreSpot() {
    const { spot } = this.props
    return spot.name && spot.name.includes('See All')
  }

  render() {
    const { spot, section, match } = this.props

    const classNames = cx(style.item, {
      [style.fade]: section.selected,
      [style.highlight]: spot.selected,
    })

    return (
      <a href={linkToPage(spot, match)} className={classNames} ref={node => (this.node = node)}>
        {section.sectionType === 'amctheatres::carousel' && <div className={style.shadowWrapper}></div>}
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        >
          {section.sectionType === 'amctheatres::carousel' && (
            <div className={style.descriptionWrapper}>
              <h1>{spot.name}</h1>
              <p>{spot.description}</p>
            </div>
          )}
          {this.isSeeMoreSpot() && (
            <div className={style.seeMoreItem}>
              <h3>
                Not finding
                <br />
                What you&apos;re <br />
                Looking for?
              </h3>
              <button>See All</button>
            </div>
          )}
        </div>
      </a>
    )
  }
}

export default withRouter(Item)
