import { deselectAllPlatforms, selectAllPlatforms, togglePlatforms } from 'Actions/platformActions'
import {
  deselectAllTerritories,
  deselectTerritory,
  selectAllTerritories,
  selectPlatformsForSelectedTerritories,
  selectTerritories,
  selectTerritoriesForSelectedPlatforms,
  selectTerritoryAndPlatforms,
  toggleTerritory,
} from 'Actions/territoryActions'
import PlatformAndTerritoryList from 'Components/PlatformAndTerritoryList'
import PlatformAndTerritoryListNew from 'Components/PlatformAndTerritoryListNew'
import { getAssociatedMerchandizingScans, getPlatforms, getSelectedPlatforms, getTerritories } from 'Selectors/index'
import React from 'react'
import { connect } from 'react-redux'
import { territorySorter } from '../utils'

const mapStateToProps = (state, ownProps) => {
  const territories = getTerritories(state)

  const platformGroups = getPlatforms(state)
    .valueSeq()
    .sort((a, b) => territorySorter(a.territory(state), b.territory(state)))
    .groupBy(p => p.name)

  const selectedPlatformsCount = getSelectedPlatforms(state)
    .map(p => p.id)
    .toSet()
    .toList()
    .count()

  const associatedMerchandizingScans = getAssociatedMerchandizingScans(state)

  return {
    territories,
    platformGroups,
    selectedPlatformsCount,
    withCheckbox: ownProps.withCheckbox,
    withTabs: ownProps.withTabs,
    newUI: ownProps.newUI,
    regionsAndPlatforms: ownProps.regionsAndPlatforms,
    associatedMerchandizingScans,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { competitors } = ownProps
  return {
    toggleTerritory: (territory, isOnScansPage, isOnMerchPage) =>
      dispatch(toggleTerritory(territory, competitors, isOnScansPage, isOnMerchPage)),
    selectAllTerritories: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllTerritories(competitors, isOnScansPage, isOnMerchPage)),
    deselectAllTerritories: () => dispatch(deselectAllTerritories(competitors)),
    togglePlatforms: (platformIds, isOnScansPage, isOnMerchPage) =>
      dispatch(togglePlatforms(platformIds, competitors, isOnScansPage, isOnMerchPage)),
    selectAllPlatforms: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllPlatforms(competitors, isOnScansPage, isOnMerchPage)),
    deselectAllPlatforms: () => dispatch(deselectAllPlatforms()),
    selectTerritories: (territoryIds, isOnScansPage, isOnMerchPage) =>
      dispatch(selectTerritories(territoryIds, competitors, isOnScansPage, isOnMerchPage)),
    deselectTerritory: territory => dispatch(deselectTerritory(territory)),
    selectPlatformsForSelectedTerritories: () => dispatch(selectPlatformsForSelectedTerritories()),
    selectTerritoriesForSelectedPlatforms: () => dispatch(selectTerritoriesForSelectedPlatforms()),
    selectTerritoryAndPlatforms: (territory, competitors, isOnScansPage, isOnMerchPage) => dispatch(selectTerritoryAndPlatforms(territory, competitors, isOnScansPage, isOnMerchPage)),
  }
}

const ConnectedComponent = props => {
  const ComponentToRender = props.newUI ? PlatformAndTerritoryListNew : PlatformAndTerritoryList
  return <ComponentToRender {...props} />
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent)
