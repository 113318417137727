import { TAGS, initialState, removeTag } from './utils.js'

export default function tagReducer(state, action) {
  switch (action.type) {
    case 'LOAD_TAGS': {
      return state.withMutations(s => {
        s.mergeIn([TAGS], action.tags)
      })
    }
    case 'CLEAR_SELECTED_TAGS': {
      const tags = state.getIn([TAGS]).filter(tag => tag.selected)
      return state.withMutations(s => {
        tags.forEach(tag => {
          s.updateIn([TAGS, tag.id, 'selected'], selected => !selected)
        })
      })
    }
    case 'DELETE_TAG': {
      const { tagId } = action
      return state.withMutations(s => {
        removeTag({
          mutable: s,
          tagId,
        })
      })
    }
    case 'SELECT_TAGS': {
      const { tags } = action
      return state.withMutations(s => {
        tags.forEach(tag => {
          s.updateIn([TAGS, tag.id, 'selected'], _selected => tag.selected)
        })
      })
    }
    default:
      return state || initialState
  }
}
