import PublisherRecord from 'Records/publisherRecord'
import { LOAD_PUBLISHERS } from 'Reducers/app/publisherReducer'
import { Map, OrderedMap, fromJS } from 'immutable'

export const loadPublishers = publishers => ({
  type: LOAD_PUBLISHERS,
  publishers,
})

export const publisherRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new PublisherRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadPublishers = data => dispatch => {
  const publishers = data.reduce((memo, item) => {
    return memo.set(item.id, new PublisherRecord({ ...item }))
  }, new OrderedMap())

  dispatch(loadPublishers(publishers))
}
