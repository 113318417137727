import { Card } from '@blueprintjs/core'
import cx from 'classnames'
import { List } from 'immutable'
import React from 'react'
import BrandScoreCard from './BrandScoreCard'
import { OptimizationScoreRecord } from './interfaces'
import styles from './styles.module.scss'

type BrandsProps = {
  mostOptimizedBrands: List<OptimizationScoreRecord>
  leastOptimizedBrands: List<OptimizationScoreRecord>
}

const Brands: React.FC<BrandsProps> = ({ mostOptimizedBrands, leastOptimizedBrands }) => (
  <>
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <h4 className={styles.pageHeader}>Most optimised brands</h4>
        <div className={cx(styles.section, styles.brandSection)}>
          {mostOptimizedBrands.toArray().map((brandScore, index) => (
            <BrandScoreCard score={brandScore} key={index} />
          ))}
        </div>
      </Card>
    </div>
    <div className={styles.gridItem}>
      <Card>
        <h4 className={styles.pageHeader}>Least optimised brands</h4>
        <div className={cx(styles.section, styles.brandSection)}>
          {leastOptimizedBrands.toArray().map((brandScore, index) => (
            <BrandScoreCard score={brandScore} key={index} />
          ))}
        </div>
      </Card>
    </div>
  </>
)

export default Brands
