import React, { useCallback, useState } from 'react'
import PerformanceDetail from './PerformanceDetail'
import { Button, MenuItem, Drawer, DrawerSize, Classes } from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'
import MetricSelector from './MetricSelector'

import { Client } from 'Records/clientRecord'
import { StudioRecord } from 'Records/studioRecord'
import useToolbarActions from 'Components/ScanPage/useToolbarActions'

import {
  SELECT_DISTRIBUTORS,
  SELECT_PLATFORMS,
  CARET_DOWN,
  OVERVIEW,
  COMPETITOR_TITLE,
  COMPETITOR_DETAILS,
  PLATFORM_TITLE,
  PLATFORM_DETAILS,
  PerformancePageEnum,
} from './constants'

import { MerchandizingScan, Scan, Competitor } from 'Interfaces/*'
import { sortPlatformsByRegionAndName } from '../utils'

import styles from './Toolbar.module.scss'
import cx from 'classnames'

type Props = {
  client: Client
  setMetric: (metric: string) => void
  metric: string
  associatedMerchScans: MerchandizingScan[]
  scan: Scan
  performancePage: PerformancePageEnum
  competitors: Competitor[]
}

const Toolbar: React.FC<Props> = ({ setMetric, metric, associatedMerchScans, performancePage, competitors }) => {
  const [caretIcon, setCaretIcon] = useState<string>(CARET_DOWN)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { toggleClientCompetitor, toggleAssociatedMerchandizingScan } = useToolbarActions({})

  const renderPerformanceDetails = () => {
    const performanceDetailsMap = {
      [PerformancePageEnum.Competitor]: <PerformanceDetail title={COMPETITOR_TITLE} description={COMPETITOR_DETAILS} />,
      [PerformancePageEnum.Platform]: <PerformanceDetail title={PLATFORM_TITLE} description={PLATFORM_DETAILS} />,
    }
    return performanceDetailsMap[performancePage] || null
  }

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  const multiSelectItem = (
    item: StudioRecord,
    { handleClick }: { handleClick: (event: React.MouseEvent<HTMLElement>) => void }
  ) => <MenuItem key={item.id} text={item.name} onClick={handleClick} active={item.selected} />

  return (
    <nav className={cx(styles.header, 'bp5-navbar')}>
      <div className={cx('bp5-navbar-group bp5-align-left', styles.toolbarGroup)}>
        <div className='bp5-button-group'>
          <MultiSelect
            items={competitors}
            selectedItems={competitors.filter(item => item.selected)}
            onItemSelect={(item: StudioRecord) => toggleClientCompetitor(item.id)}
            itemRenderer={multiSelectItem}
            tagRenderer={(item: StudioRecord) => item.name}
            tagInputProps={{
              onRemove: (item: StudioRecord) => {
                const selectedTag = competitors.find(tag => tag.name === item)
                if (selectedTag) {
                  toggleClientCompetitor(selectedTag.id)
                }
              },
              className: styles.tagWrapper,
              autoResize: true,
              tagProps: {
                minimal: true,
                className: styles.tag,
              },
            }}
            placeholder={SELECT_DISTRIBUTORS}
          />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} data-testid='navbar-divider' />
        <div className='bp5-button-group'>
          <MultiSelect
            items={sortPlatformsByRegionAndName(associatedMerchScans)}
            selectedItems={associatedMerchScans.filter(item => item.selected)}
            onItemSelect={(item: MerchandizingScan) => toggleAssociatedMerchandizingScan([item.id])}
            itemRenderer={multiSelectItem}
            tagRenderer={(item: MerchandizingScan) => item.name}
            tagInputProps={{
              onRemove: (_value: React.ReactNode, index: number) => {
                const item = associatedMerchScans.filter(item => item.selected)[index]
                if (item) {
                  toggleAssociatedMerchandizingScan([item.id])
                }
              },
              className: styles.tagWrapper,
              autoResize: true,
              tagProps: {
                minimal: true,
                className: styles.tag,
              },
            }}
            placeholder={SELECT_PLATFORMS}
          />
        </div>
        <div className={cx(styles.divider, 'bp5-navbar-divider')} data-testid='navbar-divider' />
        <div className='bp5-button-group'>
          <MetricSelector metric={metric} setMetric={setMetric} caretIcon={caretIcon} setCaretIcon={setCaretIcon} />
        </div>
      </div>
      <div className={cx('bp5-navbar-group bp5-align-right', styles.toolbarGroup)}>
        <Button className={cx('bp5-align-left', styles.infoButton)} onClick={toggleDrawer} data-testid='info-button'>
          <span className={cx('bp5-icon-info-sign')}></span>
        </Button>
        <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} size={DrawerSize.SMALL} title={OVERVIEW}>
          {renderPerformanceDetails()}
        </Drawer>
      </div>
    </nav>
  )
}

export default Toolbar
