import { Icon, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { checkCategoryAndBlue } from '../helpers'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'

const NavBarItem = ({ match, spot, spots }) => {
  const renderDropdown = () => {
    const spotsList = [...spots.filter(spot => !!spot)]
    const { isCategory, isBlue } = checkCategoryAndBlue(spot)

    const classnames = cx(style.listItem, {
      [style.selectedItem]: spot && spot.metadata && spot.metadata.selected === true,
      [style.blueBtn]: isBlue,
    })

    return (
      <div
        className={cx({
          [style.alignRight]: isCategory,
        })}
      >
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          placement={Position.LEFT}
          popoverClassName='bp5-popover-display-none'
          minimal={{ arrow: { enabled: false } }}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            },
            offset: {
              enabled: true,
              options: {
                offset: [0, 200],
              },
            },
          }}
          content={
            <>
              {spotsList.length > 0 && (
                <div className={style.dropdown}>
                  <ul>
                    {spotsList.map(spot => {
                      if (!spot) return null
                      return (
                        <li key={spot.id} className={style.listItem}>
                          <a href={linkToPage(spot, match)}>{spot.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </>
          }
        >
          <li className={classnames}>
            <a href={linkToPage(spot, match)}>
              {!isCategory ? spot.name : <Icon icon='grid' className={style.iconGrid} />}
              {spotsList.length > 0 && !isCategory && <div className={style.dropdownIcon} />}
            </a>
          </li>
        </Popover>
      </div>
    )
  }

  return renderDropdown()
}

NavBarItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.array,
  spot: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(NavBarItem)
