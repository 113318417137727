import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { OUTSIDE_SECTION } from '../constants'
import { linkToPage } from '../utils'
import Item from './Item'
import style from './VideoContainer.module.scss'

function Arrow(props) {
  const { onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
  })

  return (
    <div
      onClick={onClick}
      className={cx({
        [style.arrowWrapperLeft]: left === true,
        [style.arrowWrapperRight]: right === true,
      })}
    >
      <i className={classnames} />
    </div>
  )
}

function renderSpots(spots, section) {
  return spots.map(spot => {
    return (
      spot && (
        <div className={style.item} key={spot.id}>
          <span className={style.playWrapper}>
            <i className={style.playArrow} />
          </span>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}
function VideoContainer(props) {
  const { section, spots, match } = props

  const seeAll = spots.find(spot => spot?.metadata.expandLinkType === OUTSIDE_SECTION)

  const sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    centerPadding: 0,
    slidesToScroll: 4,
    slidesToShow: 4,
    variableWidth: false,
    arrows: true,
    swipe: true,
    initialSlide: 0,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>
          {section.name} {seeAll && <a href={linkToPage(seeAll, match)}>{seeAll.name}</a>}
        </h2>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(VideoContainer)
