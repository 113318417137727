import { getPlatformTitles, getPlatforms, getSelectedPlatforms, getSelectedTerritories } from 'Selectors/index'
import { Record } from 'immutable'

export default class TitleRecord extends Record({
  id: 0,
  name: '',
  nameStem: '',
  titleType: '',
  clientId: '',
  titleTypeCode: '',
  platformTitlesIds: [],
}) {
  platformTitles(state) {
    return getPlatformTitles(state).filter(pt => pt.titleId === this.id)
  }

  platformTitleForPlatform(state, platformId) {
    const platformTitles = this.platformTitles(state)
    return platformTitles.find(platformTitle => {
      if (!platformTitle) return false
      const platformIdStr = platformTitle.platformId != null ? platformTitle.platformId.toString() : null
      const platformIdToCompare = platformId != null ? platformId.toString() : null
      return platformIdStr === platformIdToCompare
    })
  }

  platformTitlesTags(state) {
    const platformTitlesForTitle = this.platformTitlesForSelectedPlatforms(state)

    return (
      platformTitlesForTitle &&
      platformTitlesForTitle
        .map(pt => pt.tags(state))
        .toArray()
        .flat(1)
    )
  }

  platformTitlesForSelectedTerritories(state) {
    const selectedTerritories = getSelectedTerritories(state)

    return this.platformTitles(state)
      .filter(pt => selectedTerritories.get(pt.platform(state)?.territoryId))
      .filter(pt => !!pt)
  }

  platformTitlesForSelectedPlatforms(state) {
    const selectedPlatforms = getSelectedPlatforms(state)

    return this.platformTitles(state)
      .filter(pt => selectedPlatforms.get(pt.platformId))
      .filter(pt => !!pt)
  }

  platformTitlesForGivenTerritories(state, territoryIds) {
    const territoryPlatforms = getPlatforms(state).filter(p => territoryIds.includes(p.territoryId))

    return this.platformTitles(state)
      .filter(pt => territoryPlatforms.get(pt.platformId))
      .filter(pt => !!pt)
  }
}
