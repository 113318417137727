import { SpotItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useRef, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import { RakutenTvSectionTypes } from '../enums'
import { generateClientStyle } from '../helpers'
import { linkToPage } from '../utils'
import style from './Item.module.scss'

type ItemProps = RouteComponentProps & SpotItems

const DetailsWrapper = ({ children = null }: { children?: React.ReactNode }) => {
  return <p className={style.details}>{children}</p>
}

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const { currentRating, subtitle } = spot?.metadata

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(
    () =>
      cx({
        [style.item]: section.sectionType !== RakutenTvSectionTypes._ChartContainer,
        [style.chartItem]: section.sectionType === RakutenTvSectionTypes._ChartContainer,
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected, section.sectionType]
  )

  const spotClassNames = useMemo(
    () =>
      cx({
        [style.spotBackground]: section.sectionType !== RakutenTvSectionTypes._VideoContainer,
        [style.spotBackgroundVideoContainer]: section.sectionType === RakutenTvSectionTypes._VideoContainer,
      }),
    [section.sectionType]
  )

  const renderDetails = () => {
    if (
      [
        RakutenTvSectionTypes._SaleContainer,
        RakutenTvSectionTypes._SaleGrid,
        RakutenTvSectionTypes._Container,
        RakutenTvSectionTypes._LandscapeContainer,
      ].includes(section.sectionType as RakutenTvSectionTypes)
    ) {
      return (
        <DetailsWrapper>
          {currentRating && <span className={style.currentRating}>{currentRating}</span>}
          {subtitle && <span className={style.subtitle}>{subtitle}</span>}
        </DetailsWrapper>
      )
    } else if (section.sectionType === RakutenTvSectionTypes._Category) {
      return (
        <DetailsWrapper>
          <span data-testid='categoryName' className={style.categoryName}>
            {spot.name}
          </span>
        </DetailsWrapper>
      )
    }
  }

  return (
    <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
      {spot?.label && <span className={style.releaesAdv}>{spot.label}</span>}
      {section.sectionType === RakutenTvSectionTypes._ChartContainer && (
        <span className={style.chartContainerCounter}>{spot?.position}</span>
      )}
      <div
        className={spotClassNames}
        id={`spot_${spot.id}`}
        style={{ backgroundImage: `url(${spot.artworkUrl})` }}
      ></div>
      {renderDetails()}
    </a>
  )
}

export default withRouter(Item)
