import { List } from 'immutable'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'

import { retrieveScanSummary } from 'Actions/scanActions'

import LeftRight from 'Components/LeftRight'
import ActionStat from 'Components/Overview/ActionStat'
import LeastMostOptimized from 'Components/Overview/LeastMostOptimized'
import SalesGraph from 'Components/Overview/SalesGraph'
import ScanStat from 'Components/Overview/ScanStat'
import ScoreStat from 'Components/Overview/ScoreStat'
import styles from 'Components/Overview/styles.module.scss'
import {
  getClient,
  getClientSalesSummary,
  getMarketShareFilters,
  getScanSummary,
  getSelectedScan,
} from 'Selectors/index'

import { retrieveOverviewMarketShare, toggleMovieFilter, toggleSeriesFilter } from 'Actions/marketSharesActions'
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

class Overview extends React.Component {
  componentDidMount() {
    const { scanId, retrieveScanSummary, retrieveOverviewMarketShare, weekBeginningAt, clientId } = this.props

    const filter = {}
    filter['filter[dateRange]'] = [weekBeginningAt, weekBeginningAt].join(',')
    retrieveOverviewMarketShare(filter, clientId)
    retrieveScanSummary(scanId)
  }

  render() {
    const {
      scanSummary,
      competitorShareEnabled,
      salesSummary,
      mostOptimizedBrands,
      leastOptimizedBrands,
      mostOptimizedTitles,
      leastOptimizedTitles,
      client,
    } = this.props

    if (!scanSummary) {
      return <div />
    }

    return (
      <ErrorBoundary>
        <div className={styles.root}>
          <h4 className={styles.scanTitle}>{scanSummary.scanTitle}</h4>

          <div className={styles.gridRow}>
            <ScoreStat scanSummary={scanSummary} />
            <ScanStat scanSummary={scanSummary} />
            <ActionStat client={client} scanSummary={scanSummary} />
          </div>

          <LeftRight>
            <div className={styles.leftContainer}>
              {salesSummary.data.length > 0 && <SalesGraph salesSummary={salesSummary} />}
              {!competitorShareEnabled && (
                <div className={styles.leastMostGridRow}>
                  <LeastMostOptimized
                    scanSummary={scanSummary}
                    mostOptimizedBrands={mostOptimizedBrands}
                    leastOptimizedBrands={leastOptimizedBrands}
                    mostOptimizedTitles={mostOptimizedTitles}
                    leastOptimizedTitles={leastOptimizedTitles}
                  />
                </div>
              )}
            </div>
          </LeftRight>
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => {
  const scanSummary = getScanSummary(state)
  const salesSummary = getClientSalesSummary(state)
  const scan = getSelectedScan(state)
  const weekBeginningAt = moment(scan.publishedAt || scan.createdAt)
    .startOf('isoWeek')
    .toDate()
  const filter = getMarketShareFilters(state)
  const client = getClient(state)

  const mostOptimizedBrands = (scanSummary && scanSummary.mostOptimizedBrands(state)) || new List()
  const leastOptimizedBrands = (scanSummary && scanSummary.leastOptimizedBrands(state)) || new List()
  const mostOptimizedTitles = (scanSummary && scanSummary.mostOptimizedTitles(state)) || new List()
  const leastOptimizedTitles = (scanSummary && scanSummary.leastOptimizedTitles(state)) || new List()

  return {
    scanSummary,
    weekBeginningAt,
    filter,
    salesSummary,
    leastOptimizedBrands,
    mostOptimizedBrands,
    mostOptimizedTitles,
    leastOptimizedTitles,
    client,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleMovieFilter: () => dispatch(toggleMovieFilter()),
    toggleSeriesFilter: () => dispatch(toggleSeriesFilter()),
    retrieveScanSummary: scanId => dispatch(retrieveScanSummary(scanId, false)),
    retrieveOverviewMarketShare: (filter, clientId) => dispatch(retrieveOverviewMarketShare(filter, clientId)),
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview)
