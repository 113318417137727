import { PageItems, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { SECTION_URL } from '../constants'
import { MegogoSectionTypes } from '../enums'
import { computeContainerClasses, renderSpotsWithItem } from '../helpers'
import { linkToPage } from '../utils'
import style from './Grid.module.scss'
import Item from './Item'
import RenderLinkItem from './RedirectLinkItem'

type GridProps = RouteComponentProps & PageItems

const Grid: React.FC<GridProps> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled, match }) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])

  const spotsRender = renderSpotsWithItem({
    spots: spots.filter(spot => spot?.label?.toLowerCase() !== SECTION_URL),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.gridItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const gridWrapperClassNames = useMemo(
    () =>
      cx({
        [style.gridWrapper]:
          section.sectionType === MegogoSectionTypes._Grid ||
          section.sectionType === MegogoSectionTypes._CollectionGrid,
        [style.landscapeGridWrapper]: section.sectionType === MegogoSectionTypes._LandscapeGrid,
      }),
    [section]
  )

  const seeMore = useMemo(() => spots.find((spot: Spot) => spot?.label?.toLowerCase() === SECTION_URL), [spots])

  const redirectUrl = seeMore ? linkToPage(seeMore, match) : ''

  return (
    <div className={containerClasses}>
      <div className={style.sectionHeader}>
        <h1>
          <RenderLinkItem isLink={!!redirectUrl} name={section.name} url={redirectUrl} className={style.link} />
        </h1>
      </div>
      <div className={gridWrapperClassNames}>{spotsRender}</div>
    </div>
  )
}

export default withRouter(Grid)
