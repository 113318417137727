import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './SlickItem.module.scss'

class SlickItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, match } = this.props

    return (
      <a href={linkToPage(spot, match)} className={style.titleItem} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          className={style.image}
          style={{ background: `black url(${spot.artworkUrl}) center center no-repeat `, backgroundSize: '100% 100%' }}
        ></div>
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(SlickItem)
export default ConnectedItemWithRouter
