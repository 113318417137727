import { readEndpoint } from 'Actions/apiActions'
import { getSelectedScanFromLocalStorage } from 'Actions/scanActions'
import { OrderedMap } from 'immutable'

import MatchRecord from 'Records/merchandizing/matchRecord'
import { getSelectedScan } from 'Selectors/index'

import { LOADING_MERCHANDIZING_MATCHES, LOAD_MERCHANDIZING_MATCHES } from 'Reducers/app/merchandizing/matchReducer'

import { normalizeAndLoadTitles } from 'Actions/titleActions'
import { normalizeAndLoadTitleAudits } from 'Actions/titleAuditActions'
import { MATCHES_PER_PAGE } from 'Components/Merchandizing/MatchPanel'
import queryString from 'query-string'

export const normalizeAndLoadMerchandizingMatches = (data, reset) => {
  const matches = data.reduce((memo, item) => {
    return memo.set(item.id, new MatchRecord({ ...item }))
  }, new OrderedMap())

  return loadMerchandizingMatches(matches, null, reset)
}

export const retrieveMerchandizingMatchesForPage = pageId => (dispatch, getState) => {
  const state = getState()

  const { url, options } = getMerchandizingMatchesRequestUrlAndOptions(pageId, state)
  const query = queryString.stringify(options)

  dispatch(merchandizingMatchesLoading(true))
  return readEndpoint(url + query).then(response => {
    dispatch(loadMerchandizingMatchesResponse(response.merchandizingMatches, false))
  })
}

export const retrieveMerchandizingMatchesForSections =
  (pageId, sections, pageOffset = 0) =>
  (dispatch, getState) => {
    const state = getState()
    const { url, options } = getMerchandizingMatchesRequestUrlAndOptions(pageId, state, pageOffset)

    options['filter[section_ids]'] = sections.map(t => t.id).join(',')

    const query = queryString.stringify(options)

    dispatch(merchandizingMatchesLoading(true))
    return readEndpoint(url + query).then(response => {
      if (response.merchandizingMatches.length >= MATCHES_PER_PAGE) {
        dispatch(retrieveMerchandizingMatchesForSections(pageId, sections, pageOffset + 1))
      }
      return dispatch(loadMerchandizingMatchesResponse(response.merchandizingMatches, false))
    })
  }

const getMerchandizingMatchesRequestUrlAndOptions = (pageId, state, offset = null) => {
  const auditScanId = getSelectedScanFromLocalStorage()
  const url = `merchandizing/pages/${pageId}/matches?`
  const auditScan = getSelectedScan(state)

  const options = {
    'filter[audit_scan_id]': auditScanId || auditScan?.id,
  }

  if (offset) options['page'] = offset

  return {
    url,
    options,
  }
}

const loadMerchandizingMatchesResponse = (data, reset) => dispatch => {
  const titles = data.map(match => match.title)
  const titleAudits = data.map(match => match.titleAudit)

  const merchandizingMatches = data.reduce((memo, item) => {
    return memo.set(item.id, new MatchRecord({ ...item }))
  }, new OrderedMap())

  dispatch(normalizeAndLoadTitles(titles, reset))
  dispatch(normalizeAndLoadTitleAudits(titleAudits, reset))
  dispatch(loadMerchandizingMatches(merchandizingMatches, null, reset))
  dispatch(merchandizingMatchesLoading(false))
}

export const loadMerchandizingMatches = (matches, _thing, reset) => ({
  type: LOAD_MERCHANDIZING_MATCHES,
  matches,
  reset,
})

const merchandizingMatchesLoading = isLoading => ({
  type: LOADING_MERCHANDIZING_MATCHES,
  isLoading,
})
