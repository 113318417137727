import { OrderedMap } from 'immutable'
import { DATA, FILTERS, LINKS, MERCHANDIZING, META, PAGES, SPOTS } from '../utils'
import { deselectSpots } from './utils'

export const LOAD_MERCHANDIZING_SPOTS = 'merchandizing/spots/LOAD'
export const SELECT_MERCHANDIZING_SPOT = 'merchandizing/spots/SELECT'
export const DESELECT_MERCHANDIZING_SPOT = 'merchandizing/spots/DESELECT'
export const TOGGLE_STUDIO_FILTER = 'merchandizing/spots/TOGGLE_STUDIO_FILTER'
export const UPDATE_TITLE_QUERY = 'merchandizing/spots/UPDATE_TITLE_QUERY'
export const CLEAR_TITLE_QUERY = 'merchandizing/spots/CLEAR_TITLE_QUERY'
export const SET_SCROLLING_SPOT = 'merchandizing/spots/SET_SCROLLING_SPOT'
export const SPOTS_LOADING = 'merchandizing/spots/SPOTS_LOADING'
export const UPDATE_SPOT_URL = 'merchandizing/spots/UPDATE_SPOT_URL'
export const LOAD_SPOTS_HAS_MORE_TO_LOAD = 'merchandizing/spots/meta/LOAD_SPOTS_HAS_MORE_TO_LOAD'

export default function merchandizingNodeReducer(state, action) {
  switch (action.type) {
    case DESELECT_MERCHANDIZING_SPOT: {
      return state.withMutations(s => {
        deselectSpots({ mutable: s })
      })
    }
    case SELECT_MERCHANDIZING_SPOT: {
      const { spotId } = action
      return state.withMutations(s => {
        deselectSpots({ mutable: s })
        s.setIn([MERCHANDIZING, SPOTS, DATA, spotId, 'selected'], true)
      })
    }
    case LOAD_MERCHANDIZING_SPOTS: {
      return state.withMutations(s => {
        const { spots, links, meta, reset } = action
        reset ? s.setIn([MERCHANDIZING, SPOTS, DATA], spots) : s.mergeIn([MERCHANDIZING, SPOTS, DATA], spots)

        s.setIn([MERCHANDIZING, SPOTS, LINKS], links)
        s.setIn([MERCHANDIZING, SPOTS, META], meta ?? new OrderedMap({}))
      })
    }
    case TOGGLE_STUDIO_FILTER: {
      return state.withMutations(_s => {
        const { studio } = action
        const filters = state.getIn([MERCHANDIZING, SPOTS, FILTERS])

        return state.setIn([MERCHANDIZING, SPOTS, FILTERS], filters.toggleStudio(studio))
      })
    }
    case UPDATE_TITLE_QUERY: {
      const filters = state.getIn([MERCHANDIZING, SPOTS, FILTERS])
      return state.setIn([MERCHANDIZING, SPOTS, FILTERS], filters.updateTitleQuery(action.query))
    }
    case UPDATE_SPOT_URL: {
      const { spotId, url } = action
      return state.withMutations(s => {
        s.setIn([MERCHANDIZING, SPOTS, DATA, spotId, 'screenshotUrl'], url)
      })
    }
    case LOAD_SPOTS_HAS_MORE_TO_LOAD: {
      const { hasMoreToLoad } = action
      return state.withMutations(s => {
        s.updateIn([MERCHANDIZING, SPOTS, META, 'hasMoreToLoad'], _hasMoreToLoad => hasMoreToLoad)
      })
    }
    case CLEAR_TITLE_QUERY: {
      const filters = state.getIn([MERCHANDIZING, SPOTS, FILTERS])
      return state.setIn([MERCHANDIZING, SPOTS, FILTERS], filters.clearTitleQuery())
    }
    case SET_SCROLLING_SPOT: {
      return state.updateIn([MERCHANDIZING, PAGES, META, 'scrollingSpotId'], _scrollingSpotId => action.spotId)
    }
    case SPOTS_LOADING: {
      return state.updateIn([MERCHANDIZING, SPOTS, FILTERS, 'spotsLoading'], _spotsLoading => action.isLoading)
    }

    default:
      return state
  }
}
