import { PageItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { ViaplaySectionTypes } from '../enums'
import { computeContainerClasses, renderSpotsWithItem } from '../helpers'
import style from './Grid.module.scss'
import Item from './Item'

const Grid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])
  const sectionType = section.sectionType

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.gridItem]: sectionType === ViaplaySectionTypes._Grid,
        [style.portraitGridItem]: sectionType === ViaplaySectionTypes._PortraitGrid,
        [style.collectionItem]: sectionType === ViaplaySectionTypes._Collection,
        [style.mosaicItem]: sectionType === ViaplaySectionTypes._MosaicContainer,
      }),
    [sectionType]
  )

  const filteredSpots = useMemo(() => spots.filter(spot => spot?.label?.toLowerCase() !== 'view all'), [spots])

  const spotsRender = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={containerClasses}>
      <h1 className={style.header}>{section.name}</h1>
      <div className={cx(style.spotWrapper, { [style.mosaic]: sectionType === ViaplaySectionTypes._MosaicContainer })}>
        {spotsRender}
      </div>
    </div>
  )
}

export default Grid
