import { Button } from '@blueprintjs/core'
import React from 'react'

class FormatFilter extends React.Component {
  render() {
    const { filter } = this.props
    return (
      <div className='bp5-button-group'>
        <Button active={filter.formatAll} onClick={() => this.props.toggleFormatAll()}>
          All
        </Button>
        <Button active={filter.formatHD} onClick={() => this.props.toggleFormatHD()}>
          HD
        </Button>
        <Button active={filter.formatSD} onClick={() => this.props.toggleFormatSD()}>
          SD
        </Button>
      </div>
    )
  }
}

export default FormatFilter
