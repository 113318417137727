import WrappedSection from 'Containers/WrappedSection'
import PropTypes from 'prop-types'
import React from 'react'
import style from './Page.module.scss'

class Page extends React.Component {
  render() {
    const { page, scan, rootSections } = this.props

    return (
      <div className={style.page}>
        <div className={style.container}>
          {rootSections.valueSeq().map(s => (
            <WrappedSection type={s.sectionType} key={s.id} scan={scan} page={page} section={s} />
          ))}
        </div>
      </div>
    )
  }
}

Page.propTypes = {
  page: PropTypes.object,
  scan: PropTypes.object,
  rootSections: PropTypes.object,
}

export default Page
