import {
  ADMIN_ARTWORK_PATH,
  ADMIN_COMPETITORS_PATH,
  ADMIN_DASHBOARD_PATH,
  ADMIN_MERCHANDISING_PATH,
  ADMIN_P_A_PATH,
  CLIENT_ARTWORK_PATH,
  CLIENT_COMPETITORS_PATH,
  CLIENT_DASHBOARD_PATH,
  CLIENT_MERCHANDISING_PATH,
  CLIENT_P_A_PATH,
  CLIENT_MPV_ANALYTICS,
  ADMIN_MPV_ANALYTICS,
  findPath,
} from 'Utils/path'
import { generatePath } from 'react-router'

interface GetHistoryPathProps {
  scanId: string
  clientId: string
  merchScanId?: string
  rootPageId?: string
}

const pathBuilder = ({ url, title }: { url: string; title: string }) => ({
  url: url,
  title,
})

const getPath = ({
  isClientApp,
  clientRoute,
  adminRoute,
  params,
}: {
  isClientApp: boolean
  clientRoute: { url: string; name: string }
  adminRoute: { url: string; name: string }
  params: any
}) => ({
  url: generatePath(isClientApp ? clientRoute.url : adminRoute.url, params),
  title: isClientApp ? clientRoute.name : adminRoute.name,
})

export const useNavPaths = ({ clientApp = false }) => {
  const generatePathForRoute = (clientRoute, adminRoute, params) => {
    return pathBuilder({
      ...getPath({
        isClientApp: clientApp,
        clientRoute,
        adminRoute,
        params: clientApp ? params : { id: params.clientId, ...params },
      }),
    })
  }

  const getDashboardPath = ({ scanId, clientId }) =>
    generatePathForRoute(CLIENT_DASHBOARD_PATH, ADMIN_DASHBOARD_PATH, { scanId, clientId })

  const getPriceAndAvailabilityPath = ({ scanId, clientId }) =>
    generatePathForRoute(CLIENT_P_A_PATH, ADMIN_P_A_PATH, { scanId, clientId })

  const getArtworkPath = ({ scanId, clientId }) =>
    generatePathForRoute(CLIENT_ARTWORK_PATH, ADMIN_ARTWORK_PATH, { scanId, clientId })

  const getMerchandizingPath = ({ merchScanId, rootPageId, clientId }) =>
    generatePathForRoute(CLIENT_MERCHANDISING_PATH, ADMIN_MERCHANDISING_PATH, {
      scanId: merchScanId,
      pageId: rootPageId,
      clientId,
    })

  const getCompetitorsPath = ({ scanId, clientId }) =>
    generatePathForRoute(CLIENT_COMPETITORS_PATH, ADMIN_COMPETITORS_PATH, { scanId, clientId })

  const getMpvAnalyticsPath = ({ scanId, clientId }) =>
    generatePathForRoute(CLIENT_MPV_ANALYTICS, ADMIN_MPV_ANALYTICS, { scanId, clientId })

  const getHistoryPath = ({ scanId, clientId, merchScanId, rootPageId }: GetHistoryPathProps) => {
    const currentPath = location.pathname as any
    const matchedPath = findPath(currentPath)

    if (!matchedPath) {
      return getDashboardPath({ scanId, clientId }) // Default path if no match found
    }

    switch (matchedPath) {
      case CLIENT_DASHBOARD_PATH:
      case ADMIN_DASHBOARD_PATH:
        return getDashboardPath({ scanId, clientId })
      case CLIENT_P_A_PATH:
      case ADMIN_P_A_PATH:
        return getPriceAndAvailabilityPath({ scanId, clientId })
      case CLIENT_ARTWORK_PATH:
      case ADMIN_ARTWORK_PATH:
        return getArtworkPath({ scanId, clientId })
      case CLIENT_MERCHANDISING_PATH:
      case ADMIN_MERCHANDISING_PATH:
        return merchScanId && rootPageId
          ? getMerchandizingPath({ merchScanId, rootPageId, clientId })
          : getDashboardPath({ scanId, clientId })
      case CLIENT_COMPETITORS_PATH:
      case ADMIN_COMPETITORS_PATH:
        return getCompetitorsPath({ scanId, clientId })
      case CLIENT_MPV_ANALYTICS:
      case ADMIN_MPV_ANALYTICS:
        return getMpvAnalyticsPath({ scanId: merchScanId, clientId })
      default:
        return getDashboardPath({ scanId, clientId })
    }
  }

  return {
    getDashboardPath,
    getPriceAndAvailabilityPath,
    getArtworkPath,
    getMerchandizingPath,
    getCompetitorsPath,
    getHistoryPath,
    getMpvAnalyticsPath,
  }
}
