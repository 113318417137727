import { CHANNELS, DATA } from './utils'

export const LOAD_CHANNELS = 'channels/LOAD'

export default function channelReducer(state, action) {
  if (action.type === LOAD_CHANNELS) {
    return state.withMutations(s => s.mergeIn([CHANNELS, DATA], action.channels))
  }
  return state
}
