import { SliderArrow } from 'Interfaces/interfaces'
import cx from 'classnames'
import React from 'react'
import { MegogoSectionTypes } from '../enums'
import style from './Arrow.module.scss'

type CustomArrowTypes = SliderArrow & {
  setSpotIndex?: (index: number) => void
}

const Arrow: React.FC<CustomArrowTypes> = ({ onClick, left, right, section, currentSlide, setSpotIndex }) => {
  const isHeroCarousel = section === MegogoSectionTypes._HeroCarousel
  const isPageContainer = section === MegogoSectionTypes._PageContainer
  const isLeftArrow = left && currentSlide !== 0
  const isRightArrow = right

  const arrowClass = cx({
    [style.leftArrowCarousel]: isHeroCarousel && isLeftArrow,
    [style.rightArrowCarousel]: isHeroCarousel && isRightArrow,
    [style.leftArrowPageContainer]: isPageContainer && isLeftArrow,
    [style.rightArrowPageContainer]: isPageContainer && isRightArrow,
    [style.leftArrow]: !isHeroCarousel && !isPageContainer && left,
    [style.rightArrow]: !isHeroCarousel && !isPageContainer && right,
  })

  const handleClick = () => {
    if (isPageContainer && setSpotIndex) {
      setSpotIndex(currentSlide)
    }
    onClick()
  }

  return (
    <div data-testid='arrow-div' className={arrowClass} onClick={handleClick}>
      <i className={style.arrow} data-testid='arrow-icon' />
    </div>
  )
}

export default Arrow
