import { PUBLISHERS, initialState } from './utils.js'

export const LOAD_PUBLISHERS = 'publishers/LOAD'

export default function publisherReducer(state, action) {
  switch (action.type) {
    case LOAD_PUBLISHERS: {
      return state.withMutations(s => {
        s.mergeIn([PUBLISHERS], action.publishers)
      })
    }
    default:
      return state || initialState
  }
}
