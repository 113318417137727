import { useDispatch } from 'react-redux'

import {
  clearTitleQuery,
  disablePromotedFilter,
  enablePriorityFilter,
  enablePromotedFilter,
  exportReadyTitlesForScan,
  reloadScan,
  setTitleAuditsSortParams,
  toggleAllActionsFilter,
  toggleAllArtwork,
  toggleAllAuditActionsFilter,
  toggleAllFilter,
  toggleAuditExceptionFilter,
  toggleAvailActionsFilter,
  toggleBlankArtworkFilter,
  toggleChangedArtworkFilter,
  toggleFormatAll,
  toggleFormatHD,
  toggleFormatSD,
  toggleHoldbackActionsFilter,
  toggleLicenseTypeAll,
  toggleLicenseTypeBuy,
  toggleLicenseTypeRent,
  toggleMovieFilter,
  togglePriceActionsFilter,
  toggleReadyAuditActionsFilter,
  toggleSeriesFilter,
  toggleSubmittedAuditActionsFilter,
  updateTitleQuery,
} from 'Actions/scanActions'

import {
  markUnavailableForeverTitles,
  markUnavailableTitles,
  refreshAmazonArtworks,
  relocateTitles,
  retryUnavailableTitles,
  selectClients,
  toggleClientCompetitor,
} from 'Actions/clientActions'
import { reloadTitles } from 'Actions/titleActions'
import {
  clearTitleQuery as clearQuery,
  disablePromoted,
  enablePriority,
  enablePromoted,
  setLetterFilter,
  setNewCandidatesFilter,
  stateFilterClear,
  stateFilterSet,
  toggleAllFilter as toggleAll,
  toggleEnabledFilter,
  toggleMovieFilter as toggleMovie,
  toggleSeriesFilter as toggleSeries,
  updateTitleQuery as updateQuery,
} from 'Actions/titleFilterActions'

import { deselectAllPlatforms, selectAllPlatforms, togglePlatforms } from 'Actions/platformActions'
import { deselectAllTerritories, selectAllTerritories, toggleTerritory } from 'Actions/territoryActions'
import { showScanAsGrid, showScanAsList, showScanStats } from 'Actions/uiActions'
import {
  toggleAssociatedMerchandizingScan
} from 'Actions/associatedMerchandizingScanActions'
const useToolbarActions = ({ competitors = false }) => {
  const dispatch = useDispatch()

  return {
    reloadScan: () => dispatch(reloadScan(competitors)),
    enablePromotedFilter: () => dispatch(enablePromotedFilter(competitors)),
    enablePriorityFilter: () => dispatch(enablePriorityFilter(competitors)),
    disablePromotedFilter: () => dispatch(disablePromotedFilter(competitors)),
    showScanStats: () => dispatch(showScanStats()),
    setTitleAuditsSortParams: (column, direction) => dispatch(setTitleAuditsSortParams(column, direction)),
    toggleAllActionsFilter: () => dispatch(toggleAllActionsFilter(competitors)),
    togglePriceActionsFilter: () => dispatch(togglePriceActionsFilter(competitors)),
    toggleHoldbackActionsFilter: () => dispatch(toggleHoldbackActionsFilter(competitors)),
    toggleAvailActionsFilter: () => dispatch(toggleAvailActionsFilter(competitors)),
    toggleFormatAll: () => dispatch(toggleFormatAll()),
    toggleFormatHD: () => dispatch(toggleFormatHD()),
    toggleFormatSD: () => dispatch(toggleFormatSD()),
    toggleLicenseTypeAll: () => dispatch(toggleLicenseTypeAll()),
    toggleLicenseTypeRent: () => dispatch(toggleLicenseTypeRent()),
    toggleLicenseTypeBuy: () => dispatch(toggleLicenseTypeBuy()),
    clearTitleQuery: () => dispatch(clearTitleQuery(competitors)),
    updateTitleQuery: query => dispatch(updateTitleQuery(query)),
    toggleMovieFilter: () => dispatch(toggleMovieFilter(competitors)),
    toggleSeriesFilter: () => dispatch(toggleSeriesFilter(competitors)),
    toggleAllFilter: () => dispatch(toggleAllFilter(competitors)),
    showScanAsList: () => dispatch(showScanAsList()),
    showScanAsGrid: () => dispatch(showScanAsGrid()),
    toggleAuditExceptionFilter: () => dispatch(toggleAuditExceptionFilter(competitors)),
    toggleSubmittedAuditActionsFilter: () => dispatch(toggleSubmittedAuditActionsFilter()),
    toggleReadyAuditActionsFilter: () => dispatch(toggleReadyAuditActionsFilter()),
    toggleAllAuditActionsFilter: () => dispatch(toggleAllAuditActionsFilter()),
    exportReadyTitlesForScan: () => dispatch(exportReadyTitlesForScan()),
    toggleTerritory: (territory, isOnScansPage, isOnMerchPage) =>
      dispatch(toggleTerritory(territory, competitors, isOnScansPage, isOnMerchPage)),
    selectAllTerritories: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllTerritories(isOnScansPage, isOnMerchPage)),
    deselectAllTerritories: () => dispatch(deselectAllTerritories(competitors)),
    togglePlatforms: (platformIds, isOnScansPage, isOnMerchPage) =>
      dispatch(togglePlatforms(platformIds, competitors, isOnScansPage, isOnMerchPage)),
    selectAllPlatforms: (isOnScansPage, isOnMerchPage) =>
      dispatch(selectAllPlatforms(competitors, isOnScansPage, isOnMerchPage)),
    deselectAllPlatforms: () => dispatch(deselectAllPlatforms()),
    toggleAllArtwork: () => dispatch(toggleAllArtwork()),
    toggleChangedArtworkFilter: () => dispatch(toggleChangedArtworkFilter()),
    toggleBlankArtworkFilter: () => dispatch(toggleBlankArtworkFilter()),
    setLetterFilter: letter => dispatch(setLetterFilter(letter)),
    toggleEnabledFilter: () => dispatch(toggleEnabledFilter()),
    refreshAmazonArtworks: () => dispatch(refreshAmazonArtworks()),
    markUnavailableForeverTitles: () => dispatch(markUnavailableForeverTitles()),
    markUnavailableTitles: () => dispatch(markUnavailableTitles()),
    relocateTitles: () => dispatch(relocateTitles()),
    retryUnavailableTitles: () => dispatch(retryUnavailableTitles()),
    setNewCandidatesFilter: () => dispatch(setNewCandidatesFilter()),
    stateFilterClear: () => dispatch(stateFilterClear()),
    stateFilterSet: state => dispatch(stateFilterSet(state)),
    clearQuery: () => dispatch(clearQuery()),
    toggleAll: () => dispatch(toggleAll()),
    toggleMovie: () => dispatch(toggleMovie()),
    toggleSeries: () => dispatch(toggleSeries()),
    updateQuery: query => dispatch(updateQuery(query)),
    reloadTitles: () => dispatch(reloadTitles()),
    enablePromoted: () => dispatch(enablePromoted()),
    enablePriority: () => dispatch(enablePriority()),
    disablePromoted: () => dispatch(disablePromoted()),
    selectClients: (clientIds) => dispatch(selectClients(clientIds)),
    toggleClientCompetitor: (competitorId) => dispatch(toggleClientCompetitor(competitorId)),
    toggleAssociatedMerchandizingScan: (amsIds) => dispatch(toggleAssociatedMerchandizingScan(amsIds)),
  }
}

export default useToolbarActions
