import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { linkToPage } from '../utils'
import style from './Brick2.module.scss'
import Item from './Item'

function Arrow({ currentSlide, onClick, left, right }) {
  const classnames = cx({
    [style.rightArrow]: right,
    [style.leftArrow]: left,
    [style.disabledArrow]: left && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

const getSliderSettings = (sliders, ArrowComponent) => ({
  dots: false,
  infinite: false,
  centerPadding: 0,
  slidesToScroll: sliders,
  slidesToShow: sliders,
  arrows: true,
  swipe: false,
  initialSlide: 0,
  nextArrow: <ArrowComponent right />,
  prevArrow: <ArrowComponent left />,
})

function Brick2({ section, match, spots, selectedStudios, isMerchandiseFilteringEnabled }) {
  const isStudioSelected = id => selectedStudios[id]

  const seeMoreSpot = spots.find(spot => spot && spot.name === 'View all')
  const sliders = section.sectionType === 'apple_tv::channelLockup' ? 10 : 5

  const renderSpots = () =>
    spots
      .filter(spot => spot && spot.name !== 'View all')
      .map(spot => {
        const itemClassNames = cx(style.brickItem, {
          [style.channelRoundItem]: section.sectionType === 'apple_tv::channelLockup',
          [style.item]: section.sectionType !== 'apple_tv::channelLockup',
        })

        return (
          <div className={itemClassNames} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
      })

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>
          {section.name}
          {seeMoreSpot && <a href={linkToPage(seeMoreSpot, match)}>{seeMoreSpot.name}</a>}
        </h1>
      </div>
      <Slider {...getSliderSettings(sliders, Arrow)} className={style.alignSpotsLeft}>
        {renderSpots()}
      </Slider>
    </div>
  )
}

const ConnectedBrick2 = withRouter(Brick2)
export default ConnectedBrick2
