import { Map } from 'immutable'
import { DATA, LINKS, META, TITLES, initialState } from './utils.js'
import { setLoadingState } from './loadingUtils.js'

export const UPDATE_TITLE = 'titles/UPDATE'

export default function titleReducer(state, action) {
  switch (action.type) {
    case 'LOAD_TITLES': {
      return state.withMutations(s => {
        action.reset ? s.setIn([TITLES, DATA], action.titles) : s.mergeIn([TITLES, DATA], action.titles)

        s.setIn([TITLES, LINKS], action.links)
        s.mergeIn(
          [TITLES, META],
          new Map({
            hasMoreToLoad: action.hasMoreToLoad ?? true,
            isLoading: action.isLoading ?? false,
          }),
        )
      })
    }

    case 'LOAD_TITLES_PAGE': {
      const { page } = action
      return state.withMutations(s => {
        s.mergeIn([TITLES, META], new Map({ page: page }))
      })
    }

    case UPDATE_TITLE: {
      return state.withMutations(s => {
        s.mergeIn([TITLES, DATA, action.titleId], action.attributes)
      })
    }

    case 'SET_TITLES_LOADING': {
      return setLoadingState(state, [TITLES, META], true);
    }

    default:
      return state || initialState
  }
}
