import { getChannels, getLocationCandidates, getPlatforms, getPrices, getTaggings, getTitles } from 'Selectors/index'
import { List, Map, Record } from 'immutable'
import { createSelector } from 'reselect'
import { UpdateableMixin } from './mixins'
import { getRelationshipData } from './utils'

class PlatformTitleRecord extends Record({
  id: 0,
  url: '',
  artworkUrl: '',
  artworkCdnUrl: '',
  platformId: 0,
  platformIdentifier: '',
  additionalPlatformIdentifiers: '',
  platformCdid: '',
  vendorIdentifier: '',
  name: '',
  platformName: '',
  selected: false,
  clientId: '',
  territoryName: '',
  territoryCode: '',
  titleTypeName: '',
  titleId: '',
  approved: false,
  artworkRefreshError: false,
  virtual: false,
  editing: false,
  hasNewCandidates: false,
  currentState: '',
  errorMessage: '',
  canAbort: false,
  canRetry: false,
  canApprove: false,
  canMarkAsUnavailable: false,
  canMarkAsUnavailableForever: false,
  canDeleteTransitions: false,
  canMarkAsVirtual: false,
  taggingsIds: [],
  channelsIds: [],
  isAvod: false,
  isPreorder: false,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  prices(state) {
    this.getOurPrices =
      this.getOurPrices ||
      createSelector(
        getPrices,
        (_state, props) => props.relationships.getIn(['prices', 'data']) || new List(),
        (prices, priceRelationships) => priceRelationships.map(item => prices.get(item.get('id')))
      )

    return this.getOurPrices(state, { relationships: this.relationships })
  }

  platform(state) {
    const platforms = getPlatforms(state)

    return platforms.get(this.platformId)
  }

  channels(state) {
    const channels = getChannels(state)

    return getRelationshipData(channels, this.channelsIds)
  }

  tags(state) {
    const taggings = getTaggings(state)

    return Array.from(this.taggingsIds.map(taggingId => taggings.get(taggingId)?.tag(state)).filter(tag => !!tag))
  }

  title(state) {
    const titles = getTitles(state)

    return titles.get(this.titleId)
  }

  locationCandidates(state) {
    const locationCandidates = getLocationCandidates(state)

    return locationCandidates.valueSeq().filter(locationCandidate => locationCandidate.platformTitleId === this.id)
  }

  selectedLocationCandidate(state) {
    return this.locationCandidates(state).find(lc => lc && lc.selected)
  }
}

export default UpdateableMixin(PlatformTitleRecord)
