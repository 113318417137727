import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { Intent } from '@blueprintjs/core'
import { cancelDownload, createScanDownload, retrieveScanDownloadsForClient } from 'Actions/scanDownloadActions'
import { enqueueToast } from 'Actions/uiActions'
import { useClientContext } from 'Contexts/ClientContext'
import { Scan } from 'Interfaces/*'
import queryString from 'query-string'

export enum DownloadStates {
  Complete = 'complete',
  InProgress = 'in_progress',
  Cancelled = 'cancelled',
  Error = 'error',
  Initialized = 'initialized',
}

export enum DownloadType {
  Merchandizing = 'MerchandizingScanDownload',
  Audit = 'AuditScanDownload',
  Artwork = 'ArtworkScanDownload',
}

export const useDownloads = () => {
  const dispatch = useDispatch()
  const { clientId } = useClientContext()
  const history = useHistory()

  const [scanDownloads, setScanDownloads] = useState([])

  const fetchDownloads = () => {
    retrieveScanDownloadsForClient(clientId).then(res => setScanDownloads(res.scanDownloads))
  }

  const updateDownloads = updatedDownload => {
    setScanDownloads(downloads =>
      downloads.map(d => (d.id === updatedDownload.id && d.type === updatedDownload.type ? updatedDownload : d))
    )
  }

  const cancel = async download => {
    const res: any = await dispatch(cancelDownload(download.id))
    updateDownloads(res.scanDownload)
  }

  const handleErrorResponse = response => {
    dispatch(enqueueToast({ intent: Intent.WARNING, message: response.message?.detail ?? 'Something went wrong' }))
  }

  const handleSuccessResponse = response => {
    const { scanDownload } = response
    const message = scanDownload?.type === DownloadType.Artwork ? 'PDF added to downloads' : 'Report added to downloads'

    setScanDownloads([scanDownload])

    dispatch(enqueueToast({ intent: Intent.SUCCESS, message: message }))
  }

  const startDownload = async (type: DownloadType, merchScan?: Scan) => {
    const res: any = await dispatch(createScanDownload(type, merchScan?.id))
    if (res.type === 'error') return handleErrorResponse(res)

    handleSuccessResponse(res)
  }

  const handleDownload = download => (window.location = download.url)

  const hasDownloadFinshed = useCallback(
    scanDownload => {
      if (scanDownload.status !== DownloadStates.Complete) return
      window.location.href = scanDownload.url

      const query = queryString.parse(history.location.search)

      history.push({ location: history.location.pathname, search: queryString.stringify(query) } as any)
    },
    [history]
  )

  return {
    updateDownloads,
    fetchDownloads,
    startDownload,
    cancel,
    scanDownloads,
    handleDownload,
    hasDownloadFinshed,
  }
}
