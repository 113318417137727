import AssociatedMerchandizingScanRecord from 'Records/associatedMerchandizingScanRecord'
import StudioStatisticsRecord from 'Records/studioStatisticsRecord'
import PlatformStatisticsRecord from 'Records/platformStatisticsRecord'
import {
  LOAD_ASSOCIATED_MERCHANDIZING_SCANS,
  TOGGLE_ASSOCIATED_MERCHANDISING_SCAN,
  LOAD_STATISTICS,
} from 'Reducers/app/associatedMerchandizingScanReducer'
import { Map, OrderedMap, fromJS } from 'immutable'
import { readEndpoint } from 'Actions/apiActions'

export const loadAssociatedMerchandizingScans = associatedMerchandizingScans => ({
  type: LOAD_ASSOCIATED_MERCHANDIZING_SCANS,
  associatedMerchandizingScans,
})

export const associatedMerchandizingScanRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new AssociatedMerchandizingScanRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadAssociatedMerchandizingScans = data => {
  const associatedMerchandizingScans = data.reduce((memo, item) => {
    return memo.set(item.id, new AssociatedMerchandizingScanRecord({ ...item }))
  }, new OrderedMap())

  return loadAssociatedMerchandizingScans(associatedMerchandizingScans)
}

export const toggleAssociatedMerchandizingScan = amsIds => dispatch => {
  dispatch({
    type: TOGGLE_ASSOCIATED_MERCHANDISING_SCAN,
    amsIds,
  })
}

export const retrieveMerchandizingScanStatistics = (scanIds, studioIds) => dispatch => {
  const url = `merchandizing/scans/statistics?limit=1000&page=1&filter[studio_ids]=${studioIds.join(',')}&filter[scan_ids]=${scanIds.join(',')}`

  return readEndpoint(url, 'GET').then(response => {
    dispatch(normalizeAndLoadStatistics(response))
  })
}

const createStatisticsMap = (items, type, RecordType) =>
  items.reduce((memo, item) => {
    const entityId = item[type].id
    return memo.set(entityId, new RecordType({ ...item, id: entityId }))
  }, new OrderedMap())

export const normalizeAndLoadStatistics = data => dispatch => {
  const studioStatistics = createStatisticsMap(data.studioStatistics, 'studio', StudioStatisticsRecord)

  const platformStatistics = createStatisticsMap(data.platformStatistics, 'platform', PlatformStatisticsRecord)

  dispatch({
    type: LOAD_STATISTICS,
    studioStatistics,
    platformStatistics,
  })
}
