import { SpotItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useRef, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import { YouTubeSectionTypes } from '../enums'
import { generateClientStyle } from '../helpers'
import { linkToPage } from '../utils'
import style from './Item.module.scss'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected]
  )

  const metadata = useMemo(() => {
    const defaultMetadata = { subtitle: '', currentRating: '' }
    return spot?.metadata ? { ...defaultMetadata, ...spot.metadata } : defaultMetadata
  }, [spot])

  const shouldRenderDetails = [
    YouTubeSectionTypes._Container,
    YouTubeSectionTypes._LandscapeContainer,
    YouTubeSectionTypes._Category,
  ].includes(section?.sectionType as YouTubeSectionTypes)

  return (
    <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
      <div
        id={`spot_${spot.id}`}
        style={{
          background: `black url(${spot.artworkUrl}) center center no-repeat`,
          backgroundSize: 'cover',
          marginBottom: '8px',
        }}
      ></div>
      {shouldRenderDetails && (
        <span className={style.detailWrapper}>
          <h6>{spot?.name}</h6>
          <p>
            <span className={style.collectionName}>{spot?.genre}</span>
            <br />
            {metadata.subtitle && <span className={style.subtitle}>{metadata.subtitle}</span>}
            {metadata.currentRating && <span className={style.rating}> {metadata.currentRating}</span>}
          </p>
        </span>
      )}
    </a>
  )
}

export default withRouter(Item)
