import WrappedSection from 'Containers/WrappedSection'
import { Navigation, Scan, Section, Spot } from 'Interfaces/interfaces'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage, linkToRootPage } from '../utils'
import style from './NavBar.module.scss'
const logo = require('./logo.png')

type NavBarProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, subSections, scan, match }) => {
  const shouldRenderDropdown = useCallback(
    (spot: Spot) => {
      return subSections.some(subsection => subsection.name === spot.name)
    },
    [subSections]
  )

  const renderDropdown = useCallback(
    (spot: Spot) => {
      return subSections
        .filter(s => s.name === spot.name)
        .map(s => (
          <WrappedSection
            spot={spot}
            type={s.sectionType}
            key={s.id}
            section={s}
            scan={scan}
            page={page}
            match={match}
          />
        ))
    },
    [subSections, scan, page, match]
  )

  const renderLink = useCallback(
    (spot: Spot) => (
      <a
        key={spot.id}
        href={linkToPage(spot, match)}
        className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
      >
        <span>{spot.name}</span>
      </a>
    ),
    [match, page]
  )

  const renderSpot = useCallback(
    (spot: Spot) => {
      return shouldRenderDropdown(spot) ? renderDropdown(spot) : renderLink(spot)
    },
    [shouldRenderDropdown, renderDropdown, renderLink]
  )

  const renderSpots = useMemo(() => spots.map(renderSpot), [spots, renderSpot])

  return (
    <div className={style.root}>
      <a className={style.logo} href={linkToRootPage(scan, match)}>
        <img alt='SfAnytime' data-selenium='site-logo' src={logo} />
      </a>
      <div className={style.menuLinks}>{renderSpots}</div>
    </div>
  )
}

export default withRouter(NavBar)
