import { Button } from '@blueprintjs/core'
import React from 'react'

class LicenseFilter extends React.Component {
  render() {
    const { filter } = this.props
    return (
      <div className='bp5-button-group'>
        <Button active={filter.licenseTypeAll} onClick={() => this.props.toggleLicenseTypeAll()}>
          All
        </Button>
        <Button active={filter.licenseTypeBuy} onClick={() => this.props.toggleLicenseTypeBuy()}>
          Buy
        </Button>
        <Button active={filter.licenseTypeRent} onClick={() => this.props.toggleLicenseTypeRent()}>
          Rent
        </Button>
      </div>
    )
  }
}

export default LicenseFilter
