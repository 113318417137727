import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import style from './HeroCarousel.module.scss'
import Item from './Item'

function Arrow(props) {
  const { onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
  })

  return (
    <div
      className={cx({
        [style.arrowWrapperLeft]: left === true,
        [style.arrowWrapperRight]: right === true,
      })}
      onClick={onClick}
    >
      <i className={classnames} />
    </div>
  )
}

function renderSpots(spots, section) {
  return spots.map(
    spot =>
      spot && (
        <div className={style.item} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
  )
}

function HeroCarousel(props) {
  const { section, spots } = props

  const sliderSettings = {
    dots: false,
    infinite: true,
    centerPadding: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: true,
    swipe: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }

  return (
    <div className={style.container}>
      <Slider {...sliderSettings}>{renderSpots(spots, section)}</Slider>
    </div>
  )
}

export default HeroCarousel
