import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { AmazonSectionTypes } from '../enums'
import { linkToPage, scrollToNode } from '../utils'
import icon12 from './12.png'
import icon15 from './15.png'
import icon18 from './18.png'
import style from './Item.module.scss'
import ownIcon from './own.svg'
import iconPg from './pg.png'
import shopIcon from './shop.svg'
import infoIcon from './info.svg'
import iconU from './u.png'
import IconRenderer from './IconRenderer'

class Item extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCoverHovered: false,
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  isSeeMoreSpot() {
    const { spot } = this.props
    return spot.name && spot.name.includes('See more')
  }

  renderWithLineBreaks(inputString) {
    if (!inputString) {
      return ''
    }
    const formattedText = inputString.replace(/{lineBreak}/g, '<br/>')
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  decodeHtmlEntities(inputString) {
    if (!inputString) {
      return ''
    }

    const textarea = document.createElement('textarea')
    textarea.innerHTML = inputString
    return textarea.value
  }

  renderButtonText(text) {
    if (!text) return null
    const formattedText = text
         .replace(/{lineBreak}/g, '<br/>')
         .replace(/{strike}([\d\.,\s]*\S+)/g, '<s>$1</s>')
    return <button className= {style.buttonSubtitle} dangerouslySetInnerHTML={{ __html: formattedText }} />
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section, match } = this.props
    const { coverUrl, subtitle, currentRating, icon, season, logoUrl, terms, liveInfo, promoMessage } = spot.metadata
    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const ratingIcons = {
      12: icon12,
      15: icon15,
      18: icon18,
      PG: iconPg,
      U: iconU,
    }
   
    const offerIcons = {
      OFFER_ICON: shopIcon,
      ENTITLED_ICON: ownIcon,
    }
    
    const infoIcons = {
      INFO_ICON: infoIcon
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    const shouldRenderShadow = section.sectionType === AmazonSectionTypes._HeroCarousel
    const shouldRenderSpotDetails = section.sectionType === AmazonSectionTypes._HeroCarousel

    return (
      <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        {shouldRenderShadow && <div className={style.shadow}></div>}
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
            marginBottom: '8px',
          }}
        >
          {shouldRenderSpotDetails && (
            <div className={style.amazonDetails}>
              <div className={style.amazonButtonWrapper}>
                {logoUrl && <img className={style.logoUrl} src={logoUrl} />}
                {coverUrl ? (
                  <div
                    style={{
                      height: 'fit-content',
                      marginBottom: '4px',
                    }}
                  >
                    <img
                      className={style.itemImage}
                      onMouseEnter={() => this.setState({ isCoverHovered: true })}
                      onMouseLeave={() => {
                        setTimeout(() => {
                          this.setState({ isCoverHovered: false })
                        }, 1000)
                      }}
                      src={coverUrl}
                    />
                  </div>
                ) : (
                  
                  <div className={style.title}
                    onMouseEnter={() =>
                      this.setState({ isCoverHovered: true })
                    }
                    onMouseLeave={() => {
                      setTimeout(() => {
                        this.setState({ isCoverHovered: false });
                      }, 1000);
                    }}
                  >
                    {spot.name}
                  </div>
                )}
                {liveInfo?.status && liveInfo?.time && (
                  <div className={style.liveInfo}>
                    <span className={style.status}>{liveInfo.status}</span>
                    <span className={style.time}>{liveInfo.time}</span>
                  </div>
                )}
                {promoMessage && <span className={style.promoMessage}>{promoMessage}</span>}
                <p className={`${style.season} ${this.state.isCoverHovered ? style.visible : ''}`}>
                  {this.decodeHtmlEntities(season)}
                </p>
                <div className={style.infoDetails}>
                  {this.renderButtonText(subtitle)}
                  { spot?.spotType === 'content' &&(
                    <button className={style.infoButton}>
                      <IconRenderer
                        iconKey='INFO_ICON'
                        iconMap={infoIcons}
                        className={style.infoIcon}
                        width='40'
                        height='40'
                      />
                    </button>
                  )}
                </div>
                <div className={style.amazonDescriptionWrapper}>
                  <div className={style.offerDetails}>
                    <IconRenderer
                      iconKey={icon}
                      iconMap={offerIcons}
                      className={style.shopIcon}
                      width='24'
                      height='24'
                    />
                    {icon === 'ADS_ICON' && <span className={style.offerIcon}>ads</span>}
                    <span className={style.description}>{spot.description}</span>
                  </div>
                  <div className={style.contentWrapper}>
                    <span className={style.termsMessage}>{this.renderWithLineBreaks(terms)}</span>
                    {currentRating && !Object.keys(ratingIcons).includes(currentRating) && (
                      <span className={style.currentRating}>{currentRating}</span>
                    )}
                    <IconRenderer
                      iconKey={currentRating}
                      iconMap={ratingIcons}
                      className={style.ratingIcon}
                      width='33'
                      height='33'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <span className={style.amazonCollection}>{spot.collectionName}</span>
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(Item)
export default ConnectedItemWithRouter
