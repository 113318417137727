import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import style from './GoogleTwinHero.module.scss'
import GoogleTwinheroCarousel from './GoogleTwinheroCarousel'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class GoogleTwinHero extends React.Component {
  render() {
    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 2,
      slidesToShow: 2,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={style.container}>
        <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
      </div>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props

    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <GoogleTwinheroCarousel
              key={spot.id}
              spots={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  spots() {
    const { spots } = this.props

    return spots
      .filter(spot => spot && spot.spotType === 'content')
      .reduce((acc, item) => {
        let lastGroup = acc.pop()
        const newGroup = []
        if (!lastGroup) {
          lastGroup = [item]
        } else if (lastGroup.length < 3) {
          lastGroup.push(item)
        } else if (lastGroup.length >= 3) {
          newGroup.push(item)
        }

        const result = [...acc, lastGroup]

        if (newGroup.length) {
          result.push(newGroup)
        }

        return result
      }, [])
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default GoogleTwinHero
