import { PageItems, Spot } from 'Interfaces/interfaces'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import Slider from 'react-slick'
import { AppleTvSectionTypes } from '../enums'
import { renderSpotsWithItem, sliderSettings } from '../helpers'
import { linkToPage } from '../utils'
import Arrow from './Arrow'
import Item from './Item'
import style from './Swoosh.module.scss'

const RenderChartData = (spot: Spot) => {
  return (
    <div className={style.chartWrapper}>
      <div>{spot.position}</div>
      <div>
        <p>{spot.name}</p>
        <span>{spot.metadata?.subtitle ?? ''}</span>
      </div>
    </div>
  )
}

const RenderNotesLockupDetailedData = (spot: Spot) => (
  <div className={style.notesLockupDetailWrapper}>
    <span>{spot.metadata?.subtitle ?? ''}</span>
    <p>{spot.name ?? ''}</p>
  </div>
)

const Swoosh: React.FC<PageItems> = ({ section, isMerchandiseFilteringEnabled, spots, selectedStudios }) => {
  const match = useRouteMatch()

  const isStudioSelected = id => {
    return selectedStudios ? selectedStudios[id] : false
  }

  const seeMoreSpot = spots.find(spot => spot && spot.name === 'View all')

  const renderItemDetailComponents = {
    [AppleTvSectionTypes._Chart]: RenderChartData,
    [AppleTvSectionTypes._NotesLockupDetailed]: RenderNotesLockupDetailedData,
  }

  const RenderItemDetail = renderItemDetailComponents[section.sectionType] || null

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h1>
          <span>{section.name}</span>
          {seeMoreSpot && (
            <a href={linkToPage(seeMoreSpot, match)}>
              <i className={style.seeMoreArrow} />
            </a>
          )}
        </h1>
      </div>
      <Slider
        {...sliderSettings({ nextArrow: <Arrow right />, prevArrow: <Arrow left /> })}
        className={style.alignSpotsLeft}
      >
        {renderSpotsWithItem({
          spots: spots.filter(spot => spot && spot.name !== 'View all'),
          ItemComponent: Item,
          section,
          isMerchandiseFilteringEnabled,
          styleClassName: style.item,
          additionalProps: { isStudioSelected },
          children: spot => (RenderItemDetail ? RenderItemDetail(spot) : null),
        })}
      </Slider>
    </div>
  )
}

export default Swoosh
