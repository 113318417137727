import WrappedSection from 'Containers/WrappedSection'
import React from 'react'

class GenericSection extends React.Component {
  render() {
    return <div>{this.renderSubSections()}</div>
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props

    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }
}

export default GenericSection
