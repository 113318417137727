import React from 'react'
import { Classes } from '@blueprintjs/core'

type PerformanceDetailProps = {
  title: string
  description: string
}

const PerformanceDetail: React.FC<PerformanceDetailProps> = ({ title, description }) => {
  return (
    <div className={Classes.DIALOG_BODY}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  )
}

export default PerformanceDetail
