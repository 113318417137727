import { PageItems, Spot } from 'Interfaces/interfaces'
import React, { useState, useMemo, useCallback } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { renderSpotsWithItem } from '../helpers'
import { linkToPage } from '../utils'
import style from './HeroCarousel.module.scss'
import Item from './HeroItem'

type HeroCarouselProps = RouteComponentProps & PageItems

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
  match,
}) => {
  const [selectedSpot, setSelectedSpot] = useState<Spot | null>(null)

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => spot),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
      setSelectedSpot: setSelectedSpot,
    },
  })

  const metadata = useMemo(() => (selectedSpot ? selectedSpot.metadata : {}), [selectedSpot])

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      centerPadding: 0,
      slidesToScroll: 5,
      arrows: false,
      focusOnSelect: true,
      slidesToShow: 5,
      swipe: true,
      initialSlide: 0,
    }),
    []
  )

  const handleShadowBoxClick = useCallback(() => {
    window.location.href = linkToPage(selectedSpot, match)
  }, [selectedSpot, match])

  return (
    <div
      className={style.container}
      style={{
        backgroundColor: 'black',
        backgroundImage: `url(${selectedSpot ? metadata.coverUrl : ''})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
      }}
    >
      <div className={style.shadowBox} onClick={handleShadowBoxClick}>
        <div className={style.heading}>
          {selectedSpot && (
            <React.Fragment>
              <h1>{selectedSpot.name}</h1>
              <div className={style.details}>
                {metadata.icon && <img className={style.icon} src={metadata.icon} />}
                <span className={style.collectionName}>{selectedSpot.collectionName}</span>
                <span className={style.subtitle}>{metadata.subtitle}</span>
                <span className={style.label}>{metadata.currentRating}</span>
              </div>
              <h3>{selectedSpot.description}</h3>
              <button>{metadata.subtitle}</button>
            </React.Fragment>
          )}
        </div>
        <div className={style.sliderContainer}>
          <Slider {...sliderSettings}>{renderSpots}</Slider>
        </div>
      </div>
    </div>
  )
}

export default withRouter(HeroCarousel)
