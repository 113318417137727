import { Button, Menu, MenuDivider, MenuItem, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, useHistory } from 'react-router-dom'

import { bulkApprove, refreshTitleArtworks, relocateTitle, retryUnavailables, updateTitle } from 'Actions/titleActions'
import ActionConfirmationModal from 'Components/ContainerPage/ActionConfirmationModal'
import TitleDetail from 'Components/ContainerPage/TitleDetail'
import styles from 'Components/ContainerPage/TitleRow.module.scss'
import useContainerActions from 'Components/ContainerPage/hooks/useContainerActions'
import Container from 'Containers/Container'
import { Platform } from 'Interfaces/*'
import { getRootPath, getTitleById } from 'Selectors/index'
import { markUnavailableForeverTitles, markUnavailableTitles } from '../actions/clientActions'

type PlatformWithFlagType = {
  platform: Platform
  showFlag: boolean
}

interface TitleRowProps {
  titleId: string | number
  platformsWithFlags: PlatformWithFlagType[]
}

const TitleRow: React.FC<TitleRowProps> = ({ titleId, platformsWithFlags }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const rootPath = useSelector(getRootPath)
  const title = useSelector(state => getTitleById(state, { id: titleId }))
  const { handleAction, handleCloseModal, handleSubmitAction, isModalOpen, triggeredActionName } = useContainerActions()

  const titleDetailUrl = useCallback(() => `${rootPath}/title/${title && title.id}`, [rootPath, title])

  const handleTitleDetailLinkClick = useCallback(() => {
    history.push(titleDetailUrl())
  }, [history, titleDetailUrl])

  const handleRelocateClick = useCallback(() => {
    dispatch(relocateTitle(titleId))
  }, [dispatch, titleId])

  const handleBulkApproveClick = useCallback(() => {
    dispatch(bulkApprove(titleId))
  }, [dispatch, titleId])

  const handleRetryClick = useCallback(() => {
    dispatch(retryUnavailables(titleId))
  }, [dispatch, titleId])

  const handleBulkUnavailableForeverClick = useCallback(() => {
    dispatch(markUnavailableForeverTitles(titleId))
  }, [dispatch, titleId])

  const handleBulkUnavailableClick = useCallback(() => {
    dispatch(markUnavailableTitles(titleId))
  }, [dispatch, titleId])

  const handleRefreshArtworks = useCallback(() => {
    dispatch(refreshTitleArtworks(titleId))
  }, [dispatch, titleId])

  const headingClasses = cx(styles.header, {
    [styles.tvHeading]: title && title.titleTypeCode === 'tv_season',
    [styles.movieHeading]: title && title.titleTypeCode === 'movie',
  })

  const rowClasses = cx(styles.row, {
    [styles.tvRow]: title && title.titleTypeCode === 'tv_season',
    [styles.movieRow]: title && title.titleTypeCode === 'movie',
  })

  return (
    <div className={rowClasses}>
      <div className={headingClasses}>
        <Link to={titleDetailUrl()} className={styles.link}>
          {title && <h4>{title.name}</h4>}
          {title && <div className={styles.titleType}>{title.titleType}</div>}
        </Link>
        <div className={styles.cog}>
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.BOTTOM}
            content={
              <Menu>
                <MenuItem
                  className={styles.menuItem}
                  icon='search'
                  text='Relocate pending and errored'
                  onClick={() => handleAction('relocate pending and errored', handleRelocateClick)}
                />
                <MenuItem
                  className={styles.menuItem}
                  icon='automatic-updates'
                  text='Retry unavailables'
                  onClick={() => handleAction('retry unavailables', handleRetryClick)}
                />
                <MenuItem
                  className={styles.menuItem}
                  icon='one-column'
                  text='Bulk Unavailable Forever'
                  onClick={() => handleAction('bulk unavailable forever', handleBulkUnavailableForeverClick)}
                />
                <MenuItem
                  className={styles.menuItem}
                  icon='pause'
                  text='Bulk Unavailable'
                  onClick={() => handleAction('bulk unavailable', handleBulkUnavailableClick)}
                />
                <MenuItem
                  className={styles.menuItem}
                  icon='tick-circle'
                  text='Bulk approve'
                  onClick={() => handleAction('bulk approve', handleBulkApproveClick)}
                />
                <MenuItem
                  className={styles.menuItem}
                  icon='image-rotate-left'
                  text='Refresh amazon artworks'
                  onClick={() => handleAction('refresh amazon artworks', handleRefreshArtworks)}
                />
                <MenuDivider />
                <MenuItem className={styles.menuItem} text='Edit' icon='cog' onClick={handleTitleDetailLinkClick} />
              </Menu>
            }
          >
            <Button icon='cog' aria-label='Settings' minimal />
          </Popover>
        </div>
      </div>

      <Route
        path={titleDetailUrl()}
        render={props => (
          <TitleDetail
            {...props}
            title={title}
            updateTitle={(title, attributes) => dispatch(updateTitle(title, attributes))}
          />
        )}
      />

      <ActionConfirmationModal
        title={title}
        isOpen={isModalOpen}
        actionName={triggeredActionName}
        onSubmit={handleSubmitAction}
        onClose={handleCloseModal}
      />

      {platformsWithFlags &&
        platformsWithFlags.map(
          p => p.platform && <Container key={p.platform.id} platformId={p.platform.id} titleId={title.id} />
        )}
    </div>
  )
}

export default TitleRow
