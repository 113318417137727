import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './TabMenu.module.scss'

function renderNavSpots(spots, match) {
  return (
    <div className={style.root}>
      <ul className={style.mediaNav}>
        {spots &&
          spots.map(
            spot =>
              spot && (
                <li className={style.listItem} key={spot.id}>
                  <a href={linkToPage(spot, match)}>{spot.name}</a>
                </li>
              )
          )}
      </ul>
    </div>
  )
}

function TabMenu(props) {
  const { match, spots } = props

  return (
    <div>
      <React.Fragment>{renderNavSpots(spots, match)}</React.Fragment>
    </div>
  )
}

export default withRouter(TabMenu)
