import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'
import logo from './logo.png'

class NavBar extends React.Component {
  renderSpots() {
    const { spots, match } = this.props
    return (
      spots &&
      spots.map(
        spot =>
          spot && (
            <li className={style.listItem} key={spot.id}>
              <a href={linkToPage(spot, match)}>{spot.name}</a>
            </li>
          )
      )
    )
  }

  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  renderNavSpots() {
    return (
      <div className={style.root}>
        <a href={this.rootPageUrl()} className={style.logo}>
          <img alt='MoviesAnywhere' data-selenium='site-logo' src={logo} />
        </a>
        <ul className={style.mediaNav}>{this.renderSpots()}</ul>
      </div>
    )
  }

  render() {
    return (
      <div>
        <ul>{this.renderNavSpots()}</ul>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

const ConnectedNavbarMenu = connect(mapStateToProps)(NavBar)
const ConnectedNavbarMenuWithRouter = withRouter(ConnectedNavbarMenu)

export default ConnectedNavbarMenuWithRouter
