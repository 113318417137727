import cx from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

class Panel extends React.Component {
  render() {
    const { title, children, className, headerClassname, contentClassname, date, showScanAsList } = this.props

    return (
      <div className={cx(styles.root, className)}>
        <div className={cx(styles.panelHeader, headerClassname)}>
          {showScanAsList ? (
            <span className={styles.clickableTitle} onClick={() => showScanAsList()}>
              {title}
            </span>
          ) : (
            <span>{title}</span>
          )}
          {date && <span className={styles.scanDate}>{moment(date).format('MM.DD.YYYY')}</span>}
        </div>
        <div className={cx(styles.panelContent, contentClassname)}>{children}</div>
      </div>
    )
  }
}

Panel.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
}

export default Panel
