import { Button, Dialog } from '@blueprintjs/core'
import { DateRangeInput } from '@blueprintjs/datetime'
import cx from 'classnames'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

class IgnoreActionDialog extends React.Component {
  constructor(props) {
    super(props)
    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() + 20)

    this.state = {
      minDate: new Date(props.scan.createdAt),
      maxDate: maxDate,
      startDate: this.formatDate(props.scan.createdAt),
      endDate: moment(props.scan.createdAt).add(1, 'months').format('YYYY/MM/DD'),
      errorMessage: null,
    }
  }

  formatDate = date => moment(date).format('YYYY/MM/DD')

  onDateChange = e => {
    this.setState({
      startDate: this.formatDate(e[0]),
      endDate: this.formatDate(e[1]),
      errorMessage: null,
    })
  }

  isValidDateRange = () => {
    const { startDate, endDate } = this.state

    if (!moment(startDate).isValid() || !moment(endDate).isValid()) return false
    if (startDate > endDate) return false
    return true
  }

  closeAndIgnoreAction = action => {
    const isValidDateRange = this.isValidDateRange()
    if (isValidDateRange) {
      this.props.ignoreAction(action, this.state.startDate, this.state.endDate)
      this.props.toggleIgnoreActionDialog(null)
    } else {
      this.setState({
        errorMessage: 'Invalid date range!',
      })
    }
  }

  closeAndDeleteException = auditException => {
    this.props.deleteAuditException(auditException, this.props.audit)
    this.props.toggleDeleteExceptionDialog(null)
  }

  render() {
    const { isOpen, action, auditException, toggleDeleteExceptionDialog, toggleIgnoreActionDialog } = this.props

    const renderDeleteAuditException = () => {
      return (
        <Dialog
          icon='ban-circle'
          isOpen={isOpen}
          onClose={() => toggleDeleteExceptionDialog(null)}
          title={`Delete Suppress Action`}
        >
          <React.Fragment key={auditException.id}>
            <div className='bp5-dialog-body'>
              <p>
                Type: <b>{auditException.license}</b>
              </p>
              <p>
                Variant: <b>{auditException.variant}</b>
              </p>
              {auditException.actionType !== 'AuditAvailAction' ? (
                <p>
                  Price: <b>{auditException.price}</b>
                </p>
              ) : null}
              <p>
                Action Type: <b>{auditException.actionType}</b>
              </p>
              <p>
                Start Date: <b>{auditException.startDate}</b>
              </p>
              <p>
                End Date: <b>{auditException.endDate}</b>
              </p>
              <h6>
                The following action will be generated again for <b>the next scan.</b>
              </h6>
              <h6>Are you sure?</h6>
            </div>
            <div className='bp5-dialog-footer'>
              <div className='bp5-dialog-footer-actions'>
                <Button
                  onClick={() => this.closeAndDeleteException(auditException)}
                  className='bp5-intent-primary bp5-icon-ban-circle'
                >
                  Delete
                </Button>
              </div>
            </div>
          </React.Fragment>
        </Dialog>
      )
    }

    const renderCreateIgnoredActiion = () => {
      const { errorMessage } = this.state
      const saveButtonClassnames = cx('bp5-intent-primary', {
        'bp5-disabled': !!errorMessage,
      })

      return (
        <Dialog icon='disable' isOpen={isOpen} onClose={() => toggleIgnoreActionDialog(null)} title='Ignore Action'>
          {action && (
            <React.Fragment key={action.id}>
              <div className='bp5-dialog-body'>
                <h6>This action won&apos;t be generated futher during the following period.</h6>
                <DateRangeInput
                  range={[this.state.startDate, this.state.endDate]}
                  onChange={this.onDateChange}
                  minDate={this.state.minDate}
                  maxDate={this.state.maxDate}
                  formatDate={date => (date == null ? '' : this.formatDate(date))}
                  parseDate={str => new Date(str)}
                />
              </div>
              <div className='bp5-dialog-footer'>
                {errorMessage && <p className={styles.ignoreActionErrorMessage}>{errorMessage}</p>}
                <div className='bp5-dialog-footer-actions'>
                  <Button onClick={() => this.closeAndIgnoreAction(action)} className={saveButtonClassnames}>
                    Save
                  </Button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Dialog>
      )
    }
    return auditException ? renderDeleteAuditException() : renderCreateIgnoredActiion()
  }
}

IgnoreActionDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleDeleteExceptionDialog: PropTypes.func,
  toggleIgnoreActionDialog: PropTypes.func,
  ignoreAction: PropTypes.func,
  deleteAuditException: PropTypes.func,
  action: PropTypes.object,
  auditException: PropTypes.object,
  scan: PropTypes.object,
  audit: PropTypes.object,
}

export default IgnoreActionDialog
