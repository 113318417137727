import { Spot } from 'Interfaces/interfaces'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './LinkExternalWithText.module.scss'

type LinkExternalWithTextProps = RouteComponentProps & {
  spots: Spot[]
}

const LinkExternalWithText: React.FC<LinkExternalWithTextProps> = ({ spots, match }) => {
  const spot = [...spots][0]
  const { subtitle } = spot?.metadata

  const backgroundStyle = { backgroundImage: `url(${decodeURIComponent(spot.artworkUrl)})` }

  return (
    <div className={style.container}>
      <a href={linkToPage(spot, match)}>
        <div className={style.linkExternalWrapper} style={backgroundStyle}></div>
      </a>
      <div className={style.linkExternalDetails}>
        <p className={style.spotName}>{spot.name}</p>
        <h2 className={style.spotHeader}>{subtitle}</h2>
        <p className={style.spotDescription}>{spot.description}</p>
        <a className={style.spotLink} href={linkToPage(spot, match)}>
          {spot.label}
        </a>
      </div>
    </div>
  )
}

export default withRouter(LinkExternalWithText)
