import cx from 'classnames'
import React from 'react'

const PlatformMenuItem = ({ isEnabled, onClick, item, isSelected }) => {
  const menuClassnames = cx('bp5-menu-item', {
    'bp5-icon-small-tick': isSelected,
    'bp5-disabled': !isEnabled,
  })

  return (
    <li>
      <a className={menuClassnames} onClick={onClick}>
        {item.name}
      </a>
    </li>
  )
}

export default PlatformMenuItem
