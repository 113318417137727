import { getAuditPrices, getPlatformTitles, getPrices } from 'Selectors/index'
import { Map, Record } from 'immutable'

export default class AuditActionRecord extends Record({
  id: 0,
  priceId: 0,
  auditPriceId: 0,
  channelId: 0,
  auditId: 0,
  platformTitleId: 0,
  titleAuditId: 0,
  message: '',
  name: '',
  actionClass: '',
  format: '',
  license: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
  status: '',
  statusLastTransitionDate: new Date(),
}) {
  platformTitle(state) {
    const platformTitles = getPlatformTitles(state)

    return platformTitles.get(this.platformTitleId)
  }

  price(state) {
    const prices = getPrices(state)

    return prices.get(this.priceId)
  }

  auditPrice(state) {
    const auditPrices = getAuditPrices(state)

    return auditPrices.get(this.auditPriceId)
  }
}
