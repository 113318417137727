import React from 'react'

import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './SeeMore.module.scss'

class SeeMore extends React.Component {
  render() {
    return (
      <a href={linkToPage(this.props.spot, this.props.match)} className={style.root}>
        See more
      </a>
    )
  }
}

const ConnectedSeeMoreWithRouter = withRouter(SeeMore)
export default ConnectedSeeMoreWithRouter
