import React from 'react'
import Slider from 'react-slick'
import style from './Carousel.module.scss'
import Item from './Item'

export default class HeroCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  renderSpots(spots, section) {
    return spots.map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item spot={spot} section={section} />
          </div>
        )
    )
  }

  render() {
    const { spots, section } = this.props
    const sliderSettings = {
      dots: true,
      infinite: true,
      centerPadding: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
      centerMode: true,
      arrows: false,
      swipe: true,
      appendDots: dots => {
        const customDots = React.Children.map(dots, dot => {
          if (dot.props['className'] === 'slick-active') {
            return React.cloneElement(dot, {
              className: style.slickActive,
            })
          } else {
            return React.cloneElement(dot, {
              className: style.slickNormal,
            })
          }
        })
        return (
          <div style={{ bottom: '10px' }}>
            <ul style={{ paddingLeft: '7px' }}>
              <li onClick={this.previous}>
                <i className={style.leftArrow} />
              </li>
              {customDots}
              <li onClick={this.next}>
                <i className={style.rightArrow} />
              </li>
            </ul>
          </div>
        )
      },
      customPaging: i => (
        <div
          style={{
            display: 'block',
            textIndent: '-9999px',
            width: '11px',
            height: '11px',
            boxShadow: '1px 1px 4px rgba(0,0,0,.4)',
            overflow: 'hidden',
          }}
        >
          {i + 1}
        </div>
      ),
    }
    return (
      <div className={style.container}>
        <Slider ref={c => (this.slider = c)} {...sliderSettings}>
          {this.renderSpots(spots, section)}
        </Slider>
      </div>
    )
  }
}
