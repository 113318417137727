import { Button } from '@blueprintjs/core'
import { DownloadType, useDownloads } from 'Hooks/useDownloads'
import cx from 'classnames'
import React from 'react'
import styles from './Toolbar.module.scss'

const DownloadButton = () => {
  const { startDownload } = useDownloads()

  const handleClickOnDownload = () => {
    startDownload(DownloadType.Artwork)
  }

  return (
    <div>
      <Button className={cx('bp5-button', styles.btnDefault)} onClick={handleClickOnDownload}>
        Generate PDF
      </Button>
    </div>
  )
}

export default DownloadButton
