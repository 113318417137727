import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Match, PageProps, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React from 'react'
import { linkToPage } from '../utils'
import style from './SubMenu.module.scss'

interface SubMenuProps {
  spot: Spot
  spots: Spot[]
  page: PageProps
  match: Match
}

const SubMenu: React.FC<SubMenuProps> = ({ spot, spots, page, match }) => {
  const renderDropdown = (spot, spots) => {
    return (
      <div className={style.dropdownWrapper}>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM}
          popoverClassName='bp5-popover-display-none'
          content={
            <div className={style.dropdown}>
              <ul className={style.dropdownList}>
                {spots
                  .filter(spot => !!spot)
                  .map(spot => {
                    return (
                      <li className={style.listItem} key={spot.id}>
                        <a href={linkToPage(spot, match)} className={cx('activeLink', spot.linkedPageId === page.id)}>
                          <span>{spot.name}</span>
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </div>
          }
        >
          <a className={style.dropdownItem} id={`spot_${spot.id}`}>
            <i className={style.arrowDown} data-testid='arrow-icon' /> {spot.name}
          </a>
        </Popover>
      </div>
    )
  }

  return renderDropdown(spot, spots)
}

export default SubMenu
