import { Spot } from 'Interfaces/interfaces'
import React from 'react'
import { ViaplaySectionTypes } from '../enums'
import ContainerDetails from './ContainerDetails'
import HeroCarouselDetails from './HeroCarouselDetails'
import PortraitDetails from './PortraitDetails'

const SpotDetails: React.FC<{ spot: Spot; sectionType: ViaplaySectionTypes }> = ({ spot, sectionType }) => {
  const { imdbRating, year, duration, season } = spot?.metadata

  switch (sectionType) {
    case ViaplaySectionTypes._HeroCarousel:
      return <HeroCarouselDetails spot={spot} imdbRating={imdbRating} year={year} duration={duration} />
    case ViaplaySectionTypes._Container:
    case ViaplaySectionTypes._LandscapeContainer:
    case ViaplaySectionTypes._LargeLandscapeContainer:
    case ViaplaySectionTypes._Grid:
    case ViaplaySectionTypes._Collection:
    case ViaplaySectionTypes._CollectionGrid:
    case ViaplaySectionTypes._MosaicContainer:
    case ViaplaySectionTypes._SymmetricMosaicContainer:
      return <ContainerDetails imdbRating={imdbRating} year={year} season={season} />
    case ViaplaySectionTypes._PortraitContainer:
    case ViaplaySectionTypes._PortraitGrid:
      return (
        <PortraitDetails
          spot={spot}
          sectionType={sectionType}
          imdbRating={imdbRating}
          year={year}
          season={season}
          duration={duration}
        />
      )
    default:
      return null
  }
}

export default SpotDetails
