import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import GenericSection from 'Components/Merchandizing/Itunes/GenericSection'
import style from 'Components/Merchandizing/Itunes/GenresDropdown.module.scss'

class GenresDropdown extends GenericSection {
  constructor(props) {
    super(props)

    const { section } = this.props
    const { metadata } = section
    this.metadata = JSON.parse(metadata)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    const { match } = this.props
    const { path } = match
    const toPath = pathToRegexp.compile(path)

    const pagePath = toPath({ ...match.params, pageId: event.target.value })
    window.location = pagePath
  }

  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  render() {
    const { spotsWithLinkedPages, page } = this.props
    const selectedValue = page.id || this.rootPageId()
    return (
      <div className={style.root}>
        <select onChange={this.handleChange} value={selectedValue}>
          <option key={page.id} value={this.rootPageId()}>
            {'All Genres'}
          </option>
          {spotsWithLinkedPages.map(
            s =>
              s && (
                <option key={s.id} value={s.linkedPageId}>
                  {s.label}
                </option>
              )
          )}
        </select>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const spots = ownProps.section.spots(state)
  const spotsWithLinkedPages = spots && spots.filter(spot => spot && spot.linkedPageId)
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    spotsWithLinkedPages,
    associatedMerchandizingScan,
  }
}

const ConnectedGenresDropdown = connect(mapStateToProps)(GenresDropdown)
const ConnectedGenresDropdownWithRouter = withRouter(ConnectedGenresDropdown)
export default ConnectedGenresDropdownWithRouter
