import React, { ReactNode, useEffect, useMemo, createContext, useContext } from 'react'
import { retrieveClient, retrieveCurrentClient } from 'Actions/clientActions'
import { Client } from 'Records/clientRecord'
import { getClient } from 'Selectors/index'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router'

export interface ClientProviderProps {
  children: ReactNode
}

export interface ClientContextValue {
  client: Client
  clientId: () => string
  isAdminRoute: any
}

const ClientContext = createContext<ClientContextValue>({
  client: null,
  clientId: () => '',
  isAdminRoute: false,
})

const ClientContextProvider = ClientContext.Provider

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const client = useSelector(state => getClient(state))

  const isAdminRoute: any = useMemo(() => {
    const clientScanPath = matchPath(location.pathname, { path: '/v2/clients/:id', strict: false })
    const merchandizingPath = matchPath(location.pathname, { path: '/v2/merchandizing/scans/:id', strict: false })

    return clientScanPath || merchandizingPath
  }, [location])

  const isClientsRoute: any = useMemo(
    () => matchPath(location.pathname, { path: '/v2/clients/:id', strict: false }),
    [location]
  )

  const clientId = useMemo(() => {
    if (isClientsRoute) {
      return isClientsRoute.params.id
    } else {
      return client.id || ''
    }
  }, [isClientsRoute, client.id])

  useEffect(() => {
    if (!clientId) {
      if (!isClientsRoute) dispatch(retrieveCurrentClient())
      return
    }

    if (client?.id !== clientId) dispatch(retrieveClient(clientId))
  }, [client?.id, clientId, dispatch, isClientsRoute])

  const value = {
    client,
    clientId,
    isAdminRoute: !!isAdminRoute,
  }

  return <ClientContextProvider value={value}>{children}</ClientContextProvider>
}

export const useClientContext = () => {
  return useContext(ClientContext)
}
