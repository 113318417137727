import { Icon } from '@blueprintjs/core'
import PlatformRecordType from 'Records/platformRecord'
import React from 'react'
import BulkSelector from './BulkSelector'
import MultiSelect from './MultiSelect'
import { CHEVRON_DOWN, CHEVRON_UP } from './constants'
import style from './index.module.scss'

type PlatformWithRegionGroupProps = {
  listGroup: any
  isOpen: boolean
  toggleList: (id: string) => void
  handleBulkSelect: (platformGroup: PlatformRecordType) => void
  handleBulkDeselect: (platformGroup: PlatformRecordType) => void
  getTerritoryById?: (id: number) => any
  handleOnChange: (listItem: any) => void
  disableSelection: boolean
  header?: string
}

const PlatformWithRegionGroup: React.FC<PlatformWithRegionGroupProps> = ({
  listGroup,
  isOpen,
  toggleList,
  handleBulkSelect,
  handleBulkDeselect,
  getTerritoryById,
  handleOnChange,
  disableSelection,
  header,
}) => {
  const listId = listGroup.first()?.get('id')

  return (
    <div className={style.collapseWrapper} key={listId}>
      <h4 className={style.collapseHeader} onClick={() => toggleList(listId)}>
        {listGroup.first()?.name}
        <Icon icon={isOpen ? CHEVRON_UP : CHEVRON_DOWN} className={style.icon} />
      </h4>
      {isOpen && (
        <div className={style.collapseBody}>
          <BulkSelector
            onSelect={() => !disableSelection && handleBulkSelect(listGroup)}
            onDeselect={() => handleBulkDeselect(listGroup)}
          />
          <MultiSelect
            header={header}
            list={listGroup}
            getIsoCode={getTerritoryById}
            onChange={handleOnChange}
            disableSelection={disableSelection}
          />
        </div>
      )}
    </div>
  )
}

export default PlatformWithRegionGroup
