import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import { Button } from '@blueprintjs/core'

import styles from './Pagination.module.css'

import { getAuditActions, getAuditActionsMeta } from 'Selectors/index'

import { retrieveAuditActionsNextPage, retrieveAuditActionsPrevPage } from 'Actions/auditActions'

class Pagination extends React.Component {
  render() {
    const { recordCount, actionCount } = this.props
    return (
      <nav className={cx(styles.root, 'bp5-navbar')}>
        <div className='bp5-navbar-group bp5-align-left'>
          <div className='bp5-button-group'>
            <Button className='bp5-icon-chevron-left' onClick={() => this.props.retrieveAuditActionsPrevPage()}>
              Prev
            </Button>
            <Button className='bp5-icon-chevron-right' onClick={() => this.props.retrieveAuditActionsNextPage()}>
              Next
            </Button>
          </div>
          <div className='bp5-navbar-divider' />
          <div>
            Showing {actionCount} of {recordCount} actions
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = state => {
  const meta = getAuditActionsMeta(state)
  const auditActions = getAuditActions(state)
  return {
    actionCount: auditActions.size,
    recordCount: meta && meta.get('totalCount'),
  }
}

const mapDispatchToProps = dispatch => {
  return {
    retrieveAuditActionsNextPage: () => dispatch(retrieveAuditActionsNextPage()),
    retrieveAuditActionsPrevPage: () => dispatch(retrieveAuditActionsPrevPage()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pagination)
