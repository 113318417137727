import { DATA, MERCHANDIZING, PLATFORM_TITLES } from '../utils'
export const LOAD_MERCHANDIZING_PLATFORM_TITLES = 'merchandizing/platformTitles/LOAD'
export const LOADING_MERCHANDIZING_PLATFORM_TITLES = 'merchandizing/platformTitles/LOADING'

export default function merchandizingPlatfromTitleReducer(state, action) {
  switch (action.type) {
    case LOAD_MERCHANDIZING_PLATFORM_TITLES: {
      return state.withMutations(s => {
        const { platformTitles } = action

        action.reset
          ? s.setIn([MERCHANDIZING, PLATFORM_TITLES, DATA], platformTitles)
          : s.mergeIn([MERCHANDIZING, PLATFORM_TITLES, DATA], platformTitles)
      })
    }
    default:
      return state
  }
}
