import React from 'react'

import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './GenreMenuItem.module.scss'

class GenreMenuItem extends React.Component {
  render() {
    const { spot, match } = this.props

    return (
      <div className={style.item}>
        <a className={style.link} href={linkToPage(spot, match)}>
          {spot.name}
        </a>
      </div>
    )
  }
}

const ConnectedGenreMenuItemWithRouter = withRouter(GenreMenuItem)
export default ConnectedGenreMenuItemWithRouter
