import * as Sentry from '@sentry/react'

const reportSentry = (e, metadata) => {
  Sentry.withScope(scope => {
    if (metadata) {
      const { severity, user, ...otherMetadata } = metadata

      scope.setLevel(severity || 'error')

      if (user) scope.setUser(user)

      scope.setContext('metadata', otherMetadata)
    }

    Sentry.captureException(e, {
      extra: typeof e === 'string' ? { stackTracke: new Error().stack } : null,
    })
  })
}

export const reportError = (e, metadata) => {
  const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

  if (SENTRY_DSN) {
    reportSentry(e, metadata)
  } else {
    // This will log an error in an environment without sentry
    console.error(e, metadata)
  }
}
