import { PageItems, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import Slider from 'react-slick'
import { YouTubeSectionTypes } from '../enums'
import { computeContainerClasses, renderSpotsWithItem } from '../helpers'
import { linkToPage } from '../utils'
import Arrow from './Arrow'
import style from './Container.module.scss'
import Item from './Item'

type ContainerProps = RouteComponentProps & PageItems

const Container: React.FC<ContainerProps> = ({
  section,
  isMerchandiseFilteringEnabled,
  spots,
  selectedStudios,
  match,
}) => {
  const seeMore = useMemo(() => spots.find((spot: Spot) => spot && spot.spotType === 'link'), [spots])

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.containerItem]: section.sectionType === YouTubeSectionTypes._Container,
        [style.categoryItem]: section.sectionType === YouTubeSectionTypes._Category,
        [style.landscapeContainer]: section.sectionType === YouTubeSectionTypes._LandscapeContainer,
      }),
    [section]
  )

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => spot && spot.spotType !== 'link'),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      centerPadding: 0,
      slidesToScroll: 6,
      slidesToShow: 6,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right section={section.sectionType} />,
      prevArrow: <Arrow left section={section.sectionType} />,
    }),
    [section.sectionType]
  )

  return (
    <div className={containerClasses}>
      <div className={style.heading}>
        <h1>
          {section.name} {seeMore && <a href={linkToPage(seeMore, match)}>{seeMore.name}</a>}
        </h1>
        {section.metadata?.subtitle && <h3>{section.metadata?.subtitle}</h3>}
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
