import { List, Map } from 'immutable'

export const ENABLE_TEXT_ACTION_HIGHLIGHT_MODE = 'ui/ENABLE_TEXT_ACTION_HIGHLIGHT_MODE'
export const ENABLE_TRIANGLE_ACTION_HIGHLIGHT_MODE = 'ui/ENABLE_TRIANGLE_ACTION_HIGHLIGHT_MODE'
export const ENQUEUE_TOAST = 'ui/ENQUEUE_TOAST'
export const CLEAR_TOASTS = 'ui/CLEAR_TOASTS'
export const SHOW_SCAN_AS_GRID = 'ui/SHOW_SCAN_AS_GRID'
export const SHOW_SCAN_AS_LIST = 'ui/SHOW_SCAN_AS_LIST'
export const SHOW_MERCHANDIZING_SCAN_AS_LIST = 'ui/SHOW_MERCHANDIZING_SCAN_AS_LIST'
export const SHOW_MERCHANDIZING_SCAN_AS_GRID = 'ui/SHOW_MERCHANDIZING_SCAN_AS_GRID'
export const SHOW_SCAN_STATS = 'ui/SHOW_SCAN_STATS'
export const SET_ROOT_PATH = 'ui/SET_ROOT_PATH'
export const SHOW_CONTAINER_VIEW = 'ui/SHOW_CONTAINER_VIEW'
export const SHOW_MERCH_VIEW = 'ui/SHOW_MERCH_VIEW'

export default function uiReducer(state, action) {
  switch (action.type) {
    case ENABLE_TEXT_ACTION_HIGHLIGHT_MODE: {
      return state.withMutations(s => {
        s.set('actionHighlightMode', 'text')
      })
    }

    case ENABLE_TRIANGLE_ACTION_HIGHLIGHT_MODE: {
      return state.withMutations(s => {
        s.set('actionHighlightMode', 'triangle')
      })
    }

    case ENQUEUE_TOAST: {
      return state.withMutations(s => {
        const toastQueue = s.get('toastQueue')
        s.set('toastQueue', toastQueue.push(action.toast))
      })
    }

    case CLEAR_TOASTS: {
      return state.withMutations(s => {
        s.set('toastQueue', new List())
      })
    }

    case SHOW_SCAN_AS_GRID: {
      return state.withMutations(s => {
        s.set('scanView', 'grid')
      })
    }

    case SHOW_SCAN_AS_LIST: {
      return state.withMutations(s => {
        s.set('scanView', 'list')
      })
    }

    case SHOW_MERCHANDIZING_SCAN_AS_GRID: {
      return state.withMutations(s => {
        s.set('merchandisingScanView', 'grid')
      })
    }

    case SHOW_MERCHANDIZING_SCAN_AS_LIST: {
      return state.withMutations(s => {
        s.set('merchandisingScanView', 'list')
      })
    }

    case SHOW_SCAN_STATS: {
      const value = state.get('scanStats')
      return state.withMutations(s => {
        s.set('scanStats', !value)
      })
    }

    case SET_ROOT_PATH: {
      return state.withMutations(s => {
        s.set('rootPath', action.path)
      })
    }

    case SHOW_CONTAINER_VIEW: {
      return state.withMutations(s => {
        s.set('containerView', true)
      })
    }

    default:
      return (
        state ||
        new Map({
          actionHighlightMode: 'text',
          toastQueue: new List(),
          scanView: 'grid',
          merchandisingScanView: 'grid',
          scanStats: true,
          rootPath: '',
          containerView: false,
        })
      )
  }
}
