import { Map } from 'immutable'
import { DATA, FILTERS, MARKET_SHARES, META } from './utils'

export const LOADING_MARKET_SHARES = 'market_share/LOADING'
export const LOAD_MARKET_SHARES = 'market_share/LOAD'
export const LOAD_MARKET_SHARES_FILTERING = 'market_share_filtering/LOAD'
export const TOGGLE_STUDIO = 'market_share/TOGGLE_STUDIO'
export const TOGGLE_TERRITORY = 'market_share/TOGGLE_TERRITORY'
export const TOGGLE_PLATFORMS = 'market_share/TOGGLE_PLATFORMS'
export const TOGGLE_DATE_RANGE = 'market_share/TOGGLE_DATE_RANGE'
export const TOGGLE_GROUP_BY_STUDIO = 'market_share/TOGGLE_GROUP_BY_STUDIO'
export const TOGGLE_MOVIES = 'market_share/TOGGLE_MOVIES'
export const TOGGLE_SERIES = 'market_share/TOGGLE_SERIES'

export default function clientMarketSharesSummaryReducer(state, action) {
  switch (action.type) {
    case LOADING_MARKET_SHARES: {
      return state.withMutations(s => {
        s.setIn([MARKET_SHARES, META], new Map({ loading: true }))
      })
    }
    case LOAD_MARKET_SHARES: {
      return state.withMutations(s => {
        s.setIn([MARKET_SHARES, DATA], action.marketShares)
        s.setIn([MARKET_SHARES, META], new Map({ loading: false }))
      })
    }
    case LOAD_MARKET_SHARES_FILTERING: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.loadFilter(action))
    }
    case TOGGLE_STUDIO: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleStudio(action.studio))
    }
    case TOGGLE_TERRITORY: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleTerritory(action.territory))
    }
    case TOGGLE_PLATFORMS: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.togglePlatforms(action.platforms))
    }
    case TOGGLE_DATE_RANGE: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleDateRange(action.dateRange))
    }
    case TOGGLE_GROUP_BY_STUDIO: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleGroupByStudio())
    }
    case TOGGLE_MOVIES: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleMovies())
    }
    case TOGGLE_SERIES: {
      const filters = state.getIn([MARKET_SHARES, FILTERS])
      return state.setIn([MARKET_SHARES, FILTERS], filters.toggleSeries())
    }
    default:
      return state
  }
}
