import cx from 'classnames'
import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { scrollToNode } from '../../utils'
import style from './SeeMoreItem.module.scss'

class SeeMoreItem extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  spotLink() {
    const { spot, match } = this.props

    if (!spot.linkedPageId) {
      return spot.url
    }

    const { path } = match

    const toPath = pathToRegexp.compile(path)
    return toPath({ ...match.params, pageId: spot.linkedPageId })
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section } = this.props

    const metadata = spot.metadata || {}

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    return (
      <a href={this.spotLink()} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{ background: `black url(${spot.artworkUrl}) center center no-repeat`, backgroundSize: 'cover' }}
        ></div>
        <div className={style.titleInfo}>
          <h6>{spot.name}</h6>
          <p>IMDb {metadata.imdb_rating}</p>
          <p>{metadata.year}</p>
        </div>
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(SeeMoreItem)
export default ConnectedItemWithRouter
