import { Record } from 'immutable'

export const ClientTitleTypeTV = 'tv_season'
export const ClientTitleTypeFilm = 'movie'

export interface Client {
  id: string
  name: string
  color: string
  selected: boolean
  clientTitleType: string
  titleTypeSeason: string
  titleTypeMovie: string
  titleTypeBoth: string
  appSections: {
    merchandizing: boolean
    artwork: boolean
    availabilityPricing: boolean
    competitors: boolean
    analyticsMpv: boolean
  }
  sortOptions: {
    'titles.name': { default: boolean; enabled: boolean; direction: string }
    actionCount: { default: boolean; enabled: boolean; direction: string }
    'titles.totalSalesUnits': { default: boolean; enabled: boolean; direction: string }
    'titles.productionYear': { default: true; enabled: boolean; direction: 'desc' }
  }
  variants: {}
  platformTypes: {}
  platforms: []
  marketShareReportEnabled: boolean
  isMerchandizingListEnabled: boolean
  isConnectedDevicesEnabled: boolean
  isScreenshotEnabled: boolean
  tableauEnabled: boolean
  tagsEnabled: boolean
  dragonflyEnabled: boolean
  dragonflyMerchUrl: string
  dragonflyArtworkUrl: string
  higlightedTitles: boolean
  uberActions: true
  loadingPlatformTitles: boolean
  identityProvider: null
  studios: []
}

export const defaultValues: Client = {
  id: '0',
  name: '',
  color: '',
  selected: false,
  clientTitleType: '',
  titleTypeSeason: '',
  titleTypeMovie: '',
  titleTypeBoth: '',
  appSections: {
    merchandizing: false,
    artwork: false,
    availabilityPricing: false,
    competitors: false,
    analyticsMpv: false,
  },
  sortOptions: {
    'titles.name': { default: false, enabled: false, direction: 'asc' },
    actionCount: { default: false, enabled: false, direction: 'asc' },
    'titles.totalSalesUnits': { default: false, enabled: false, direction: 'asc' },
    'titles.productionYear': { default: true, enabled: false, direction: 'desc' },
  },
  variants: {},
  platformTypes: {},
  platforms: [],
  marketShareReportEnabled: false,
  isMerchandizingListEnabled: false,
  isConnectedDevicesEnabled: false,
  isScreenshotEnabled: false,
  tableauEnabled: false,
  tagsEnabled: false,
  dragonflyEnabled: false,
  dragonflyMerchUrl: '',
  dragonflyArtworkUrl: '',
  higlightedTitles: false,
  uberActions: true,
  loadingPlatformTitles: false,
  identityProvider: null,
  studios: [],
}

export default class ClientRecord extends Record(defaultValues) {
  get<T extends keyof Client>(value: T): Client[T] {
    return super.get(value)
  }
}
