import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

type ActionItemProps = {
  title: string
  total: number | string
  difference: number
}

const ActionItem: React.FC<ActionItemProps> = ({ title, total, difference }) => (
  <div className={styles.actionStateItem}>
    <span className={styles.title}>{title}</span>
    <span>
      <span className={styles.number}>{total}</span>
      <span
        className={cx({
          [styles.difference]: difference === 0,
          [styles.differenceDown]: difference > 0,
          [styles.differenceUp]: difference < 0,
        })}
      >
        {`${difference > 0 ? '+' : ''}${difference}`}
      </span>
    </span>
  </div>
)

export default ActionItem
