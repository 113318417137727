import WrappedSection from 'Containers/WrappedSection'
import { Navigation, Scan, Section, Spot } from 'Interfaces/interfaces'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'

type NavBarProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, subSections, scan, match }) => {
  const isDropdown = useCallback((spot: Spot, subSections: Section[]) => {
    return subSections.some(subsection => subsection.name === spot.name)
  }, [])

  const renderDropdown = useCallback(
    (spot: Spot, subSections: Section[]) => {
      return subSections.map(
        s =>
          s.name === spot.name && (
            <WrappedSection
              spot={spot}
              type={s.sectionType}
              key={s.id}
              section={s}
              scan={scan}
              page={page}
              match={match}
            />
          )
      )
    },
    [scan, page, match]
  )

  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
        >
          <span>{spot.name}</span>
        </a>
      )
    },
    [match, page]
  )

  const renderSpots = useMemo(() => {
    return spots.map(spot => {
      if (isDropdown(spot, subSections)) {
        return renderDropdown(spot, subSections)
      }
      return renderLink(spot)
    })
  }, [spots, isDropdown, subSections, renderLink, renderDropdown])

  return (
    <div className={style.root}>
      <div className={style.menuLinks}>{renderSpots}</div>
    </div>
  )
}

export default withRouter(NavBar)
