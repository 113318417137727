import WrappedSection from 'Containers/WrappedSection'
import React from 'react'

import style from './ActionBarItem.module.scss'

import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'

class ActionBarItem extends React.Component {
  renderSpot() {
    const spot = this.props.spots.first()

    const url = linkToPage(spot, this.props.match)

    return <a href={url}>{spot && spot.name}</a>
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }

  render() {
    const spot = this.props.spots.first()

    return <div className={style.item}>{spot ? this.renderSpot() : this.renderSubSections()}</div>
  }
}

const ConnectedActionBarItemWithRouter = withRouter(ActionBarItem)
export default ConnectedActionBarItemWithRouter
