import React, { useState } from 'react'
import { Button, Icon, Menu, MenuItem, Popover, PopoverInteractionKind, Position, Tooltip } from '@blueprintjs/core'
import DownloadModal from 'Components/DownloadModal/DownloadModal'
import { useClientContext } from 'Contexts/ClientContext'
import cx from 'classnames'
import styles from './styles.module.scss'

const HELP_URL = 'https://looperinsightscore.zendesk.com/hc/en-gb'

const RightButtons = () => {
  const { isAdminRoute } = useClientContext()
  const [downloadModalOpen, setDownloadModalOpen] = useState(false)
  const signOut = () => {
    window.location.href = `${process.env.REACT_APP_CORE_API_URL}/logout`
  }

  const handleClickOpenHelp = () => window.open(HELP_URL, '_blank')

  return (
    <div className='bp5-navbar-group bp5-align-right'>
      <Tooltip content='Downloads' position={Position.BOTTOM}>
        <Button
          data-testid='download-button'
          className={cx('bp5-button', 'bp5-minimal', styles.btnDefault)}
          onClick={() => setDownloadModalOpen(true)}
        >
          <Icon icon='document' className={styles.labelColor} />
        </Button>
      </Tooltip>
      {!isAdminRoute && (
        <>
          <span className='bp5-navbar-divider' />

          <Tooltip content='Help centre' position={Position.BOTTOM}>
            <Button
              data-testid='help-button'
              className={cx('bp5-button', 'bp5-minimal', styles.btnDefault)}
              onClick={handleClickOpenHelp}
            >
              <Icon icon='help' className={styles.labelColor} />
            </Button>
          </Tooltip>
          <span className='bp5-navbar-divider' />

          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            position={Position.BOTTOM_RIGHT}
            content={
              <Menu>
                <MenuItem data-testid='logout-button' text='Log Out' onClick={signOut} />
              </Menu>
            }
          >
            <Button data-testid='user-button' className={cx('bp5-button', 'bp5-minimal', styles.btnDefault)}>
              <Icon icon='user' className={styles.labelColor} />
            </Button>
          </Popover>
        </>
      )}
      <DownloadModal isOpen={downloadModalOpen} onClose={() => setDownloadModalOpen(false)} />
    </div>
  )
}

export default RightButtons
