import { Navigation, Section, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { linkToPage } from '../utils'
import style from './TabMenu.module.scss'

type PageTabMenuProps = RouteComponentProps &
  Navigation & {
    section: Section
  }

const PageTabMenu: React.FC<PageTabMenuProps> = ({ spots, page, section, match }) => {
  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          className={cx(style.tabItem, { [style.activeTab]: spot.linkedPageId === page.id })}
          href={linkToPage(spot, match)}
        >
          {spot.name}
        </a>
      )
    },
    [page, match]
  )

  const renderSpots = useMemo(() => spots.map(renderLink), [spots, renderLink])

  return (
    <div className={style.tabMenuContainer}>
      <div className={style.tabWrapper} id={section.name}>
        {renderSpots}
      </div>
    </div>
  )
}
export default withRouter(PageTabMenu)
