import { getAuditActions } from 'Selectors/index'
import { Record } from 'immutable'

export default class ChannelRecord extends Record({
  id: 0,
  name: '',
}) {
  auditActions(state) {
    const auditActions = getAuditActions(state)

    return auditActions.filter(auditAction => auditAction.channelId === this.id)
  }
}
