import { PageItems } from 'Interfaces/*'
import React from 'react'
import { renderSpotsWithItem } from '../helpers'
import Item from './Item'

import style from './Mosaic.module.scss'

type MosaicProps = PageItems

const Mosaic: React.FC<MosaicProps> = ({ section, isMerchandiseFilteringEnabled, spots, selectedStudios }) => {
  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.mosaicSpot,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return <div className={style.mosaicContainer}>{renderSpots}</div>
}

export default Mosaic
