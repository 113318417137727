import PropTypes from 'prop-types'
import React from 'react'
import { matchPath } from 'react-router'
import { withRouter } from 'react-router-dom'
import { sortPlatformGroupsByNameAndTerritory, territorySorter } from '../utils'
import PlatformMenuItem from './PlatformMenuItem'
import PlatformMenuItemWithCheckbox from './PlatformMenuItemWithCheckbox'
import TerritoryMenuItem from './TerritoryMenuItem'
import style from './index.module.scss'

class PlatformAndTerritoryList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOnScansPage: null,
      isOnMerchPage: null,
      openPlatformDropdown: {},
    }
  }

  componentDidMount() {
    this.checkPageType()
  }

  componentDidUpdate(prevProps) {
    const { territories } = this.props
    if (prevProps.territories !== territories) {
      this.handleTerritoryChange()
    }
  }

  setOpenPlatformDropdown = platformCode => {
    this.setState({ openPlatformDropdown: platformCode })
  }

  matchPathHelper = path => matchPath(window.location.pathname, { path, strict: false })

  checkPageType = () => {
    const isOnScansPage = this.matchPathHelper(`/v2/clients/:id/scans`) || this.matchPathHelper(`/scans/:id`)
    const isOnMerchPage =
      this.matchPathHelper(`/v2/clients/:id/merchandizing/scans`) || this.matchPathHelper(`/merch/:scan_id/pages/:id`)
    this.setState({ isOnScansPage, isOnMerchPage })
  }

  handleTerritoryChange = () => {
    const { territories } = this.props
    const areAnyTerritoriesSelected = territories.some(territory => territory.get('selected'))
    if (!areAnyTerritoriesSelected) {
      this.setState({ openPlatformDropdown: {} })
    }
  }

  deselectAll = () => {
    const { deselectAllTerritories, deselectAllPlatforms } = this.props
    deselectAllTerritories()
    deselectAllPlatforms()
  }

  selectAll = () => {
    const { isOnScansPage, isOnMerchPage } = this.state
    const { selectAllTerritories, selectAllPlatforms } = this.props
    !isOnScansPage && selectAllTerritories(isOnScansPage, isOnMerchPage)
    selectAllPlatforms(isOnScansPage, isOnMerchPage)
  }

  isEnabled = platformGroup => {
    const { territories } = this.props
    return !!territories.filter(t => {
      if (!t.selected) return false
      return platformGroup
        .map(pg => pg.territoryId)
        .toArray()
        .includes(parseInt(t.id))
    }).size
  }

  isSelected = platformGroup => {
    const { territories } = this.props

    const selectedTerritory = territories.find(territory => territory.selected)
    return !!platformGroup.find(p => selectedTerritory?.id === p.territoryId)?.selected
  }

  renderBulkSelectors = () => {
    const { selectAllTerritories, deselectAllTerritories } = this.props
    const { isOnScansPage, isOnMerchPage } = this.state
    return (
      <React.Fragment>
        {!isOnScansPage && (
          <li className={`bp5--menu-header ${style.bulkSelectors}`}>
            <a onClick={this.selectAll}>Select All</a>
            <span className='separator'> | </span>
            <a onClick={this.deselectAll}>Clear All</a>
          </li>
        )}
        <li className={`bp5--menu-header ${style.bulkSelectors}`}>
          <div className={style.selectorContainer}>
            <strong>Territories</strong>
          </div>
          {!isOnScansPage && (
            <div>
              <a
                onClick={() => {
                  selectAllTerritories(isOnScansPage, isOnMerchPage)
                }}
              >
                Select All
              </a>
              <span className='separator'> | </span>
              <a onClick={deselectAllTerritories}>Clear All</a>
            </div>
          )}
        </li>
      </React.Fragment>
    )
  }

  renderPlatformSelectors = () => {
    const { selectAllPlatforms, deselectAllPlatforms } = this.props
    const { isOnScansPage, isOnMerchPage } = this.state

    return (
      <li className={`bp5--menu-header ${style.bulkSelectors}`}>
        <div className={style.selectorContainer}>
          <h6>Platforms</h6>
        </div>
        <div>
          <a
            type='button'
            data-testid='selectAllPlatforms'
            onClick={() => {
              selectAllPlatforms(isOnScansPage, isOnMerchPage)
            }}
          >
            Select All
          </a>
          <span className='separator'> | </span>
          <a type='button' data-testid='clearAllPlatforms' onClick={deselectAllPlatforms}>
            Clear All
          </a>
        </div>
      </li>
    )
  }

  render() {
    const {
      territories,
      toggleTerritory,
      togglePlatforms,
      platformGroups,
      withCheckbox,
      selectTerritories,
      deselectAllPlatforms,
      selectPlatformsForSelectedTerritories,
    } = this.props

    const { isOnScansPage, isOnMerchPage, openPlatformDropdown } = this.state

    return (
      <ul className={`bp5-menu ${style.platformAndTerritoryList}`}>
        {this.renderBulkSelectors()}
        {territories
          .valueSeq()
          .sort(territorySorter)
          .map(territory => (
            <TerritoryMenuItem
              item={territory}
              onClick={() => {
                toggleTerritory(territory, isOnScansPage, isOnMerchPage)
                selectPlatformsForSelectedTerritories()
              }}
              key={territory.get('id')}
            />
          ))}
        {this.renderPlatformSelectors()}
        {sortPlatformGroupsByNameAndTerritory(platformGroups)
          .valueSeq()
          .map(platformGroup => {
            const isEnabled = this.isEnabled(platformGroup)
            const isSelected = this.isSelected(platformGroup)
            const selectedPlatform = platformGroup.filter(p => p.selected).first() || platformGroup.first()

            if (!withCheckbox) {
              return (
                <PlatformMenuItem
                  isSelected={isSelected}
                  item={selectedPlatform}
                  key={selectedPlatform.id}
                  isEnabled={isEnabled}
                  onClick={() => {
                    togglePlatforms(platformGroup.map(i => i.id).valueSeq(), isOnScansPage, isOnMerchPage)
                  }}
                />
              )
            } else if (withCheckbox) {
              return (
                <PlatformMenuItemWithCheckbox
                  isSelected={isSelected}
                  items={platformGroup}
                  key={selectedPlatform.get('id')}
                  isEnabled={isEnabled}
                  togglePlatforms={togglePlatforms}
                  territories={territories}
                  setOpenPlatformDropdown={this.setOpenPlatformDropdown}
                  selectTerritories={selectTerritories}
                  isOnScansPage={isOnScansPage}
                  isOnMerchPage={isOnMerchPage}
                  deselectAllPlatforms={deselectAllPlatforms}
                  deselectAll={this.deselectAll}
                  toggleTerritory={toggleTerritory}
                  selectedPlatform={selectedPlatform}
                  isPlatformListEnabled={
                    openPlatformDropdown === selectedPlatform.get('code') && selectedPlatform.get('selected')
                  }
                />
              )
            }
          })}
      </ul>
    )
  }
}

PlatformAndTerritoryList.propTypes = {
  territories: PropTypes.object,
  toggleTerritory: PropTypes.func,
  selectAllTerritories: PropTypes.func,
  deselectAllTerritories: PropTypes.func,
  togglePlatforms: PropTypes.func,
  platformGroups: PropTypes.object,
  selectTerritories: PropTypes.func,
}

export default withRouter(PlatformAndTerritoryList)
