import React from 'react'
import MultiRowItem from './MultiRowItem'

function MultiRowCarousel(props) {
  const { spots, client, section, hideTitle, isMerchandiseFilteringEnabled } = props

  return spots.map(
    spot =>
      spot && (
        <MultiRowItem
          key={spot.id}
          spot={spot}
          isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
          client={client}
          section={section}
          hideTitle={hideTitle}
        />
      )
  )
}

export default MultiRowCarousel
