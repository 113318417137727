import { NonIdealState, Spinner } from '@blueprintjs/core'
import LocationCandidate from 'Containers/LocationCandidate'
import React from 'react'
import styles from './LocationCandidatesPanel.module.scss'

class LocationCandidatesPanel extends React.Component {
  render() {
    const { locationCandidates, platformTitle, closeParent, areLocationCandidatesLoading } = this.props

    return (
      <div className={styles.root}>
        {areLocationCandidatesLoading || platformTitle.currentState === 'locating' ? (
          <Spinner />
        ) : (
          <React.Fragment>
            {locationCandidates.isEmpty() && <NonIdealState visual='search' title='No candidates available' />}
            {locationCandidates.map(
              locationCandidate =>
                locationCandidate && (
                  <LocationCandidate
                    locationCandidate={locationCandidate}
                    key={locationCandidate.id}
                    platformTitle={platformTitle}
                    closeParent={closeParent}
                  />
                )
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default LocationCandidatesPanel
