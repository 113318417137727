import { Menu, MenuItem } from '@blueprintjs/core'
import { Cell, Column, ColumnHeaderCell, Table } from '@blueprintjs/table'
import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import { retrieveAuditActions } from 'Actions/auditActions'
import PlatformCell from 'Components/ScanPage/ActionList/PlatformCell'
import PlatformTitleCell from 'Components/ScanPage/ActionList/PlatformTitleCell'
import TitleCell from 'Components/ScanPage/ActionList/TitleCell'
import styles from 'Components/ScanPage/ActionList/style.module.css'
import { getAuditActions } from 'Selectors/index'

import { setActionListSortParams } from 'Actions/scanActions'
import { getScanFilters } from 'Selectors/index'

class ActionList extends React.Component {
  componentDidMount() {
    const { scan, retrieveAuditActions, competitors } = this.props
    retrieveAuditActions(scan.id, competitors)
  }

  renderTitleCell = rowIndex => {
    return <TitleCell rowIndex={rowIndex} attribute={'name'} />
  }

  renderTitleTypeCell = rowIndex => {
    return <TitleCell rowIndex={rowIndex} attribute={'titleType'} />
  }

  renderVendorIdCell = rowIndex => {
    return <PlatformTitleCell rowIndex={rowIndex} attribute={'vendorIdentifier'} />
  }

  renderActionCell = rowIndex => {
    const { auditActions } = this.props
    const item = auditActions.valueSeq().get(rowIndex)
    return <Cell>{item.message}</Cell>
  }

  renderActionTypeCell = rowIndex => {
    const { auditActions } = this.props
    const item = auditActions.valueSeq().get(rowIndex)
    return <Cell>{item.name}</Cell>
  }

  renderPlatformCell = rowIndex => {
    return <PlatformCell rowIndex={rowIndex} attribute='name' propName='platform' />
  }

  renderTerritoryCell = rowIndex => {
    return <PlatformCell rowIndex={rowIndex} attribute='isoCode' propName='territory' />
  }

  renderLicenseCell = rowIndex => {
    return <PlatformCell rowIndex={rowIndex} attribute='license' propName='auditAction' />
  }

  renderFormatCell = rowIndex => {
    return <PlatformCell rowIndex={rowIndex} attribute='format' propName='auditAction' />
  }

  columnHeaderCellRenderer = (name, columnSortName) => () => {
    const { filter, setActionListSortParams } = this.props
    const renderMenu = () => {
      return (
        <Menu>
          <MenuItem
            icon='sort-asc'
            text={`Sort ${name} Asc`}
            onClick={() => setActionListSortParams(columnSortName, 'asc')}
          />
          <MenuItem
            icon='sort-desc'
            text={`Sort ${name} Desc`}
            onClick={() => setActionListSortParams(columnSortName, 'desc')}
          />
        </Menu>
      )
    }

    const columnClassName = cx('test', {
      [styles.sortColumn]: columnSortName === filter.actionListSortColumn,
    })

    return <ColumnHeaderCell name={name} renderMenu={renderMenu} className={columnClassName} />
  }

  render() {
    const { auditActions } = this.props
    return (
      <Table
        numRows={auditActions.count()}
        defaultColumnWidth={300}
        fillBodyWithGhostCells
        columnWidths={[100, 300, 100, 100, 100, 100, 100, 100, 300]}
        defaultRowHeight={35}
        className={styles.root}
      >
        <Column
          name='Vendor ID'
          cellRenderer={this.renderVendorIdCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Vendor ID', 'platform-title.title.vendor-identifier')}
        />
        <Column
          name='Title'
          cellRenderer={this.renderTitleCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Title', 'platform-title.title.name')}
        />
        <Column
          name='Type'
          cellRenderer={this.renderTitleTypeCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Type', 'platform-title.title.title_type_id')}
        />
        <Column
          name='Platform'
          cellRenderer={this.renderPlatformCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Platform', 'platform-title.platform.name')}
        />
        <Column
          name='Territory'
          cellRenderer={this.renderTerritoryCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Territory', 'platform-title.platform.territory.iso-code')}
        />
        <Column name='License' cellRenderer={this.renderLicenseCell} />
        <Column name='Format' cellRenderer={this.renderFormatCell} />
        <Column
          name='Action Type'
          cellRenderer={this.renderActionTypeCell}
          renderColumnHeader={this.columnHeaderCellRenderer('Action Type', 'type')}
        />
        <Column name='Action' cellRenderer={this.renderActionCell} />
      </Table>
    )
  }
}

const mapStateToProps = state => {
  const filter = getScanFilters(state)
  return {
    auditActions: getAuditActions(state),
    filter,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    retrieveAuditActions: (scanId, competitors) => dispatch(retrieveAuditActions(scanId, competitors)),
    setActionListSortParams: (column, direction) => dispatch(setActionListSortParams(column, direction)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionList)
