import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './Menu.module.scss'

class MenuItem extends React.Component {
  renderSpot(spot) {
    return (
      <li key={spot.id} className={style.dropdownItem}>
        <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
      </li>
    )
  }

  renderDropdown() {
    const { spot, spots } = this.props
    const spotsList = [...spots.filter(spot => !!spot)]

    return (
      <React.Fragment>
        <li>
          {spot && (
            <a id={`spot_${spot.id}`} href={linkToPage(spot, this.props.match)}>
              {spot.name}
            </a>
          )}
        </li>
        {spotsList.map(spot => this.renderSpot(spot))}
      </React.Fragment>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

MenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(MenuItem)
export default ConnectedNavbarDropdownWithRouter
