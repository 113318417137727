import { getClients, getMerchandizingScans, getStudios } from 'Selectors/index'
import { Record } from 'immutable'

export default class MarketShareRecord extends Record({
  id: 0,
  spotsCount: 0,
  totalCount: 0,
  weekBeginningAt: '',
  merchandizingScanId: 0,
  clientId: 0,
  analysedStudioId: 0,
}) {
  merchandizingScan(state) {
    const scans = getMerchandizingScans(state)

    return scans.get(this.merchandizingScanId)
  }

  client(state) {
    const clients = getClients(state)

    return clients.get(this.clientId)
  }

  analysedStudio(state) {
    const studios = getStudios(state)

    return studios.get(this.analysedStudioId)
  }
}
