import WrappedSection from 'Containers/WrappedSection'
import { Map } from 'immutable'
import React from 'react'

interface SectionRendererProps {
  rootSections: Map<string, any>
  scan: any
  page: any
}

const SectionRenderer: React.FC<SectionRendererProps> = ({ rootSections, scan, page }) => {
  return (
    <>
      {rootSections
        .valueSeq()
        .toArray()
        .map((s: any) => (
          <React.Fragment key={s.id}>
            <WrappedSection type={s.sectionType} scan={scan} page={page} section={s} />
          </React.Fragment>
        ))}
    </>
  )
}

export default SectionRenderer
