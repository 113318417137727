import {
  CLEAR_TITLE_QUERY,
  DISABLE_PROMOTED_FILTER,
  ENABLE_PRIORITY_FILTER,
  ENABLE_PROMOTED_FILTER,
  SET_LAST_USED_QUERY,
  SET_LETTER_FILTER,
  SET_NEW_CANDIDATES_FILTER,
  STATE_FILTER_CLEAR,
  STATE_FILTER_SET,
  TOGGLE_ALL_FILTER,
  TOGGLE_ENABLED_FILTER,
  TOGGLE_MOVIE_FILTER,
  TOGGLE_SERIES_FILTER,
  UPDATE_TITLE_QUERY,
} from 'Reducers/app/titleFilterReducer'

import { reloadTitles } from 'Actions/titleActions'

export const toggleAllFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_ALL_FILTER,
  })
  dispatch(reloadTitles())
}

export const toggleMovieFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_MOVIE_FILTER,
  })
  dispatch(reloadTitles())
}

export const toggleSeriesFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_SERIES_FILTER,
  })
  dispatch(reloadTitles())
}

export const toggleEnabledFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_ENABLED_FILTER,
  })
  dispatch(reloadTitles())
}

export const stateFilterClear = () => dispatch => {
  dispatch({
    type: STATE_FILTER_CLEAR,
  })
  dispatch(reloadTitles())
}

export const stateFilterSet = state => dispatch => {
  dispatch({
    type: STATE_FILTER_SET,
    state,
  })
  dispatch(reloadTitles())
}

export const setNewCandidatesFilter = () => dispatch => {
  dispatch({
    type: SET_NEW_CANDIDATES_FILTER,
  })
  dispatch(reloadTitles())
}

export const updateTitleQuery = query => dispatch => {
  dispatch({
    type: UPDATE_TITLE_QUERY,
    query,
  })
}

export const setLetterFilter = letter => dispatch => {
  dispatch({
    type: SET_LETTER_FILTER,
    letter,
  })
  dispatch(reloadTitles())
}

export const clearTitleQuery = () => dispatch => {
  dispatch({
    type: CLEAR_TITLE_QUERY,
  })
  dispatch(reloadTitles())
}

export const setLastUsedQuery = query => ({
  type: SET_LAST_USED_QUERY,
  query,
})

export const enablePromoted = () => dispatch => {
  dispatch({
    type: ENABLE_PROMOTED_FILTER,
  })
  dispatch(reloadTitles())
}

export const enablePriority = () => dispatch => {
  dispatch({
    type: ENABLE_PRIORITY_FILTER,
  })
  dispatch(reloadTitles())
}

export const disablePromoted = () => dispatch => {
  dispatch({
    type: DISABLE_PROMOTED_FILTER,
  })
  dispatch(reloadTitles())
}
