import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './Item.module.scss'
class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  render() {
    const { spot, section, match } = this.props

    const classNames = cx(style.item, {
      [style.fade]: section.selected,
      [style.highlight]: spot.selected,
    })

    return (
      <a href={linkToPage(spot, match)} className={classNames} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: 'cover',
          }}
        ></div>
      </a>
    )
  }
}

export default withRouter(Item)
