import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

class ActionRow extends React.Component {
  render() {
    const { action } = this.props
    const classnames = cx(styles.platformItem)
    return (
      <tr className={classnames}>
        <td>{action?.platformName}</td>
        <td>{action?.isoCode}</td>
        <td>{action.license}</td>
        <td>{action.format}</td>
        <td>{action.name}</td>
        <td>{action.message}</td>
      </tr>
    )
  }
}

export default ActionRow
