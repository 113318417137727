import { Map } from 'immutable'
import { DATA, MERCHANDIZING, META, SECTIONS } from '../utils'
import { deselectSections } from './utils'

export const LOAD_MERCHANDIZING_SECTIONS = 'merchandizing/sections/LOAD'
export const SELECT_MERCHANDIZING_SECTION = 'merchandizing/sections/SELECT'
export const DESELECT_MERCHANDIZING_SECTION = 'merchandizing/sections/DESELECT'
export const LOAD_MERCHANDIZING_SECTION_PAGE = 'merchandizing/sections/LOAD_PAGE'

export default function merchandizingNodeReducer(state, action) {
  switch (action.type) {
    case DESELECT_MERCHANDIZING_SECTION: {
      return state.withMutations(s => {
        deselectSections({ mutable: s })
      })
    }
    case SELECT_MERCHANDIZING_SECTION: {
      const { sectionId } = action

      return state.withMutations(s => {
        deselectSections({ mutable: s })
        s.setIn([MERCHANDIZING, SECTIONS, DATA, sectionId, 'selected'], true)
      })
    }
    case LOAD_MERCHANDIZING_SECTIONS: {
      return state.withMutations(s => {
        const { sections, reset } = action

        reset
          ? s.setIn([MERCHANDIZING, SECTIONS, DATA], sections)
          : s.mergeIn([MERCHANDIZING, SECTIONS, DATA], sections)
      })
    }

    case LOAD_MERCHANDIZING_SECTION_PAGE: {
      return state.withMutations(s => {
        s.setIn([MERCHANDIZING, SECTIONS, META], new Map({ page: action.page }))
      })
    }

    default:
      return state
  }
}
