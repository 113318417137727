import { Button, Intent, Popover, Position } from '@blueprintjs/core'
import { addTag, addTagging } from 'Actions/tagActions'
import { enqueueToast } from 'Actions/uiActions'
import { Tag, Title } from 'Interfaces/*'
import { getSelectedScan, getTags } from 'Selectors/index'
import cx from 'classnames'
import { List } from 'immutable'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChooseRegionModal from './ChooseRegionModal'
import ConfirmOptionsList, { ConfirmOptions } from './ConfirmOptions'
import Input from './Input'
import styles from './Tag.module.scss'
import TagSuggestions from './TagSuggestions'

interface Props {
  onClose: () => void
  titleTags: Tag[]
  title: Title
  onError: () => void
}

const TagPopover = ({ onClose, titleTags, title, onError }: Props) => {
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<ConfirmOptions>(ConfirmOptions.CurrentRegion)
  const [chooseRegionModalOpen, setChooseRegionModalOpen] = useState(false)

  const { territories, allTags } = useSelector(state => {
    const scan = getSelectedScan(state)
    const territories = (scan && scan.territories(state)) || List()
    const allTags = getTags(state)

    return {
      territories,
      allTags,
    }
  })

  const onChange = event => {
    if (event.key === 'Escape') {
      onClose()
    }

    setSearchValue(event.target.value)
  }

  const onInputKeyDown = e => {
    const tagSearchName = e.target.value

    if (e.key === 'Escape') {
      onClose()
    }

    if (e.key !== 'Enter') return
    if (!tagSearchName) {
      return
    }

    onSubmit(tagSearchName)
  }

  const onSubmit = (searchValue: string, selectedTerritoryIds?: number[]) => {
    if (!searchValue) {
      return
    }

    const territoriesToCreateTagging = getTerritoryIdsBySelectedOption(selectedTerritoryIds)
    const existingTag = allTags.find(t => t.name === searchValue)

    setSearchValue('')
    if (!existingTag) {
      dispatch(addTag(searchValue, title, territoriesToCreateTagging)).then(() => {
        dispatch(enqueueToast({ intent: Intent.SUCCESS, message: 'Tag added successfully' }))
        onClose()
      })
      return
    }
    const titleTag = titleTags.find(t => t.name === searchValue)
    if (!titleTag) {
      dispatch(addTagging(existingTag.id, title, territoriesToCreateTagging)).then(() => {
        dispatch(enqueueToast({ intent: Intent.SUCCESS, message: 'Tag added successfully' }))
        onClose()
      })
    } else {
      onError()
    }
  }

  const getTerritoryIdsBySelectedOption = (selectedTerritoryIds?: number[]) => {
    if (selectedOption === ConfirmOptions.SelectRegion) {
      return selectedTerritoryIds
    }
    if (selectedOption === ConfirmOptions.CurrentRegion) {
      return territories
        .toArray()
        .filter(t => t.selected)
        .map(t => t.id)
    }

    return territories.toArray().map(t => t.id)
  }

  const inputProps = {
    value: searchValue,
    onChange: onChange,
    onKeyDown: onInputKeyDown,
    autoFocus: true,
  }

  const onSuggestionClick = (tagName: string) => {
    setSearchValue(tagName)
  }

  const onOptionClick = (option: ConfirmOptions) => {
    setSelectedOption(option)

    if (option === ConfirmOptions.SelectRegion) {
      setChooseRegionModalOpen(true)
    }
  }

  return (
    <div className={styles.popoverContainer}>
      <Popover
        position={Position.BOTTOM_LEFT}
        popoverClassName='bp5-popover-display-none'
        className={styles.popover}
        isOpen={true}
        enforceFocus={false}
        openOnTargetFocus={false}
        autoFocus={false}
        onClose={onClose}
        content={
          <ul className={styles.dropdown}>
            <ConfirmOptionsList selectedOption={selectedOption} onOptionClick={onOptionClick} />
            <Button
              disabled={!selectedOption}
              className={cx(styles.confirmButton, 'bp5-button', 'bp5-intent-primary')}
              onClick={() => onSubmit(searchValue)}
            >
              Confirm
            </Button>
            <TagSuggestions
              searchValue={searchValue}
              onSuggestionClick={onSuggestionClick}
              alreadySelectedTagIds={titleTags.map(t => t.id)}
            />
            <ChooseRegionModal
              territories={territories}
              isOpen={chooseRegionModalOpen}
              onApply={territoryIds => {
                onSubmit(searchValue, territoryIds)
              }}
              onClose={() => setChooseRegionModalOpen(false)}
            />
          </ul>
        }
      >
        <Input inputProps={inputProps} />
      </Popover>
    </div>
  )
}

export default TagPopover
