import * as Sentry from '@sentry/react'
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from '../reducers/'

export default function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: [],
      })
    : compose

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer))

  return store
}
