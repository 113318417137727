import { Button } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

class TitleTypeFilter extends React.Component {
  render() {
    const { filter } = this.props

    return (
      <div className='bp5-button-group'>
        <Button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.all })}
          onClick={() => this.props.toggleAllFilter()}
        >
          All
        </Button>
        <Button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.movies })}
          onClick={() => this.props.toggleMovieFilter()}
        >
          Films
        </Button>
        <Button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.tv })}
          onClick={() => this.props.toggleSeriesFilter()}
        >
          TV Seasons
        </Button>
      </div>
    )
  }
}

export default TitleTypeFilter
