import { Map, Record } from 'immutable'

export default class TagRecord extends Record({
  id: 0,
  name: '',
  selected: false,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {}
