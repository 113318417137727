import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Motion, spring } from 'react-motion'

import styles from './styles.module.scss'

class BigNumber extends React.Component {
  render() {
    const {
      number,
      figure,
      units,
      className,
      numberClassname,
      figureClassname,
      unitsClassname,
      decimalPlaces,
      center,
      right,
      headingLevel,
      text,
    } = this.props
    const classes = cx(styles.root, className, {
      [styles.center]: center,
      [styles.right]: right,
    })

    const Header = `h${headingLevel}`

    return (
      <div className={classes}>
        <div className={cx(styles.figure, figureClassname)}>{figure}</div>
        <Header className={cx(styles.number, numberClassname)}>
          {text ? (
            text
          ) : (
            <Motion defaultStyle={{ x: 0 }} style={{ x: spring(number, { damping: 100, stiffness: 500 }) }}>
              {value => (
                <div>
                  {value.x.toFixed(decimalPlaces)}
                  {<span className={cx(styles.units, unitsClassname)}>{units}</span>}
                </div>
              )}
            </Motion>
          )}
        </Header>
      </div>
    )
  }
}

BigNumber.propTypes = {
  number: PropTypes.number,
  figure: PropTypes.string,
  units: PropTypes.string,
  decimalPlaces: PropTypes.number,
}

BigNumber.defaultProps = {
  units: null,
  decimalPlaces: 0,
  center: false,
  right: false,
  headingLevel: 4,
}

export default BigNumber
