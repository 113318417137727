import logo from 'Components/Nav/logo.png'
import styles from 'Components/Nav/styles.module.scss'
import { useClientContext } from 'Contexts/ClientContext'
import cx from 'classnames'
import React from 'react'

export const Nav = () => {
  const { client } = useClientContext()

  return (
    <nav className={cx('bp5-navbar', 'bp5-fixed-top', styles.navBar)}>
      <div className={styles.navigation}>
        <div className='bp5-navbar-group bp5-align-left'>
          <a
            style={{
              backgroundImage: `url(${logo})`,
              width: '50px',
              height: '40px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              marginRight: '10px',
            }}
            href={`${process.env.REACT_APP_CORE_API_URL}/clients/${client.id}`}
            title={'Home'}
          />
        </div>
      </div>
    </nav>
  )
}
