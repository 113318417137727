import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import NavPopover from 'Components/Nav/NavPopover'
import { useClientContext } from 'Contexts/ClientContext'
import { useNavPaths } from 'Hooks/useNavPaths'
import { getSelectedScan } from 'Selectors/index'

const NavLinks = ({ open, onOpenClick }: { open: boolean; onOpenClick: (_isOpen: boolean) => void }) => {
  const { client, isAdminRoute } = useClientContext()

  const { scan } = useSelector(state => ({
    scan: getSelectedScan(state),
  }))

  const isAssociatedWithMerchScan = useSelector(state => scan && scan.defaultAssocMerchandizingScan(state))

  const {
    getDashboardPath,
    getPriceAndAvailabilityPath,
    getArtworkPath,
    getMerchandizingPath,
    getCompetitorsPath,
    getMpvAnalyticsPath,
  } = useNavPaths({ clientApp: !isAdminRoute })

  const sectionSettings = useMemo(
    () =>
      (client && client.appSections) || {
        merchandizing: true,
        artwork: true,
        availabilityPricing: true,
        competitors: true,
        analyticsMpv: true,
      },
    [client]
  )

  const items = [
    { ...getDashboardPath({ scanId: scan.id, clientId: client?.id }) },
    sectionSettings.availabilityPricing && {
      ...getPriceAndAvailabilityPath({ scanId: scan.id, clientId: client?.id }),
    },
    sectionSettings.artwork && { ...getArtworkPath({ scanId: scan.id, clientId: client?.id }) },
    sectionSettings.merchandizing &&
      isAssociatedWithMerchScan?.merchandizingScanId &&
      isAssociatedWithMerchScan?.rootPageId && {
        ...getMerchandizingPath({
          merchScanId: isAssociatedWithMerchScan.merchandizingScanId,
          rootPageId: isAssociatedWithMerchScan.rootPageId,
          clientId: client.id,
        }),
      },
    sectionSettings.competitors && { ...getCompetitorsPath({ scanId: scan.id, clientId: client?.id }) },
    sectionSettings.analyticsMpv &&
      isAssociatedWithMerchScan?.merchandizingScanId &&
      isAssociatedWithMerchScan?.rootPageId && {
        ...getMpvAnalyticsPath({ scanId: isAssociatedWithMerchScan.merchandizingScanId, clientId: client?.id }),
      },
  ]

  const filteredNavItems = items.filter(item => !!item)

  return (
    <NavPopover items={filteredNavItems} isOpen={open} setIsOpen={onOpenClick} iconClassName={'bp5-icon-layout-grid'} />
  )
}
export default NavLinks
