import { apiRequest, readEndpoint } from 'Actions/apiActions'
import { getClient, getScanById, getScanFilters, getSelectedPlatforms, getSelectedScan, getUI } from 'Selectors/index'
import { OrderedMap } from 'immutable'

import { normalizeAndLoadAssociatedMerchandizingScans } from 'Actions/associatedMerchandizingScanActions'
import {
  normalizeAndLoadBrandOptimizationScores,
  normalizeAndLoadOptimizationScores,
} from 'Actions/optimizationScoreActions'
import { normalizeAndLoadPlatforms } from 'Actions/platformActions'
import { normalizeAndLoadPublishers } from 'Actions/publisherActions'
import { normalizeAndLoadTerritories } from 'Actions/territoryActions'
import ScanRecord from 'Records/scanRecord'
import ScanSummaryRecord from 'Records/scanSummaryRecord'

import {
  CLEAR_TITLE_QUERY,
  DISABLE_PROMOTED_FILTER,
  ENABLE_PRIORITY_FILTER,
  ENABLE_PROMOTED_FILTER,
  HAS_MORE_DELIVERED_SCANS_TO_LOAD,
  LOAD_SCANS,
  LOAD_SCAN_SUMMARY,
  RESET_FILTER,
  SET_ACTION_LIST_SORT_PARAMS,
  SET_INCLUDES,
  SET_LAST_USED_QUERY,
  SET_SCAN_FILTER_FROM_URL,
  SET_TITLE_AUDITS_SORT_PARAMS,
  SET_TITLE_IDS,
  TOGGLE_ALL_ACTIONS_FILTER,
  TOGGLE_ALL_ARTWORK_FILTER,
  TOGGLE_ALL_AUDIT_ACTIONS_FILTER,
  TOGGLE_ALL_FILTER,
  TOGGLE_AUDIT_EXCEPTION_FILTER,
  TOGGLE_AVAIL_ACTIONS_FILTER,
  TOGGLE_BLANK_ARTWORK_FILTER,
  TOGGLE_CHANGED_ARTWORK_FILTER,
  TOGGLE_FORMAT_ALL,
  TOGGLE_FORMAT_HD,
  TOGGLE_FORMAT_SD,
  TOGGLE_HOLDBACK_ACTIONS_FILTER,
  TOGGLE_LICENSE_TYPE_ALL,
  TOGGLE_LICENSE_TYPE_BUY,
  TOGGLE_LICENSE_TYPE_RENT,
  TOGGLE_MOVIE_FILTER,
  TOGGLE_PRICE_ACTIONS_FILTER,
  TOGGLE_READY_AUDIT_ACTIONS_FILTER,
  TOGGLE_SERIES_FILTER,
  TOGGLE_SUBMITTED_AUDIT_ACTIONS_FILTER,
  UPDATE_TITLE_QUERY,
} from 'Reducers/app/scanReducer'

import { reloadAuditActions } from 'Actions/auditActions'
import { reloadTitleAudits, resetTitleAudits } from 'Actions/titleAuditActions'
import queryString from 'query-string'

export const setSelectedScan = scanId => {
  if (!scanId) return

  localStorage.setItem('scanId', scanId)

  return scanId
}

export const getSelectedScanFromLocalStorage = () => {
  const parsedQuery = queryString.parse(window.location.search)

  return setSelectedScan(parsedQuery?.scan_id) || localStorage.getItem('scanId')
}

export const normalizeAndLoadScanResponse = (response, query) => dispatch => {
  const scanResponse = response.scan
  const { platforms, territories, associatedMerchandizingScans, publishers } = scanResponse
  const scan = new ScanRecord({
    ...scanResponse,
    platformIds: platforms.map(p => p.id),
    territoryIds: territories.map(t => t.id),
    associatedMerchandizingScansIds: associatedMerchandizingScans.map(ams => ams.id),
    publisherIds: publishers.map(p => p.id),
  })

  if (query && query.featured) dispatch({ type: ENABLE_PROMOTED_FILTER })

  setSelectedScan(scan.id)
  dispatch(loadScan(scan))
  dispatch(normalizeAndLoadPublishers(publishers))
  dispatch(normalizeAndLoadPlatforms(platforms))
  dispatch(normalizeAndLoadTerritories(territories))
  dispatch(normalizeAndLoadAssociatedMerchandizingScans(associatedMerchandizingScans))
}

export const retrieveScan = (scanId, query) => dispatch => {
  return readEndpoint(`scans/${scanId}`).then(response => dispatch(normalizeAndLoadScanResponse(response, query)))
}

export const retrieveDefaultMerchandizingScan = scanId => readEndpoint(`scans/${scanId}/default_merchandizing_scan`)

export const retrieveClientDeliveredScans =
  (page = 1) =>
  (dispatch, getState) => {
    const client = getClient(getState())
    return dispatch(retrieveDeliveredScans(client.id, page))
  }

export const retrieveDeliveredScans =
  (clientId, page = 1) =>
  dispatch => {
    const pageLimit = 1000
    const url = `scans?filter[clientId]=${clientId}&filter[delivered]=true&page=${page}&limit=${pageLimit}`

    return readEndpoint(url).then(response => {
      const scans = response.scans.reduce((memo, item) => {
        return memo.set(
          item.id,
          new ScanRecord({
            id: item.id,
            ...item,
          })
        )
      }, new OrderedMap())

      const hasMoreToLoad = response.scans.length >= pageLimit

      dispatch(setHasMoreToLoad(hasMoreToLoad))
      dispatch(loadScans(scans))
    })
  }

export const loadScan = scan => ({
  type: 'LOAD_SCAN',
  scan,
})

export const setHasMoreToLoad = hasMoreToLoad => ({
  type: HAS_MORE_DELIVERED_SCANS_TO_LOAD,
  hasMoreToLoad,
})

export const resetFilter = () => ({
  type: RESET_FILTER,
})

export const loadScans = scans => ({
  type: LOAD_SCANS,
  scans,
})

export const toggleAllFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_ALL_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleMovieFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_MOVIE_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleSeriesFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_SERIES_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleAllActionsFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_ALL_ACTIONS_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const togglePriceActionsFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_PRICE_ACTIONS_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleSubmittedAuditActionsFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_SUBMITTED_AUDIT_ACTIONS_FILTER,
  })
  dispatch(reloadScan())
}

export const toggleReadyAuditActionsFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_READY_AUDIT_ACTIONS_FILTER,
  })
  dispatch(reloadScan())
}

export const toggleAllAuditActionsFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_ALL_AUDIT_ACTIONS_FILTER,
  })
  dispatch(reloadScan())
}

export const toggleAuditExceptionFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_AUDIT_EXCEPTION_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleHoldbackActionsFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_HOLDBACK_ACTIONS_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const toggleAvailActionsFilter = competitors => dispatch => {
  dispatch({
    type: TOGGLE_AVAIL_ACTIONS_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const updateTitleQuery = query => dispatch => {
  dispatch({
    type: UPDATE_TITLE_QUERY,
    query,
  })
}

export const setTitleIds = (titleIds, limit) => dispatch => {
  dispatch({
    type: SET_TITLE_IDS,
    titleIds,
    limit,
  })
  dispatch(reloadScan(null))
}

export const clearTitleQuery = competitors => dispatch => {
  dispatch({
    type: CLEAR_TITLE_QUERY,
  })
  dispatch(reloadScan(competitors))
}

export const setLastUsedQuery = query => ({
  type: SET_LAST_USED_QUERY,
  query,
})

export const toggleLicenseTypeAll = () => dispatch => {
  dispatch({
    type: TOGGLE_LICENSE_TYPE_ALL,
  })
  dispatch(reloadScan())
}

export const toggleLicenseTypeRent = () => dispatch => {
  dispatch({
    type: TOGGLE_LICENSE_TYPE_RENT,
  })
  dispatch(reloadScan())
}

export const toggleLicenseTypeBuy = () => dispatch => {
  dispatch({
    type: TOGGLE_LICENSE_TYPE_BUY,
  })
  dispatch(reloadScan())
}

export const toggleFormatAll = () => dispatch => {
  dispatch({
    type: TOGGLE_FORMAT_ALL,
  })
  dispatch(reloadScan())
}

export const setScanFiltersFromUrlParams = filters => dispatch => {
  dispatch({
    type: SET_SCAN_FILTER_FROM_URL,
    filters,
  })
}

export const toggleFormatHD = () => dispatch => {
  dispatch({
    type: TOGGLE_FORMAT_HD,
  })
  dispatch(reloadScan())
}

export const toggleFormatSD = () => dispatch => {
  dispatch({
    type: TOGGLE_FORMAT_SD,
  })
  dispatch(reloadScan())
}

export const reloadScan = competitors => (dispatch, getState) => {
  const state = getState()
  const scanView = getUI(state).get('scanView')
  // empty title audits when we reload scan.
  // e.g. when we filtering page, we first clear titles, then load new filtered titles
  dispatch(resetTitleAudits())
  if (scanView === 'grid') {
    const scanId = getSelectedScanFromLocalStorage()
    return dispatch(reloadTitleAudits(competitors)), dispatch(retrieveScanSummary(scanId))
  } else {
    return dispatch(reloadAuditActions())
  }
}

export const setActionListSortParams = (column, direction) => dispatch => {
  dispatch({
    type: SET_ACTION_LIST_SORT_PARAMS,
    column,
    direction,
  })
  dispatch(reloadScan())
}

export const setTitleAuditsSortParams =
  (column, direction, reload = true) =>
  dispatch => {
    dispatch({
      type: SET_TITLE_AUDITS_SORT_PARAMS,
      column,
      direction,
    })
    reload && dispatch(reloadScan())
  }

export const retrieveScanSummary =
  (scanId, isScanPage = true) =>
  (dispatch, getState) => {
    const state = getState()
    const scan = getScanById(state, { id: scanId })

    let filters = {}
    if (isScanPage) {
      filters = getScanFilters(state).toParams(state)
      filters['filter[isScanPage]'] = true
      filters['filter[platforms]'] = getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .join(',')
    }

    const query = queryString.stringify(filters)

    const url = `scans/${scan.id}/scan_summaries?`

    return readEndpoint(url + query).then(response => {
      const scanSummaryResponse = response.scanSummary

      const { mostOptimizedBrands, mostOptimizedTitles, leastOptimizedBrands, leastOptimizedTitles } =
        scanSummaryResponse

      if (!isScanPage) {
        scanSummaryResponse.mostOptimizedBrandsIds = mostOptimizedBrands.map(mob => mob.id)
        scanSummaryResponse.mostOptimizedTitlesIds = mostOptimizedTitles.map(mot => mot.id)
        scanSummaryResponse.leastOptimizedBrandsIds = leastOptimizedBrands.map(lob => lob.id)
        scanSummaryResponse.leastOptimizedTitlesIds = leastOptimizedTitles.map(lot => lot.id)
      }

      const scanSummary = new ScanSummaryRecord({ ...scanSummaryResponse })

      if (!isScanPage) {
        dispatch(normalizeAndLoadBrandOptimizationScores([mostOptimizedBrands, leastOptimizedBrands].flat()))
        dispatch(normalizeAndLoadOptimizationScores([mostOptimizedTitles, leastOptimizedTitles].flat()))
      }
      dispatch(loadScanSummary(scanSummary))
    })
  }

// This endpoint is only for testing purposes
export const exportReadyTitlesForScan = () => (dispatch, getState) => {
  const state = getState()
  const scan = getSelectedScan(state)
  const url = scan.relationships.getIn(['exportTitles', 'links', 'related'])
  return dispatch(
    apiRequest(url, 'POST', {
      attributes: {},
      type: 'exportTitles',
    })
  )
}

export const loadScanSummary = scanSummary => ({
  type: LOAD_SCAN_SUMMARY,
  scanSummary,
})

export const toggleAllArtwork = () => dispatch => {
  dispatch({
    type: TOGGLE_ALL_ARTWORK_FILTER,
  })
  dispatch(reloadScan())
}

export const toggleChangedArtworkFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_CHANGED_ARTWORK_FILTER,
  })
  dispatch(reloadScan())
}

export const toggleBlankArtworkFilter = () => dispatch => {
  dispatch({
    type: TOGGLE_BLANK_ARTWORK_FILTER,
  })
  dispatch(reloadScan())
}

export const setIncludes = includes => ({
  type: SET_INCLUDES,
  includes,
})

export const enablePromotedFilter = competitors => dispatch => {
  dispatch({
    type: ENABLE_PROMOTED_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const enablePriorityFilter = competitors => dispatch => {
  dispatch({
    type: ENABLE_PRIORITY_FILTER,
  })
  dispatch(reloadScan(competitors))
}

export const disablePromotedFilter = competitors => dispatch => {
  dispatch({
    type: DISABLE_PROMOTED_FILTER,
  })
  dispatch(reloadScan(competitors))
}
