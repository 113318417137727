import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './Menu.module.scss'

class MenuItem extends React.Component {
  renderSpot(spot) {
    const classnames = cx(style.listItem, style.dropdownItem)

    return (
      <li key={spot.id} className={classnames}>
        <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
      </li>
    )
  }

  renderDropdown() {
    const { spot, spots } = this.props

    const spotsList = [...spots.filter(spot => !!spot)]
    const classnames = cx(style.listItem, style.dropdownItem, style.subMenuItem)
    return (
      <li className={classnames} key={spot.id}>
        <a href={linkToPage(spot, this.props.match)}>
          <span className={style.firstLetter}>{spot.name.slice(0, 1)}</span>
          {spot.name.slice(1)}
        </a>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM_RIGHT}
          popoverClassName='bp5-popover-display-none'
          content={
            <div className={style.dropdown}>
              <ul>{spotsList.map(spot => this.renderSpot(spot))}</ul>
            </div>
          }
        >
          {spot && <i className={style.rightArrow} />}
        </Popover>
      </li>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

MenuItem.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  spot: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarDropdownWithRouter = withRouter(MenuItem)
export default ConnectedNavbarDropdownWithRouter
