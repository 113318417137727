import { OverlayToaster, Position, ToasterPosition } from '@blueprintjs/core'
import { clearToasts } from 'Actions/uiActions'
import { getUI } from 'Selectors/index'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const AppToaster = ({ position = Position.TOP }: { position?: ToasterPosition }) => {
  const dispatch = useDispatch()
  let toaster = null
  const toastQueue = useSelector(state => getUI(state).get('toastQueue'))

  useEffect(() => {
    if (toastQueue.size) {
      toastQueue.forEach(toast => {
        toaster.show(toast)
      })
      dispatch(clearToasts())
    }
  }, [dispatch, toastQueue, toaster])

  const refHandlers = {
    toaster: el => (toaster = el),
  }

  return <OverlayToaster position={position} ref={refHandlers.toaster} />
}

export default AppToaster
