import { setScanFiltersFromUrlParams } from 'Actions/scanActions'
import {
  getPlatforms,
  getScanFilters,
  getSelectedPlatforms,
  getSelectedTags,
  getSelectedTerritories,
  getTags,
} from 'Selectors/index'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const usePreserveFilter = ({ enabled }: { enabled: boolean }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    filter,
    selectedTerritoriesIds,
    selectedPlatformsIds,
    selectedTagsIds,
    allPlatforms,
    allTerritories,
    allTags,
  } = useSelector(state => ({
    filter: getScanFilters(state),
    selectedTerritoriesIds: getSelectedTerritories(state)
      .map(t => t.id)
      .toArray()
      .join(','),
    selectedPlatformsIds: getSelectedPlatforms(state)
      .map(p => p.id)
      .toArray()
      .join(','),
    selectedTagsIds: getSelectedTags(state)
      .map(t => t.id)
      .toArray()
      .join(','),
    allPlatforms: getPlatforms(state),
    allTerritories: getPlatforms(state),
    allTags: getTags(state),
  }))

  // This useEffect hook is responsible for updating the URL parameters with the filter state
  // whenever the filter state changes, but only after the component has mounted.
  useEffect(() => {
    if (!enabled) return

    const params = filter.toUrlParams()
    const urlParams = queryString.parse(history.location.search)
    const query = queryString.stringify({ ...urlParams, ...params })
    const { platformIds, territoryIds, tagIds } = urlParams

    history.push({
      pathname: history.location.pathname,
      search: filter.hasChangedParams() ? query : queryString.stringify({ platformIds, territoryIds, tagIds }),
    })
  }, [filter, history, enabled])

  // This useEffect hook is responsible for synchronizing the state filter with the URL parameters
  // when the component mounts.
  useEffect(() => {
    const query = queryString.parse(history.location.search)

    dispatch(setScanFiltersFromUrlParams(query))
  }, [dispatch, history.location.search])

  // This useEffect hook synchronizes the selected platforms and territories from the state with the URL parameters.
  useEffect(() => {
    if (!enabled) return
    if (!allPlatforms.count() || !allTerritories.count()) return

    const urlParams = queryString.parse(history.location.search)
    const query = queryString.stringify({
      ...urlParams,
      platformIds: selectedPlatformsIds,
      territoryIds: selectedTerritoriesIds,
    })

    history.push({
      pathname: history.location.pathname,
      search: query,
    })
  }, [selectedTerritoriesIds, selectedPlatformsIds, enabled, allPlatforms, allTerritories, history])

  // This useEffect hook synchronizes the selected tags from the state with the URL parameters.
  useEffect(() => {
    if (!enabled) return
    if (!allTags.count()) return

    const urlParams = queryString.parse(history.location.search)
    if (selectedTagsIds.length) {
      urlParams['tagIds'] = selectedTagsIds
    } else {
      delete urlParams['tagIds']
    }
    const query = queryString.stringify({ ...urlParams })

    history.push({
      pathname: history.location.pathname,
      search: query,
    })
  }, [enabled, selectedTagsIds, allTags, history])
}
export default usePreserveFilter
