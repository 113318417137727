import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage, scrollToNode } from '../utils'
import style from './Item.module.scss'

class Item extends React.Component {
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.spot.selected) scrollToNode(this.node)
  }

  isSeeMoreSpot() {
    const { spot } = this.props

    return spot.name && spot.name.includes('SEE MORE' || 'SEE ALL')
  }

  render() {
    const { spot, client, isMerchandiseFilteringEnabled, section, hideTitle, match } = this.props

    const inlineStyle = {}
    if (client) {
      inlineStyle.borderStyle = 'solid'
      inlineStyle.borderColor = client.color
      inlineStyle.boxShadow = `0 0 10px ${client.color}`
    }

    const classNames = cx(style.item, {
      [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
      [style.highlight]: spot.selected || client,
    })

    const { subtitle, coverUrl } = spot.metadata
    return (
      <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={node => (this.node = node)}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url(${spot.artworkUrl}) center center no-repeat`,
            backgroundSize: '100% 100%',
          }}
        >
          {this.isSeeMoreSpot() && <div className={style.seeMoreItem}>See more</div>}
          {section.sectionType === 'cineplex::herocarousel' && spot.label && coverUrl && subtitle && (
            <div className={style.cineplexDetails}>
              <span className={style.description}>{spot.label}</span>
              <img className={style.itemImage} src={coverUrl} />
              <button>{subtitle}</button>
            </div>
          )}
        </div>
        {spot.name && !hideTitle && (
          <div className={style.titleContainer}>
            <span className={style.title}>{spot.name}</span>
          </div>
        )}
      </a>
    )
  }
}

const ConnectedItemWithRouter = withRouter(Item)
export default ConnectedItemWithRouter
