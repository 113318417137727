import { TAGGINGS, initialState, removeTaggings } from './utils.js'

export default function taggingReducer(state, action) {
  switch (action.type) {
    case 'LOAD_TAGGINGS': {
      return state.withMutations(s => {
        s.mergeIn([TAGGINGS], action.taggings)
      })
    }
    case 'DELETE_TAGGING': {
      const { taggingId } = action
      return state.withMutations(s => {
        removeTaggings({
          mutable: s,
          taggingId,
        })
      })
    }
    default:
      return state || initialState
  }
}
