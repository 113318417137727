import { getAuditActions } from 'Selectors/index'
import { Map, Record } from 'immutable'

export default class PriceRecord extends Record({
  id: 0,
  amountCents: 0,
  amount: 0,
  amountCurrency: '',
  offerType: '',
  variant: '',
  license: '',
  format: '',
  nullPrice: '',
  isPreorder: false,
  mostRecent: false,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  auditActions(state) {
    const auditActions = getAuditActions(state)

    return auditActions.filter(auditAction => {
      if (auditAction.auditPriceId) {
        return auditAction.auditPriceId.toString() === this.id.toString()
      }
      if (auditAction.priceId) {
        return auditAction.priceId.toString() === this.id.toString()
      }
    })
  }
}
