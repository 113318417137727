import { Button, Icon, Position, Tooltip } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

class ViewSwitcher extends React.Component {
  render() {
    const { scanView, showScanAsList, showScanAsGrid } = this.props
    return (
      <div className='bp5-button-group'>
        <Tooltip content='Title List' position={Position.BOTTOM_LEFT}>
          <Button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: scanView === 'grid' })}
            onClick={() => showScanAsGrid()}
          >
            <Icon className={styles.labelColor} icon='grid' />
          </Button>
        </Tooltip>

        <Tooltip content='Action List' position={Position.BOTTOM}>
          <Button
            className={cx('bp5-button', styles.btnDefault, { [styles.active]: scanView === 'list' })}
            onClick={() => showScanAsList()}
          >
            <Icon className={styles.labelColor} icon='list' />
          </Button >
        </Tooltip >
      </div >
    )
  }
}

export default ViewSwitcher
