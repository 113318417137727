import { Button, Classes, Dialog } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import styles from './ContainerEdit.module.scss'

import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import { withRouter } from 'react-router-dom'
import Artwork from '../TitleDetail/Artwork'

class ContainerEdit extends React.Component {
  constructor(props) {
    super(props)
    const { platformTitle } = this.props
    const { url, platformIdentifier, platformCdid, artworkCdnUrl, vendorIdentifier } = platformTitle

    this.state = {
      performingAction: false,
      url,
      platformIdentifier,
      platformCdid,
      artworkUrl: artworkCdnUrl,
      vendorIdentifier,
    }
  }

  handleClose = () => {
    this.props.history.goBack()
  }

  handleUrlChange = e => {
    this.setState({ url: e.target.value })
  }

  handleArtworkUrlChange = e => {
    this.setState({ artworkUrl: e.target.value })
  }

  handlePlatformIdentifierChange = e => {
    this.setState({ platformIdentifier: e.target.value })
  }

  handlePlatformCdidChange = e => {
    this.setState({ platformCdid: e.target.value })
  }

  handleVendorIdentifierChange = e => {
    this.setState({ vendorIdentifier: e.target.value })
  }

  handleSave = () => {
    const { platformTitle, update } = this.props
    const { url, platformIdentifier, vendorIdentifier, artworkUrl, platformCdid } = this.state
    this.setState({ performingAction: true }, () => {
      update(platformTitle, { url, platformIdentifier, vendorIdentifier, artworkUrl, platformCdid }).then(() => {
        this.handleClose()
      })
    })
  }

  handleApprove = () => {
    this.setState({ performingAction: true })
    const { url, platformIdentifier, vendorIdentifier, artworkUrl, platformCdid } = this.state
    this.props
      .updatePlatformTitleThenApprove(this.props.platformTitle, {
        url,
        platformIdentifier,
        platformCdid,
        vendorIdentifier,
        artworkUrl,
      })
      .then(() => {
        this.handleClose()
      })
  }

  render() {
    const { title, platformTitle } = this.props

    const { performingAction, url, platformIdentifier, platformCdid, vendorIdentifier, artworkUrl } = this.state

    return (
      <Dialog isOpen onClose={this.handleClose} className={cx(styles.dialog, 'bp5-focus-disabled')}>
        <div className={styles.popup}>
          <div className={styles.header}>
            <LeftRight>
              <FluidLeft>
                <h4>{title.name}</h4>
                <div className={styles.platform}>{platformTitle.platformName}</div>
                <a className={styles.link} target='_blank' href={platformTitle.url} rel='noreferrer'>
                  {platformTitle.url}
                </a>
              </FluidLeft>
              <div className={styles.artworkContainer}>
                {<Artwork width={120} height={120} artworkUrl={platformTitle.artworkCdnUrl} />}
              </div>
            </LeftRight>
          </div>

          <div className={cx(styles.body, 'bp5-dialog-body')}>
            <label className='bp5-label'>
              URL
              <span className='bp5-text-muted'>(required)</span>
              <input
                className='bp5-input bp5-fill'
                type='text'
                placeholder='URL'
                dir='auto'
                value={url}
                onChange={this.handleUrlChange}
                autoFocus
              />
            </label>
            <label className='bp5-label'>
              Platform Identifier
              <span className='bp5-text-muted'>(required)</span>
              <input
                className='bp5-input bp5-fill'
                type='text'
                placeholder='Platform Identifier'
                dir='auto'
                value={platformIdentifier}
                onChange={this.handlePlatformIdentifierChange}
              />
            </label>
            <label className='bp5-label'>
              Platform Cdid
              <input
                className='bp5-input bp5-fill'
                type='text'
                placeholder='Platform Cdid'
                dir='auto'
                value={platformCdid}
                onChange={this.handlePlatformCdidChange}
              />
            </label>
            <label className='bp5-label'>
              Vendor Identifier
              <span className='bp5-text-muted'>(required)</span>
              <input
                className='bp5-input bp5-fill'
                type='text'
                placeholder='Vendor Identifier'
                dir='auto'
                value={vendorIdentifier}
                onChange={this.handleVendorIdentifierChange}
              />
            </label>
            <label className='bp5-label'>
              Artwork URL
              <span className='bp5-text-muted'>(required)</span>
              <input
                className='bp5-input bp5-fill'
                type='text'
                placeholder='Artwork URL'
                dir='auto'
                value={artworkUrl}
                onChange={this.handleArtworkUrlChange}
              />
            </label>
          </div>

          <div className={cx(Classes.DIALOG_FOOTER, styles.footer)}>
            <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, styles.secondaryActions)}>
              <Button disabled={performingAction} className='' onClick={this.handleClose}>
                Close
              </Button>
              {platformTitle.canApprove ? (
                <Button disabled={performingAction} className='bp5-intent-success' onClick={this.handleApprove}>
                  Save And Approve
                </Button>
              ) : (
                <Button disabled={performingAction} className='bp5-intent-primary' onClick={this.handleSave}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const ConnectedContainerDetailWithRouter = withRouter(ContainerEdit)
export default ConnectedContainerDetailWithRouter
