import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import style from './CarouselList.module.scss'
import Item from './Item'

class CarouselList extends React.Component {
  render() {
    const sliderSettings = {
      dots: true,
      infinite: false,
      centerMode: true,
      centerPadding: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
      appendDots: dots => {
        const customDots = React.Children.map(dots, dot => {
          if (dot.props['className'] === 'slick-active') {
            return React.cloneElement(dot, {
              className: style.slickActive,
            })
          } else {
            return React.cloneElement(dot, {
              className: style.slickNormal,
            })
          }
        })
        return (
          <div style={{ top: '10px', width: '15px' }}>
            <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
          </div>
        )
      },
    }

    return (
      <div className={style.root}>
        <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
        <span className={style.sectionDivider}></span>
      </div>
    )
  }

  renderSpots() {
    const { spots, section, isMerchandiseFilteringEnabled } = this.props
    return spots.map(
      spot =>
        spot && (
          <div key={spot.id} className={style.carouselItem}>
            <Item
              key={spot.id}
              spot={spot}
              studio={this.isStudioSelected(spot.id)}
              section={section}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
            />
          </div>
        )
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot)
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

CarouselList.propTypes = {
  spots: PropTypes.object,
  selectedStudios: PropTypes.object,
  section: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default withRouter(CarouselList)
