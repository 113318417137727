import { ARTWORK_COMPARISONS, initialState } from './utils.js'

export const LOAD_ARTWORK_COMPARISONS = 'artworkComparisons/LOAD'

export default function artworkComparisonReducer(state, action) {
  switch (action.type) {
    case LOAD_ARTWORK_COMPARISONS: {
      return state.withMutations(s => {
        s.mergeIn([ARTWORK_COMPARISONS], action.artworkComparisons)
      })
    }
    default:
      return state || initialState
  }
}
