import { Spinner } from '@blueprintjs/core'
import { Cell, Column, Table2 as Table } from '@blueprintjs/table'
import React from 'react'
import { matchPath } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import SpotTableCell from './SpotTableCell'
import style from './style.module.scss'

class SpotTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: props.spotsLoading,
      page: 1,
    }
  }

  elementInfiniteLoad = () => {
    return (
      <div className={style.pageSpinner}>
        <Spinner />
      </div>
    )
  }

  isClientVersion = () => {
    return matchPath(window.location.pathname, {
      path: `/dashboard/merch/:scanId/pages/:pageId`,
      strict: false,
    })
  }

  pageLink = spot => {
    const { scan, client } = this.props

    if (this.isClientVersion()) {
      return `/dashboard/merch/${scan?.id}/pages/${spot.pageId}`
    } else if (client && Object.keys(client).length > 0) {
      return `/v2/clients/${client?.id}/merchandizing/scans/${scan?.id}/pages/${spot.pageId}`
    } else {
      return `/v2/merchandizing/scans/${scan?.id}/pages/${spot.pageId}`
    }
  }

  handleInfiniteLoad = ({ rowIndexEnd }) => {
    const { retrieveSpotsNextPage, spots, hasMoreSpotsToLoad } = this.props
    const { loading } = this.state
    const spotsCount = spots.count()

    const hasMoreToLoad = rowIndexEnd + 1 >= spotsCount && !loading && hasMoreSpotsToLoad

    if (hasMoreToLoad) {
      this.setState({ loading: true })

      retrieveSpotsNextPage(this.state.page + 1).then(() =>
        this.setState({ loading: false, page: this.state.page + 1 })
      )
    }
  }

  handleClickOnLink(spot) {
    const { showMerchandizingScanAsGrid, setScrollingSpot } = this.props

    setScrollingSpot(spot)
    showMerchandizingScanAsGrid()
  }

  handleDownloadBtn(spot) {
    const { runMerchandizingScreenshootService, scan, client, auditScan } = this.props
    runMerchandizingScreenshootService(scan, spot, client.id, auditScan.id)
  }

  renderTitleCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <Cell>{item.name}</Cell>
  }
  renderPageNameCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <SpotTableCell spot={item} attribute={'PageNameCell'} />
  }
  renderPlatformIdentifer = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <SpotTableCell spot={item} attribute={'PlatformIdentifer'} />
  }
  renderColumnCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <Cell>{item.position}</Cell>
  }
  renderStudioCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <SpotTableCell spot={item} attribute={'StudioCell'} />
  }
  renderRowCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return <SpotTableCell spot={item} attribute={'RowCell'} />
  }

  renderLinkCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)
    return (
      <Cell>
        <Link to={this.pageLink(item)} onClick={() => this.handleClickOnLink(item)}>
          {item.name}
        </Link>
      </Cell>
    )
  }

  renderScreenshotLinkCell = rowIndex => {
    const { spots } = this.props
    const item = spots.valueSeq().get(rowIndex)

    if (!item.screenshotUrl) {
      return <Cell></Cell>
    }

    const screenshotUrl = item.screenshotUrl.startsWith('https')
      ? item.screenshotUrl
      : `${process.env.REACT_APP_CORE_API_URL}${item.screenshotUrl}`

    return (
      <Cell>
        <a href={screenshotUrl} target='_blank' rel='noopener noreferrer'>
          Link
        </a>
      </Cell>
    )
  }

  receiveUpdate = payload => {
    const { updateSpotUrl } = this.props
    const { spot_id, url } = payload
    updateSpotUrl(spot_id, url)
  }

  render() {
    const { spots, client } = this.props

    const columnWidths =
      !client || client.isScreenshotEnabled
        ? [200, 200, 300, 250, 100, 100, 200, 200]
        : [200, 200, 300, 250, 100, 100, 200]
    const columns = [
      { name: 'Title', cellRenderer: this.renderTitleCell },
      { name: 'Page Name', cellRenderer: this.renderPageNameCell },
      { name: 'Platform Identifier', cellRenderer: this.renderPlatformIdentifer },
      { name: 'Studio', cellRenderer: this.renderStudioCell },
      { name: 'Row', cellRenderer: this.renderRowCell },
      { name: 'Column', cellRenderer: this.renderColumnCell },
      { name: 'Link', cellRenderer: this.renderLinkCell },
      (!client || client.isScreenshotEnabled) && {
        name: 'Screenshot Link',
        cellRenderer: this.renderScreenshotLinkCell,
      },
    ]
    return (
      <div className={style.spotTable}>
        <React.Fragment>
          <Table
            numRows={spots.count()}
            defaultColumnWidth={300}
            fillBodyWithGhostCells
            columnWidths={columnWidths}
            defaultRowHeight={35}
            onVisibleCellsChange={this.handleInfiniteLoad}
          >
            {columns.filter(Boolean).map((column, index) => (
              <Column key={index} name={column.name} cellRenderer={column.cellRenderer} />
            ))}
          </Table>
          {this.state.loading && this.elementInfiniteLoad()}
          {this.state.loading && (
            <div className={style.loadingSpinner}>
              <Spinner />
            </div>
          )}
        </React.Fragment>
      </div>
    )
  }
}

export default withRouter(SpotTable)
