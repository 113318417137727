import cx from 'classnames'
import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import Item from './Item'
import style from './SeasonCarousel.module.scss'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

class SeasonCarousel extends React.Component {
  render() {
    const { section } = this.props
    const metadata = section.metadata || {}
    const seeMoreSpot = this.seeMore()

    const sliderSettings = {
      dots: false,
      infinite: false,
      centerMode: false,
      centerPadding: 0,
      slidesToScroll: 4,
      slidesToShow: 4,
      variableWidth: true,
      arrows: true,
      swipe: false,
      initialSlide: 0,
      nextArrow: <Arrow right />,
      prevArrow: <Arrow left />,
    }
    return (
      <div className={style.container}>
        <div className={style.heading}>
          <h2>
            {section.name} {seeMoreSpot && <a href={this.spotLink()}>{seeMoreSpot.name}</a>}
          </h2>
          {metadata.subtitle && <h3>{metadata.subtitle}</h3>}
        </div>
        <Slider {...sliderSettings}>{this.renderSpots()}</Slider>
      </div>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled, spots } = this.props

    return spots.map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <Item
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }

  // this is a custom link spot that takes us to see more pages, others are content spots
  seeMore() {
    const { spots } = this.props

    return spots.find(spot => spot && spot.spotType === 'link')
  }

  spotLink() {
    const { match } = this.props
    const spot = this.seeMore()
    if (!spot.linkedPageId) {
      return spot.url
    }

    const { path } = match

    const toPath = pathToRegexp.compile(path)
    return toPath({ ...match.params, pageId: spot.linkedPageId })
  }
}

const ConnectedSeasonCarousel = withRouter(SeasonCarousel)
export default ConnectedSeasonCarousel
