import cx from 'classnames'
import React from 'react'

import style from './Background.module.scss'

class Background extends React.Component {
  render() {
    const { section } = this.props
    const metadata = section.metadata || {}

    const classnames = cx(style.root, {
      [style.tallCover]: metadata.cover_type === 'tall-cover',
      [style.squareCover]: metadata.cover_type === 'square-cover',
      [style.halfCover]: metadata.cover_type === 'half-cover',
    })
    return (
      <div className={classnames} style={{ backgroundColor: `${section.metadata.background_color}` }}>
        <div className={style.imageContainer}>
          <div className={style.imageContainerInner}>
            <img className={style.backgroundImage} src={metadata.background_image} />
          </div>
        </div>
      </div>
    )
  }
}

export default Background
