import { apiRequest, readEndpoint } from 'Actions/apiActions'
import { normalizeAndLoadPlatformTitles } from 'Actions/platformTitleActions'
import { setTitleAuditsSortParams } from 'Actions/scanActions'
import ClientRecord from 'Records/clientRecord'
import ClientMetaRecord from 'Records/clientMetaRecord'
import ClientSalesSummaryRecord from 'Records/clientSalesSummaryRecord'
import {
  LOAD_CLIENT_DASHBOARD,
  LOAD_CLIENT_META,
  SELECT_CLIENTS,
  LOAD_CLIENT_COMPETITORS,
  TOGGLE_CLIENT_COMPETITOR,
} from 'Reducers/app/clientReducer'
import { LOAD_CLIENT_SALES_SUMMARY } from 'Reducers/app/clientSalesSummaryReducer'
import { getClient, getSelectedPlatforms, getSelectedTerritories, getTitleFilters } from 'Selectors/index'
import { Map, OrderedMap, fromJS } from 'immutable'
import queryString from 'query-string'

export const clientMapper = (memo, item) => {
  return memo.set(
    item.id,
    new ClientRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const filterClients = item => item.type === 'clients'

export const loadClient = client => ({
  type: 'LOAD_CLIENT',
  client,
})

export const loadClientMeta = meta => ({
  type: LOAD_CLIENT_META,
  meta,
})

export const loadClients = clients => ({
  type: 'LOAD_CLIENTS',
  clients,
})

export const loadClientCompetitors = competitors => ({
  type: LOAD_CLIENT_COMPETITORS,
  competitors,
})

export const toggleClientCompetitor = competitorId => ({
  type: TOGGLE_CLIENT_COMPETITOR,
  competitorId,
})

export const retrieveClients = () => dispatch => {
  return dispatch(readEndpoint('clients')).then(response => {
    const clients = response.data.reduce(clientMapper, new OrderedMap())
    dispatch(loadClients(clients))
  })
}

export const retrieveClient = clientId => dispatch => {
  return readEndpoint(`clients/${clientId}`).then(response => dispatch(loadClientResponse(response)))
}

export const retrieveCurrentClient = () => dispatch => {
  return readEndpoint('clients/logged_in_client').then(response => dispatch(loadClientResponse(response)))
}

const loadClientResponse = response => dispatch => {
  const clientResponse = response?.client
  const clientMeta = new ClientMetaRecord(response?.meta)
  const { sortOptions } = clientResponse

  const client = new ClientRecord({
    id: clientResponse.id,
    ...clientResponse,
    sortOptions: {
      actionCount: sortOptions.actionCount,
      'titles.name': sortOptions['title.name'],
      'titles.productionYear': sortOptions['title.productionYear'],
      'titles.totalSalesUnits': sortOptions['title.totalSalesUnits'],
    },
  })

  dispatch(loadClient(client))
  dispatch(loadClientCompetitors(client?.studios))
  dispatch(loadClientMeta(clientMeta))
  dispatch(setClientSortOptions(client))
}

export const setClientSortOptions = client => dispatch => {
  const { sort: sortParam } = queryString.parse(window.location.search)

  if (sortParam) return

  const { sortOptions } = client
  const defaultSortColumn =
    Object.keys(sortOptions).find(sortColumn => sortOptions[sortColumn] && sortOptions[sortColumn]['default']) ||
    'titles.name'
  const defaultsortDirection = sortOptions[defaultSortColumn]['direction'] || 'asc'
  dispatch(setTitleAuditsSortParams(defaultSortColumn, defaultsortDirection, false))
}

export const relocateTitles = () => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const url = `clients/${client.id}/titles/relocate`

  const filters = getTitleFilters(state).toBody(state)
  const body = {
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
      territories: getSelectedTerritories(state)
        .valueSeq()
        .map(t => t.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const retryUnavailableTitles = () => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const url = `clients/${client.id}/titles/retry_unavailable_titles`
  const filters = getTitleFilters(state).toBody(state)
  const body = {
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
      territories: getSelectedTerritories(state)
        .valueSeq()
        .map(t => t.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const markUnavailableForeverTitles =
  (titleId = null) =>
  (dispatch, getState) => {
    const state = getState()
    const client = getClient(state)
    const filter = getTitleFilters(state).toBody(state)

    const titleIds = titleId ? [titleId] : []
    const url = `clients/${client.id}/titles/mark_unavailable_forever_titles`

    const body = {
      filter: {
        ...filter,
        platforms: getSelectedPlatforms(state)
          .valueSeq()
          .map(p => p.id)
          .toArray()
          .join(','),
        territories: getSelectedTerritories(state)
          .valueSeq()
          .map(t => t.id)
          .toArray()
          .join(','),
        ids: titleIds,
      },
    }

    return dispatch(apiRequest(url, 'POST', body)).then(response => {
      dispatch(normalizeAndLoadPlatformTitles(response.platformTitles, false))
    })
  }

export const markUnavailableTitles =
  (titleId = null) =>
  (dispatch, getState) => {
    const state = getState()
    const client = getClient(state)
    const filter = getTitleFilters(state).toBody(state)

    const titleIds = titleId ? [titleId] : []
    const url = `clients/${client.id}/titles/mark_unavailable_titles`

    const body = {
      filter: {
        ...filter,
        platforms: getSelectedPlatforms(state)
          .valueSeq()
          .map(p => p.id)
          .toArray()
          .join(','),
        territories: getSelectedTerritories(state)
          .valueSeq()
          .map(t => t.id)
          .toArray()
          .join(','),
        ids: titleIds,
      },
    }

    return dispatch(apiRequest(url, 'POST', body)).then(response => {
      dispatch(normalizeAndLoadPlatformTitles(response.platformTitles, false))
    })
  }

export const refreshAmazonArtworks = () => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const url = `clients/${client.id}/titles/refresh_amazon_artworks`

  const filters = getTitleFilters(state).toBody(state)
  const body = {
    filter: {
      ...filters,
      platforms: getSelectedPlatforms(state)
        .valueSeq()
        .map(p => p.id)
        .toArray()
        .join(','),
      territories: getSelectedTerritories(state)
        .valueSeq()
        .map(t => t.id)
        .toArray()
        .join(','),
    },
  }

  return dispatch(apiRequest(url, 'POST', body))
}

export const setClient = clientId => ({
  type: 'SET_CLIENT',
  clientId,
})

export const loadClientSalesSummary = clientSalesSummary => ({
  type: LOAD_CLIENT_SALES_SUMMARY,
  clientSalesSummary,
})

export const selectClients = clientIds => dispatch => {
  dispatch({
    type: SELECT_CLIENTS,
    clientIds,
  })
}

export const retrieveSalesSummary = () => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)

  const url = `clients/${client.id}/sales_summaries`

  return readEndpoint(url).then(response => {
    const clientSalesSummary = new ClientSalesSummaryRecord({ ...response.clientSalesSummary })

    dispatch(loadClientSalesSummary(clientSalesSummary))
  })
}

export const loadClientDashboard = clientDashboard => ({
  type: LOAD_CLIENT_DASHBOARD,
  clientDashboard,
})

export const normalizeAndLoadClients = data => dispatch => {
  const clients = data.reduce((memo, item) => {
    return memo.set(item.id, new ClientRecord({ ...item }))
  }, new OrderedMap())

  return dispatch(loadClients(clients))
}
