import { Button } from '@blueprintjs/core'
import { deselectMerchandizingSection } from 'Actions/merchandizing/sectionActions'
import { deselectMerchandizingSpot, findSpotOnPage, setScrollingSpot } from 'Actions/merchandizing/spotActions'
import style from 'Components/Search/style.module.scss'
import { getMerchandizingSections, getScrollingSpotId } from 'Selectors/index'
import { List } from 'immutable'
import escapeRegExp from 'lodash.escaperegexp'
import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
    }

    this.getSuggestions = this.getSuggestions.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
  }

  resetInput = () => {
    this.setState({ value: '' })
  }

  getSuggestions = value => {
    const spots = this.props.spots
    const inputValue = escapeRegExp(value.trim().toLowerCase())
    return spots.filter(spot => new RegExp(inputValue, 'i', 'g').test(spot.name)).slice(0, 5)
  }

  getSuggestionValue = suggestion => suggestion.name

  onSuggestionClick = suggestion => {
    this.setState({ query: suggestion.name })
    this.props.onSuggestionClick(suggestion.id)
  }

  renderSuggestion = suggestion => {
    return (
      <div className={style.suggestionContent} onClick={() => this.onSuggestionClick(suggestion)}>
        <img className={style.suggestionContentImage} src={suggestion.artworkUrl} />
        <div className={style.suggestionContentInfo}>
          <h6>{suggestion.name}</h6>
          <p>{suggestion.section.get('name')}</p>
        </div>
      </div>
    )
  }

  renderInputComponent = inputProps => {
    const { key, ...restProps } = inputProps
    return (
      <div className='bp5-navbar-group'>
        <div className='bp5-input-group'>
          <span className='bp5-icon bp5-icon-search' />
          <input {...restProps} className='bp5-round bp5-input' placeholder='Search' dir='auto' />
        </div>
      </div>
    )
  }

  onChange = e => {
    this.setState({ value: e.target.value })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    })
  }

  render() {
    const { deselectMerchandizingSpot, deselectMerchandizingSection, selectedSpotId, setScrollingSpot } = this.props

    const inputProps = {
      placeholder: 'Search',
      value: this.state.value ?? '',
      onChange: this.onChange,
    }

    return (
      <div className='bp5-navbar-group'>
        <Autosuggest
          theme={style}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderInputComponent={this.renderInputComponent}
          inputProps={inputProps}
          renderSuggestion={this.renderSuggestion}
          focusInputOnSuggestionClick={false}
        />
        {(this.state.value || selectedSpotId) && (
          <div className='bp5-navbar-group'>
            <div className='bp5-navbar-divider' />
            <Button
              className='bp5-icon-cross'
              onClick={() => {
                this.resetInput()
                deselectMerchandizingSpot()
                deselectMerchandizingSection()
                if (selectedSpotId) {
                  const emptyList = new List()
                  setScrollingSpot(emptyList)
                }
              }}
            >
              Clear Search
            </Button>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const sections = getMerchandizingSections(state)
  const spots = getSpotSections(sections, state)
  const selectedSpotId = getScrollingSpotId(state)

  return {
    sections,
    spots,
    selectedSpotId,
  }
}

const getSpotSections = (sections, state) => {
  const spots = []

  sections
    .valueSeq()
    .toArray()
    .forEach(section => {
      const sectionSpots = (section && section.spots(state)) || new List()
      for (const spot of sectionSpots) {
        if (spot && spot.spotType === 'content') {
          const s = spot.toJS()
          s.section = section
          spots.push(s)
        }
      }
    })

  return spots
}

const mapDispatchToProps = dispatch => {
  return {
    onSuggestionClick: id => dispatch(findSpotOnPage(id)),
    deselectMerchandizingSpot: () => dispatch(deselectMerchandizingSpot()),
    deselectMerchandizingSection: () => dispatch(deselectMerchandizingSection()),
    setScrollingSpot: emptyList => dispatch(setScrollingSpot(emptyList)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
