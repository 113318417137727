import cx from 'classnames'
import React from 'react'
import style from './CineplexSlick.module.scss'
import SlickItem from './SlickItem'

class CineplexSlick extends React.Component {
  render() {
    const { spots } = this.props

    const classNames = cx({
      [style.root]: spots.size <= 4,
      [style.collection]: spots.size > 4,
    })

    return <div className={classNames}>{this.renderSectionContent()}</div>
  }

  renderSectionContent() {
    const { section, spots } = this.props
    return spots.map(
      spot =>
        spot && (
          <div key={spot.id} className={style.spotItem}>
            <SlickItem key={spot.id} spot={spot} section={section} />
          </div>
        )
    )
  }
}

export default CineplexSlick
