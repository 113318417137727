import { Cell } from '@blueprintjs/table'
import React from 'react'
import { connect } from 'react-redux'

import { makeGetAuditActionForRowIndex } from 'Selectors/index'

class PlatformTitleCell extends React.Component {
  render() {
    const { attribute, platformTitle } = this.props

    return <Cell {...this.props}>{platformTitle && platformTitle.get(attribute)}</Cell>
  }
}

const makeMapStateToProps = () => {
  const getAuditActionForRowIndex = makeGetAuditActionForRowIndex()

  const mapStateToProps = (state, ownProps) => {
    const auditAction = getAuditActionForRowIndex(state, {
      rowIndex: ownProps.rowIndex,
    })
    const platformTitle = auditAction?.platformTitle(state)
    return {
      auditAction,
      platformTitle,
    }
  }

  return mapStateToProps
}

export default connect(makeMapStateToProps)(PlatformTitleCell)
