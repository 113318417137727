import TaggingRecord from 'Records/taggingRecord'
import { Map, OrderedMap } from 'immutable'

import { getTaggings } from 'Selectors/index'
import { reportError } from '../utils/errors'
import { apiRequest } from './apiActions'
import { showErrorMessageToast } from './uiActions'

export const loadTaggings = taggings => ({
  type: 'LOAD_TAGGINGS',
  taggings,
})

export const deleteTagging = taggingId => ({
  type: 'DELETE_TAGGING',
  taggingId,
})

export const taggingsRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new TaggingRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      relationships: new Map(item.relationships),
    })
  )
}

export const normalizeAndLoadTaggings = data => dispatch => {
  if (data) {
    const taggings = data.reduce((memo, item) => {
      return memo.set(item.id, new TaggingRecord({ ...item }))
    }, new OrderedMap())

    return dispatch(loadTaggings(taggings))
  }
}

export const removeTagging = (tagId, title) => (dispatch, getState) => {
  const state = getState()
  const taggings = getTaggings(state)
  const platformTitlesIds = title.platformTitlesForSelectedPlatforms(state).map(pt => pt.id)
  const taggingsToBeDeleted = taggings
    .filter(t => parseInt(t.tagId) === parseInt(tagId) && platformTitlesIds.includes(t.taggableId))
    .map(t => t.id)
    .join(',')

  return dispatch(apiRequest(`tags/${tagId}/taggings/${taggingsToBeDeleted}`, 'DELETE'))
    .then(response => {
      if (response.type === 'error') throw new Error(response.message.detail)

      taggingsToBeDeleted.split(',').map(id => dispatch(deleteTagging(parseInt(id))))
    })
    .catch(error => {
      reportError(error)

      dispatch(showErrorMessageToast('There was a problem when deleting the tag. Our team has been notified.'))
      return Promise.reject()
    })
}
