export const PLATFORM_PERFORMANCE = 'Platform Performance'
export const SELECT_DISTRIBUTORS = 'Select distributors'
export const SELECT_PLATFORMS = 'Select Platforms'
export const SELECT_METRIC = 'Select metric'
export const MPV = 'MPV'
export const TOTAL_MPV = 'totalMpv'
export const TOTAL_SOV = 'totalShareOfVoice'
export const SHARE_OF_VOICE = 'Share Of Voice'
export const CARET_DOWN = 'bp5-icon-caret-down'
export const CARET_UP = 'bp5-icon-caret-up'
export const PERFORMANCE_PAGE_KEY = 'performancePage'
export const METRIC_KEY = 'metric'
export const NO_PLATFORM_SELECTED = 'No Platform Selected'
export const NO_PLATFORM_DESCRIPTION = 'Please select a platform filter to refine the data displayed on the graph.'
export const NO_DATA = 'No Data Available'
export const NO_DATA_DESCRIPTION = 'There is currently no data to display.'
export const NO_STUDIO_SELECTED = 'No Studio Selected'
export const NO_STUDIO_DESCRIPTION = 'Please select a studio filter to refine the data displayed on the graph.'
export const OVERVIEW = 'Overview'

export enum PerformancePageEnum {
  Competitor = 'Competitor Performance',
  Platform = 'Platform Performance',
}

export const COMPETITOR_TITLE = 'Competitor Analysis'
export const COMPETITOR_DETAILS =
  'These charts allow you to see your performance against your competitors across all platforms. These can be filtered using a Total MPV score that aggregates the total Media Placement Value of all spots on each Webstore, or a Share of Voice percentage that calculates the percentage Share of Voice your placements receive on each Webstore.'

export const PLATFORM_TITLE = 'Platform Performance'
export const PLATFORM_DETAILS =
  'These charts allow you to see the performance of your placements on each Webstore platform. These can be filtered using a Total MPV score that aggregates the total Media Placement Value for each Platform, or a Share of Voice percentage that displays the Share of Voice metrics for each platform.'
