import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Item from '../GenericScan/Item'
import { linkToPage } from '../utils'
import style from './ShopNow.module.scss'

function ShopNow({ spots, section, match }) {
  const menuSpots = () => {
    return spots.filter(spot => spot && spot.linkedPageId && !spot.artworkUrl)
  }

  const artworkSpots = () => {
    return spots.filter(spot => spot && spot.artworkUrl)
  }

  const renderShopNowMenu = () => (
    <ul>
      <h3>{section.name}</h3>
      {menuSpots().map(spot => (
        <li key={spot.id}>
          <a href={linkToPage(spot, match)}>{spot.name}</a>
        </li>
      ))}
    </ul>
  )

  return (
    <div className={style.root}>
      {menuSpots().isEmpty() && <h3>{section.name}</h3>}
      <ul>
        {!menuSpots().isEmpty() && <li className={style.shopNowMenu}>{renderShopNowMenu()}</li>}
        {artworkSpots().map(
          spot =>
            spot && (
              <li key={spot.id}>
                <Item spot={spot} section={section} showTitleName={true} />
              </li>
            )
        )}
      </ul>
    </div>
  )
}

ShopNow.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(ShopNow)
