import BigNumber from 'Components/BigNumber'
import PanelSection from 'Components/PanelSection'
import styles from 'Components/TitleDetail/styles.module.scss'
import React from 'react'
import { connect } from 'react-redux'

class OptimizationScore extends React.Component {
  render() {
    const { optimizationScore } = this.props

    return (
      optimizationScore && (
        <PanelSection flex noPadding>
          <BigNumber
            number={optimizationScore?.score || 0}
            decimalPlaces={2}
            units='%'
            numberClassname={styles.scores}
            unitsClassname={styles.scoreUnit}
          />
        </PanelSection>
      )
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { titleAudit, territory } = ownProps

  const optimizationScore = titleAudit && titleAudit.getOptimizationScoreForTerritory(state, territory.id)

  return {
    titleAudit,
    territory,
    optimizationScore,
  }
}

export default connect(mapStateToProps)(OptimizationScore)
