import React from 'react'
import style from './CineplexSeeMore.module.scss'
import CineplexSeeMoreItem from './CineplexSeeMoreItem'

class CineplexSeeMore extends React.Component {
  render() {
    const { page } = this.props
    return (
      <React.Fragment>
        <div className={style.sectionHeader}>{<h3>{page.name}</h3>}</div>
        <div className={style.container}>{this.renderSpots()}</div>
      </React.Fragment>
    )
  }

  renderSpots() {
    const { section, isMerchandiseFilteringEnabled } = this.props

    return this.spots().map(
      spot =>
        spot && (
          <div className={style.item} key={spot.id}>
            <CineplexSeeMoreItem
              spot={spot}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
              client={this.isStudioSelected(spot.id)}
              section={section}
              hideTitle
            />
          </div>
        )
    )
  }

  isFirstSection() {
    const { index } = this.props

    return index === 0
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

export default CineplexSeeMore
