export enum YouTubeSectionTypes {
  _Container = 'youtube::container',
  _Category = 'youtube::category',
  _LandscapeContainer = 'youtube::landscape_container',
  _LandscapeGrid = 'youtube::landscape_grid',
  _Grid = 'youtube::grid',
}

export enum AppleTvSectionTypes {
  _Spotlight = 'apple_tv::spotlight',
  _Swoosh = 'apple_tv::swoosh',
  _Brick2 = 'apple_tv::brick2',
  _NotesLockup = 'apple_tv::notesLockup',
  _ThemedLockup = 'apple_tv::themedLockup',
  _InfiniteGrid = 'apple_tv::infiniteGrid',
  _ChannelUpsell = 'apple_tv::channelUpsell',
  _ChannelLockup = 'apple_tv::channelLockup',
  _GridWithText = 'apple_tv::grid_with_text',
  _Chart = 'apple_tv::chart',
  _NotesLockupDetailed = 'apple_tv::noteslockupdetailed',
}

export enum GooglePlaySectionTypes {
  _BannerSection = 'google_play::banner_section',
}

export enum RowEightSectionTypes {
  _Grid = 'row8::grid',
  _HeroCarousel = 'row8::hero_carousel',
}

export enum RakutenTvSectionTypes {
  _Menu = 'rakuten::menu',
  _TabMenu = 'rakuten::tabmenu',
  _SubMenu = 'rakuten::submenu',
  _HeroCarousel = 'rakuten::hero_carousel',
  _Container = 'rakuten::container',
  _SaleContainer = 'rakuten::sale_container',
  _VideoContainer = 'rakuten::video_container',
  _Category = 'rakuten::category',
  _LandscapeContainer = 'rakuten::landscape_container',
  _Grid = 'rakuten::grid',
  _LandscapeGrid = 'rakuten::landscape_grid',
  _SaleGrid = 'rakuten::sale_grid',
  _ChartContainer = 'rakuten::chart_container',
}

export enum PatheSectionTypes {
  _Menu = 'pathe::menu',
  _SubMenu = 'pathe::submenu',
  _HeroCarousel = 'pathe::hero_carousel',
  _Container = 'pathe::container',
  _PortraitContainer = 'pathe::portrait_container',
  _LandscapeContainer = 'pathe::landscape_container',
  _Collection = 'pathe::collection',
  _Grid = 'pathe::grid',
}

export enum SFAnytimeSectionTypes {
  _Menu = 'sfanytime::main_menu',
  _SubMenu = 'sfanytime::submenu',
  _HeroCarousel = 'sfanytime::hero_carousel',
  _Container = 'sfanytime::container',
  _SubheadingContainer = 'sfanytime::subheading_container',
  _ChartContainer = 'sfanytime::chart_container',
  _LandscapeContainer = 'sfanytime::landscape_container',
  _LargeLandscapeContainer = 'sfanytime::large_landscape_container',
  _Grid = 'sfanytime::grid',
}

export enum ViaplaySectionTypes {
  _Menu = 'viaplay::main_menu',
  _SubMenu = 'viaplay::submenu',
  _HeroCarousel = 'viaplay::hero_carousel',
  _CategoryHeroCarousel = 'viaplay::category_hero_carousel',
  _Container = 'viaplay::container',
  _LandscapeContainer = 'viaplay::landscape_container',
  _LargeLandscapeContainer = 'viaplay::large_landscape_container',
  _PortraitContainer = 'viaplay::portrait_container',
  _PortraitGrid = 'viaplay::portrait_grid',
  _Grid = 'viaplay::grid',
  _Collection = 'viaplay::collection',
  _CollectionGrid = 'viaplay::collection_grid',
  _MosaicContainer = 'viaplay::mosaic_container',
  _SymmetricMosaicContainer = 'viaplay::symmetric_mosaic_container',
}

export enum OrangeSectionTypes {
  _HeroCarousel = 'orange::hero_carousel',
  _Container = 'orange::container',
  _LargeContainer = 'orange::large_container',
  _LandscapeContainer = 'orange::landscape_container',
  _ChartContainer = 'orange::chart',
  _Grid = 'orange::grid',
  _LandscapeGrid = 'orange::landscape_grid',
}

export enum CanalPlusSectionTypes {
  _HeroCarousel = 'canalplus::hero_carousel',
  _LinkExternalWithText = 'canalplus::link_external_with_text',
  _Container = 'canalplus::container',
  _PageContainer = 'canalplus::page_container',
  _LargeContainer = 'canalplus::large_container',
  _PortraitContainer = 'canalplus::portrait_container',
  _XLContainer = 'canalplus::xl_container',
  _WideScreenContainer = 'canalplus::widescreen_container',
  _LargePortraitContainer = 'canalplus::large_portrait_container',
  _Grid = 'canalplus::grid',
  _PortraitGrid = 'canalplus::portrait_grid',
  _BannerImage = 'canalplus::banner_image',
  _LinkExternal = 'canalplus::link_external',
  _HeaderImage = 'canalplus::header_image',
  _ChartContainer = 'canalplus::chart_container',
  _MainMenu = 'canalplus::menu',
  _MosaicContainer = 'canalplus::mosaic_container',
}

export enum MegogoSectionTypes {
  _HeroCarousel = 'megogo::hero_carousel',
  _Submenu = 'megogo::submenu',
  _Container = 'megogo::container',
  _Collection = 'megogo::collection',
  _Portrait = 'megogo::portrait_container',
  _LargeLandscapeContainer = 'megogo::large_landscape_container',
  _LandscapeContainer = 'megogo::landscape_container',
  _PageContainer = 'megogo::page_container',
  _Grid = 'megogo::grid',
  _CollectionGrid = 'megogo::collection_grid',
  _LandscapeGrid = 'megogo::landscape_grid',
  _HeaderImage = 'megogo::header_image',
  _TabMenu = 'megogo::tabmenu',
}

export enum MegogoPaymentTypes {
  _Free = 'free',
  _Payment = 'payment',
  _Subscription = 'subscription',
}

export enum AmazonSectionTypes {
  _HeroCarousel = 'amazon::hero_carousel',
  _ContainerWithBackground = 'amazon::container_with_background',
  _ChartContainerWithBackground = 'amazon::chart_container_with_background',
}

export enum MoviesAnywhereTypes {
  _PageContainer = 'movies_anywhere::page_container',
}
