import {
  getMerchandizingPages,
  getMerchandizingPlatformTitles,
  getMerchandizingScans,
  getMerchandizingSections,
} from 'Selectors/index'
import { getPlatformTitles } from 'Selectors/index'
import { Map, Record } from 'immutable'
import { createSelector } from 'reselect'
export default class SpotRecord extends Record({
  id: 0,
  selected: false,
  position: 0,
  name: '',
  label: '',
  artworkUrl: '',
  linkedPageId: '',
  sectionId: '',
  pageId: '',
  spotType: '',
  metadata: '',
  platformIdentifier: '',
  highlight: false,
  merchandizingPlatformTitleId: '',
  url: '',
  genre: '',
  description: '',
  kind: '',
  collectionName: '',
  screenshotUrl: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {
  linkedPage(state) {
    const pages = getMerchandizingPages(state)

    return this.linkedPageId && pages.get(this.linkedPageId)
  }

  // Page
  page(state) {
    const pages = getMerchandizingPages(state)
    if (!pages || pages.isEmpty()) return new Map()

    return pages.get(this.pageId)
  }

  // Section
  section(state) {
    const sections = getMerchandizingSections(state)
    if (!sections || sections.isEmpty()) return new Map()

    return sections.get(this.sectionId)
  }

  // Merchandizing Platform Title
  merchandizingPlatformTitle(state) {
    const merchandizingPlatformTitles = getMerchandizingPlatformTitles(state)
    if (!merchandizingPlatformTitles || merchandizingPlatformTitles.isEmpty()) return new Map()

    return merchandizingPlatformTitles.get(this.merchandizingPlatformTitleId)
  }

  // Scan
  merchandizingScan(state) {
    const scans = getMerchandizingScans(state)
    if (!scans || scans.isEmpty()) return new Map()

    const scanId = this.getIn(['relationships', 'scan', 'data', 'id']) || new Map()

    return scans.get(scanId)
  }

  platformTitles(state) {
    this.getPlatformTitle =
      this.getPlatformTitle ||
      createSelector(
        getPlatformTitles,
        (_state, props) => props.relationships.getIn(['platformTitles', 'data']),
        (platformTitles, platformTitlesRel) => {
          return platformTitlesRel.map(item => platformTitles.get(item.get('id')))
        }
      )

    return this.getPlatformTitle(state, { relationships: this.relationships })
  }
}
