import { CLIENT, CLIENTS, CLIENT_DASHBOARD, CLIENT_META, CLIENT_COMPETITORS } from './utils'
export const LOAD_CLIENT_DASHBOARD = 'clients/LOAD_DASHBOARD'
export const SELECT_CLIENTS = 'clients/SELECT_CLIENTS'
export const LOAD_CLIENT_META = 'clients/LOAD_CLIENT_META'
export const LOAD_CLIENT_COMPETITORS = 'clients/LOAD_CLIENT_COMPETITORS'
export const TOGGLE_CLIENT_COMPETITOR = 'clients/TOGGLE_CLIENT_COMPETITOR'

export default function clientReducer(state, action) {
  switch (action.type) {
    case LOAD_CLIENT_DASHBOARD: {
      return state.withMutations(s => {
        s.set(CLIENT_DASHBOARD, action.clientDashboard)
      })
    }
    case 'LOAD_CLIENTS': {
      return state.withMutations(s => {
        const clients = state.get(CLIENTS)
        clients.size === 0 ? s.set(CLIENTS, action.clients) : s.mergeIn([CLIENTS], action.clients)
      })
    }
    case 'LOAD_CLIENT': {
      return state.withMutations(s => {
        s.set(CLIENT, action.client)
      })
    }
    case LOAD_CLIENT_META: {
      return state.withMutations(s => {
        s.set(CLIENT_META, action.meta)
      })
    }
    case 'SET_CLIENT': {
      return state.withMutations(s => {
        s.setIn([CLIENTS, action.clientId, 'selected'], true)
      })
    }
    case SELECT_CLIENTS: {
      const { clientIds } = action
      return state.withMutations(s => {
        clientIds.forEach(id => {
          s.updateIn([CLIENTS, id, 'selected'], selected => !selected)
        })
      })
    }
    case LOAD_CLIENT_COMPETITORS: {
      const { competitors } = action;
      return state.withMutations(s => {
        const updatedCompetitors = competitors.map(competitor => ({
          ...competitor,
          selected: true
        }));
        s.set(CLIENT_COMPETITORS, updatedCompetitors);
      });
    }
    case TOGGLE_CLIENT_COMPETITOR: {
      const { competitorId } = action
      const updatetCompetitors = state.getIn([CLIENT_COMPETITORS]).map(competitor => {
        if (competitor.id === competitorId) {
          competitor.selected = !competitor.selected;
        }
        return competitor;
      })
      return state.withMutations(s => {
        s.set(CLIENT_COMPETITORS, updatetCompetitors);
      })
    }
    default:
      return state
  }
}
