import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getClient,
  getScanFilters,
  getSelectedPlatforms,
  getSelectedPlatformsWithShowFlags,
  getSelectedScan,
  getTitleAuditsForScan,
  getUI,
} from 'Selectors/index'

import { resetFilter, retrieveScan, setIncludes } from 'Actions/scanActions'
import { resetTitleAudits, retrieveMoreTitleAudits, retrieveTitleAudits } from 'Actions/titleAuditActions'

import styles from 'Components/CompetitorsPage/styles.module.scss'
import cx from 'classnames'

import { setRootPath } from 'Actions/uiActions'
import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import Grid from 'Components/ScanPage/Grid'
import Toolbar from 'Components/ScanPage/Toolbar'
import { RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps<{ scanId: string }> {
  scanId: number
  competitors: boolean
}

const CompetitorsPage = ({ match, scanId, competitors = true }: Props) => {
  const dispatch = useDispatch()
  const scan = useSelector(state => getSelectedScan(state))
  const client = useSelector(state => getClient(state))
  const titleAudits = useSelector(state => getTitleAuditsForScan(state, { scan }))
  const platforms = useSelector(state => getSelectedPlatforms(state))
  const scanView = useSelector(state => getUI(state).get('scanView'))
  const platformsWithFlags = useSelector(state => getSelectedPlatformsWithShowFlags(state))
  const filter = useSelector(state => getScanFilters(state))
  const scanStats = useSelector(state => getUI(state).get('scanStats'))

  useEffect(() => {
    dispatch(resetFilter())
    dispatch(resetTitleAudits())
    dispatch(retrieveScan(scanId)).then(() => {
      dispatch(retrieveTitleAudits(scanId, competitors))
    })
    dispatch(setRootPath(match.url))
  }, [competitors, dispatch, match.url, scanId])

  return (
    <div>
      <div className={cx(styles.root, 'bp5-focus-disabled')}>
        <Toolbar
          hideScanView={true}
          hideUnitSales={true}
          client={client}
          scanStats={scanStats}
          scanView={scanView}
          scan={scan}
          filter={filter}
          competitors={competitors}
        />
        {scan && (
          <LeftRight>
            <FluidLeft>
              <Grid
                scan={scan}
                competitors={true}
                platforms={platforms}
                client={client}
                titleAudits={titleAudits}
                retrieveMoreTitleAudits={retrieveMoreTitleAudits}
                platformsWithFlags={platformsWithFlags}
                setIncludes={setIncludes}
              />
            </FluidLeft>
          </LeftRight>
        )}
      </div>
    </div>
  )
}

export default CompetitorsPage
