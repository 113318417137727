import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './Menu.module.scss'

class SubMenuLinks extends React.Component {
  renderDropdown() {
    const { spots, section } = this.props

    return (
      <ul>
        {spots &&
          section.sectionType === 'amctheatres::submenu_links' &&
          spots.map(s => (
            <li className={style.dropdownItem} key={s.id}>
              <a className={style.dropdownItemLink} href={linkToPage(s, this.props.match)}>
                {s.name}
              </a>
              {s.name === 'See All' && <i className={style.rightArrow} />}
            </li>
          ))}
        {spots &&
          section.sectionType === 'amctheatres::submenu_image' &&
          spots.map(s => (
            <li className={style.dropdownItem} key={s.id}>
              <a href={linkToPage(s, this.props.match)}>
                <div
                  style={{
                    background: `black url(${s.artworkUrl}) center center no-repeat`,
                    backgroundSize: 'cover',
                    width: '285px',
                    height: '171px',
                    marginBottom: '20px',
                  }}
                ></div>
                <h3>{s.name}</h3>
              </a>
            </li>
          ))}
      </ul>
    )
  }

  render() {
    return this.renderDropdown()
  }
}

SubMenuLinks.propTypes = {
  spots: PropTypes.object,
  match: PropTypes.object,
}

export default withRouter(SubMenuLinks)
