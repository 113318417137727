import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { retrieveClientDeliveredScans } from 'Actions/scanActions'
import { getHasMoreDeliveredScansToLoad, getScans } from 'Selectors/index'

export const useScans = () => {
  const dispatch = useDispatch()
  const [offset, setOffset] = useState(2)

  const { hasMoreToLoad, scans } = useSelector(state => {
    const deliveredScans = getScans(state)
    const scansArray = Array.isArray(deliveredScans) ? deliveredScans : deliveredScans.valueSeq().toArray()

    return {
      hasMoreToLoad: getHasMoreDeliveredScansToLoad(state),
      scans: scansArray.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    }
  })

  useEffect(() => {
    dispatch(retrieveClientDeliveredScans())
  }, [dispatch])

  const handleInfiniteLoad = () => {
    if (hasMoreToLoad) {
      dispatch(retrieveClientDeliveredScans(offset)).then(() => setOffset(offset + 1))
    }
  }

  return { scans, hasMoreToLoad, handleInfiniteLoad }
}
