import { PageItems, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import Slider from 'react-slick'
import { SFAnytimeSectionTypes } from '../enums'
import { computeContainerClasses, renderSpotsWithItem, sliderSettings } from '../helpers'
import { linkToPage } from '../utils'
import Arrow from './Arrow'
import style from './Container.module.scss'
import Item from './Item'

type ContainerProps = RouteComponentProps & PageItems

const Container: React.FC<ContainerProps> = ({
  section,
  isMerchandiseFilteringEnabled,
  spots,
  selectedStudios,
  match,
}) => {
  const seeMore = useMemo(() => spots.find((spot: Spot) => spot?.label?.toLowerCase() === 'view all'), [spots])

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.containerItem]: [SFAnytimeSectionTypes._Container, SFAnytimeSectionTypes._ChartContainer].includes(
          section.sectionType as SFAnytimeSectionTypes
        ),
        [style.landscapeContainerItem]: section.sectionType === SFAnytimeSectionTypes._LandscapeContainer,
        [style.largeLandscapeContainerItem]: section.sectionType === SFAnytimeSectionTypes._LargeLandscapeContainer,
      }),
    [section]
  )

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => {
      if (!spot) return false
      return spot?.label?.toLowerCase() !== 'view all'
    }),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const spotsPerSlider = {
    [SFAnytimeSectionTypes._Container]: 6,
    [SFAnytimeSectionTypes._ChartContainer]: 6,
    [SFAnytimeSectionTypes._LandscapeContainer]: 4,
    [SFAnytimeSectionTypes._LargeLandscapeContainer]: 3,
  }

  return (
    <div className={containerClasses}>
      <div className={style.heading}>
        <h1>{section.name}</h1>
        {seeMore && (
          <a href={linkToPage(seeMore, match)}>
            {seeMore.name}
            <i className={style.rightArrow} />
          </a>
        )}
      </div>
      <Slider
        {...sliderSettings({
          slidesToScroll: spotsPerSlider[section.sectionType],
          slidesToShow: spotsPerSlider[section.sectionType],
          arrow: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
