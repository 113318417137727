import WrappedSection from 'Containers/WrappedSection'
import React from 'react'
import style from './Header.module.scss'

function Header(props) {
  const { subSections, scan, page, section } = props
  const metadata = JSON.parse(section.metadata)

  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>{section.name}</h2>
        {metadata.subtitle && (
          <p>
            <label className={style.label}>1</label>
            {metadata.subtitle}
          </p>
        )}
      </div>
      {subSections &&
        subSections.map(
          s => s && <WrappedSection spot={s} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
        )}
    </div>
  )
}

export default Header
