import styles from 'Components/Merchandizing/Itunes/BrickHero.module.scss'
import GenericSection from 'Components/Merchandizing/Itunes/GenericSection'
import WrappedSection from 'Containers/WrappedSection'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

const BrickHeroWrapper = styled.div`
  width: calc((200px + 20px) * ${props => props.brickHeroCount});
`
class BrickHeroContainer extends GenericSection {
  render() {
    const { subSections, section } = this.props
    const { name } = section
    return (
      <div className={styles.container}>
        {name && <h4 className={styles.header}>{name}</h4>}

        <div className={styles.scrollWrapper}>
          <BrickHeroWrapper className={styles.inner} brickHeroCount={subSections.size}>
            {this.renderSubSections()}
          </BrickHeroWrapper>
        </div>
      </div>
    )
  }

  renderSubSections() {
    const { subSections, scan, page, fade } = this.props
    return (
      subSections &&
      subSections.map(s => (
        <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} fade={fade} />
      ))
    )
  }
}

const mapStateToProps = (_state, ownProps) => {
  const { subSections, selectedSection } = ownProps
  return {
    fade: subSections.includes(selectedSection),
  }
}

export default connect(mapStateToProps)(BrickHeroContainer)
