import { getAuditActions, getAudits, getMerchandizingMatches, getOptimizationScores, getTitles } from 'Selectors/index'
import { List, Map, Record } from 'immutable'
import { UpdateableMixin } from './mixins'
import { getRelationshipData } from './utils'

class TitleAuditRecord extends Record({
  id: 0,
  name: '',
  scanId: 0,
  titleId: 0,
  primaryArtworkUrl: null,
  actionCount: 0,
  promoted: false,
  isPreorder: false,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  merchandizingMatchesIds: [],
  auditsIds: [],
  auditActionsIds: [],
  optimizationScoresIds: [],
}) {
  getMerchandizingMatches(state) {
    const matches = getMerchandizingMatches(state)

    return getRelationshipData(matches, this.merchandizingMatchesIds)
  }

  getAudits(state) {
    const audits = getAudits(state)

    return getRelationshipData(audits, this.auditsIds)
  }

  getActions(state) {
    const actions = getAuditActions(state)

    return getRelationshipData(actions, this.auditActionsIds)
  }

  getAuditForPlatform(state, platformId) {
    const auditsForTitle = this.getAudits(state)
    return auditsForTitle
      .valueSeq()
      .filter(audit => audit && audit.platformId.toString() === platformId.toString())
      .first()
  }

  getOptimizationScoreForTerritory(state, territoryId) {
    const optimizationScores = this.optimizationScores(state)
    return optimizationScores
      .valueSeq()
      .filter(score => score && score.territoryId.toString() === territoryId.toString())
      .first()
  }

  getTitle(state) {
    const titles = getTitles(state)

    const { titleId } = this
    return titles.get(titleId.toString())
  }

  getActionCount(state) {
    const auditsForTitle = this.getAudits(state)

    return auditsForTitle.valueSeq().reduce((m, audit) => {
      return audit ? m + audit.actions(state).size : 0
    }, 0)
  }

  optimizationScores(state) {
    const optimizationScores = getOptimizationScores(state)

    return new List(this.optimizationScoresIds.map(optimizationScoreId => optimizationScores.get(optimizationScoreId)))
  }
}

export default UpdateableMixin(TitleAuditRecord)
