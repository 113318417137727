import { getAuditArtworks } from 'Selectors/index'
import { Map, Record } from 'immutable'

export default class ArtworkComparisonRecord extends Record({
  id: 0,
  changeDetected: false,
  distance: 0,
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  selected: false,
  previousArtworkId: 0,
}) {
  previousArtwork(state) {
    const artworks = getAuditArtworks(state)

    return artworks.get(this.previousArtworkId)
  }
}
