import { OrderedMap } from 'immutable'

export const resetData = loadMethod => loadMethod(new OrderedMap(), true)

export const toSnakeCase = str => {
  return str
    .split(/[\s,]+/)
    .map(part => part.replace(/([A-Z])/g, '_$1').toLowerCase())
    .join(' ')
}
