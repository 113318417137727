import { Navigation, Scan, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'

type NavBarProps = RouteComponentProps &
  Navigation & {
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, match }) => {
  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={cx({
            [style.listItem]: spot.label !== 'align_right',
            [style.listItemRight]: spot.label === 'align_right',
            [style.activeNav]: spot.linkedPageId === page.id,
          })}
        >
          <span>{spot.name}</span>
        </a>
      )
    },
    [match, page]
  )

  const renderSpots = useMemo(() => {
    return spots.map(spot => {
      return renderLink(spot)
    })
  }, [spots, renderLink])

  return (
    <div className={style.root}>
      <div className={style.menuLinks}>{renderSpots}</div>
    </div>
  )
}

export default withRouter(NavBar)
