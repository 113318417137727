import { Button, Classes, Dialog } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import styles from './ContainerDialog.module.scss'

import { retrievePlatformTitle } from 'Actions/platformTitleActions'
import LeftRight from 'Components/LeftRight'
import FluidLeft from 'Components/LeftRight/FluidLeft'
import { getPlatformTitleById } from 'Selectors/index'
import { Link, Route, withRouter } from 'react-router-dom'
import Artwork from '../TitleDetail/Artwork'
import ContainerBadge from './ContainerBadge'
import ContainerDetailPanel from './ContainerDetailPanel'
import ContainerEdit from './ContainerEdit'
import LocationCandidatesPanel from './LocationCandidatesPanel'

import { Popover, PopoverInteractionKind, Position, Tab, Tabs } from '@blueprintjs/core'

class ContainerDialog extends React.Component {
  constructor(props) {
    super(props)

    const { platformTitle } = this.props

    const selectedTabId = platformTitle.canApprove ? 'candidates' : 'platformTitleDetails'

    this.state = {
      performingAction: false,
      approveSpinner: false,
      unavailableSpinner: false,
      loadingCandidates: false,
      selectedTabId,
    }
  }

  componentDidMount() {
    const { platformTitleId, retrievePlatformTitle } = this.props

    this.setState({ loadingCandidates: true })
    retrievePlatformTitle(platformTitleId).then((platformTitle) => {
      if (platformTitle.canApprove) this.handleTabChange('candidates')
        
      this.setState({ loadingCandidates: false })
    })
  }

  handleClose = () => {
    this.props.history.goBack()
  }

  handleRetry = () => {
    this.setState({ retrySpinner: true, performingAction: true })
    this.props.retryPlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ retrySpinner: false, performingAction: false })
    })
  }

  handleUnavailable = () => {
    this.setState({ unavailableSpinner: true, performingAction: true })
    this.props.markAsUnavailablePlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ unavailableSpinner: false, performingAction: false })
    })
  }

  handleUnavailableForever = () => {
    this.setState({ performingAction: true })
    this.props.markAsUnavailableForeverPlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ performingAction: false })
    })
  }

  handleAbort = () => {
    this.setState({ abortSpinner: true, performingAction: true })
    this.props.abortPlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ abortSpinner: false, performingAction: false })
    })
  }

  handleDeleteTransitions = () => {
    this.setState({ abortSpinner: true, performingAction: true })
    this.props.deleteTransitionsPlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ abortSpinner: false, performingAction: false })
      this.handleClose()
    })
  }

  handleTabChange = selectedTabId => {
    this.setState({
      selectedTabId,
    })
  }

  handleVirtualClick = () => {
    this.setState({ approveSpinner: true, performingAction: true })
    this.props.markAsVirtualPlatformTitle(this.props.platformTitle.id).then(() => {
      this.setState({ approveSpinner: false, performingAction: false })
    })
  }

  render() {
    const { title, platformTitle, match, locationCandidates, updatePlatformTitleThenApprove, update } = this.props

    const { performingAction, selectedTabId } = this.state

    const { approved } = platformTitle

    const editUrl = `${match.url}/edit`

    const badge = <ContainerBadge className={styles.badge} platformTitle={platformTitle} />

    return (
      <Dialog isOpen onClose={this.handleClose} className={cx(styles.dialog, 'bp5-focus-disabled')}>
        <div className={styles.popup}>
          <div className={styles.header}>
            <LeftRight>
              <FluidLeft>
                <h3>
                  {title.name}
                  <Popover
                    interactionKind={PopoverInteractionKind.HOVER}
                    popoverClassName='bp5-dark bp5-popover-content-sizing'
                    position={Position.RIGHT}
                    boundary='viewport'
                    content={platformTitle.errorMessage}
                  >
                    {badge}
                  </Popover>
                </h3>
                <div className={styles.platform}>
                  {platformTitle.platformName}, {platformTitle.territoryIsoCode}
                </div>
                <a className={styles.link} target='_blank' href={platformTitle.url} rel='noreferrer'>
                  {platformTitle.url}
                </a>
              </FluidLeft>
              <div className={styles.artworkContainer}>
                {approved && <Artwork width={120} height={120} artworkUrl={platformTitle.artworkCdnUrl} />}
              </div>
            </LeftRight>
          </div>

          <div className={styles.body}>
            <div className={styles.tabs}>
              <Tabs id='Tabs2Example' onChange={this.handleTabChange} selectedTabId={selectedTabId}>
                <Tab id='platformTitleDetails' title='Details' />
                <Tab id='candidates' title='Candidates' />
              </Tabs>
            </div>

            <div className={styles.tabsContainer}>
              {selectedTabId === 'candidates' && (
                <LocationCandidatesPanel
                  locationCandidates={locationCandidates}
                  platformTitle={platformTitle}
                  areLocationCandidatesLoading={this.state.loadingCandidates}
                  closeParent={this.handleClose}
                />
              )}
              {selectedTabId === 'platformTitleDetails' && <ContainerDetailPanel platformTitle={platformTitle} />}
            </div>
          </div>

          <div className={cx(Classes.DIALOG_FOOTER, styles.footer)}>
            <div className={styles.primaryActions}>
              {
                <Link to={editUrl}>
                  <a disabled={performingAction} className={cx('bp5-button bp5-intent-primary', styles.actionButton)}>
                    Edit
                  </a>
                </Link>
              }

              <Route
                path={editUrl}
                render={() => (
                  <ContainerEdit
                    platformTitle={platformTitle}
                    title={title}
                    updatePlatformTitleThenApprove={updatePlatformTitleThenApprove}
                    update={update}
                  />
                )}
              />
              {platformTitle.canMarkAsVirtual && (
                <Button
                  disabled={performingAction}
                  className={cx('bp5-intent-success', styles.actionButton)}
                  onClick={this.handleVirtualClick}
                >
                  Virtual
                </Button>
              )}
              {platformTitle.canRetry && (
                <Button
                  disabled={performingAction}
                  className={cx('bp5-intent-warning', styles.actionButton)}
                  onClick={this.handleRetry}
                >
                  Retry
                </Button>
              )}
              {platformTitle.canMarkAsUnavailable && (
                <Button
                  disabled={performingAction}
                  className={cx(styles.lightRed, styles.actionButton)}
                  onClick={this.handleUnavailable}
                >
                  Unavailable
                </Button>
              )}
              {platformTitle.canMarkAsUnavailableForever && (
                <Button
                  disabled={performingAction}
                  className={cx('bp5-intent-danger', styles.actionButton)}
                  onClick={this.handleUnavailableForever}
                >
                  Unavailable Forever
                </Button>
              )}
              {platformTitle.canAbort && (
                <Button
                  disabled={performingAction}
                  className={cx('bp5-intent-warning', styles.actionButton)}
                  onClick={this.handleAbort}
                >
                  Abort
                </Button>
              )}
              {platformTitle.canDeleteTransitions && (
                <Button
                  disabled={performingAction}
                  className={cx('bp5-intent-warning', styles.actionButton)}
                  onClick={this.handleDeleteTransitions}
                >
                  Delete Transitions
                </Button>
              )}
            </div>
            <div className={cx(Classes.DIALOG_FOOTER_ACTIONS, styles.secondaryActions)}>
              <Button className='bp5-intent-primary' onClick={this.handleClose}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { platformTitleId } = ownProps
  const platformTitle = getPlatformTitleById(state, { id: platformTitleId })

  return {
    platformTitle,
  }
}

const mapDispatchToProps = dispatch => ({
  retrievePlatformTitle: platformTitleId => dispatch(retrievePlatformTitle(platformTitleId)),
})

const ConnectedContainerDialog = connect(mapStateToProps, mapDispatchToProps)(ContainerDialog)
const ConnectedContainerDialogWithRouter = withRouter(ConnectedContainerDialog)
export default ConnectedContainerDialogWithRouter
