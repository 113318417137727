import { Analytics, Client, ClientMeta, User } from 'Interfaces/*';
import { Map } from 'immutable';
import { useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const initializeMultipleGTM = (gtmIds: string[], user: User) => {
  // Prevent duplicate DL pushes by checking existing dataLayer
  if (window.dataLayer && window.dataLayer.some(entry => entry.event === 'user_init')) {
    return; // Skip if already initialized
  }
  
  gtmIds.forEach(gtmId => {
    const tagManagerArgs = {
      gtmId,
      dataLayer: {
        event: 'user_init', // Unique event to avoid multiple firings
        id: user.username,
        account: user.account,
        employee: user.employee,
        user_email: user.email,
      },
    };
    TagManager.initialize(tagManagerArgs);
  });
};

const useAnalytics = (client: Map<string, Client>, clientMeta: Map<string, ClientMeta>) => {
  const hasInitialized = useRef(false); // Prevent multiple initializations

  useEffect(() => {
    if (!hasInitialized.current && client?.size > 0 && clientMeta?.size > 0) {
      const user = clientMeta.get('user') as unknown as User;
      const analytics = clientMeta.get('analytics') as unknown as Analytics;
      const clientName = client.get('name') as unknown as string;

      initializeMultipleGTM(analytics.gtm, { ...user, account: clientName });
      hotjar.initialize(Number(analytics.hotjar), Number(process.env.YOUR_HOTJAR_SNIPPET_VERSION));

      hasInitialized.current = true; // Mark initialization as done
    }
  }, [client, clientMeta]);
};

export default useAnalytics;
