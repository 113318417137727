import cx from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import style from './HeroCarousel.module.scss'
import Item from './Item'
import arrow from './arrow.png'

function Arrow(props) {
  const { onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
  })

  return (
    <div
      className={cx({
        [style.leftWrapper]: left === true,
        [style.rightWrapper]: right === true,
      })}
      onClick={onClick}
    >
      <img className={classnames} alt='sliderArrow' src={arrow} />
    </div>
  )
}

function renderSpots(spots, section) {
  return spots.map(spot => {
    return (
      spot && (
        <div className={style.item} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function HeroCarousel(props) {
  const { section, spots } = props

  const sliderSettings = {
    dots: true,
    infinite: true,
    centerPadding: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplay: true,
    speed: 800,
    arrows: true,
    swipe: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
    appendDots: dots => {
      const customDots = React.Children.map(dots, dot => {
        if (dot.props['className'] === 'slick-active') {
          return React.cloneElement(dot, {
            className: style.slickActive,
          })
        } else {
          return React.cloneElement(dot, {
            className: style.slickNormal,
          })
        }
      })
      return (
        <div style={{ bottom: '10px' }}>
          <ul style={{ paddingLeft: '7px' }}>{customDots}</ul>
        </div>
      )
    },
    customPaging: i => (
      <div
        style={{
          display: 'block',
          textIndent: '-9999px',
          backgroundColor: 'rgba(255,255,255,0.5)',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          boxShadow: '1px 1px 4px rgba(0,0,0,.4)',
          overflow: 'hidden',
        }}
      >
        {i + 1}
      </div>
    ),
  }

  return (
    <div className={style.container}>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default HeroCarousel
