import { Icon, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import style from './styles.module.scss'

function AvodItem(props) {
  const { client, actions, isAvod } = props

  const classNames = cx(style.avodItem, {
    [style.seasonAvod]: client && client.titleTypeSeason,
  })
  return (
    <div className={classNames}>
      {isAvod ? (
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          popoverClassName='bp5-popover-content-sizing'
          position={Position.RIGHT}
          disabled={actions.isEmpty()}
          content={
            <div>
              {actions.valueSeq().map(action => (
                <div key={action.id}>{action.message}</div>
              ))}
            </div>
          }
        >
          <div>
            {isAvod && actions.isEmpty() ? (
              <Icon color='#a7b6c2' icon='tick' iconSize={Icon.SIZE_LARGE} />
            ) : (
              <Icon color='red' icon='cross' iconSize={Icon.SIZE_LARGE} />
            )}
          </div>
        </Popover>
      ) : (
        <span className={style.icontickColor}>-</span>
      )}
    </div>
  )
}

AvodItem.propTypes = {
  audit: PropTypes.object,
  client: PropTypes.object,
}

export default AvodItem
