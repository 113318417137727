import { readEndpoint } from 'Actions/apiActions'
import { Map, OrderedMap, fromJS } from 'immutable'

import { highlightTitles } from 'Actions/studioActions'
import MerchandizingPlatformTitleRecord from 'Records/merchandizing/platformTitleRecord'

import {
  LOADING_MERCHANDIZING_PLATFORM_TITLES,
  LOAD_MERCHANDIZING_PLATFORM_TITLES,
} from 'Reducers/app/merchandizing/platformTitleReducer'
import { getMerchandizingPages, getMerchandizingScanById } from 'Selectors/index'

export const filterMerchandizngPlatformTitles = item => item.type === 'platformTitles'

export const merchandizingPlatformTitleRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new MerchandizingPlatformTitleRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadMerchandizingPlatformTitles = (data, reset) => dispatch => {
  const merchandizingPlatformTitles = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new MerchandizingPlatformTitleRecord({
        ...item,
      })
    )
  }, new OrderedMap())

  dispatch(loadMerchandizingPlatformTitles(merchandizingPlatformTitles, reset))
}

export const retrieveMerchandizingPlatformTitlesForStudio = (studio, scan) => (dispatch, getState) => {
  const state = getState()
  const merchScan = getMerchandizingScanById(state, { id: scan.id })
  const pages = getMerchandizingPages(state)
  const page = pages.valueSeq().find(p => p.scanId === merchScan.id)
  dispatch(loadingMerchandizingPlatformTitles(studio.id))

  return dispatch(
    readEndpoint(
      `merchandizing/platform-titles?filter[studio_id]=${studio.id}&filter[page_id]=${page.id}&page[limit]=100`
    )
  ).then(response => {
    const merchandizingPlatformTitles = response.data
      .filter(filterMerchandizngPlatformTitles)
      .reduce(merchandizingPlatformTitleRecordsMapper, new OrderedMap())

    dispatch(loadMerchandizingPlatformTitles(merchandizingPlatformTitles))
    dispatch(loadingMerchandizingPlatformTitles(studio.id))
    dispatch(highlightTitles(studio.id))
  })
}

export const loadingMerchandizingPlatformTitles = studioId => ({
  type: LOADING_MERCHANDIZING_PLATFORM_TITLES,
  studioId,
})

export const loadMerchandizingPlatformTitles = (platformTitles, reset) => ({
  type: LOAD_MERCHANDIZING_PLATFORM_TITLES,
  platformTitles,
  reset,
})
