import GenresDropdown from 'Containers/GenresDropdown'
import React from 'react'
import GenericSection from './GenericSection'
import style from './GenresDropdown.module.scss'

class Genres extends GenericSection {
  render() {
    return <div className={style.container}>{this.renderSubSections()}</div>
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => <GenresDropdown type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }
}

export default Genres
