import { Navigation, Scan, Section, Spot } from 'Interfaces/interfaces'
import React, { useCallback } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'
import logo from './logo.png'

type NavBarProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const NavBar: React.FC<NavBarProps> = ({ spots, page, match }) => {
  const renderLink = useCallback(
    (spot: Spot) => {
      return (
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
        >
          <span>{spot.name}</span>
        </a>
      )
    },
    [match, page]
  )

  return (
    <div className={style.root}>
      <div className={style.menuLinks}>
        <a className={style.logo}>
          <img alt='Viaplay' data-selenium='site-logo' src={logo} />
        </a>
        {spots.map((spot: Spot) => renderLink(spot))}
      </div>
    </div>
  )
}

export default withRouter(NavBar)
