import { Tooltip } from '@blueprintjs/core'
import styles from 'Components/ArtworkPage/PlatformHeader/styles.module.scss'
import { getPlatformById, getTerritoryById } from 'Selectors/index'
import cx from 'classnames'
import React from 'react'
import FlagTooltip from 'Components/FlagTooltip'
import { connect } from 'react-redux'

const PlatformHeaderItem = ({ platform, territory, showFlag }) => {
  const classnames = cx(styles.platformItem, {
    [styles.withFlag]: showFlag,
  })
  return (
    platform && (
      <div className={classnames}>
        <FlagTooltip territory={territory} showFlag={showFlag} width={20} height={47} offsetY={-10} />
        <Tooltip content={`${platform && platform.name}, ${territory && territory.name}`}>
          <div
            className={styles.platformLogo}
            style={{
              backgroundImage: `url(${platform.logoUrl})`,
            }}
          />
        </Tooltip>
      </div>
    )
  )
}

const mapStateToProps = (state, ownProps) => {
  const platform = getPlatformById(state, { id: ownProps.platformId })
  const territory = platform && getTerritoryById(state, { id: platform.territoryId })

  return {
    platform,
    territory,
  }
}

export default connect(mapStateToProps)(PlatformHeaderItem)
