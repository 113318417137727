import { Icon } from '@blueprintjs/core'
import { GooglePlaySectionTypes } from 'Components/Merchandizing/enums'
import { Metadata, SpotItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useRef, useEffect, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import style from './Item.module.scss'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section }) => {
  const node = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (spot.selected) {
      node.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [spot.selected])

  const inlineStyle: React.CSSProperties = useMemo(() => {
    const style: React.CSSProperties = {}
    if (client) {
      style.borderStyle = 'solid'
      style.borderColor = client.color
      style.boxShadow = `0 0 10px ${client.color}`
    }
    return style
  }, [client])

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected]
  )

  const titleClassnames = useMemo(
    () =>
      cx({
        [style.title]: section && section.sectionType !== GooglePlaySectionTypes._BannerSection,
        [style.bannerTitle]: section && section.sectionType === GooglePlaySectionTypes._BannerSection,
      }),
    [section]
  )

  const oldPriceClassnames = useMemo(
    () =>
      cx({
        [style.oldPrice]: section && section.sectionType !== GooglePlaySectionTypes._BannerSection,
        [style.bannerOldPrice]: section && section.sectionType === GooglePlaySectionTypes._BannerSection,
      }),
    [section]
  )

  const newPriceClassnames = useMemo(
    () =>
      cx({
        [style.newPrice]: section && section.sectionType !== GooglePlaySectionTypes._BannerSection,
        [style.bannerNewPrice]: section && section.sectionType === GooglePlaySectionTypes._BannerSection,
      }),
    [section]
  )

  const ratingClassnames = useMemo(
    () =>
      cx({
        [style.currentRating]: section && section.sectionType !== GooglePlaySectionTypes._BannerSection,
        [style.bannerCurrentRating]: section && section.sectionType === GooglePlaySectionTypes._BannerSection,
      }),
    [section]
  )

  const starIconClassnames = useMemo(
    () =>
      cx({
        [style.starIcon]: section && section.sectionType !== GooglePlaySectionTypes._BannerSection,
        [style.bannerStarIcon]: section && section.sectionType === GooglePlaySectionTypes._BannerSection,
      }),
    [section]
  )

  const { currentRating, oldPrice, newPrice }: Metadata = spot.metadata

  return (
    <div>
      <div className={classNames} style={inlineStyle} ref={node}>
        <div
          id={`spot_${spot.id}`}
          style={{
            background: `black url('${spot.artworkUrl}') center center no-repeat`,
            backgroundSize: 'cover',
            borderRadius: '5px',
          }}
        ></div>
      </div>
      {spot.name && (
        <div className={style.titleContainer}>
          <span className={titleClassnames}>{spot.name}</span>
          <div className={style.detail}>
            {currentRating && (
              <div className={style.rating}>
                <div className={ratingClassnames}>{currentRating}</div>
                <Icon className={starIconClassnames} icon='star' />
              </div>
            )}
            {oldPrice && <div className={oldPriceClassnames}>{oldPrice}</div>}
            {newPrice && <div className={newPriceClassnames}>{newPrice}</div>}
          </div>
        </div>
      )}
    </div>
  )
}

const ConnectedItemWithRouter = withRouter(Item)
export default ConnectedItemWithRouter
