import React from 'react'
import { Icon } from '@blueprintjs/core'
import style from './styles.module.scss'

type EmptyState = {
  header: string
  description: string
}

const EmptyState: React.FC<EmptyState> = ({header, description}) => {
  return (
    <div className={style.emptyStateWrapper}>
      <Icon icon='filter-remove' size={50} className={style.icon} />
      <h1>{header}</h1>
      <p>{description}</p>
    </div>
  )
}

export default EmptyState