import PlatformHeaderItem from 'Containers/ArtworkPlatformHeaderItem'
import React from 'react'
import styles from './styles.module.scss'

const PlatformHeader = ({ platformsWithFlags }) => {
  return (
    <div className={styles.platformHeader}>
      {platformsWithFlags.map(({ platform, showFlag }) => {
        return platform && <PlatformHeaderItem platformId={platform.id} key={platform.id} showFlag={showFlag} />
      })}
    </div>
  )
}

export default PlatformHeader
