import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import { GraphData } from 'Interfaces/*'

export const setAdapter = (template, property, colorField) => {
  template.adapters.add(property, (_value, target) => {
    const dataContext = target.dataItem.dataContext as GraphData
    return dataContext[colorField] ? am5.color(dataContext[colorField]) : _value
  })
}

export const createPieChart = (root: am5.Root, innerRadius: number) => {
  const chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(innerRadius),
    })
  )

  return chart
}

export const createLegend = (root: any, series: any, labels: boolean = false) => {
  const legend = root.container.children.push(
    am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      centerY: am5.percent(100),
      y: am5.percent(100),
      layout: root.horizontalLayout,
      marginTop: 30,
    })
  )

  legend.data.setAll(series.dataItems)

  if (labels) {
    legend.labels.template.set('text', '{categoryX}')
  }

  return legend
}

export const generateGraphTooltipHTML = (dataContext, metric, scanDate) => {
  const value = dataContext[metric] ?? 'N/A'
  const formattedDate = new Date(scanDate).toLocaleDateString('en-UK', { day: 'numeric', month: 'short' })
  return `
    <div style="background: white; padding: 10px; border-radius: 5px; color: black;">
      <strong>${formattedDate}</strong><br/>
      <div style="margin-top: 20px; width: 10px; height: 10px; background: ${dataContext.studioColor}; 
          border-radius: 50%; display: inline-block; margin-right: 5px;"></div>
      ${dataContext.name}: ${value}
    </div>
  `
}

export const setTooltipAdapter = (template, metric, scanDate) => {
  return template.add('tooltipHTML', (_html, target) => {
    const dataContext = target.dataItem.dataContext as GraphData
    return generateGraphTooltipHTML(dataContext, metric, scanDate)
  })
}
