import React from 'react'
import ChekboxInput from './Checkbox'
import style from './index.module.scss'

type MultiSelectType = {
  header?: string
  list: any
  getIsoCode?: (id: number) => any
  onChange: (listItem: any) => void
  disableSelection?: boolean
  children?: any
}

const MultiSelect: React.FC<MultiSelectType> = ({ header, list, getIsoCode, onChange, disableSelection, children }) => {
  return (
    <div className={style.multiSelectWrapper}>
      {header && <h3 className={style.listHeader}>{header}</h3>}
      {children}
      {list.map(listItem => {
        const territory = (getIsoCode && getIsoCode(listItem.territoryId).isoCode) || ''
        return (
          <ChekboxInput
            checked={listItem.selected}
            onChange={() => onChange(listItem)}
            label={`${listItem.name} ${territory}`}
            disabled={disableSelection && !listItem.selected}
            key={listItem.get('id')}
          />
        )
      })}
    </div>
  )
}

export default MultiSelect
