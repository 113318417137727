import WrappedSection from 'Containers/WrappedSection'
import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { renderMenuSpots } from '../helpers'
import { linkToPage } from '../utils'
import style from './Menu.module.scss'
import logo from './logo.png'

class Menu extends React.Component {
  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  renderLink = spot => {
    const { subSections, scan, page, match } = this.props

    const isDropdown = subSections.some(subsection => subsection.name === spot.name)
    if (isDropdown) {
      return (
        subSections &&
        subSections.map(
          s =>
            s &&
            s.name === spot.name && (
              <WrappedSection spot={spot} type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
            )
        )
      )
    }
    return (
      spot && (
        <a href={linkToPage(spot, match)} className={style.listItem}>
          {spot.name}
        </a>
      )
    )
  }

  render() {
    const { spots } = this.props
    return (
      <div className={style.root}>
        <a href={this.rootPageUrl()} className={style.logo}>
          <img alt='Kaleidescape' data-selenium='site-logo' src={logo} />
        </a>
        <div className={style.mediaNav}>{renderMenuSpots(spots, this.renderLink)}</div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

const ConnectedNavbarMenu = connect(mapStateToProps)(Menu)
export default withRouter(ConnectedNavbarMenu)
