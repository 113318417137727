import React from 'react';
import { Button } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { getTitlesLoading } from 'Selectors/index';
import cx from 'classnames'
import styles from './Toolbar.module.scss'

const ContainerToolbarButton = ({ text, className, active, onClick, ...restProps }) => {
  const isTitlesLoading = useSelector(state => getTitlesLoading(state));

  return (
    <Button
      text={text}
      className={cx(styles.btnDefault, className, { [styles.active]: active })}
      onClick={onClick}
      disabled={isTitlesLoading}
      {...restProps}
    />
  );
};

export default ContainerToolbarButton; 