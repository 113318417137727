import { PageItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { computeContainerClasses, renderSpotsWithItem } from '../helpers'
import style from './CollectionGrid.module.scss'
import Item from './Item'

const CollectionGrid: React.FC<PageItems> = ({ spots, selectedStudios, section, isMerchandiseFilteringEnabled }) => {
  const containerClasses = useMemo(() => computeContainerClasses(section, style, 4), [section])
  const totalSpots = useMemo(() => [...spots].length, [spots])

  const filteredSpots = useMemo(() => spots.filter(spot => spot?.label?.toLowerCase() !== 'view all'), [spots])

  const rednerCollectionSpots = renderSpotsWithItem({
    spots: filteredSpots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.collectionItem,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const gridWrapperClassName = useMemo(
    () =>
      cx({
        [style.smCollectionGrid]: totalSpots === 5,
        [style.xlCollectionGrid]: totalSpots === 7,
      }),
    [totalSpots]
  )

  return (
    <div className={containerClasses}>
      <h1 className={style.header}>{section.name}</h1>
      <div className={gridWrapperClassName}>{rednerCollectionSpots}</div>
    </div>
  )
}

export default CollectionGrid
