import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import React, { useLayoutEffect, useRef } from 'react'
import { createPieChart } from '../chartUtils'

type PieChartProps = {
  percentage: number
}

const PieChart: React.FC<PieChartProps> = ({ percentage }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current!)
    root._logo.dispose()

    const chart = createPieChart(root, 65)

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        alignLabels: false,
        tooltipText: '',
      })
    )

    series.slices.template.setAll({
      stateAnimationDuration: 0,
      interactive: false,
    })

    series.slices.template.states.create('hover', {
      scale: 1,
      strokeWidth: 0,
      stateAnimationDuration: 0,
    })

    series.set('tooltip', am5.Tooltip.new(root, { forceHidden: true }))
    series.labels.template.set('text', '')

    series.set(
      'colors',
      am5.ColorSet.new(root, {
        colors: [am5.color(0x32a467), am5.color(0xdddddd)],
      })
    )

    series.data.setAll([
      { category: 'Filled', value: percentage },
      { category: 'Remaining', value: 100 - Number(percentage) },
    ])

    return () => root.dispose()
  }, [percentage])

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>
}

export default PieChart
