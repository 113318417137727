import BrandOptimizationScoreRecord from 'Records/brandOptimizationScoreRecord'
import OptimizationScoreRecord from 'Records/optimizationScoreRecord'
import { LOAD_BRAND_OPTIMIZATION_SCORES, LOAD_OPTIMIZATION_SCORES } from 'Reducers/app/optimizationScoreReducer'
import { Map, OrderedMap, fromJS } from 'immutable'

export const optimizationScoreMapper = (memo, item) => {
  return memo.set(
    item.id,
    new OptimizationScoreRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const brandOptimizationScoreMapper = (memo, item) => {
  return memo.set(
    item.id,
    new BrandOptimizationScoreRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const loadBrandOptimizationScores = (optimizationScores, reset) => ({
  type: LOAD_BRAND_OPTIMIZATION_SCORES,
  optimizationScores,
  reset,
})

export const loadOptimizationScores = (optimizationScores, reset) => ({
  type: LOAD_OPTIMIZATION_SCORES,
  optimizationScores,
  reset,
})

export const normalizeAndLoadOptimizationScores = (data, reset) => {
  const optimizationScores = data.reduce((memo, item) => {
    return memo.set(item.id, new OptimizationScoreRecord({ ...item }))
  }, new OrderedMap())

  return loadOptimizationScores(optimizationScores, reset)
}

export const normalizeAndLoadBrandOptimizationScores = (data, reset) => {
  const brandOptimizationScore = data.reduce((memo, item) => {
    return memo.set(item.id, new BrandOptimizationScoreRecord({ ...item }))
  }, new OrderedMap())

  return loadBrandOptimizationScores(brandOptimizationScore, reset)
}
