import { PageItems, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { SECTION_URL } from '../constants'
import { MegogoSectionTypes } from '../enums'
import { computeContainerClasses, renderSpotsWithItem, sliderSettings } from '../helpers'
import { linkToPage } from '../utils'
import Arrow from './Arrow'
import style from './Container.module.scss'
import Item from './Item'
import RenderLinkItem from './RedirectLinkItem'

type ContainerProps = RouteComponentProps & PageItems

const Container: React.FC<ContainerProps> = ({
  section,
  isMerchandiseFilteringEnabled,
  spots,
  selectedStudios,
  match,
}) => {
  const seeMore = useMemo(() => spots.find((spot: Spot) => spot?.label?.toLowerCase() === SECTION_URL), [spots])

  const containerClasses = useMemo(() => computeContainerClasses(section, style, 3), [section])

  const itemClassNames = useMemo(
    () =>
      cx({
        [style.containerItem]: section.sectionType === MegogoSectionTypes._Container,
        [style.collectionItem]: section.sectionType === MegogoSectionTypes._Collection,
        [style.portraitItem]: section.sectionType === MegogoSectionTypes._Portrait,
        [style.largeLandscapeItem]: section.sectionType === MegogoSectionTypes._LargeLandscapeContainer,
        [style.landscapeItem]: section.sectionType === MegogoSectionTypes._LandscapeContainer,
      }),
    [section]
  )

  const renderSpots = renderSpotsWithItem({
    spots: spots.filter((spot: Spot) => spot.label?.toLowerCase() !== SECTION_URL),
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: itemClassNames,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  const spotsPerSlider = {
    [MegogoSectionTypes._Container]: 6,
    [MegogoSectionTypes._Collection]: 5,
    [MegogoSectionTypes._Portrait]: 5,
    [MegogoSectionTypes._LandscapeContainer]: 4,
    [MegogoSectionTypes._LargeLandscapeContainer]: 4,
  }

  const redirectUrl = seeMore ? linkToPage(seeMore, match) : ''

  return (
    <div
      className={cx(containerClasses, {
        [style.collectionStyling]: section.sectionType === MegogoSectionTypes._Collection,
      })}
    >
      <div className={style.heading}>
        <RenderLinkItem isLink={!!redirectUrl} name={section.name} url={redirectUrl} className={style.link} />
      </div>
      <Slider
        {...sliderSettings({
          slidesToScroll: spotsPerSlider[section.sectionType],
          slidesToShow: spotsPerSlider[section.sectionType],
          arrow: true,
          swipe: true,
          swipeToSlide: true,
          nextArrow: <Arrow right section={section.sectionType} />,
          prevArrow: <Arrow left section={section.sectionType} />,
        })}
        className={style.alignSpotsLeft}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
