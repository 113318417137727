import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Item from './Item'
import style from './ToutList.module.scss'

class ToutList extends React.Component {
  render() {
    const { section, isMerchandiseFilteringEnabled } = this.props
    return (
      <div className={style.root}>
        {this.spots().map(spot => (
          <div key={spot.id} className={style.toutItem}>
            <Item
              key={spot.id}
              spot={spot}
              studio={this.isStudioSelected[spot.id]}
              section={section}
              isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
            />
          </div>
        ))}
      </div>
    )
  }
  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot)
  }

  isStudioSelected(id) {
    return this.props.selectedStudios[id]
  }
}

ToutList.propTypes = {
  spots: PropTypes.object,
  selectedStudios: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
  section: PropTypes.object,
}

export default withRouter(ToutList)
