import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import cx from 'classnames'
import pathToRegexp from 'path-to-regexp'
import React from 'react'
import { withRouter } from 'react-router-dom'
import style from './NavBar.module.scss'

class NavBarItem extends React.Component {
  spotLink(spot) {
    const { match } = this.props
    if (!spot.linkedPageId) {
      return spot.url
    }

    const { path } = match

    const toPath = pathToRegexp.compile(path)
    return toPath({ ...match.params, pageId: spot.linkedPageId })
  }

  spot() {
    return this.props.spots.first()
  }

  isDropdownItem() {
    return this.props.spots.count() > 1
  }

  dropdownMenuSpots() {
    return this.props.spots.filter(spot => !!spot.url)
  }

  renderDropdown() {
    const spot = this.spot()
    const classnames = cx(style.listItem, {
      [style.selectedItem]: spot && spot.metadata && spot.metadata.selected === true,
    })

    return (
      <div>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM_LEFT}
          popoverClassName='bp5-popover-display-none'
          content={
            <ul className={style.dropdown}>{this.dropdownMenuSpots().map(spot => spot && this.renderSpot(spot))}</ul>
          }
        >
          <li className={classnames}>
            <a>{this.spot().name}</a>
            <div className={style.dropdownIcon} />
          </li>
        </Popover>
      </div>
    )
  }

  renderSpot(spot) {
    const classnames = cx(style.listItem, {
      [style.selectedItem]: spot && spot.metadata && spot.metadata.selected === true,
    })

    return <li className={classnames}>{spot && <a href={this.spotLink(spot)}>{spot.name}</a>}</li>
  }

  render() {
    const spot = this.spot()
    return this.isDropdownItem() ? this.renderDropdown() : this.renderSpot(spot)
  }
}

const ConnectedNavBarItemWithRouter = withRouter(NavBarItem)
export default ConnectedNavBarItemWithRouter
