import React from 'react'

import WrappedSection from 'Containers/WrappedSection'
import cx from 'classnames'
import FormattingContext from './FormattingContext'
import style from './Page.module.scss'

class Page extends React.Component {
  isHomePage() {
    const { rootSections } = this.props

    return rootSections.size === 1 && rootSections.first().sectionType === 'itunes::254'
  }
  render() {
    const { page, scan, rootSections } = this.props
    const metadata = (page && page.metadata) || ''

    const pageStyle = {
      backgroundColor: metadata.background_color && `#${metadata.background_color}`,
    }

    const masterArt = metadata.master_art
    const masterArtStyle = masterArt && {
      backgroundImage: `radial-gradient(farthest-corner at 50% 20%, transparent 30%, #${metadata.background_color} 70%), url(${masterArt.url})`,
      height: masterArt.height / 2,
    }

    const formatting = {
      titleTextColor: metadata && `#${metadata.title_text_color}`,
      primaryTextColor: metadata && `#${metadata.primary_text_color}`,
    }

    const innerClassnames = cx({
      [style.center]: !this.isHomePage(),
    })

    return (
      <div className={style.page} style={pageStyle}>
        {page && page.displayName && (
          <div>
            <h3 className={style.header}>{page.displayName}</h3>
            <hr />
          </div>
        )}
        {masterArt && <div style={masterArtStyle} className={style.uber} />}

        <div className={innerClassnames}>
          <FormattingContext.Provider value={formatting}>
            {rootSections.valueSeq().map((s, index) => (
              <WrappedSection type={s.sectionType} key={index} scan={scan} page={page} section={s} />
            ))}
          </FormattingContext.Provider>
        </div>
      </div>
    )
  }
}

export default Page
