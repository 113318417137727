import WrappedSection from 'Containers/WrappedSection'
import React from 'react'
import style from './Page.module.scss'

class Page extends React.Component {
  render() {
    const { page, scan, rootSections } = this.props

    return (
      <div className={style.page}>
        {rootSections.map(s => (
          <WrappedSection type={s.sectionType} key={s.id} scan={scan} page={page} section={s} />
        ))}
      </div>
    )
  }
}

export default Page
