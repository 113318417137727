import cx from 'classnames'
import React from 'react'
import styles from './NewCandidateBadge.module.scss'

const NewCandidateBadge = ({ badgeForModalPage }) => {
  const classNames = cx(styles.newCandidateBadge, { [styles.badgeOnModal]: badgeForModalPage })
  return <span className={classNames}>New</span>
}

export default NewCandidateBadge
