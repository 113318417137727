import React from 'react'

import styles from './styles.module.scss'

class HeaderTvod extends React.Component {
  render() {
    const { purchaseOptions } = this.props

    return (
      <React.Fragment>
        {purchaseOptions.length === 1 ? (
          <div className={styles.fullWidth}>{purchaseOptions[0]}</div>
        ) : (
          <div className={styles.tvodStyle}>
            <div className={styles.halfWidth}>Buy</div>
            <div className={styles.withLeftBorder}>Rent</div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default HeaderTvod
