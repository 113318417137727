import cx from 'classnames'
import React, { useCallback } from 'react'
import CheckboxInput from './Checkbox'
import style from './index.module.scss'

const PlatformMenuItemWithCheckbox = ({
  items,
  isEnabled,
  isSelected,
  territories,
  setOpenPlatformDropdown,
  togglePlatforms,
  selectTerritories,
  isOnScansPage,
  isOnMerchPage,
  toggleTerritory,
  selectedPlatform,
  isPlatformListEnabled,
  deselectAll,
}) => {
  const dropdownClassName = cx({
    'bp5-icon-standard bp5-icon-caret-down bp5-align-right': true,
    [style.dropdownIconDisabled]: !isEnabled,
    [style.dropdownIcon]: isEnabled,
  })

  const onPlatformSelect = (items, togglePlatforms, deselectAll) => {
    const selectedTerritoryCount = territories.filter(territory => territory.selected === true).size

    if (selectedTerritoryCount !== 1) {
      deselectAll()
      selectTerritories([selectedPlatform.territoryId], isOnScansPage, isOnMerchPage)
    } else {
      togglePlatforms(items.map(i => i.id).valueSeq(), isOnScansPage, isOnMerchPage)
    }

    if (isPlatformListEnabled) {
      setOpenPlatformDropdown({})
    }
  }

  const onDropdownClick = useCallback(
    async (
      event,
      isEnabled,
      items,
      selectedPlatform,
      isOnScansPage,
      isOnMerchPage,
      deselectAll,
      togglePlatforms,
      selectTerritories,
      setOpenPlatformDropdown,
      isPlatformListEnabled
    ) => {
      event.stopPropagation()
      if (isEnabled && selectedPlatform.selected) {
        if (isPlatformListEnabled) {
          setOpenPlatformDropdown({})
        } else {
          deselectAll()
          togglePlatforms(items.map(i => i.id).valueSeq(), isOnScansPage, isOnMerchPage)
          selectTerritories(items.map(i => i.territoryId).toArray(), isOnScansPage, isOnMerchPage)
          setOpenPlatformDropdown(selectedPlatform.code)
        }
      }
    },
    []
  )

  const onPlatformAndTerritorySelect = useCallback(
    (event, territory, platform, isOnScansPage, isOnMerchPage) => {
      event.stopPropagation()
      toggleTerritory(territory)
      togglePlatforms([platform.id], isOnScansPage, isOnMerchPage)
    },
    [toggleTerritory, togglePlatforms]
  )

  return (
    <li className={style.platformItem}>
      <div className={style.itemWrapper}>
        <CheckboxInput
          checked={isSelected}
          disabled={!isEnabled}
          onChange={() => onPlatformSelect(items, togglePlatforms, deselectAll)}
          label={selectedPlatform.name}
        />
        <span
          className={dropdownClassName}
          onClick={event =>
            onDropdownClick(
              event,
              isEnabled,
              items,
              selectedPlatform,
              isOnScansPage,
              isOnMerchPage,
              deselectAll,
              togglePlatforms,
              selectTerritories,
              setOpenPlatformDropdown,
              isPlatformListEnabled
            )
          }
        ></span>
      </div>
      {isPlatformListEnabled && (
        <ul className={style.platformList}>
          {items.valueSeq().map(platform => {
            const territory = territories.valueSeq().find(t => parseInt(t.id) === platform.territoryId)
            return (
              <li key={platform.id}>
                <CheckboxInput
                  checked={platform.selected}
                  disabled={!isEnabled}
                  label={`${platform.name} ${territory.isoCode}`}
                  onChange={event =>
                    onPlatformAndTerritorySelect(event, territory, platform, isOnScansPage, isOnMerchPage)
                  }
                />
              </li>
            )
          })}
        </ul>
      )}
    </li>
  )
}

export default PlatformMenuItemWithCheckbox
