import WrappedSection from 'Containers/WrappedSection'
import { PageProps, Scan } from 'Interfaces/interfaces'
import { Map } from 'immutable'
import React from 'react'
import { CanalPlusSectionTypes } from '../enums'
import style from './Page.module.scss'
import { DONT_RENDER_NAME } from './constants'

interface Props {
  page: PageProps
  scan: Scan
  rootSections: Map<string, any>
}

const Page: React.FC<Props> = ({ page, scan, rootSections }) => {
  return (
    <div className={style.page}>
      {rootSections
        .valueSeq()
        .toArray()
        .map((s, i) => (
          <React.Fragment key={s.id}>
            <WrappedSection type={s.sectionType} scan={scan} page={page} section={s} index={i} />
            {s.sectionType === CanalPlusSectionTypes._MainMenu &&
              !page.isRoot &&
              page.metadata?.pageComponent !== DONT_RENDER_NAME && <h1 className={style.pageName}>{page?.name}</h1>}
          </React.Fragment>
        ))}
    </div>
  )
}

export default Page
