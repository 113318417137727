import { Intent } from '@blueprintjs/core'
import * as uiActionTypes from 'Reducers/uiReducer'

export const enableTextActionHighlightMode = () => dispatch => {
  dispatch({
    type: uiActionTypes.ENABLE_TEXT_ACTION_HIGHLIGHT_MODE,
  })
  dispatch(
    enqueueToast({
      iconName: 'bp5-icon-font',
      intent: Intent.PRIMARY,
      message: 'Highlight mode set to Text',
    })
  )
}

export const enableTriagleActionHighlightMode = () => dispatch => {
  dispatch({
    type: uiActionTypes.ENABLE_TRIANGLE_ACTION_HIGHLIGHT_MODE,
  })
  dispatch(
    enqueueToast({
      iconName: 'bp5-icon-symbol-triangle-up',
      intent: Intent.PRIMARY,
      message: 'Highlight mode set to Triangle',
    })
  )
}

export const clearToasts = () => ({
  type: uiActionTypes.CLEAR_TOASTS,
})

export const enqueueToast = toast => dispatch => {
  dispatch({
    type: uiActionTypes.ENQUEUE_TOAST,
    toast,
  })
}

export const showScanAsList = () => ({
  type: uiActionTypes.SHOW_SCAN_AS_LIST,
})

export const showScanAsGrid = () => ({
  type: uiActionTypes.SHOW_SCAN_AS_GRID,
})

export const showMerchandizingScanAsList = () => ({
  type: uiActionTypes.SHOW_MERCHANDIZING_SCAN_AS_LIST,
})

export const showMerchandizingScanAsGrid = () => ({
  type: uiActionTypes.SHOW_MERCHANDIZING_SCAN_AS_GRID,
})

export const showScanStats = () => ({
  type: uiActionTypes.SHOW_SCAN_STATS,
})

export const showContainerView = () => ({
  type: uiActionTypes.SHOW_CONTAINER_VIEW,
})

export const setRootPath = path => ({
  type: uiActionTypes.SET_ROOT_PATH,
  path,
})

export const showErrorMessageToast = message => dispatch => {
  dispatch(
    enqueueToast({
      iconName: 'bp5-icon-symbol-triangle-up',
      intent: Intent.WARNING,
      message: message,
    })
  )
}
