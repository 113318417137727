import React from 'react'
import { PerformancePageEnum } from './constants'
import { Button, IconName, Alignment } from '@blueprintjs/core'
import cx from 'classnames'
import styles from './Toolbar.module.scss'

type Props = {
  performancePage: PerformancePageEnum
  handleSetPerformancePage: (page: PerformancePageEnum) => void
}
const PerformanceButtons: React.FC<Props> = ({ performancePage, handleSetPerformancePage }) => {
  const performancePages = [PerformancePageEnum.Competitor, PerformancePageEnum.Platform]
  const btnIcons: IconName[] = ['grid-view', 'video']

  return (
    <>
      {performancePages.map((page, index) => {
        return (
          <Button
            fill={true}
            icon={btnIcons[index]}
            key={page}
            text={page}
            alignText={Alignment.LEFT}
            className={cx(styles.menuBtn, {
              [styles.active]: performancePage === page,
            })}
            minimal
            onClick={() => handleSetPerformancePage(page)}
            intent={performancePage === page ? 'primary' : 'none'}
          />
        )
      })}
    </>
  )
}

export default PerformanceButtons
