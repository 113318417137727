export const AVAILABILITY = 'Availability'
export const PRICING = 'Pricing'
export const ARTWORK = 'Artwork'
export const ACTION_OVERVIEW_DESCRIPTION =
  'Number of Actions that require attention, with the highlighted number representing the increase/decrease in Actions compared to the previous scan'
export const AUDIT_REPORT_DESCRIPTION =
  'This shows the total coverage of the audit in regards to Titles, Platforms and Territories scanned (scroll to view the full list)'
export const OPTIMIZATION_OVERVIEW_DESCRIPTION =
  'This represents how many Actions your titles have. 100% signifies that your titles are fully optimised and require no attention, whereas a lower % indicates that you have more Actions to address'
export const MOST_OPTIMISED_TITLES_DESCRIPTION =
  'This displays your titles with the fewest Actions, with the highlighted number representing the increase/decrease in optimisation score compared to the previous scan'
export const LEAST_OPTIMISED_TITLES_DESCRIPTION =
  'This displays your titles with the most Actions, with the highlighted number representing the increase/decrease in optimisation score compared to the previous scan'
