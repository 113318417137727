import reduceReducers from 'reduce-reducers'
import artworkComparisonReducer from './artworkComparisonReducer'
import associatedMerchandizingScanReducer from './associatedMerchandizingScanReducer'
import auditActionReducer from './auditActionReducer'
import auditArtworkReducer from './auditArtworkReducer'
import auditExceptionReducer from './auditExceptionReducer'
import auditPriceReducer from './auditPriceReducer'
import auditReducer from './auditReducer'
import channelReducer from './channelReducer'
import clientReducer from './clientReducer'
import clientSalesSummaryReducer from './clientSalesSummaryReducer'
import locationCandidateReducer from './locationCandidateReducer'
import marketShareReducer from './marketShareReducer'
import merchandizingMatchReducer from './merchandizing/matchReducer'
import merchandizingPageReducer from './merchandizing/pageReducer'
import merchandizingPlatformTitleReducer from './merchandizing/platformTitleReducer'
import merchandizingScanReducer from './merchandizing/scanReducer'
import merchandizingSectionReducer from './merchandizing/sectionReducer'
import merchandizingSpotReducer from './merchandizing/spotReducer'
import optimizationScoreReducer from './optimizationScoreReducer'
import platformReducer from './platformReducer'
import platformTitleReducer from './platformTitleReducer'
import priceReducer from './priceReducer'
import publisherReducer from './publisherReducer'
import scanReducer from './scanReducer'
import studioReducer from './studioReducer'
import tableauReducer from './tableauReducer'
import tagReducer from './tagReducer'
import territoryReducer from './territoryReducer'
import titleAuditReducer from './titleAuditReducer'
import titleFilterReducer from './titleFilterReducer'
import titleReducer from './titleReducer'
import titlesForCorrectionReducer from './titlesForCorrectionReducer'

import { enableBatching } from 'redux-batched-actions'
import merchandizingScanDownloadReducer from './merchandizing/scanDownloadReducer'
import scanDownloadReducer from './scanDownloadReducer'
import taggingReducer from './taggingReducer'

export default enableBatching(
  reduceReducers(
    clientReducer,
    titleReducer,
    scanReducer,
    auditReducer,
    auditArtworkReducer,
    titleAuditReducer,
    platformReducer,
    territoryReducer,
    priceReducer,
    auditPriceReducer,
    channelReducer,
    auditActionReducer,
    auditExceptionReducer,
    platformTitleReducer,
    optimizationScoreReducer,
    clientSalesSummaryReducer,
    marketShareReducer,
    publisherReducer,
    artworkComparisonReducer,
    titleFilterReducer,
    locationCandidateReducer,
    merchandizingScanReducer,
    merchandizingPageReducer,
    merchandizingSpotReducer,
    merchandizingSectionReducer,
    merchandizingMatchReducer,
    associatedMerchandizingScanReducer,
    titlesForCorrectionReducer,
    tagReducer,
    studioReducer,
    merchandizingPlatformTitleReducer,
    tableauReducer,
    taggingReducer,
    scanDownloadReducer,
    merchandizingScanDownloadReducer
  )
)
