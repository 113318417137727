import { Button, Icon, Popover, PopoverInteractionKind, Position, Spinner } from '@blueprintjs/core'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

class ActionFilter extends React.Component {
  render() {
    const {
      filter,
      toggleAllActionsFilter,
      togglePriceActionsFilter,
      toggleAuditExceptionFilter,
      toggleAvailActionsFilter,
      scan,
      correctedAuditActions,
      toggleSubmittedAuditActionsFilter,
      toggleReadyAuditActionsFilter,
      toggleAllAuditActionsFilter,
      titlesForCorrectionLoading,
    } = this.props
    let correctionsButtonText = ''
    if (filter.auditActionsStatus.includes('submitted') && filter.auditActionsStatus.length === 1) {
      correctionsButtonText = `: Overdue (${
        correctedAuditActions.filter(act => act && act.status === 'submitted').size
      })`
    } else if (filter.auditActionsStatus.includes('ready') && filter.auditActionsStatus.length === 1) {
      correctionsButtonText = `: Due (${correctedAuditActions.filter(act => act && act.status === 'ready').size})`
    } else if (filter.auditActionsStatus.length) {
      correctionsButtonText = `: All (${
        correctedAuditActions.filter(act => act && (act.status === 'ready' || act.status === 'submitted')).size
      })`
    }
    return (
      <div className='bp5-button-group'>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.allActions })}
          onClick={toggleAllActionsFilter}
        >
          All
        </button>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.priceActions })}
          onClick={togglePriceActionsFilter}
        >
          Price
        </button>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.availActions })}
          onClick={toggleAvailActionsFilter}
        >
          Avails
        </button>
        <button
          className={cx('bp5-button', styles.btnDefault, { [styles.active]: filter.auditExceptions })}
          onClick={toggleAuditExceptionFilter}
        >
          Exceptions
        </button>
        {scan && correctedAuditActions && scan.isAutobotEnabled && (
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            popoverClassName={'bp5-popover-content-sizing'}
            position={Position.BOTTOM}
            content={
              <div className={styles.corrections}>
                <h6>Store updates:</h6>
                {titlesForCorrectionLoading ? (
                  <Spinner />
                ) : (
                  <div>
                    <div onClick={() => toggleReadyAuditActionsFilter()}>
                      Due ({correctedAuditActions.filter(act => act && act.status === 'ready').size}){' '}
                      {filter.auditActionsStatus.includes('ready') && filter.auditActionsStatus.length === 1 && (
                        <Icon icon='tick' />
                      )}
                    </div>
                    <div onClick={() => toggleSubmittedAuditActionsFilter()}>
                      <p>
                        Overdue ({correctedAuditActions.filter(act => act && act.status === 'submitted').size}){' '}
                        {filter.auditActionsStatus.includes('submitted') && filter.auditActionsStatus.length === 1 && (
                          <Icon icon='tick' />
                        )}
                      </p>
                    </div>
                    <div onClick={() => toggleAllAuditActionsFilter()}>
                      All {filter.auditActionsStatus.length > 1 && <Icon icon='tick' />}
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <Button active={filter.auditActionsStatus.length}>
              Updates{!titlesForCorrectionLoading && correctionsButtonText}
            </Button>
          </Popover>
        )}
      </div>
    )
  }
}

ActionFilter.propTypes = {
  filter: PropTypes.object,
  correctedAuditActions: PropTypes.object,
  scan: PropTypes.object,
  toggleAllActionsFilter: PropTypes.func,
  togglePriceActionsFilter: PropTypes.func,
  toggleAuditExceptionFilter: PropTypes.func,
  toggleHoldbackActionsFilter: PropTypes.func,
  toggleAvailActionsFilter: PropTypes.func,
  toggleSubmittedAuditActionsFilter: PropTypes.func,
  toggleReadyAuditActionsFilter: PropTypes.func,
  toggleAllAuditActionsFilter: PropTypes.func,
  titlesForCorrectionLoading: PropTypes.bool,
}

export default ActionFilter
