import cx from 'classnames'
import React from 'react'
import GenericSection from './GenericSection'
import styles from './Menu263.module.scss'

class Menu263 extends GenericSection {
  render() {
    return <div className={cx(styles.root)}>{this.renderSubSections()}</div>
  }
}

export default Menu263
