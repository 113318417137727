import { Map } from 'immutable'
import { DATA, LINKS, META, TITLE_AUDITS } from './utils.js'
export const LOADING_TITLE_AUDITS = 'title_audit/LOADING'

export default function titleAuditReducer(state, action) {
  switch (action.type) {
    case 'LOAD_TITLE_AUDITS': {
      return state.withMutations(s => {
        action.reset
          ? s.setIn([TITLE_AUDITS, DATA], action.titleAudits)
          : s.mergeIn([TITLE_AUDITS, DATA], action.titleAudits)

        s.setIn([TITLE_AUDITS, LINKS], action.links)
        s.setIn([TITLE_AUDITS, META], new Map({ loading: false, hasMoreToLoad: action.hasMoreToLoad ?? true }))
      })
    }
    case LOADING_TITLE_AUDITS: {
      return state.withMutations(s => {
        s.mergeIn([TITLE_AUDITS, META], new Map({ loading: true }))
      })
    }
    case 'LOAD_TITLE_AUDITS_PAGE': {
      return state.withMutations(s => {
        s.mergeIn([TITLE_AUDITS, META], new Map({ page: action.page }))
      })
    }
    case 'UPDATE_TITLE_AUDIT': {
      const { titleAudit } = action

      return state.withMutations(s => {
        s.setIn([TITLE_AUDITS, DATA, titleAudit.id], titleAudit)
        s.mergeIn([TITLE_AUDITS, META], new Map({ loading: false }))
      })
    }
    default:
      return state
  }
}
