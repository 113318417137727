import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import style from './Container.module.scss'
import Item from './Item'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return (
    <div
      className={cx({
        [style.arrowWrapperLeft]: left === true,
        [style.arrowWrapperRight]: right === true,
      })}
      onClick={onClick}
    >
      <i className={classnames} />
    </div>
  )
}

function renderSpots(spots, section) {
  const sectionType = section.sectionType
  const classnames = cx({
    [style.item]: sectionType === 'row8::container',
    [style.itemRectangle]: sectionType === 'row8::rectangle_container',
  })
  return spots.map(spot => {
    return (
      spot && (
        <div className={classnames} key={spot.id}>
          <Item spot={spot} section={section} />
        </div>
      )
    )
  })
}

function Container(props) {
  const { spots, section } = props
  const sectionType = section.sectionType
  const sliders = sectionType === 'row8::container' ? 10 : 4
  const responsiveSliders = sectionType === 'row8::container' ? 8 : 4

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: sliders,
    slidesToShow: sliders,
    arrows: true,
    swipe: true,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: responsiveSliders,
          slidesToScroll: responsiveSliders,
        },
      },
    ],
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>{section.name}</h2>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
