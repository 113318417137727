import { PageItems } from 'Interfaces/interfaces'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { renderSpotsWithItem, sliderSettings } from '../helpers'
import style from './HeroCarousel.module.scss'
import Item from './Item'

type HeroCarouselProps = RouteComponentProps & PageItems

const HeroCarousel: React.FC<HeroCarouselProps> = ({
  selectedStudios,
  section,
  isMerchandiseFilteringEnabled,
  spots,
}) => {
  const renderSpots = renderSpotsWithItem({
    spots: spots,
    ItemComponent: Item,
    section,
    isMerchandiseFilteringEnabled,
    styleClassName: style.item,
    additionalProps: {
      isStudioSelected: id => selectedStudios[id],
    },
  })

  return (
    <div className={style.container}>
      <Slider
        {...sliderSettings({
          slidesToScroll: 1,
          slidesToShow: 1,
          arrow: false,
        })}
      >
        {renderSpots}
      </Slider>
    </div>
  )
}

export default withRouter(HeroCarousel)
