import { Spinner } from '@blueprintjs/core'
import Match from 'Containers/Match'
import cx from 'classnames'
import { List } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import style from './MatchPanel.module.scss'

export const MATCHES_PER_PAGE = 100

function MatchPanel({
  matches,
  areMerchandisingMatchesLoading,
  deselectMerchandizingSpot,
  deselectMerchandizingSection,
}) {
  const onClick = () => {
    deselectMerchandizingSpot()
    deselectMerchandizingSection()
  }

  return (
    <div className={cx(style.matchPanel, 'pt-navbar')} onClick={onClick}>
      <div className={style.matchContainer}>
        {areMerchandisingMatchesLoading && (
          <div className={style.matchSpinnerContainer}>
            <Spinner />
          </div>
        )}
        {matches.map(m => (
          <Match key={m.id} match={m} />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { page } = ownProps
  const matches = ((page && page.getMatches(state)) || new List())
    .valueSeq()
    .sort((a, b) => a.title(state) && a.title(state).name.localeCompare(b.title(state)?.name))
    .toList()

  return { matches }
}

export default connect(mapStateToProps)(MatchPanel)
