import { Spinner } from '@blueprintjs/core'
import cx from 'classnames'
import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import Infinite from 'react-infinite'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import TitleAuditRow from 'Containers/TitleAuditRow'
import PlatformHeader from './PlatformHeader/PlatformHeader'

import { mergePlatformAndClientPlatofrmTypes } from 'components/utils.js'
import GhostColumn from './GhostColumn'
import GhostPrice from './GhostPrice'
import GhostTvod from './GhostTvod'
import PriceHeader from './PriceHeader'
import styles from './styles.module.scss'
import cssVariables from './variables.scss'
import useInfiniteScroll from 'hooks/useInfiniteScroll'

const GridContainer = styled.div`
  min-width: calc(${cssVariables.totalRowHeaderWidth} + ${props => props.dimension}px);
`

const GhostRow = ({ ghostCells, children, client, platforms }) => {
  return (
    <div className={styles.row}>
      <div className={styles.card}>{children}</div>
      <PriceHeader fade />
      {platforms.valueSeq().map((platform, index) => {
        const tvodColumnWidth = cx({
          [styles.halfTvodColumn]: platform.purchaseOptions.length < 2,
          [styles.tvodColumn]: platform.purchaseOptions.length >= 2,
        })

        const platformTypes = mergePlatformAndClientPlatofrmTypes(client, platform)
        const platformTypeExists = Object.keys(platformTypes).some(element => platformTypes[element])
        return (
          <div key={index} className={styles.price}>
            {platformTypeExists && (
              <table className={styles.platformItemTable}>
                <tr>
                  {Object.keys(platformTypes).map(element => {
                    if (platformTypes[element]) {
                      if (element === 'tvod')
                        return (
                          <td className={tvodColumnWidth} key={`ghost-tvod-${index}`}>
                            <GhostTvod client={client} />
                          </td>
                        )
                      if (element === 'svod')
                        return (
                          <td className={styles.svodColumn} key={`ghost-svod-${index}`}>
                            <GhostColumn type={element} />
                          </td>
                        )
                      if (element === 'avod')
                        return (
                          <td className={styles.avodColumn} key={`ghost-avod-${index}`}>
                            <GhostColumn type={element} />
                          </td>
                        )
                    }
                  })}
                </tr>
              </table>
            )}
          </div>
        )
      })}
      {new Array(Math.max(0, ghostCells)).fill(0).map((_i, index) => (
        <GhostPrice client={client} key={`ghost${index}`} />
      ))}
    </div>
  )
}

const Grid = ({
  titleAudits,
  scan,
  platforms,
  client,
  platformsWithFlags,
  competitors,
  autobotEnabled,
  scanSummary,
  retrieveMoreTitleAudits,
  hasMoreTitleAuditsToLoad
}) => {
  const customLoadingElement = (ghostCells) => {
    return <GhostRow ghostCells={ghostCells} client={client} platforms={platforms}>
      <Spinner />
    </GhostRow>
  };

  const { isInfiniteLoading, handleInfiniteLoad, elementInfiniteLoad } = useInfiniteScroll(
    retrieveMoreTitleAudits,
    hasMoreTitleAuditsToLoad,
    null,
    customLoadingElement
  );

  const heightsForInfinite = (ghostRows) => {
    const ghostRowHeights = new Array(Math.max(0, ghostRows)).fill(parseInt(cssVariables.gridCellHeight));
    return titleAudits
      .valueSeq()
      .map(() => {
        const titleHeight = parseInt(cssVariables.gridCellHeight);
        return titleHeight || 0;
      })
      .toArray()
      .concat(ghostRowHeights);
  };

  const headerOffset = 100;
  const gridCellWidth = parseInt(cssVariables.gridCellWidth);
  const gridCellHeight = parseInt(cssVariables.gridCellHeight);

  const platformWidth = platformsWithFlags.reduce((width, item) => {
    const platformTypes = mergePlatformAndClientPlatofrmTypes(client, item.platform)
    Object.keys(platformTypes).forEach(element => {
      if (platformTypes[element]) {
        if (element === 'tvod' && item.platform.buyEnabled()) {
          width += parseInt(cssVariables.seasonGridCellWidth)
        }
        if (element === 'tvod' && item.platform.rentEnabled()) {
          width += parseInt(cssVariables.tvodCellWidth)
        }
        if (element === 'svod') width += parseInt(cssVariables.svodCellWidth)
        if (element === 'avod') width += parseInt(cssVariables.avodCellWidth)
      }
    })
    return width
  }, 0)

  return (
    <GridContainer className={styles.gridContainer} dimension={platformWidth}>
      <ContainerDimensions>
        {({ width, height }) => {
          const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
          const ghostCells = Math.trunc((rowBodyWidth - platformWidth) / gridCellWidth) + cssVariables.ghostCell
          const ghostRowCount = Math.max(
            0,
            Math.trunc((height - headerOffset - titleAudits.size * gridCellHeight) / gridCellHeight) +
              cssVariables.ghostCell
          )

          const ghostRows = new Array(ghostRowCount)
            .fill(0)
            .map((_s, index) => (
              <GhostRow client={client} key={`ghost${index}`} ghostCells={ghostCells} platforms={platforms} />
            ))

          return (
            height && (
              <div className={styles.gridContainer}>
                <div className={styles.gridHeader}>
                  {scanSummary && (
                    <div className={styles.titlesCount}>
                      Titles: <span>{scanSummary.titlesCount}</span>
                    </div>
                  )}
                  <PlatformHeader
                    competitors={competitors}
                    paddingWidth={cssVariables.rowHeaderWidth}
                    client={client}
                    platformWidth={platformWidth}
                    platformsWithFlags={platformsWithFlags}
                    contenerDimension={width}
                  />
                </div>
                <Infinite
                  containerHeight={height - headerOffset}
                  elementHeight={heightsForInfinite(ghostRowCount)}
                  onInfiniteLoad={handleInfiniteLoad}
                  infiniteLoadBeginEdgeOffset={400}
                  isInfiniteLoading={isInfiniteLoading}
                  loadingSpinnerDelegate={elementInfiniteLoad(ghostCells)}
                  ghostRows={ghostRows}
                >
                  {titleAudits
                    .valueSeq()
                    .map(titleAudit => (
                      <TitleAuditRow
                        titleAuditId={titleAudit.id}
                        key={titleAudit.id}
                        scanId={scan.id}
                        ghostCells={ghostCells}
                        competitors={competitors}
                        autobotEnabled={autobotEnabled}
                        client={client}
                        platforms={platforms}
                      />
                    ))
                    .concat(ghostRows)}
                </Infinite>
              </div>
            )
          )
        }}
      </ContainerDimensions>
    </GridContainer>
  )
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
