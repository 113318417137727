import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { linkToPage } from '../utils'
import style from './Container.module.scss'
import Item from './Item'
import arrow from './arrow.png'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <img onClick={onClick} className={classnames} alt='sliderArrow' src={arrow} />
}

function seeMore(spots) {
  return spots.find(spot => spot && spot.name === 'See more')
}

function renderSpots(spots, section) {
  return spots
    .filter(spot => spot && spot.name !== 'See more')
    .map(spot => {
      return (
        spot && (
          <div className={style.item} key={spot.id}>
            <Item spot={spot} section={section} />
          </div>
        )
      )
    })
}

function Container(props) {
  const { spots, section, match } = props

  const seeMoreSpot = seeMore(spots)

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToScroll: 7,
    slidesToShow: 7,
    arrows: true,
    centerMode: false,
    initialSlide: 0,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>
          {section.name}{' '}
          {seeMoreSpot && (
            <a href={linkToPage(seeMoreSpot, match)}>
              {seeMoreSpot.name} <i className={style.viewAllArrow} />
            </a>
          )}
        </h2>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(Container)
