import { Map, Record } from 'immutable'

export default class ClientSalesSummaryRecord extends Record({
  id: 0,
  data: [],
  type: '',
  labels: new Map(),
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
}) {}
