import React from 'react'
import style from './index.module.scss'

type BulkSelectrTypes = {
  onSelect: () => void
  onDeselect: () => void
}

const BulkSelector: React.FC<BulkSelectrTypes> = ({ onSelect, onDeselect }) => {
  return (
    <div className={`bp5-menu-header ${style.bulkSelectors}`}>
      <a onClick={onSelect}>Select All</a>
      <span className='separator'> | </span>
      <a onClick={onDeselect}>Clear All</a>
    </div>
  )
}

export default BulkSelector
