export const SECTION_URL = 'section_url'
export const VIEW_ALL = 'view all'
export const CATEGORIES = 'categories'
export const BLUE = 'blue'
export const CHEVRON_UP = `chevron-up`
export const CHEVRON_DOWN = `chevron-down`
export const ADDS_ICON = 'ADS_ICON'
export const OUTSIDE_SECTION = 'outside_section'
export const WITHIN_SECTION = 'within_section'
export const SIDE_MENU_HEIGHT = 270
