import React, { useEffect, useState } from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { withRouter } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import Toolbar from 'Components/ContainerPage/Toolbar'
import PlatformHeader from 'Components/ContainerPage/PlatformHeader/PlatformHeader'
import TitleRow from 'Containers/TitleRow.tsx'
import useInfiniteScroll from 'Hooks/useInfiniteScroll'

import GhostRow from 'Components/ContainerPage/GhostRow'
import styles from 'Components/ContainerPage/Grid.module.scss'
import cssVariables from 'Components/ContainerPage/variables.scss'
import ErrorBoundary from 'Components/ErrorBoundary'

const GridContainer = styled.div`
  min-width: calc(
    ${cssVariables.rowHeaderWidth} + ${cssVariables.gridCellTotalWidth} + ${cssVariables.gridCellTotalWidth} *
      ${props => props.cells}
  );
`

const Grid = ({
  client,
  titles,
  platforms,
  platformsWithFlags,
  retrieveTitles,
  showContainerView,
  retrieveMoreTitles,
  hasMoreTitlesToLoad,
  markAllAsVirtualPlatformTitles,
}) => {
  const { handleInfiniteLoad, elementInfiniteLoad, isInfiniteLoading } = useInfiniteScroll(
    retrieveMoreTitles,
    hasMoreTitlesToLoad,
    styles.spinnerWrapper
  )
  const [pinFilter, setPinFilter] = useState(() => JSON.parse(localStorage.getItem('pinFilter')) || false)

  useEffect(() => {
    retrieveTitles()
    showContainerView()
    window.scrollTo(0, 0)
  }, [retrieveTitles, showContainerView])

  const gridCellWidth = parseInt(cssVariables.gridCellWidth)
  const gridCellHeight = parseInt(cssVariables.gridCellHeight)

  useEffect(() => localStorage.setItem('pinFilter', pinFilter), [pinFilter])

  return (
    <GridContainer className={styles.gridContainer} cells={platforms.size}>
      <ErrorBoundary>
        <ContainerDimensions>
          {({ width, height }) => {
            const rowBodyWidth = width - parseInt(cssVariables.rowHeaderWidth)
            const ghostCells = Math.trunc((rowBodyWidth - platforms.size * gridCellWidth) / gridCellWidth) + 1
            const ghostRowCount = Math.max(
              0,
              Math.trunc((height - titles.size * gridCellHeight) / gridCellHeight) + (isInfiniteLoading ? 2 : 1)
            )

            const ghostRows = new Array(ghostRowCount)
              .fill(0)
              .map((_s, index) => <GhostRow key={`ghost${index}`} ghostCells={ghostCells + platforms.size} />)

            const FULL_HEIGHT = height + 105

            return (
              height && (
                <InfiniteScroll
                  className={styles.infiniteScrollContainer}
                  dataLength={titles.size}
                  next={handleInfiniteLoad}
                  hasMore={hasMoreTitlesToLoad}
                  loader={elementInfiniteLoad()}
                  height={FULL_HEIGHT}
                  scrollThreshold={0.8}
                >
                  <>
                    <Toolbar client={client} pinFilter={pinFilter} setPinFilter={setPinFilter} />
                    <PlatformHeader
                      platforms={platforms}
                      paddingWidth={cssVariables.rowHeaderWidth}
                      platformsWithFlags={platformsWithFlags}
                      markAllAsVirtualPlatformTitles={markAllAsVirtualPlatformTitles}
                      pinFilter={pinFilter}
                    />
                    {titles.valueSeq().map(title => (
                      <TitleRow
                        titleId={title.id}
                        key={title.id}
                        ghostCells={ghostCells}
                        platformsWithFlags={platformsWithFlags}
                      />
                    ))}
                    {ghostRows}
                  </>
                </InfiniteScroll>
              )
            )
          }}
        </ContainerDimensions>
      </ErrorBoundary>
    </GridContainer>
  )
}

const ConnectedGridWithRouter = withRouter(Grid)
export default ConnectedGridWithRouter
