import { readEndpoint } from 'Actions/apiActions'
import { List, OrderedMap } from 'immutable'

import { findSpotOnPage, normalizeAndLoadMerchandizingSpots, setScrollingSpot } from 'Actions/merchandizing/spotActions'
import {
  getGetMerchandizingSpotsById,
  getMerchandizingPageById,
  getMerchandizingSectionsPage,
  getScrollingSpotId,
} from 'Selectors/index'

import { retrieveMerchandizingMatchesForSections } from 'Actions/merchandizing/matchActions'
import { updateMerchandizingPage } from 'Actions/merchandizing/pageActions'
import SectionRecord from 'Records/merchandizing/sectionRecord'
import { LOAD_MERCHANDIZING_PAGE_SECTIONS } from 'Reducers/app/merchandizing/pageReducer'
import {
  DESELECT_MERCHANDIZING_SECTION,
  LOAD_MERCHANDIZING_SECTIONS,
  LOAD_MERCHANDIZING_SECTION_PAGE,
  SELECT_MERCHANDIZING_SECTION,
} from 'Reducers/app/merchandizing/sectionReducer'
import { batch } from 'react-redux'

export const normalizeAndLoadMerchandizingSections = (data, reset) => dispatch => {
  const spots = data
    .map(section => section.spots)
    .flat()
    .filter(s => !!s)

  const merchandizingSections = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new SectionRecord({
        ...item,
        spotsIds: item.spots?.map(spot => spot.id) || [],
        childSectionIds: item.sections?.map(s => s.id) || [],
      })
    )
  }, new OrderedMap())

  batch(() => {
    dispatch(normalizeAndLoadMerchandizingSpots(spots, reset))
    dispatch(loadMerchandizingSections(merchandizingSections, reset))
  })
}

export const retrieveMerchandizingSections =
  (pageId, page = 1, reset = true, retrieveMatches = true) =>
  (dispatch, getState) => {
    return readEndpoint(`merchandizing/sections/?filter[pageId]=${pageId}&page=${page}`).then(response => {
      dispatch(loadMerchandizingSectionsResponse(response.merchandizingSections, pageId, reset))
      dispatch(loadSectionsPage(page))
      const state = getState()
      const selectedSpotId = getScrollingSpotId(state)

      if (selectedSpotId) {
        if (!getGetMerchandizingSpotsById(state, { id: selectedSpotId })) {
          dispatch(retrieveMoreMerchandizingSections(pageId, retrieveMatches))
        } else {
          dispatch(findSpotOnPage(selectedSpotId))
          const emptyList = new List()
          dispatch(setScrollingSpot(emptyList))
        }
      }

      if (retrieveMatches) dispatch(retrieveMerchandizingMatchesForSections(pageId, response.merchandizingSections))
    })
  }

export const retrieveMoreMerchandizingSections =
  (pageId, retrieveMatches = true, defaultStartingPage = 0) =>
  (dispatch, getState) => {
    const state = getState()
    const currentPageOffset = getMerchandizingSectionsPage(state) || defaultStartingPage

    return dispatch(retrieveMerchandizingSections(pageId, currentPageOffset + 1, false, retrieveMatches))
  }

export const loadMerchandizingSectionsResponse =
  (merchandizingSections, pageId, reset = true) =>
  (dispatch, getState) => {
    const state = getState()

    const page = getMerchandizingPageById(state, { id: pageId })
    const updatedPage = page.merge({
      sectionIds: [...page.sectionIds, ...merchandizingSections.map(s => s.id)],
    })

    batch(() => {
      dispatch(normalizeAndLoadMerchandizingSections(merchandizingSections, reset))
      dispatch(updateMerchandizingPage(updatedPage))
    })
  }

export const loadMerchandizingPageSections = (pageId, sections, reset) => ({
  type: LOAD_MERCHANDIZING_PAGE_SECTIONS,
  sections,
  pageId,
  reset,
})

export const loadMerchandizingSections = (sections, reset) => ({
  type: LOAD_MERCHANDIZING_SECTIONS,
  sections,
  reset,
})

export const selectMerchandizingSection = sectionId => ({
  type: SELECT_MERCHANDIZING_SECTION,
  sectionId,
})

export const deselectMerchandizingSection = () => ({
  type: DESELECT_MERCHANDIZING_SECTION,
})

export const loadSectionsPage = (page = 1) => ({
  type: LOAD_MERCHANDIZING_SECTION_PAGE,
  page,
})
