import cx from 'classnames'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Slider from 'react-slick'
import { OUTSIDE_SECTION } from '../constants'
import { MoviesAnywhereTypes } from '../enums'
import { linkToPage } from '../utils'
import Item from './Item'
import style from './SquareCarousel.module.scss'

function Arrow(props) {
  const { onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
  })

  return (
    <div
      className={cx({
        [style.arrowWrapperLeft]: left === true,
        [style.arrowWrapperRight]: right === true,
      })}
      onClick={onClick}
    >
      <i className={classnames} />
    </div>
  )
}

function seeAll(spots) {
  return spots.find(spot => spot?.metadata.expandLinkType === OUTSIDE_SECTION)
}

function renderSpots(spots, section) {
  const sectionType = section.sectionType
  return spots
    .filter(spot => spot?.metadata.expandLinkType !== OUTSIDE_SECTION)
    .map(spot => {
      return (
        spot && (
          <div
            className={cx(style.item, {
              [style.pageContainer]: sectionType === MoviesAnywhereTypes._PageContainer,
            })}
            key={spot.id}
          >
            <Item spot={spot} section={section} />
          </div>
        )
      )
    })
}

function SquareCarousel(props) {
  const { spots, match, section } = props

  const seeAllSpot = seeAll(spots)

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerPadding: 0,
    slidesToScroll: 8,
    slidesToShow: 8,
    variableWidth: false,
    arrows: true,
    swipe: true,
    initialSlide: 0,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }
  return (
    <div className={style.container}>
      <div className={style.heading}>
        <h2>
          {section.name} {seeAllSpot && <a href={linkToPage(seeAllSpot, match)}>{seeAllSpot.name}</a>}
        </h2>
      </div>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots(spots, section)}
      </Slider>
    </div>
  )
}

export default withRouter(SquareCarousel)
