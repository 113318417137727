import React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import type { RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'

import Toolbar from 'Components/MpvAnalytics/Toolbar'
import XYChart from 'Components/Graphs/XYChart'
import MultiDataPieChart from 'Components/Graphs/MultiDataPieChart'
import EmptyState from 'Components/MpvAnalytics/EmptyState'
import PerformanceButtons from 'Components/MpvAnalytics/PerformanceButtons'
import { Card, Spinner } from '@blueprintjs/core'
import {
  TOTAL_MPV,
  TOTAL_SOV,
  PERFORMANCE_PAGE_KEY,
  METRIC_KEY,
  NO_PLATFORM_SELECTED,
  NO_PLATFORM_DESCRIPTION,
  NO_DATA,
  NO_DATA_DESCRIPTION,
  NO_STUDIO_SELECTED,
  NO_STUDIO_DESCRIPTION,
  PLATFORM_PERFORMANCE,
  PerformancePageEnum,
} from 'Components/MpvAnalytics/constants'

import { getSelectedScanFromLocalStorage, retrieveScan } from 'Actions/scanActions'
import { retrieveMerchandizingScan } from 'Actions/merchandizing/scanActions'
import { retrieveMerchandizingScanStatistics } from 'Actions/associatedMerchandizingScanActions'
import { setRootPath } from 'Actions/uiActions'
import { useClientContext } from 'Contexts/ClientContext'
import {
  getSelectedScan,
  getMerchandizingScanById,
  getSelectedAssociatedMerchandizingScans,
  getSelectedClientCompetitors,
  getClientCompetitors,
  getStudioStatistics,
  getPlatformStatistics,
} from 'Selectors/index'
import { List, Map } from 'immutable'
import { formatStudioStatisticsData, formatPlatformStatisticsData } from 'Components/utils'

import styles from 'Components/MpvAnalytics/styles.module.scss'
import cx from 'classnames'

const SpinnerWrapper = () => (
  <div className={styles.spinnerWrapper}>
    <Spinner size={50} />
  </div>
)

interface Props extends RouteComponentProps {
  scanId: string
  clientVersion: boolean
  competitors: boolean
}

const MpvAnalytics = ({ scanId, clientVersion }: Props) => {
  const storedPerformancePage = sessionStorage.getItem(PERFORMANCE_PAGE_KEY) || PerformancePageEnum.Competitor
  const storedMetric = sessionStorage.getItem(METRIC_KEY) || TOTAL_MPV
  const [performancePage, setPerformancePage] = useState<PerformancePageEnum>(
    storedPerformancePage as PerformancePageEnum
  )
  const [metric, setMetric] = useState<string>(storedMetric)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { client } = useClientContext()

  const scan = useSelector(state => getMerchandizingScanById(state, { id: scanId }))
  const scanDate = scan?.get('createdAt')
  const auditScan = useSelector(state => (client.id || clientVersion ? getSelectedScan(state) : null))
  const associatedMerchScans = useSelector(state => auditScan?.associatedMerchandizingScans(state) || List())
  const studioStatistics = useSelector(state => getStudioStatistics(state) || List())
  const platformStatistics = useSelector(state => getPlatformStatistics(state) || List())
  const selectedAmsScans = useSelector(state => getSelectedAssociatedMerchandizingScans(state) || Map())
  const selectedCompetitors = useSelector(state => getSelectedClientCompetitors(state) || [])
  const clientCompetitors = useSelector(state => getClientCompetitors(state) || [])

  const selectedScanIds = useMemo(
    () =>
      selectedAmsScans
        .valueSeq()
        .map(p => p.merchandizingScanId)
        .toArray(),
    [selectedAmsScans]
  )
  const selectedCompetitorIds = useMemo(() => selectedCompetitors.map(p => p.id), [selectedCompetitors])

  const handleSetPerformancePage = useCallback((page: string) => {
    setPerformancePage(page as PerformancePageEnum)
    sessionStorage.setItem(PERFORMANCE_PAGE_KEY, page)
  }, [])

  const handleSetMetric = useCallback((metric: string) => {
    setMetric(metric)
    sessionStorage.setItem(METRIC_KEY, metric)
  }, [])

  const fetchMerchandizingStats = useCallback(async () => {
    if (selectedScanIds.length === 0 || selectedCompetitorIds.length === 0) return
    setLoading(true)
    try {
      await dispatch(retrieveMerchandizingScanStatistics(selectedScanIds, selectedCompetitorIds))
    } finally {
      setLoading(false)
    }
  }, [dispatch, selectedScanIds, selectedCompetitorIds])

  const fetchScanData = useCallback(() => {
    const parsedQuery = queryString.parse(location.search)
    const auditScanId = getSelectedScanFromLocalStorage() || parsedQuery.scan_id

    if (auditScanId) {
      dispatch(retrieveScan(auditScanId))
    }
    dispatch(setRootPath(location.pathname))
  }, [dispatch, location])

  useEffect(() => {
    if (client?.id && scanId) {
      dispatch(retrieveMerchandizingScan(scanId, client.id))
    }
  }, [client?.id, scanId, dispatch])

  useEffect(() => {
    if (selectedScanIds.length > 0 && selectedCompetitorIds.length > 0) {
      fetchMerchandizingStats()
    }
  }, [selectedScanIds, selectedCompetitorIds])

  useEffect(() => {
    fetchScanData()
  }, [fetchScanData])

  const hasSelectedScans = useMemo(() => selectedAmsScans.size > 0, [selectedAmsScans])
  const hasStatistics = useMemo(() => studioStatistics.size > 0, [studioStatistics])
  const hasSelectedStudios = useMemo(() => selectedCompetitors.length > 0, [selectedCompetitors])

  const renderPerformanceComponent = () => {
    const studioData = formatStudioStatisticsData(studioStatistics.valueSeq().toJS())
    const platformData = formatPlatformStatisticsData(platformStatistics.valueSeq().toJS())
    if (performancePage === PerformancePageEnum.Competitor) {
      return (
        <Card>
          <h2 className={styles.cardHeader}>{PerformancePageEnum.Competitor}</h2>
          {metric === TOTAL_MPV && <XYChart data={studioData} metric={metric} scanDate={scanDate} />}
          {metric === TOTAL_SOV && <MultiDataPieChart data={studioData} metric={metric} scanDate={scanDate} />}
        </Card>
      )
    }
    if (performancePage === PerformancePageEnum.Platform) {
      return (
        <Card>
          <h2 className={styles.cardHeader}>{PLATFORM_PERFORMANCE}</h2>
          {metric === TOTAL_MPV && <XYChart data={platformData} metric={metric} scanDate={scanDate} />}
          {metric === TOTAL_SOV && <XYChart data={platformData} metric={metric} scanDate={scanDate} />}
        </Card>
      )
    }
    return null
  }

  const renderEmptyState = () => {
    if (!hasSelectedScans) {
      return <EmptyState header={NO_PLATFORM_SELECTED} description={NO_PLATFORM_DESCRIPTION} />
    }
    if (!hasSelectedStudios) {
      return <EmptyState header={NO_STUDIO_SELECTED} description={NO_STUDIO_DESCRIPTION} />
    }
    if (!hasStatistics) {
      return <EmptyState header={NO_DATA} description={NO_DATA_DESCRIPTION} />
    }
    return null
  }

  const renderContent = () => {
    if (loading) {
      return <SpinnerWrapper />
    }
    const emptyState = renderEmptyState()
    if (emptyState) {
      return emptyState
    }
    return (
      <div className={styles.analyticsWrapper}>
        <Card className={styles.sideMenu}>
          <PerformanceButtons performancePage={performancePage} handleSetPerformancePage={handleSetPerformancePage} />
        </Card>
        <div className={styles.main}>{renderPerformanceComponent()}</div>
      </div>
    )
  }

  return (
    <div className={cx('bp5-focus-disabled', styles.root)}>
      <Toolbar
        client={client}
        setMetric={handleSetMetric}
        metric={metric}
        scan={scan}
        associatedMerchScans={associatedMerchScans.toJS()}
        performancePage={performancePage}
        competitors={clientCompetitors}
      />
      {renderContent()}
    </div>
  )
}

export default MpvAnalytics
