import { Navigation, PageProps, Spot } from 'Interfaces/interfaces'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'
import logo from './logo.png'

type NavBarProps = RouteComponentProps & Navigation

const NavBar: React.FC<NavBarProps> = ({ spots, page, match }) => {
  const renderLink = useCallback(
    (spot: Spot, page: PageProps) => {
      return (
        spot && (
          <a
            key={spot.id}
            href={linkToPage(spot, match)}
            className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
          >
            <span>{spot.name}</span>
          </a>
        )
      )
    },
    [match]
  )

  const renderSpots = useMemo(() => {
    return spots?.map(spot => spot && renderLink(spot, page))
  }, [spots, page, renderLink])

  return (
    <div className={style.root}>
      <div className={style.menuLinks}>
        <a className={style.listItem}>
          <img alt='RakutenTV' data-selenium='site-logo' src={logo} />
        </a>
        {renderSpots}
      </div>
    </div>
  )
}

export default withRouter(NavBar)
