import { Map } from 'immutable'
import { CLIENT, DATA, FILTERS, META, SCANS, SUMMARY, deselectScans } from './utils'
export const TOGGLE_MOVIE_FILTER = 'scan/TOGGLE_MOVIE_FILTER'
export const TOGGLE_SERIES_FILTER = 'scan/TOGGLE_SERIES_FILTER'
export const TOGGLE_ALL_FILTER = 'scan/TOGGLE_ALL_FILTER'

export const TOGGLE_ALL_ACTIONS_FILTER = 'scan/TOGGLE_ALL_ACTIONS_FILTER'
export const TOGGLE_PRICE_ACTIONS_FILTER = 'scan/TOGGLE_PRICE_ACTIONS_FILTER'
export const TOGGLE_HOLDBACK_ACTIONS_FILTER = 'scan/TOGGLE_HOLDBACK_ACTIONS_FILTER'
export const TOGGLE_AVAIL_ACTIONS_FILTER = 'scan/TOGGLE_AVAIL_ACTIONS_FILTER'
export const TOGGLE_AUDIT_EXCEPTION_FILTER = 'scan/TOGGLE_AUDIT_EXCEPTION_FILTER'
export const TOGGLE_SUBMITTED_AUDIT_ACTIONS_FILTER = 'scan/TOGGLE_SUBMITTED_AUDIT_ACTIONS_FILTER'
export const TOGGLE_READY_AUDIT_ACTIONS_FILTER = 'scan/TOGGLE_READY_AUDIT_ACTIONS_FILTER'
export const TOGGLE_ALL_AUDIT_ACTIONS_FILTER = 'scan/TOGGLE_ALL_AUDIT_ACTIONS_FILTER'

export const SET_ACTION_LIST_SORT_PARAMS = 'scan/SET_ACTION_LIST_SORT_PARAMS'
export const SET_TITLE_AUDITS_SORT_PARAMS = 'scan/SET_TITLE_AUDITS_SORT_PARAMS'

export const UPDATE_TITLE_QUERY = 'scan/UPDATE_TITLE_QUERY'
export const CLEAR_TITLE_QUERY = 'scan/CLEAR_TITLE_QUERY'
export const SET_LAST_USED_QUERY = 'scan/SET_LAST_USED_QUERY'

export const TOGGLE_LICENSE_TYPE_ALL = 'scan/TOGGLE_LICENSE_TYPE_ALL'
export const TOGGLE_LICENSE_TYPE_BUY = 'scan/TOGGLE_LICENSE_TYPE_BUY'
export const TOGGLE_LICENSE_TYPE_RENT = 'scan/TOGGLE_LICENSE_TYPE_RENT'

export const TOGGLE_FORMAT_ALL = 'scan/TOGGLE_FORMAT_ALL'
export const TOGGLE_FORMAT_HD = 'scan/TOGGLE_FORMAT_HD'
export const TOGGLE_FORMAT_SD = 'scan/TOGGLE_FORMAT_SD'

export const SET_SCAN_FILTER_FROM_URL = 'scan/SET_SCAN_FILTER_FROM_URL'
export const MOVIE_FILTER = 'MOVIE'
export const SERIES_FILTER = 'SERIES'
export const TITLE_QUERY = 'TITLE_QUERY'

export const TOGGLE_ALL_ARTWORK_FILTER = 'scan/TOGGLE_ALL_ARTWORK_FILTER'
export const TOGGLE_BLANK_ARTWORK_FILTER = 'scan/TOGGLE_BLANK_ARTWORK_FILTER'
export const TOGGLE_CHANGED_ARTWORK_FILTER = 'scan/TOGGLE_CHANGED_ARTWORK_FILTER'
export const ENABLE_PROMOTED_FILTER = 'scan/ENABLE_PROMOTED_FILTER'
export const ENABLE_PRIORITY_FILTER = 'scan/ENABLE_PRIORITY_FILTER'
export const DISABLE_PROMOTED_FILTER = 'scan/DISABLE_PROMOTED_FILTER'
export const TOGGLE_OPTIMIZED_FILTER = 'scan/TOGGLE_OPTIMIZED_FILTER'

export const HAS_MORE_DELIVERED_SCANS_TO_LOAD = 'scan/meta/HAS_MORE_DELIVERED_SCANS_TO_LOAD'

export const LOAD_SCANS = 'LOAD_SCANS'
export const LOAD_SCAN_SUMMARY = 'LOAD_SCAN_SUMMARY'
export const SET_INCLUDES = 'SET_INCLUDES'

export const RESET_FILTER = 'RESET_FILTER'
export const SET_TITLE_IDS = 'SET_TITLE_IDS'

export default function scanReducer(state, action) {
  switch (action.type) {
    case 'LOAD_SCAN': {
      const { scan } = action
      return state.withMutations(s => {
        s.setIn([SCANS, DATA, scan.id], scan)
        deselectScans({ mutable: s })
        s.setIn([SCANS, DATA, scan.id, 'selected'], true)
      })
    }
    case LOAD_SCANS: {
      const { scans } = action
      return state.withMutations(s => {
        const existingScans = s.getIn([SCANS, DATA])
        if (existingScans.isEmpty()) {
          s.setIn([SCANS, DATA], scans)
        } else {
          const mergedScans = scans.mergeWith((prev, next, key) => (key === 'selected' ? prev : next), existingScans)
          s.mergeIn([SCANS, DATA], mergedScans)
        }
      })
    }

    case LOAD_SCAN_SUMMARY: {
      const { scanSummary } = action
      return state.withMutations(s => {
        s.setIn([SCANS, SUMMARY], scanSummary)
      })
    }

    case TOGGLE_MOVIE_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableMovies())
    }

    case TOGGLE_SERIES_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableTv())
    }

    case TOGGLE_ALL_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAll())
    }

    case TOGGLE_ALL_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAllActions())
    }

    case TOGGLE_PRICE_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enablePriceActions())
    }

    case TOGGLE_HOLDBACK_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableHoldbackActions())
    }

    case TOGGLE_AVAIL_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAvailActions())
    }

    case TOGGLE_AUDIT_EXCEPTION_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAuditExceptions())
    }

    case TOGGLE_SUBMITTED_AUDIT_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableSubmittedExportActions())
    }

    case TOGGLE_READY_AUDIT_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableReadyExportActions())
    }

    case TOGGLE_ALL_AUDIT_ACTIONS_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAllExportActions())
    }

    case TOGGLE_CHANGED_ARTWORK_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableChangedArtwork())
    }

    case TOGGLE_BLANK_ARTWORK_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableBlankArtwork())
    }

    case ENABLE_PROMOTED_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enablePromoted())
    }

    case ENABLE_PRIORITY_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enablePriority())
    }

    case DISABLE_PROMOTED_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.disablePromoted())
    }

    case TOGGLE_ALL_ARTWORK_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.enableAllArtwork())
    }

    case UPDATE_TITLE_QUERY: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.updateTitleQuery(action.query))
    }

    case SET_TITLE_IDS: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.setTitleIds(action.titleIds, action.limit))
    }

    case CLEAR_TITLE_QUERY: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.clearTitleQuery())
    }

    case SET_LAST_USED_QUERY: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.setLastUsedQuery(action.query))
    }

    case SET_ACTION_LIST_SORT_PARAMS: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn(
        [SCANS, FILTERS],
        filters.setActionListSortParams({
          column: action.column,
          direction: action.direction,
        })
      )
    }

    case SET_TITLE_AUDITS_SORT_PARAMS: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn(
        [SCANS, FILTERS],
        filters.setTitleAuditsSortParams({
          column: action.column,
          direction: action.direction,
        })
      )
    }

    case TOGGLE_LICENSE_TYPE_ALL: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleLicenseTypeAll())
    }

    case TOGGLE_LICENSE_TYPE_BUY: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleLicenseTypeBuy())
    }

    case TOGGLE_LICENSE_TYPE_RENT: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleLicenseTypeRent())
    }

    case TOGGLE_FORMAT_ALL: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleFormatAll())
    }
    case SET_SCAN_FILTER_FROM_URL: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.setFromUrlParams(action.filters))
    }

    case TOGGLE_FORMAT_HD: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleFormatHD())
    }
    case TOGGLE_FORMAT_SD: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.toggleFormatSD())
    }
    case SET_INCLUDES: {
      const filters = state.getIn([SCANS, FILTERS])
      return state.setIn([SCANS, FILTERS], filters.setIncludes(action.includes))
    }
    case 'UPDATE_SCAN_EXPORT_ACTIONS_COUNT': {
      return state.withMutations(s => {
        s.mergeIn([SCANS, DATA, action.scanId], action.attributes)
      })
    }
    case HAS_MORE_DELIVERED_SCANS_TO_LOAD: {
      return state.withMutations(s => {
        s.setIn([SCANS, META], new Map({ hasMoreDeliveredScansToLoad: action.hasMoreToLoad ?? true }))
      })
    }

    case RESET_FILTER: {
      const filters = state.getIn([SCANS, FILTERS])
      const client = state.getIn([CLIENT])
      return state.setIn([SCANS, FILTERS], filters.reset(client))
    }

    default:
      return state
  }
}
