import AppToaster from 'Components/AppToaster'
import ClientArtworkPage from 'Containers/ArtworkPage.tsx'
import ClientCompetitorsPage from 'Containers/CompetitorsPage.tsx'
import ClientMerchPage from 'Containers/MerchandizingPage.tsx'
import MpvAnalyticsPage from 'Containers/MpvAnalyticsPage.tsx'
import Nav from 'Containers/Nav'
import OverviewPage from 'Containers/OverviewPage.tsx'
import { ScanPage } from 'Containers/ScanPage.tsx'
import { ClientProvider } from 'Contexts/ClientContext.tsx'
import useAnalytics from 'Hooks/useAnalytics'
import { getClient, getClientMeta } from 'Selectors/index'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

const ClientApp = () => {
  const { clientMeta, client } = useSelector(state => ({
    clientMeta: getClientMeta(state),
    client: getClient(state),
  }))

  useAnalytics(client, clientMeta)

  const renderMerchandizingPage = props => {
    const {
      match: {
        params: { scanId, pageId },
      },
    } = props

    return <ClientMerchPage key={pageId} {...props} scanId={scanId} pageId={pageId} clientVersion />
  }

  return (
    <>
      <AppToaster />
      <ClientProvider>
        <Nav />
        <Switch>
          <Route
            path='/scans/:id/dashboard/artwork'
            render={props => <ClientArtworkPage scanId={props.match.params.id} {...props} />}
          />
          <Route
            path='/scans/:id/dashboard'
            render={props => <OverviewPage key={props.match.params.id} scanId={props.match.params.id} {...props} />}
            exact
          />
          <Route
            path='/scans/:id/dashboard/competitors'
            render={props => <ClientCompetitorsPage scanId={props.match.params.id} {...props} competitors />}
          />
          <Route
            path='/scans/:id/dashboard/availability_pricing'
            render={props => <ScanPage scanId={props.match.params.id} {...props} />}
          />
          <Route path='/dashboard/merch/:scanId/pages/:pageId' render={renderMerchandizingPage} />
          <Route
            path='/scans/:scanId/mpv/analytics'
            render={props => <MpvAnalyticsPage key={props.match.params.id} scanId={props.match.params.id} {...props} />}
          />
          <Route
            path={['/', '/clients']}
            render={() => {
              window.location = `${process.env.REACT_APP_CORE_API_URL}`
              return null
            }}
            exact
          />
        </Switch>
      </ClientProvider>
    </>
  )
}

export default ClientApp
