import WrappedSection from 'Containers/WrappedSection'
import { Navigation, Scan, Section, Spot } from 'Interfaces/interfaces'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './TabMenu.module.scss'

type TabMenuProps = RouteComponentProps &
  Navigation & {
    subSections: Section[]
    scan: Scan
  }

const TabMenu: React.FC<TabMenuProps> = ({ spots, page, subSections, scan, match }) => {
  const shouldRenderDropdown = useCallback(
    (spot: Spot) => {
      return subSections.some(subsection => subsection.name === spot.name)
    },
    [subSections]
  )

  const renderDropdown = useCallback(
    (spot: Spot) => {
      return subSections
        .filter(s => s.name === spot.name)
        .map(s => (
          <WrappedSection
            spot={spot}
            type={s.sectionType}
            key={s.id}
            section={s}
            scan={scan}
            page={page}
            match={match}
          />
        ))
    },
    [subSections, scan, page, match]
  )

  const renderLink = useCallback(
    (spot: Spot) => (
      <div className={style.firstItem}>
        <a
          key={spot.id}
          href={linkToPage(spot, match)}
          className={`${style.listItem} ${spot.linkedPageId === page.id ? style.activeNav : ''}`}
        >
          <span>{spot.name}</span>
        </a>
      </div>
    ),
    [match, page]
  )

  const renderSpot = useCallback(
    (spot: Spot) => {
      return shouldRenderDropdown(spot) ? renderDropdown(spot) : renderLink(spot)
    },
    [shouldRenderDropdown, renderDropdown, renderLink]
  )

  const renderSpots = useMemo(() => spots.map(renderSpot), [spots, renderSpot])

  return <div className={style.wrapper}>{renderSpots}</div>
}

export default withRouter(TabMenu)
