import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import WrappedSection from 'Containers/WrappedSection'
import cx from 'classnames'
import pathToRegexp from 'path-to-regexp'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavbarMenu.module.scss'

class TubiNavbarMenu extends React.Component {
  render() {
    const { section } = this.props

    return section.isRoot ? this.renderDropdown() : this.renderDropdownSection()
  }

  renderDropdownSection() {
    const { spots, section } = this.props
    return (
      <ul>
        <li className={style.listItemSectionName}>{section.name}</li>
        {spots.map(spot => this.renderSpot(spot))}
      </ul>
    )
  }

  renderDropdown() {
    const { section, subSections, scan, page } = this.props

    return (
      <ul className={style.navBar}>
        <li className={style.navHeader}>
          <a href={this.rootPageUrl()} className={style.logo}>
            <h3>tubi</h3>
          </a>
        </li>
        <li className={style.listItem}>
          <Popover
            interactionKind={PopoverInteractionKind.HOVER}
            position={Position.BOTTOM_LEFT}
            popoverClassName='bp5-popover-display-none'
            content={
              <div className={style.dropdown}>
                {subSections &&
                  subSections.map(
                    s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />
                  )}
              </div>
            }
          >
            {section.name}
          </Popover>
        </li>
      </ul>
    )
  }

  renderSpot(spot, hasImage = false) {
    const classnames = cx(style.listItem, style.dropdownItem)

    return (
      <li key={spot.id} className={classnames}>
        {hasImage ? (
          <a href={linkToPage(spot, this.props.match)}>
            <img src={spot.artworkUrl}></img>
            <h4>{spot.name}</h4>
          </a>
        ) : (
          <a href={linkToPage(spot, this.props.match)}>{spot.name}</a>
        )}
      </li>
    )
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

TubiNavbarMenu.propTypes = {
  scan: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object,
  subSections: PropTypes.object,
  spots: PropTypes.object,
  associatedMerchandizingScan: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbar = connect(mapStateToProps)(TubiNavbarMenu)
const ConnectedNavbarWithRouter = withRouter(ConnectedNavbar)
export default ConnectedNavbarWithRouter
