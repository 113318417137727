import React from 'react'
import { markAllAsVirtualPlatformTitles } from 'Actions/platformActions'
import { retrieveTitles, retrieveMoreTitles } from 'Actions/titleActions'
import { showContainerView } from 'Actions/uiActions'
import {
  getClient,
  getSelectedPlatforms,
  getSelectedTerritories,
  getTitles,
  getHasMoreTitlesToLoad,
} from 'Selectors/index'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import consumer from '../cable'

import { updatePlatformTitleFromCable } from 'Actions/platformTitleActions'

import { setRootPath } from 'Actions/uiActions'

import { retrievePlatforms } from 'Actions/platformActions'
import { retrieveTerritories } from 'Actions/territoryActions'
import styles from 'Components/ContainerPage/styles.module.scss'
import Grid from 'Containers/ContainerGrid'
import type { Match, Platform, Title } from 'Interfaces/*'
import cx from 'classnames'
import { territorySorter } from '../components/utils'

interface PlatformWithFlag {
  platform: Platform
  showFlag: boolean
}

type MarkAllAsVirtualPlatformTitlesType = (platformId: string | number) => (dispatch: any) => void
type RetrieveTitles = (scanId: string) => (dispatch: any) => void
type ShowContainerView = () => (dispatch: any) => void

interface Props {
  clientId: string
  match: Match
  titles: Title[]
  platforms: Platform[]
  platformsWithFlags: PlatformWithFlag[]
  markAllAsVirtualPlatformTitles: MarkAllAsVirtualPlatformTitlesType
  retrieveTitles: RetrieveTitles
  retrieveMoreTitles: RetrieveTitles
  showContainerView: ShowContainerView
  hasMoreToLoad: boolean
}

const ContainerPage = ({
  match,
  clientId,
  titles,
  platforms,
  platformsWithFlags,
  markAllAsVirtualPlatformTitles,
  retrieveTitles,
  retrieveMoreTitles,
  showContainerView,
  hasMoreToLoad,
}: Props) => {
  const dispatch = useDispatch()
  const client = useSelector(state => getClient(state))

  const configureCableSubscription = useCallback(() => {
    try {
      consumer.subscriptions.create(
        { channel: 'PlatformTitleCableChannel' },
        {
          received(payload) {
            dispatch(updatePlatformTitleFromCable(payload))
          },
        }
      )
    } catch (error) {
      console.error('Error handling WebSocket subscription:', error)
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(setRootPath(match.url))
    dispatch(retrievePlatforms(clientId))
    dispatch(retrieveTerritories(clientId))
    configureCableSubscription()
  }, [clientId, dispatch, match.url, configureCableSubscription])

  return (
    <div className={cx(styles.root, 'bp5-focus-disabled')}>
      {!client.toSeq().isEmpty() && (
        <Grid
          client={client}
          titles={titles}
          platforms={platforms}
          platformsWithFlags={platformsWithFlags}
          retrieveTitles={retrieveTitles}
          retrieveMoreTitles={retrieveMoreTitles}
          showContainerView={showContainerView}
          markAllAsVirtualPlatformTitles={markAllAsVirtualPlatformTitles}
          hasMoreTitlesToLoad={hasMoreToLoad}
        />
      )}
    </div>
  )
}

const getSortedPlatformsWithFlags = (platforms, territoriesSorted) => {
  return (
    territoriesSorted &&
    platforms &&
    platforms.first() &&
    territoriesSorted.flatMap(territory => {
      return [
        ...platforms
          .valueSeq()
          .filter(p => p.territoryId === territory.id)
          .map((p, index) => (index === 0 ? { platform: p, showFlag: true } : { platform: p, showFlag: false })),
      ]
    })
  )
}

const mapStateToProps = state => {
  const client = getClient(state)
  const platforms = !client.toSeq().isEmpty() && getSelectedPlatforms(state)
  const territoriesSorted = getSelectedTerritories(state).valueSeq().sort(territorySorter).toArray()
  const hasMoreToLoad = getHasMoreTitlesToLoad(state)

  const sortedPlatformsWithFlags = getSortedPlatformsWithFlags(platforms, territoriesSorted)
  const titles = getTitles(state)

  return {
    client,
    titles,
    platforms,
    platformsWithFlags: sortedPlatformsWithFlags,
    hasMoreToLoad,
  }
}

const mapDispatchToProps = dispatch => ({
  retrieveMoreTitles: () => dispatch(retrieveMoreTitles()),
  showContainerView: () => dispatch(showContainerView()),
  retrieveTitles: scanId => dispatch(retrieveTitles(scanId)),
  markAllAsVirtualPlatformTitles: platformId => dispatch(markAllAsVirtualPlatformTitles(platformId)),
})

const ConnectedContainerPage = connect(mapStateToProps, mapDispatchToProps)(ContainerPage)
export default ConnectedContainerPage
