import React from 'react'
import { Button, Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import PlatformAndTerritoryListNew from 'Components/PlatformAndTerritoryListNew'
import { PLATFORM } from 'Components/PlatformAndTerritoryListNew/constants'
import { DeselectAllPlatformsFunction, DeselectAllTerritoriesFunction, SelectAllPlatformsFunction } from 'Interfaces/*'
import { useSelector } from 'react-redux'
import { territorySorter } from '../utils'
import styes from './styles.module.scss'

interface Props {
  territories
  platforms
  setSelectedPlatofrmIds: (ids: Array<number>) => void
  setSelectedTerritoriesIds: (ids: Array<number>) => void
  selectedPlatformsIds: Array<number>
}

const MerchandizingFilter = ({
  territories,
  platforms,
  setSelectedPlatofrmIds,
  setSelectedTerritoriesIds,
  selectedPlatformsIds,
}: Props) => {
  const platformGroups = useSelector(state => {
    return platforms
      .valueSeq()
      .sort((a, b) => territorySorter(a.territory(state), b.territory(state)))
      .groupBy(p => p.name)
  })

  const deselectAllTerritories = () => {
    setSelectedPlatofrmIds([])
  }

  const selectAllPlatforms = () => {
    setSelectedPlatofrmIds(platforms.map(p => p.id))
  }

  const deselectAllPlatforms = () => {
    setSelectedPlatofrmIds([])
  }

  const togglePlatforms = (platformIds: number[]) => {
    const platformsToSelect = platformIds.filter(p => !selectedPlatformsIds.includes(p))
    const a = [...selectedPlatformsIds.filter(p => !platformIds.includes(p)), ...platformsToSelect]
    setSelectedPlatofrmIds(a)
  }

  const selectTerritories = territoryIds => {
    setSelectedTerritoriesIds(territoryIds)
  }

  return (
    <Popover
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_RIGHT}
      content={<div>
        <PlatformAndTerritoryListNew
          territories={territories}
          platformGroups={platformGroups}
          withTabs
          availableTabs={[PLATFORM]}
          deselectAllTerritories={deselectAllTerritories as DeselectAllTerritoriesFunction}
          deselectAllPlatforms={deselectAllPlatforms as DeselectAllPlatformsFunction}
          selectAllPlatforms={selectAllPlatforms as any}
          togglePlatforms={togglePlatforms as any}
          selectTerritories={selectTerritories}
          selectPlatformsForSelectedTerritories={() => { }}
          selectTerritoriesForSelectedPlatforms={() => { }}
          toggleTerritory={() => { }}
        />
      </div>
      }
    >
      <Button className={styes.filterButton} rightIcon={'double-caret-vertical'}>
        All Platforms
      </Button>
    </Popover>
  )
}

export default MerchandizingFilter
