import React from 'react'
import RadioInput from './RadioInput'
import style from './index.module.scss'

type SingleSelectType = {
  header: string
  list: any
  onChange: (territory: any) => void
  children?: any
}

const SingleSelect: React.FC<SingleSelectType> = ({ header, list, onChange, children }) => {
  return (
    <div className={style.singleSelectWrapper}>
      {header && <h3 className={style.listHeader}>{header}</h3>}
      {list.map(item => (
        <React.Fragment key={item.get('id')}>
          <RadioInput
            className={style.radioInput}
            checked={item.selected}
            onChange={() => onChange(item)}
            label={item.name}
            value={item.selected}
          />
          {children && item.selected && <div className={style.childWrapper}>{children}</div>}
        </React.Fragment>
      ))}
    </div>
  )
}

export default SingleSelect
