import { SpotItems } from 'Interfaces/interfaces'
import cx from 'classnames'
import React, { useRef, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useScrollIntoView } from '../../../hooks/useScrollIntoView'
import { CanalPlusSectionTypes } from '../enums'
import { generateClientStyle } from '../helpers'
import { linkToPage } from '../utils'
import style from './Item.module.scss'
import SpotDetails from './SpotDetails'
import SpotIcon from './SpotIcon'

type ItemProps = RouteComponentProps & SpotItems

const Item: React.FC<ItemProps> = ({ spot, client, isMerchandiseFilteringEnabled, section, match }) => {
  const nodeRef = useRef<HTMLAnchorElement | null>(null)
  const { sectionType } = section

  useScrollIntoView({ condition: !!spot.selected, ref: nodeRef })

  const inlineStyle = useMemo(() => generateClientStyle(client), [client])

  const classNames = useMemo(
    () =>
      cx(style.item, {
        [style.fade]: section.selected || (isMerchandiseFilteringEnabled && !client),
        [style.highlight]: spot.selected || client,
      }),
    [section.selected, isMerchandiseFilteringEnabled, client, spot.selected]
  )

  const isChartSection = useMemo(() => sectionType === CanalPlusSectionTypes._ChartContainer, [sectionType])
  const isMosaicFirstSpot = useMemo(
    () => sectionType === CanalPlusSectionTypes._MosaicContainer && spot.position === 1,
    [sectionType, spot]
  )

  const renderMosaicFirstSpotDetails = () => (
    <span className={style.mosaicDetails}>
      <span className={style.spotName}>{spot.name}</span>
      <span className={style.subtitle}>{spot?.metadata.subtitle}</span>
      <span className={style.description}>{spot?.description}</span>
      <span className={style.label}>{spot?.label}</span>
    </span>
  )

  const renderSpotBackground = () => (
    <div className={style.spotBackground} id={`spot_${spot.id}`} style={{ backgroundImage: `url(${spot.artworkUrl})` }}>
      {isMosaicFirstSpot ? (
        renderMosaicFirstSpotDetails()
      ) : (
        <SpotIcon icon={spot.metadata?.icon} coverUrl={spot.metadata?.coverUrl} />
      )}
    </div>
  )

  return (
    <>
      {isChartSection ? (
        <div className={style.chartWrapper}>
          <span className={style.position}>{spot.position}</span>
          <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
            {renderSpotBackground()}
          </a>
        </div>
      ) : (
        <a href={linkToPage(spot, match)} className={classNames} style={inlineStyle} ref={nodeRef}>
          {renderSpotBackground()}
          <SpotDetails spot={spot} sectionType={sectionType} />
        </a>
      )}
    </>
  )
}

export default withRouter(Item)
