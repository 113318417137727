import ArtworkComparisonRecord from 'Records/artworkComparisonRecord'
import { LOAD_ARTWORK_COMPARISONS } from 'Reducers/app/artworkComparisonReducer'
import { Map, OrderedMap, fromJS } from 'immutable'

export const loadArtworkComparisons = artworkComparisons => ({
  type: LOAD_ARTWORK_COMPARISONS,
  artworkComparisons,
})

export const artworkComparisonRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new ArtworkComparisonRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadArtworkComparisons = (data, reset) => {
  const artworkComparisons = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new ArtworkComparisonRecord({
        ...item,
      })
    )
  }, new OrderedMap())

  return loadArtworkComparisons(artworkComparisons, reset)
}
