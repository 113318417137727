import { Card } from '@blueprintjs/core'
import TitleScoreCard from 'Containers/TitleScoreCard'
import cx from 'classnames'
import { List } from 'immutable'
import React from 'react'
import SectionInfo from './SectionInfo'
import { LEAST_OPTIMISED_TITLES_DESCRIPTION, MOST_OPTIMISED_TITLES_DESCRIPTION } from './constants'
import { OptimizationScoreRecord } from './interfaces'
import styles from './styles.module.scss'

type TitlesProps = {
  leastOptimizedTitles: List<OptimizationScoreRecord>
  mostOptimizedTitles: List<OptimizationScoreRecord>
}

const Titles: React.FC<TitlesProps> = ({ leastOptimizedTitles, mostOptimizedTitles }) => (
  <>
    <div className={styles.gridItem}>
      <Card className={styles.card}>
        <div>
          <h4 className={styles.pageHeader}>
            Most optimised titles
            <SectionInfo info={MOST_OPTIMISED_TITLES_DESCRIPTION} />
          </h4>
          <div className={cx(styles.section, styles.brandSection)}>
            {mostOptimizedTitles.toArray().map((brandScore, index) => (
              <TitleScoreCard score={brandScore} key={index} />
            ))}
          </div>
        </div>
      </Card>
    </div>
    <div>
      <Card className={styles.card}>
        <div>
          <h4 className={styles.pageHeader}>
            Least optimised titles
            <SectionInfo info={LEAST_OPTIMISED_TITLES_DESCRIPTION} />
          </h4>
          <div className={cx(styles.section, styles.brandSection)}>
            {leastOptimizedTitles.toArray().map((brandScore, index) => (
              <TitleScoreCard score={brandScore} key={index} />
            ))}
          </div>
        </div>
      </Card>
    </div>
  </>
)

export default Titles
