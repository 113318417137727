import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import styles from './AuditArtwork.module.scss'

interface ArtworkPopoverImageProps {
  artwork: any
  style: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  imageUrl: string
  changeIndicator?: string
}

const ArtworkPopoverImage = ({ artwork, style, imageUrl, changeIndicator }: ArtworkPopoverImageProps) => {
  return artwork && artwork.width && artwork.height ? (
    <div className={styles.overlay} style={style}>
      {changeIndicator && <div className={styles.changeIndicator}>{changeIndicator}</div>}
    </div>
  ) : (
    <div className={styles.overlay}>
      <img className={styles.popoverImage} src={imageUrl} alt='Artwork' />
      {changeIndicator && <div className={styles.changeIndicator}>{changeIndicator}</div>}
    </div>
  )
}

export default ArtworkPopoverImage
