import { Map } from 'immutable'
import { DATA, META, TITLES_FOR_CORRECTION, initialState } from './utils.js'
import { setLoadingState } from './loadingUtils.js'


export default function titlesForCorrectionReducer(state, action) {
  switch (action.type) {  
    case 'LOADING_TITLES_FOR_CORRECTION': {
      return setLoadingState(state, [TITLES_FOR_CORRECTION, META], true);
    }
    case 'LOAD_TITLES_FOR_CORRECTION': {
      state.withMutations(s => {
        s.mergeIn([TITLES_FOR_CORRECTION, DATA], action.titlesForCorrection)
      })
      return setLoadingState(state, [TITLES_FOR_CORRECTION, META], false);
    }
    case 'LOAD_TITLE_FOR_CORRECTION': {
      return state.withMutations(s => {
        const titleForCorrection = action.titleForCorrection
        const titles = s.getIn([TITLES_FOR_CORRECTION, DATA])
        const titlesForCorrection = titles.set(titleForCorrection.id, titleForCorrection)
        return s.setIn([TITLES_FOR_CORRECTION, DATA], titlesForCorrection)
      })
    }
    case 'ADD_AUDIT_ACTION_TO_TITLE_FOR_CORRECTION': {
      return state.withMutations(s => {
        const correctionData = s.getIn([
          TITLES_FOR_CORRECTION,
          DATA,
          action.title.id,
          'relationships',
          'auditActions',
          'data',
        ])
        const data = correctionData
          .push(new Map({ id: action.actionId, type: 'auditActions' }))
          .toSet()
          .toList()

        return s.mergeIn([TITLES_FOR_CORRECTION, DATA, action.title.id, 'relationships', 'auditActions', 'data'], data)
      })
    }
    default:
      return state || initialState
  }
}
