import { PageProps, Scan } from 'Interfaces/interfaces'
import { Map } from 'immutable'
import React, { useMemo, useRef, useState, useCallback } from 'react'
import SectionRenderer from '../SectionRenderer'
import style from './Page.module.scss'

interface Props {
  page: PageProps
  scan: Scan
  rootSections: Map<string, any>
}

const Page: React.FC<Props> = ({ page, scan, rootSections }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollDemoRef = useRef(null)
  const scrollHeight = 50

  const handleScroll = useCallback(() => {
    if (scrollDemoRef.current) {
      const { scrollTop } = scrollDemoRef.current
      setScrollPosition(scrollTop)
    }
  }, [scrollDemoRef])

  const showPageName = useMemo(() => scrollPosition < scrollHeight, [scrollPosition])

  return (
    <div className={style.page} ref={scrollDemoRef} onScroll={handleScroll}>
      {showPageName && <h1 className={style.pageName}>{page.name}</h1>}
      <SectionRenderer rootSections={rootSections} scan={scan} page={page} />
    </div>
  )
}

export default Page
