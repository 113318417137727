import styles from 'Components/TitleDetail/styles.module.scss'
import { getPlatformById, getTerritoryById } from 'Selectors/index'
import cx from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { openLinkOnPopup } from '../components/utils'

const sectionTypesMap = {
  'itunes::255': 'Container',
  'itunes::256': 'Container',
  'itunes::258': 'Header',
  'itunes::259': 'Header',
  'itunes::271': 'Section Header',
  'itunes::261': 'Brick Hero',
  'itunes::262': 'Brick Hero',
  'itunes::260': 'Plain Spot',
  'itunes::83': 'Plain Spot',
  'itunes::263': 'Menu',
  'itunes::264': 'Menu',
  'itunes::18': 'Menu',
  'itunes::257': 'Header Image',
  'itunes::267': 'Genres',
  'itunes::265': 'Menu',
  'itunes::266': 'Link',
  'google_play::menu': 'Menu',
  'google_play::container': 'Plain Spot',
  'google_play::action_bar': 'Action Bar',
  'google_play::action_bar_item': 'Action Bar Item',
  'google_play::action_bar_spacer': 'Spacer',
  'google_play::submenu': 'Submenu',
  'google_play::submenu::column': 'Submenu Item',
  'amazon::SuperHero ': 'Header',
  'amazon::NavBar': 'NavBar',
  'amazon::TwinHero': 'Twin Hero',
  'amazon::Carousel': 'Plain Spot',
  'amazon::SquareCarousel': 'Plain Spot',
  'amazon::SeasonCarousel': 'Plain Spot',
  'amazon::SeeMore': 'Plain Spot',
  'chili::showcase': 'Plain Spot',
  'chili::showcase_page': 'Plain Spot',
  'chili::showcase_filter': 'Filter',
  'chili::navigation': 'Navigation',
  'chili::header': 'Header',
}

class MerchandizingMatchDetail extends React.Component {
  render() {
    const { match, platform, territory, section, scan } = this.props

    const classnames = cx(styles.platformItem)

    return (
      <tr className={classnames}>
        <td>{platform && platform.name}</td>
        <td>{territory && territory.isoCode}</td>
        <td>
          <a onClick={() => openLinkOnPopup(match.linkToMerchPage)}>{match.breadcrumbs}</a>
        </td>
        <td>{match.pageDepth}</td>
        <td>{match.name}</td>
        <td>{section.position}</td>
        {scan.isMpvCalculated && <td>{!match.mpv ? 0 : match.mpv.toFixed(2)}</td>}
        <td>{sectionTypesMap[section.sectionType]}</td>
        <td>{match.position}</td>
        <td>{match.totalSpots}</td>
      </tr>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps
  const platform = getPlatformById(state, { id: match.platformId })
  const section = match.section(state)
  const territory = platform && getTerritoryById(state, { id: platform.territoryId })

  return {
    platform,
    territory,
    section,
  }
}

export default connect(mapStateToProps)(MerchandizingMatchDetail)
