import PriceRecord from 'Records/priceRecord'
import { Map, OrderedMap, fromJS } from 'immutable'

export const loadAuditPrices = (auditPrices, reset) => ({
  type: 'LOAD_AUDIT_PRICES',
  auditPrices,
  reset,
})

export const auditPriceRecordsMapper = (memo, item) => {
  return memo.set(
    item.id,
    new PriceRecord({
      id: item.id,
      ...item.attributes,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const normalizeAndLoadAuditPrices = (data, reset) => {
  const auditPrices = data.reduce((memo, item) => {
    return memo.set(
      item.id,
      new PriceRecord({
        ...item,
      })
    )
  }, new OrderedMap())

  return loadAuditPrices(auditPrices, reset)
}
