import WrappedSection from 'Containers/WrappedSection'
import React from 'react'
import Card from './Card'

import style from './ActionBar.module.scss'

class ActionBar extends React.Component {
  render() {
    return <div className={style.menu}>{this.renderSubSections()}</div>
  }

  renderSpots() {
    return this.spots().map(spot => spot && <Card key={spot.id} spot={spot} />)
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props

    return (
      subSections &&
      subSections.map(s => <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }

  spots() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'content')
  }

  links() {
    const { spots } = this.props

    return spots.filter(spot => spot && spot.spotType === 'link')
  }

  seeMore() {
    const links = this.links()

    return links.find(link => link.name === 'See more')
  }
}

export default ActionBar
