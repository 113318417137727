import React, { useState } from 'react'
import { Button, Dialog, Intent } from '@blueprintjs/core'
import Checkbox from 'Components/PlatformAndTerritoryListNew/Checkbox'
import BulkSelector from 'Components/PlatformAndTerritoryListNew/BulkSelector'
import { REGIONS } from 'Components/PlatformAndTerritoryListNew/constants'
import TerritoryRecord, { TerritoryRecordType } from 'Records/territoryRecord'
import styles from './Tag.module.scss'

interface Props {
  onApply: (regionIds: number[]) => void
  onClose: () => void
  isOpen: boolean
  territories: TerritoryRecord[]
}

const ChooseRegionModal = ({ isOpen, onApply, onClose, territories }: Props) => {
  const [selectedTerritoriesIds, setSelectedTerritoriesIds] = useState<number[]>(
    territories.find(t => t.selected) ? [territories.find(t => t.selected)!.id] : []
  )

  const selectAllTerritories = () => {
    setSelectedTerritoriesIds([...territories.map(t => t.id)])
  }

  const deselectAllTerritories = () => {
    setSelectedTerritoriesIds([])
  }

  const toggleTerritory = (territory: TerritoryRecordType) => {
    if (selectedTerritoriesIds.find(id => territory.id === id)) {
      setSelectedTerritoriesIds(selectedTerritoriesIds.filter(id => id !== territory.id))
      return
    }

    setSelectedTerritoriesIds([...selectedTerritoriesIds, territory.id])
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} className={styles.regionModal} title={'Choose Regions to Apply the Tag'}>
      <div className={styles.platformsWrapper}>
        <h3>{REGIONS}</h3>
        <div className={styles.selectorsWrapper}>
          <BulkSelector onSelect={selectAllTerritories} onDeselect={deselectAllTerritories}/>
          <div className={styles.inputWrapper}>
            {territories.map(territory => (
              <Checkbox
                key={territory.id}
                label={territory.name}
                checked={selectedTerritoriesIds.includes(territory.id)}
                onChange={() => toggleTerritory(territory)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='bp5-dialog-footer-actions'>
        <div className={styles.footerWrapper}>
          <Button intent={Intent.NONE} onClick={onClose} text={'Cancel'} />
          <Button
            disabled={!selectedTerritoriesIds.length}
            intent={Intent.PRIMARY}
            onClick={() => {
              onApply(selectedTerritoriesIds)
              onClose()
            }}
            text={'Apply'}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default ChooseRegionModal
