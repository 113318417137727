import { Colors, Icon, IconSize, Tooltip } from '@blueprintjs/core'
import React from 'react'
import styles from './styles.module.scss'

type SectioInfoType = {
  info: string
}

const SectionInfo: React.FC<SectioInfoType> = ({ info }) => (
  <Tooltip
    content={<div className={styles.tooltipPopover}>{info}</div>}
    className={styles.tooltip}
    position='top-right'
  >
    <Icon icon='info-sign' size={IconSize.STANDARD} intent='none' color={Colors.GRAY5} />
  </Tooltip>
)

export default SectionInfo
