import WrappedSection from 'Containers/WrappedSection'
import pathToRegexp from 'path-to-regexp'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import style from './NavbarMenu.module.scss'

class NavbarMenu extends React.Component {
  render() {
    return (
      <div className={style.root}>
        <a href={this.rootPageUrl()} className={style.logo}>
          <img
            alt='Fandango Now'
            data-selenium='site-logo'
            src='https://img04.mgo-images.com/image/static/content/web/logos/fandango-now.png'
          />
        </a>
        <span className={style.divider}></span>
        <ul className={style.mediaNav}>{this.renderSubSections()}</ul>
      </div>
    )
  }

  rootPageUrl() {
    const { match } = this.props
    const { path } = match
    const rootPageId = this.rootPageId()

    const toPath = pathToRegexp.compile(path)
    return rootPageId && toPath({ ...match.params, pageId: rootPageId })
  }

  rootPageId() {
    const { associatedMerchandizingScan } = this.props

    return associatedMerchandizingScan && associatedMerchandizingScan.rootPageId
  }

  renderSubSections() {
    const { subSections, scan, page } = this.props
    return (
      subSections &&
      subSections.map(s => s && <WrappedSection type={s.sectionType} key={s.id} section={s} scan={scan} page={page} />)
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const associatedMerchandizingScan = ownProps.scan.associatedMerchandizingScan(state)

  return {
    associatedMerchandizingScan,
  }
}

NavbarMenu.propTypes = {
  scan: PropTypes.object,
  page: PropTypes.object,
  section: PropTypes.object,
  subSections: PropTypes.object,
  spots: PropTypes.object,
  associatedMerchandizingScan: PropTypes.object,
  match: PropTypes.object,
}

const ConnectedNavbarMenu = connect(mapStateToProps)(NavbarMenu)
const ConnectedNavbarMenuWithRouter = withRouter(ConnectedNavbarMenu)
export default ConnectedNavbarMenuWithRouter
