import React from 'react'
import MenuItem from './GenreMenuItem'

class SubmenuColumn extends React.Component {
  render() {
    const { spots } = this.props

    return <div>{spots.map(spot => spot && <MenuItem key={spot} spot={spot} />)}</div>
  }
}

export default SubmenuColumn
