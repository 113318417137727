import { readEndpoint } from 'Actions/apiActions'

import TitlesForCorrectionRecord from 'Records/titlesForCorrectionRecord'
import { getClient, getSelectedScan } from 'Selectors/index'
import { Map, OrderedMap, fromJS } from 'immutable'

import { normalizeAndLoadAuditActions } from 'Actions/auditActions'
import queryString from 'query-string'

export const titlesForCorrectionRecordsMapper = (memo, item, response) => {
  // Create direct relation between title and audit action to simplify things
  item.relationships.auditActions = { data: [] }

  const titleAudits = response.included.filter(t => t.type === 'titleAudits' && t.attributes.titleId === item.id)

  const auditActions = response.included
    .filter(
      a => a.type === 'auditActions' && titleAudits.find(titleAudit => titleAudit.id === a.attributes.titleAuditId)
    )
    .map(a => ({ id: a.id, type: a.type }))

  item.relationships.auditActions.data.push(...auditActions)

  return memo.set(
    item.id,
    new TitlesForCorrectionRecord({
      id: item.id,
      name: item.attributes.name,
      links: new Map(item.links),
      meta: new Map(item.meta),
      relationships: fromJS(item.relationships),
    })
  )
}

export const loadTitlesForCorrection = titlesForCorrection => ({
  type: 'LOAD_TITLES_FOR_CORRECTION',
  titlesForCorrection,
})

export const loadTitleForCorrection = (title, actionId) => {
  const relationships = new Map({
    auditActions: new Map({
      data: fromJS([
        {
          type: 'auditActions',
          id: actionId,
        },
      ]),
    }),
  })

  const titleForCorrection = new TitlesForCorrectionRecord({
    id: title.id,
    name: title.name,
    links: new Map(title.links),
    meta: new Map(title.meta),
    relationships,
  })

  return {
    type: 'LOAD_TITLE_FOR_CORRECTION',
    titleForCorrection,
  }
}

export const addAuditActionToTitleForCorrection = (title, actionId) => {
  return {
    type: 'ADD_AUDIT_ACTION_TO_TITLE_FOR_CORRECTION',
    title,
    actionId,
  }
}

export const retrieveTitlesForCorrection = () => (dispatch, getState) => {
  const state = getState()
  const client = getClient(state)
  const scan = getSelectedScan(state)
  dispatch({ type: 'LOADING_TITLES_FOR_CORRECTION' })
  const url = `clients/${client.id}/titles?`

  const options = {
    'filter[correction]': true,
    'filter[scan_id]': scan.id,
  }

  const queryParams = queryString.stringify(options)

  return readEndpoint(url + queryParams).then(response => {
    const titlesResponse = response.titles
    const auditActions = titlesResponse
      .map(t => t.titleAudits.map(ta => ta.auditActions.map(action => action.id)))
      .flat()
    dispatch(normalizeAndLoadAuditActions(auditActions))

    const titles = titlesResponse.reduce((memo, item) => {
      return memo.set(
        item.id,
        new TitlesForCorrectionRecord({
          ...item,
          auditActionsIds: item.titleAudits.map(ta => ta.auditActions.map(action => action.id)).flat(),
        })
      )
    }, new OrderedMap())

    return loadTitlesForCorrection(titles)
  })
}
