import { Record } from 'immutable'

export default class ScanFilterRecord extends Record({
  groupByStudio: true,
  studios: [],
  platforms: [],
  territories: [],
  dateRange: [],
  movie: true,
  series: false,
}) {
  toParams() {
    const filter = {}

    filter['filter[analysedStudios]'] = this.studios.map(s => s.id).join(',')
    filter['filter[platforms]'] = this.platforms.map(s => s.id).join(',')
    filter['filter[territories]'] = this.territories.map(s => s.id).join(',')
    filter['filter[dateRange]'] = this.dateRange.join(',')

    if (this.movie) filter['filter[movie]'] = true
    if (this.series) filter['filter[series]'] = true

    return filter
  }

  loadFilter(action) {
    return this.withMutations(s => {
      s.studios = action.studios.toArray()
      s.platforms = action.platforms.toArray()
      s.territories = action.territories.toArray()
    })
  }

  toggleStudio(studio) {
    return this.withMutations(s => {
      if (s.studios.find(s => s.id === studio.id)) {
        s.studios = s.studios.filter(s => s.id !== studio.id)
      } else {
        s.studios.push(studio)
      }
    })
  }

  toggleTerritory(territory) {
    return this.withMutations(s => {
      if (s.territories.find(s => s.id === territory.id)) {
        s.territories = s.territories.filter(s => s.id !== territory.id)
      } else {
        s.territories.push(territory)
      }
    })
  }

  togglePlatforms(platforms) {
    return this.withMutations(s => {
      platforms.forEach(platform => {
        if (s.platforms.find(s => s.id === platform.id)) {
          s.platforms = s.platforms.filter(s => s.id !== platform.id)
        } else {
          s.platforms.push(platform)
        }
      })
    })
  }

  toggleGroupByStudio() {
    return this.withMutations(s => {
      s.groupByStudio = !s.groupByStudio
    })
  }

  toggleMovies() {
    return this.withMutations(s => {
      s.movie = true
      s.series = false
    })
  }

  toggleSeries() {
    return this.withMutations(s => {
      s.series = true
      s.movie = false
    })
  }

  toggleDateRange(dateRange) {
    return this.withMutations(s => {
      s.dateRange = dateRange
    })
  }
}
