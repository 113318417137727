import { Tag } from '@blueprintjs/core'
import style from 'Components/Search/style.module.scss'
import cx from 'classnames'
import React from 'react'

interface InputProps {
  selectedTitles: { id: string; name: string }[]
  onRemove: (_id: string) => void
  inputProps: any
}

const Input: React.FC<InputProps> = ({ selectedTitles, onRemove, inputProps }) => {
  const { key, ...restInputProps } = inputProps
  return (
    <div className={cx('bp5-navbar-group', style.navBar)}>
      <div className={cx('bp5-input-group', style.searchBar)}>
        <div className={cx(style.search)}>
          <span className={cx('bp5-icon', 'bp5-icon-search')} />
        </div>
        <div className={cx(style.tag)}>
          {selectedTitles.map(selectedTitle => (
            <Tag
              key={selectedTitle.id}
              className={cx('.modifier', style.span)}
              onRemove={() => onRemove(selectedTitle.id)}
            >
              {selectedTitle.name}
            </Tag>
          ))}
        </div>

        <input
          key={key}
          {...restInputProps}
          className={cx('bp5-round', 'bp5-input', style.input)}
          placeholder='Search'
          dir='auto'
        />
      </div>
    </div>
  )
}

export default Input
