import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import Item from './Item'
import style from './SpotLight.module.scss'

function Arrow(props) {
  const { currentSlide, onClick, left, right } = props
  const classnames = cx({
    [style.rightArrow]: right === true,
    [style.leftArrow]: left === true,
    [style.disabledArrow]: left === true && currentSlide === 0,
  })

  return <i className={classnames} onClick={onClick} />
}

function SpotLight(props) {
  const { spots, section, selectedStudios, isMerchandiseFilteringEnabled } = props

  const sliderSettings = {
    infinite: false,
    centerMode: true,
    centerPadding: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: true,
    swipe: false,
    nextArrow: <Arrow right />,
    prevArrow: <Arrow left />,
  }

  const renderSpots = () => {
    return spots
      .filter(spot => spot && spot.label !== 'View all')
      .map(spot => (
        <div key={spot.id} className={style.spotLightItem}>
          <Item
            section={section}
            spot={spot}
            isMerchandiseFilteringEnabled={isMerchandiseFilteringEnabled}
            studio={isStudioSelected(spot.id)}
          />
        </div>
      ))
  }

  const isStudioSelected = id => {
    return selectedStudios[id]
  }

  return (
    <div className={style.container}>
      <Slider {...sliderSettings} className={style.alignSpotsLeft}>
        {renderSpots()}
      </Slider>
    </div>
  )
}

SpotLight.propTypes = {
  section: PropTypes.object,
  spots: PropTypes.object,
  studio: PropTypes.object,
  selectedStudios: PropTypes.object,
  isMerchandiseFilteringEnabled: PropTypes.bool,
}

export default SpotLight
