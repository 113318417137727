import { getAuditActions } from 'Selectors/index'
import { List, Map, Record } from 'immutable'
import { createSelector } from 'reselect'

export default class TitlesForCorrectionRecord extends Record({
  id: 0,
  name: '',
  links: new Map(),
  meta: new Map(),
  relationships: new Map(),
  auditActionsIds: [],
}) {
  auditActions(state) {
    this.getOurAuditActions =
      this.getOurAuditActions ||
      createSelector(
        getAuditActions,
        (_state, props) => props.relationships.getIn(['auditActions', 'data']) || new List(),
        (auditActions, auditActionRelations) => {
          return auditActionRelations.map(item => auditActions.get(item.get('id')))
        }
      )

    return this.getOurAuditActions(state, { relationships: this.relationships })
  }
}
