import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core'
import { Match, PageProps, Spot } from 'Interfaces/interfaces'
import cx from 'classnames'
import React from 'react'
import { linkToPage } from '../utils'
import style from './SubMenu.module.scss'

interface SubMenuProps {
  spot: Spot
  spots: Spot[]
  page: PageProps
  match: Match
}

const SubMenu: React.FC<SubMenuProps> = ({ spot, spots, page, match }) => {
  const listItemClassNames = (name: string) => {
    name = name.toLowerCase()
    const viewAll = 'view all'
    return cx({
      [style.listItem]: name !== viewAll,
      [style.listItemViewAll]: name === viewAll,
    })
  }

  const renderDropdown = (spot, spots) => {
    return (
      <div className={style.dropdownWrapper}>
        <Popover
          interactionKind={PopoverInteractionKind.HOVER}
          position={Position.BOTTOM}
          popoverClassName='bp5-popover-display-none'
          content={
            <div className={style.dropdown}>
              <ul className={style.dropdownList}>
                {spots
                  .filter(s => !!s)
                  .map(s => {
                    return (
                      <li className={listItemClassNames(s.name)} key={s.id}>
                        <a href={linkToPage(s, match)} className={cx('activeLink', s.linkedPageId === page.id)}>
                          <span>{s.name}</span>
                        </a>
                      </li>
                    )
                  })}
              </ul>
            </div>
          }
        >
          <a className={style.dropdownItem} id={`spot_${spot.id}`}>
            {spot.name}
          </a>
        </Popover>
      </div>
    )
  }

  return renderDropdown(spot, spots)
}

export default SubMenu
