import React, { useLayoutEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { Button, Card } from '@blueprintjs/core'
import styles from './SalesGraph.module.scss'
import shared from './styles.module.scss'

const SalesGraph = ({ salesSummary }) => {
  const [isUnitsData, setIsUnitsData] = useState(salesSummary.type !== 'value')

  useLayoutEffect(() => {
    const root = am5.Root.new('chartdiv')
    root._logo.dispose()
    root.setThemes([am5themes_Animated.new(root)])

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      })
    )

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: true,
        baseInterval: { timeUnit: 'month', count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    )

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    )

    yAxis.get('renderer').labels.template.setAll({
      forceHidden: false,
      text: '{value}',
    })

    const yAxisRight = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: true,
        }),
      })
    )

    yAxisRight.get('renderer').labels.template.setAll({
      forceHidden: false,
      text: '{value}',
    })

    const scrollbar = am5xy.XYChartScrollbar.new(root, {
      orientation: 'horizontal',
    })
    chart.set('scrollbarX', scrollbar)
    chart.bottomAxesContainer.children.push(scrollbar)

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: 15,
      })
    )

    salesSummary.labels.forEach(label => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: label.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: isUnitsData ? label.uId : label.vId,
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            labelText: `${label.name}: {valueY}`,
          }),
        })
      )

      series.strokes.template.setAll({
        strokeWidth: 2,
      })

      series.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get('fill'),
          }),
        })
      })

      const formattedData = salesSummary.data.map(d => ({ ...d, date: new Date(d.date).getTime() }))
      series.data.setAll(formattedData)

      legend.data.push(series)
    })

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'zoomX',
      })
    )
    cursor.lineX.setAll({ visible: true, strokeOpacity: 1 })
    cursor.lineY.setAll({ visible: false })
    cursor.snapToSeries = chart.series.values

    return () => {
      root.dispose()
    }
  }, [salesSummary, isUnitsData])

  return (
    <div>
      <div className={shared.pageHeaderContainer}>
        <h4 className={shared.pageHeader}>Sales Units Performance</h4>
        {salesSummary.type === 'both' && (
          <>
            <Button active={isUnitsData} onClick={() => setIsUnitsData(true)}>
              Units
            </Button>
            <Button active={!isUnitsData} onClick={() => setIsUnitsData(false)}>
              Values
            </Button>
          </>
        )}
      </div>
      <Card className={styles.root}>
        <div id='chartdiv' style={{ width: '100%', height: '500px' }}></div>
      </Card>
    </div>
  )
}

export default SalesGraph
