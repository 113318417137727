import { Tag } from 'Interfaces/*'
import { getTags } from 'Selectors/index'
import cx from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './TagSuggestions.module.scss'

interface TagSuggestions {
  alreadySelectedTagIds: string[]
  searchValue: string
  onSuggestionClick: (suggestionId: string) => void
}

const TagSuggestions = ({ alreadySelectedTagIds, searchValue, onSuggestionClick }: TagSuggestions) => {
  const [suggestions, setSuggestions] = useState<Tag[]>([])
  const allTags = useSelector(state => getTags(state))

  const searchTags = useCallback(
    (value: string) => {
      const searchedTags = allTags && allTags.filter(t => t.name && t.name.toLowerCase().includes(value.toLowerCase()))

      const tagsNotPresent = searchedTags.filter(t => !alreadySelectedTagIds.includes(t.id))
      const notPriorityPromoted = tagsNotPresent.filter(t => t.name !== 'Priority' && t.name !== 'Promoted')

      return notPriorityPromoted.toArray()
    },
    [allTags, alreadySelectedTagIds]
  )

  useEffect(() => {
    if (!searchValue.length) return setSuggestions([])

    setSuggestions(searchTags(searchValue))
  }, [searchTags, searchValue])

  const handleClickOnSuggestion = suggestion => {
    const findTag = allTags.find(t => t.name === suggestion.name)
    onSuggestionClick(findTag.name)
  }

  return (
    <div className={styles.suggestionsContainer}>
      {suggestions.map(suggestion => (
        <div
          key={suggestion.id}
          className={cx(styles.suggestion, { [styles.highlighted]: suggestion.name === searchValue })}
          onClick={() => handleClickOnSuggestion(suggestion)}
        >
          <div className={styles.info}>
            <h6>{suggestion.name}</h6>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TagSuggestions
