import cx from 'classnames'
import React from 'react'
import { PLATFORM, REGION, SELECT_BY_PLATFORM, SELECT_BY_REGION } from './constants'
import style from './index.module.scss'

type TabsProps = {
  activeTab: string
  children: any
  onClick: (tab: typeof REGION | typeof PLATFORM) => void
}

const Tabs: React.FC<TabsProps> = ({ activeTab, onClick, children }) => (
  <>
    <li className={cx('bp5-menu-header', style.selectByEntity)}>
      <div className='bp5-tabs'>
        <ul className={cx(style.tabsList, 'bp5-tab-list')} role='tablist'>
          <li
            className={cx(
              'bp5-tab',
              { 'bp5-active': activeTab === REGION, [style.active]: activeTab === REGION },
              style.tabStyle
            )}
            role='tab'
            onClick={() => onClick(REGION)}
            aria-selected={activeTab === REGION}
          >
            {SELECT_BY_REGION}
          </li>
          <li
            className={cx(
              'bp5-tab',
              { 'bp5-active': activeTab === PLATFORM, [style.active]: activeTab === PLATFORM },
              style.tabStyle
            )}
            role='tab'
            onClick={() => onClick(PLATFORM)}
            aria-selected={activeTab === PLATFORM}
          >
            {SELECT_BY_PLATFORM}
          </li>
        </ul>
      </div>
    </li>
    {children && <>{children}</>}
  </>
)

export default Tabs
