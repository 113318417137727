import { Navigation, PageProps, Spot } from 'Interfaces/interfaces'
import React, { useCallback, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { linkToPage } from '../utils'
import style from './NavBar.module.scss'
const logo = require('./logo.png')

type NavBarProps = RouteComponentProps & Navigation

const NavBar: React.FC<NavBarProps> = ({ spots, page, match }) => {
  const renderLink = useCallback(
    (spot: Spot, page: PageProps) => {
      const pageId = page.id

      return (
        spot && (
          <a
            key={spot.id}
            href={linkToPage(spot, match)}
            className={`${style.listItem} ${spot.linkedPageId === pageId ? style.active : ''}`}
          >
            {spot.name}
          </a>
        )
      )
    },
    [match]
  )

  const renderSpots = useMemo(() => {
    return spots && spots.map(spot => spot && renderLink(spot, page))
  }, [spots, renderLink, page])

  return (
    <div className={style.root}>
      <div className={style.menuHeader}>
        <a className={style.logo}>
          <img alt='Youtube' data-selenium='site-logo' src={logo} />
        </a>
        <h2>Movies & TV</h2>
      </div>
      <div className={style.menuLinks}>{renderSpots}</div>
    </div>
  )
}

export default withRouter(NavBar)
