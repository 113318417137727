import { applyEmaPriceToAll } from 'Actions/priceActions'
import GhostPrice from 'Components/ScanPage/GhostPrice'
import PriceHeader from 'Components/ScanPage/PriceHeader'
import styles from 'Components/ScanPage/styles.module.scss'
import Tag from 'Components/Tag/Tag.tsx'
import Price from 'Containers/Price'
import TitleDetail from 'Containers/TitleDetail'
import {
  getAuditActions,
  getClient,
  getRootPath,
  getSelectedScan,
  getTitleAuditById,
  makeGetActionCountForTitleAudit,
} from 'Selectors/index'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, Route, withRouter } from 'react-router-dom'
import documentMissing from '../assets/images/document-missing.png'

class TitleAuditRow extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      titleDetailOpen: false,
    }
  }

  actionCountText() {
    return `${this.actionCount()} issues`
  }

  actionCount() {
    const { actions, client } = this.props
    let actionCount = 0

    if (!client.uberActions) {
      actionCount = actions.filter(action => action.name !== 'Uber Image').size
    } else {
      actionCount = actions.size
    }

    return actionCount
  }

  onClose = () => {
    const { history, rootPath } = this.props;
    history.push(rootPath);
  };

  render() {
    const {
      scan,
      title,
      platforms,
      titleAudit,
      ghostCells,
      rootPath,
      client,
      actions,
      competitors,
      autobotEnabled,
      gridContainer,
      isPreorder,
    } = this.props
   
    const { primaryArtworkUrl } = titleAudit && titleAudit
    const url = primaryArtworkUrl || documentMissing
    const titleDetailLink = `${rootPath}/title/${title && title.id}`
    const featuredTag = scan.enableMerchandizing && titleAudit.promoted

    const renderPrice = () => {
      return (
        <div>
          {platforms?.valueSeq().map(platform => (
            <Price
              key={`${titleAudit.id}-${platform.id}`}
              titleAuditId={titleAudit.id}
              platformId={platform.id}
              competitors={competitors}
              titleAuditActions={actions}
              autobotEnabled={autobotEnabled}
              gridContainer={gridContainer}
              client={client}
            />
          ))}
        </div>
      )
    }

    return (
      <div className={cx(styles.titleAuditRowPosition, 'title-audit-row')}>
        <div className={styles.row}>
          <Link
            to={titleDetailLink}
            onClick={e => {
              if (e.target && !e.currentTarget.contains(e.target)) {
                e.preventDefault()
              }
            }}
            className={cx(styles.card)}
          >
            <div className={styles.artwork} style={{ backgroundImage: `url('${url}')` }} />
            <div>
              <div className={styles.textContainer}>
                {title && <h6 className={styles.title}>{title.name}</h6>}
                {title && titleAudit && (
                  <Route
                    path={titleDetailLink}
                    render={props => (
                      <TitleDetail {...props} onClose={this.onClose} title={title} titleAudit={titleAudit} competitors={competitors} />
                    )}
                  />
                )}
                {titleAudit && actions && this.actionCount() > 0 && (
                  <div className={styles.auditCount}>{this.actionCountText()}</div>
                )}
                {title && <div className={styles.titleType}>{title.titleType}</div>}
              </div>
              {client && client.tagsEnabled && titleAudit && (
                <Tag featuredTag={featuredTag} isPreorder={isPreorder} titleAudit={titleAudit} />
              )}
            </div>
          </Link>

          <PriceHeader client={client} />

          {renderPrice()}

          {new Array(Math.max(0, ghostCells)).fill(0).map((_i, index) => (
            <GhostPrice client={client} key={`ghost${index}`} />
          ))}
        </div>
      </div>
    )
  }
}

TitleAuditRow.propTypes = {
  titleAuditId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const makeMapStateToProps = () => {
  const getActionCountForTitleAudit = makeGetActionCountForTitleAudit()

  const mapStateToProps = (state, ownProps) => {
    const scan = getSelectedScan(state)
    const scanPlatforms = scan.platforms(state)
    const titleAudit = getTitleAuditById(state, { id: ownProps.titleAuditId })
    const audit = titleAudit && scanPlatforms.first() && titleAudit.getAuditForPlatform(state, scanPlatforms.first().id)
    const actionCount = (titleAudit && getActionCountForTitleAudit(state, { titleAudit })) || 0
    const title = titleAudit && titleAudit.getTitle(state)
    const client = getClient(state)

    const actions = getAuditActions(state).filter(auditAction => auditAction.titleAuditId === titleAudit?.id)

    const platformTitle = audit && audit.platformTitle(state)
    const prices = platformTitle && platformTitle.prices

    const isPreorder = scanPlatforms.some(platform => {
      const platformAudit = platform && titleAudit?.getAuditForPlatform(state, platform.id)
      const auditPlatformTitle = platformAudit?.platformTitle(state)

      if (auditPlatformTitle?.isPreorder) return true

      const auditPrices = platformAudit?.prices(state)
      return auditPrices?.find(p => p?.isPreorder && !p?.nullPrice)
    })

    return {
      titleAudit,
      title,
      actionCount,
      rootPath: getRootPath(state),
      scan,
      client,
      actions,
      prices,
      isPreorder,
    }
  }
  return mapStateToProps
}

const mapDispatchToProps = dispatch => ({
  applyEmaPriceToAll: (price, actions, title) => dispatch(applyEmaPriceToAll(price, actions, title)),
})

const ConnectedTitleAuditRow = connect(makeMapStateToProps, mapDispatchToProps)(TitleAuditRow)
const ConnectedTitleAuditRowWithRouter = withRouter(ConnectedTitleAuditRow)
export default ConnectedTitleAuditRowWithRouter
