import { Record } from 'immutable'

interface Region {
  id: number
  name: string
}

interface Platform {
  id: number
  name: string
  code: string
  region: Region
}

export interface PlatformStatistics {
  platform: Platform
  totalMpv: string
  totalSpots: number
  totalStudioSpots: number
  totalShareOfVoice: string
}

export const defaultPlatformStatistics: PlatformStatistics = {
  platform: {
    id: 0,
    name: '',
    code: '',
    region: {
      id: 0,
      name: '',
    },
  },
  totalMpv: '0',
  totalSpots: 0,
  totalStudioSpots: 0,
  totalShareOfVoice: '0',
}

export default class PlatformStatisticsRecord extends Record(defaultPlatformStatistics) {
  get<T extends keyof PlatformStatistics>(value: T): PlatformStatistics[T] {
    return super.get(value)
  }
}
