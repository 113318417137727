import React from 'react'
import { Icon } from '@blueprintjs/core'
import style from './styles.module.scss'

const ArtworkNotFound = () => {
  return (
    <div className={style.artworkNotFoundWrapper}>
      <Icon icon='search' className={style.icon} />
      <h1>No Artwork Found</h1>
      <p>
        No results for your currently selected filters.
        <br />
        Try searching for something else, or adjust your filters.
      </p>
    </div>
  )
}

export default ArtworkNotFound
